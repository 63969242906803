import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';
import { CheckboxPillGroup } from '../../../../../../../components/CheckboxPillGroup';
import { CheckboxPill } from '../../../../../../../components/CheckboxPill';

interface PropTypes {
  currency?: string;
  salary?: number;
  setCurrency: (currency: string) => void;
  setSalary: (salary: number) => void;
}

export function SalaryInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={'Salary'} isRequired={true} />
      <div className='d-flex flex-nowrap gap-3'>
        <Input
          type='number'
          className='fs-5 w-40'
          placeholder='(e.g. 50000)'
          defaultValue={props.salary}
          onChange={(event) => {
            props.setSalary(event.target.value as unknown as number);

            if (props.currency == null && event.target.value != null) {
              props.setCurrency('GBP');
            }
          }}
        />
        <CheckboxPillGroup
          selectedValue={props.currency}
          onChange={(ev) => props.setCurrency(ev.currentTarget.value)}
          resetValue={() => props.setCurrency(null)}
        >
          {['GBP', 'EUR', 'USD', 'JPY'].map((currency: string) => (
            <CheckboxPill
              key={currency}
              id={currency}
              label={currency}
              value={currency}
            />
          ))}
        </CheckboxPillGroup>
      </div>
    </>
  );
}
