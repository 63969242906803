import React, { useState } from 'react';
import { Dropdown } from '../../../../../components/Dropdown';
import { IconSpan } from '../../../../../components/IconSpan';
import { JobHiringMemberRole } from '../../../../../entities/JobHiringMemberRole';
import { User } from '../../../../../entities/User';
import { buildSchedulingActionInfo } from '../../../../../utils/applicant_tracking/actions/schedulingActions';
import { PropTypes as ActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { ScheduleInterviewModal } from '../../../../../components/ApplicantTracking/ScheduleInterviewModal';
import { ApplicationActionInfo } from '../../../../../utils/applicant_tracking/actions/applicationActions';
import {
  PipelineApplication,
  PipelineApplicationResume,
} from '../../../../../entities/applicant_tracking/PipelineApplication';
import { renderPushToHris } from '../../../../CandidatePage/CandidateActions/DropdownActions';
import { PushToHrisModal } from '../../../../CandidatePage/CandidateActions/PushToHrisModal';
import { AlertObject } from '../../../../../components/Alert';
import { SendOfferModal } from '../../../../applicant_tracking/Offers/SendOfferModal';
import { RedactedResumeService } from '../../../../../services/v1/applicant_tracking/RedactedResumeService';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';

interface PropTypes {
  authorizeUrl: string;
  application: PipelineApplication;
  currentUser: User;
  jobHiringMemberRole?: JobHiringMemberRole;
  moveToStageActionInfo: ApplicationActionInfo;
  profileLink: string;
  offerEnabled: boolean;
  moveCandidateToStage: () => void;
  reloadJobStages: () => Promise<void>;
  setAlert: (alert: AlertObject) => void;
}

function viewCandidate(link: string) {
  return {
    action: () => (location.href = link),
    buttonChild: (
      <IconSpan
        spanText={'View Candidate'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function moveToStage(moveCandidateToStage: () => void) {
  return {
    action: moveCandidateToStage,
    buttonChild: (
      <IconSpan
        spanText={'Move to Stage'}
        icon={{ name: 'bi-arrow-return-right', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function scheduleInterview(
  setIsScheduleInterviewModalOpen: (isOpen: boolean) => void,
) {
  return {
    action: () => setIsScheduleInterviewModalOpen(true),
    buttonChild: (
      <IconSpan
        spanText={'Schedule Interview'}
        icon={{ name: 'bi-calendar4-week', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function viewCV(
  setIsLoading: (isLoading: boolean) => void,
  redacted: boolean,
  resume: PipelineApplicationResume,
) {
  function handleClick() {
    setIsLoading(true);

    RedactedResumeService.show(resume.id).then((resume) => {
      window.open(resume.url, '_blank');
      setIsLoading(false);
    });
  }

  return {
    action: () =>
      redacted ? handleClick() : window.open(resume.url, '_blank'),
    buttonChild: (
      <IconSpan
        spanText={'View CV'}
        icon={{ name: 'bi-box-arrow-up-right', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function viewInterviews(profileLink: string) {
  return {
    action: () => (location.href = profileLink + '&tab=interviews'),
    buttonChild: (
      <IconSpan
        spanText={'View Interviews'}
        icon={{ name: 'bi-collection-play', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function sendOffer(setIsOfferModalOpen: (isOpen: boolean) => void) {
  return {
    action: () => setIsOfferModalOpen(true),
    buttonChild: (
      <IconSpan
        spanText={'Send Offer'}
        icon={{ name: 'bi-file-earmark-medical', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function buildMenuActions(
  props: PropTypes,
  setIsLoading: (b: boolean) => void,
  setIsScheduleInterviewModalOpen: (b: boolean) => void,
  setIsPushToHrisModalOpen: (b: boolean) => void,
  setIsOfferModalOpen: (b: boolean) => void,
): ActionPropTypes[] {
  const menuActions: ActionPropTypes[] = [viewCandidate(props.profileLink)];

  if (!props.application.redacted) {
    menuActions.push(viewInterviews(props.profileLink));
  }

  if (
    props.moveToStageActionInfo.allowed &&
    !props.moveToStageActionInfo.disabled
  ) {
    menuActions.push(moveToStage(props.moveCandidateToStage));
  }

  if (props.application.resume != null) {
    menuActions.unshift(
      viewCV(
        setIsLoading,
        props.application.redacted,
        props.application.resume,
      ),
    );
  }

  const schedulingPermission = buildSchedulingActionInfo(
    props.application,
    props.currentUser,
    props.jobHiringMemberRole,
  );

  if (
    schedulingPermission.allowed &&
    !schedulingPermission.disabled &&
    !props.application.redacted
  ) {
    menuActions.push(scheduleInterview(setIsScheduleInterviewModalOpen));
  }

  const pushToHrisAction = renderPushToHris(
    () => setIsPushToHrisModalOpen(true),
    props.application.redacted,
    props.application.status,
    props.application.hrisEmployee?.externalUrl,
    props.application.allowedHrisProvider,
  );
  if (pushToHrisAction) {
    menuActions.push(pushToHrisAction);
  }

  if (
    props.offerEnabled &&
    props.application.sendOfferPermitted == true &&
    !props.application.redacted
  ) {
    menuActions.push(sendOffer(setIsOfferModalOpen));
  }

  return menuActions;
}

export function DropdownCandidate(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPushToHrisModalOpen, setIsPushToHrisModalOpen] =
    useState<boolean>(false);

  const [isScheduleInterviewModalOpen, setIsScheduleInterviewModalOpen] =
    useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);

  const menuActions = buildMenuActions(
    props,
    setIsLoading,
    setIsScheduleInterviewModalOpen,
    setIsPushToHrisModalOpen,
    setIsOfferModalOpen,
  );

  const handlePushToHrisModalClose = async () => {
    await props.reloadJobStages();
    setIsPushToHrisModalOpen(false);
  };

  const handleOfferModalClose = async () => {
    setIsOfferModalOpen(false);
  };

  if (isLoading) {
    return (
      <LoadingSpinner
        showBackdrop
        message='Redacting candidate data from the CV...'
      />
    );
  }

  return (
    <>
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'ms-2 fs-5 text-blue',
        }}
        menuActions={menuActions}
      />
      <ScheduleInterviewModal
        isOpen={isScheduleInterviewModalOpen}
        applicationId={props.application.id}
        interviewStageId={props.application.nextInterviewStageId}
        onClose={() => setIsScheduleInterviewModalOpen(false)}
      />
      <PushToHrisModal
        applicationIds={[props.application.id]}
        allowedHrisProvider={props.application.allowedHrisProvider}
        isOpen={isPushToHrisModalOpen}
        onClose={handlePushToHrisModalClose}
        setAlert={props.setAlert}
        candidateName={props.application.candidate.name}
        jobName={props.application.job.name}
      />
      <SendOfferModal
        applicationId={props.application.id}
        authorizeUrl={props.authorizeUrl}
        hasEmailConnected={props.currentUser.hasEmailConnected}
        isOpen={isOfferModalOpen}
        to={props.application.candidate.email}
        onClose={handleOfferModalClose}
        setAlert={props.setAlert}
      />
    </>
  );
}
