import React from 'react';
import { PropTypes } from '../AttachmentInputBody';
import { Path } from 'react-hook-form';
import { Button } from 'reactstrap';
import { Icon } from '../../Icon';

export function ResumeParserInputBody<E>(props: PropTypes<E>) {
  return (
    <div className='d-flex align-items-center gap-2 bg-light-info p-3 rounded border'>
      <input
        type='hidden'
        {...props.formRegister(props.controllerContentName as Path<E>, {
          required: props.isRequired,
        })}
        disabled={props.disabled}
      />
      <Icon name={'bi-file-earmark-arrow-up'} />
      <div className='fw-semibold fs-5'>Autofill with Resume</div>
      {props.value && (
        <div className='fw-normal fs-5 text-muted'>{props.value}</div>
      )}
      <div className='ms-auto'>
        <Button
          size='sm'
          color='primary'
          disabled={props.disabled}
          onClick={() => props.openFileSelector()}
        >
          Browse File
        </Button>
      </div>
    </div>
  );
}
