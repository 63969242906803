export const AVAILABLE_WORKING_MODELS = [
  { id: 'Onsite', label: 'Onsite', value: 'onsite' },
  { id: 'Remote', label: 'Remote', value: 'remote' },
  { id: 'Hybrid', label: 'Hybrid', value: 'hybrid' },
];

export const AVAILABLE_CURRENCIES = [
  { id: 'gbp', label: 'GBP', value: 'gbp' },
  { id: 'eur', label: 'EUR', value: 'eur' },
  { id: 'usd', label: 'USD', value: 'usd' },
  { id: 'jpy', label: 'JPY', value: 'jpy' },
];

export const AVAILABLE_EMPLOYMENT_TYPES = [
  { id: 'Full-time', label: 'Full-time', value: 'full_time' },
  { id: 'Part-time', label: 'Part-time', value: 'part_time' },
  { id: 'Contractor', label: 'Contractor', value: 'contractor' },
];
