import React from 'react';
import { Application } from '../../../entities/Application';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { Chip } from '../../../components/Chip';
import { CandidatePanel } from './CandidatePanel';
import { JobApplicationsPanel } from './JobApplicationsPanel';
import { LatestActivityPanel } from './LatestActivityPanel';
import { PendingActionsPanel } from './PendingActionsPanel';
import { User } from '../../../entities/User';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { ApplicationQuestionsPanel } from './ApplicationQuestionsPanel';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import classNames from 'classnames';
import { Card } from '../../../components/Card';
import { AlertObject } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';

interface PropTypes {
  application: Application;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobHiringMemberRole?: JobHiringMemberRole;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
  applicationStylesPath: string;
  setIsLoading: (v: boolean) => void;
}

function StageChip(props: {
  label: string;
  chipClass: string;
  value: JSX.Element;
}) {
  return (
    <div className='d-flex flex-wrap gap-2 align-items-center'>
      <span className='fs-5'>{props.label}</span>
      <Chip className={classNames('text-primary py-2', props.chipClass)}>
        <h5 className='mb-0'>{props.value}</h5>
      </Chip>
    </div>
  );
}

function StagePanel(props: { application: Application }) {
  const daysInProcess = moment().diff(
    moment(props.application.appliedAt),
    'days',
  );

  return (
    <Card>
      <div className='d-flex justify-content-between'>
        <StageChip
          label='Current Stage'
          chipClass='bg-light-danger'
          value={<>{props.application.job_stage_name}</>}
        />
        <StageChip
          label='Time on stage'
          chipClass='bg-light-info'
          value={<>{props.application.daysInJobStage} days</>}
        />
        <StageChip
          label='Time in process'
          chipClass='bg-light-info'
          value={<>{daysInProcess} days</>}
        />
      </div>
    </Card>
  );
}

export function ProfileTab(props: PropTypes) {
  return (
    <div>
      <div className='d-flex align-items-center mb-3'>
        <div className='d-flex gap-3 align-items-center me-auto'>
          <CandidatePageSubHeader text='Profile' />
          <div className='ms-3 fs-5 fw-normal text-muted'>
            Applied at{' '}
            {moment(props.application.appliedAt).format('MMM DD, YYYY')}
          </div>
        </div>
        <div className='fs-5 text-muted'>
          Updated at{' '}
          {moment(props.application.lastInteractionAt).format(
            'MMMM DD, YYYY, hh:mm A',
          )}
        </div>
      </div>
      <Row className='flex-nowrap mx-0'>
        <Col xs='3'>
          <Row>
            <CandidatePanel
              application={props.application}
              setIsLoading={props.setIsLoading}
            />
          </Row>
        </Col>
        <Col xs='6' className='px-5h'>
          <Row className='mb-4'>
            <StagePanel application={props.application} />
          </Row>
          <ApplicationQuestionsPanel application={props.application} />
          <Row>
            <LatestActivityPanel
              application={props.application}
              currentUser={props.currentUser}
              setAlert={props.setAlert}
              reloadApplication={props.reloadApplication}
              emailAccountIntegration={props.emailAccountIntegration}
              applicationStylesPath={props.applicationStylesPath}
            />
          </Row>
        </Col>
        <Col xs='3'>
          <>
            <Row>
              <PendingActionsPanel
                application={props.application}
                currentUser={props.currentUser}
                jobHiringMemberRole={props.jobHiringMemberRole}
              />
            </Row>
            <Row>
              {props.application.candidate.applications?.length > 1 && (
                <JobApplicationsPanel application={props.application} />
              )}
            </Row>
          </>
        </Col>
      </Row>
    </div>
  );
}
