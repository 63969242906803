import React from 'react';
import { Header } from './Header';
import { User } from '../../../../entities/User';
import { getQueryParam } from '../../../../utils/url';
import { Body } from './Body';

interface PropTypes {
  applicationId: number;
  currentUser: User;
  viewAllScorecards: boolean;
}

export default function ListScorecardsPage(props: PropTypes) {
  const ownScorecardsParam: boolean =
    getQueryParam('own_scorecards')?.toLowerCase() === 'true';
  const statusParam: string = getQueryParam('status');

  return (
    <div>
      <Header
        applicationId={props.applicationId}
        currentUser={props.currentUser}
      />
      <Body
        currentUser={props.currentUser}
        applicationId={props.applicationId}
        viewAllScorecards={props.viewAllScorecards}
        ownScorecardsParam={ownScorecardsParam}
        statusParam={statusParam}
      />
    </div>
  );
}
