import React, { useState } from 'react';
import { Application } from '../../../entities/Application';
import { User } from '../../../entities/User';
import { PreviousCandidateActions } from './PreviousCandidateActions';
import { DropdownActions } from './DropdownActions';
import { MoveCandidateToStageButton } from './MoveCandidateToStageButton';
import { ApplicationSelection } from '../ApplicationSelection';
import { CandidateTab } from '../CandidateTab';
import { SpanTooltip } from '../../../components/SpanTooltip';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { getApplicationActionInfo } from '../../../utils/applicant_tracking/actions/applicationActions';
import { ScheduleInterviewAction } from './ScheduleInterviewAction';
import { ActionButton } from './ActionButton';
import { SendEmailModal } from './SendEmailModal';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { AlertObject } from '../../../components/Alert';

interface PropTypes {
  application: Application;
  applicantTrackingEnabled: boolean;
  currentTab: CandidateTab;
  interviewIntelligenceEnabled: boolean;
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  emailAccountIntegration: AccountIntegration;
  newApplicationPermitted: boolean;
  sendOfferPermitted: boolean;
  onMoveCandidateStage: (status: string) => void;
  setAlert: (alert: AlertObject) => void;
  setOpenSharePulseSurveyModal: (flag: boolean) => void;
  reloadApplication: () => void;
}

export function CandidateActions(props: PropTypes) {
  if (!props.applicantTrackingEnabled) {
    return <PreviousCandidateActions {...props} />;
  }

  const baseApplicationActionInfo = getApplicationActionInfo(
    props.application,
    props.currentUser.isAdmin,
    props.jobHiringMemberRole,
    'Move to Stage',
  );

  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);

  const handleClose = (succeeded: boolean) => {
    if (succeeded) {
      props.reloadApplication();
    }
  };

  const handleEmailModalClose = (succeeded: boolean) => {
    setIsEmailModalOpen(false);
    handleClose(succeeded);
  };

  return (
    <div className='d-flex gap-2 text-end'>
      {props.application.candidate.applications?.length > 1 && (
        <ApplicationSelection
          currentTab={props.currentTab}
          application={props.application}
        />
      )}
      {baseApplicationActionInfo.allowed && !props.application.redacted && (
        <SpanTooltip
          text={
            <ActionButton
              iconName={'bi-pencil'}
              onClick={() =>
                (window.location.href = `/applicant_tracking/applications/${props.application.id}/edit`)
              }
            />
          }
          tooltipText='Edit Job Application'
        />
      )}
      <ScheduleInterviewAction
        application={props.application}
        currentUser={props.currentUser}
        jobHiringMemberRole={props.jobHiringMemberRole}
      />
      {baseApplicationActionInfo.allowed && (
        <SpanTooltip
          text={
            <MoveCandidateToStageButton
              application={props.application}
              disabled={baseApplicationActionInfo.disabled}
              onMoveCandidateStage={props.onMoveCandidateStage}
              reloadApplication={props.reloadApplication}
              setAlert={props.setAlert}
            />
          }
          tooltipText={baseApplicationActionInfo.tooltipText}
          trigger='hover'
        />
      )}
      {props.emailAccountIntegration.enabled && !props.application.redacted && (
        <SpanTooltip
          text={
            <ActionButton
              iconName={'bi-envelope'}
              onClick={() => {
                setIsEmailModalOpen(true);
              }}
              disabled={false}
            />
          }
          tooltipText='Send Email'
        />
      )}
      {isEmailModalOpen && (
        <SendEmailModal
          authorizeUrl={props.emailAccountIntegration.authorizeUrl}
          hasEmailConnected={props.currentUser.hasEmailConnected}
          applicationIds={[props.application.id]}
          toEmails={[props.application.candidate.email]}
          isOpen={isEmailModalOpen}
          onClose={handleEmailModalClose}
          setAlert={props.setAlert}
        />
      )}
      <DropdownActions
        application={props.application}
        authorizeUrl={props.emailAccountIntegration.authorizeUrl}
        disabled={
          !baseApplicationActionInfo.allowed ||
          baseApplicationActionInfo.disabled
        }
        user={props.currentUser}
        sendEmailEnabled={props.emailAccountIntegration.enabled}
        newApplicationPermitted={props.newApplicationPermitted}
        sendOfferPermitted={props.sendOfferPermitted}
        reloadApplication={props.reloadApplication}
        onClose={handleClose}
        onShareCNPSSurvey={props.setOpenSharePulseSurveyModal}
        setAlert={props.setAlert}
        setIsEmailModalOpen={setIsEmailModalOpen}
      />
    </div>
  );
}
