import classNames from 'classnames';
import React from 'react';
import { Chip } from '../Chip';

export type IconChipPosition = 'left' | 'right';

export function IconChip(props: {
  className: string;
  children: JSX.Element | string | number;
  iconPosition?: IconChipPosition;
  iconClass?: string;
}) {
  return (
    <Chip className={props.className}>
      <>
        {props.iconPosition === 'left' && (
          <i className={classNames('me-2', props.iconClass)} />
        )}
        {props.children}
        {props.iconPosition === 'right' && (
          <i className={classNames('ms-2', props.iconClass)} />
        )}
      </>
    </Chip>
  );
}
