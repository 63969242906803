import { StylesConfig } from 'react-select';
import stylesheetExports from '../../stylesheets/export.module.scss';
import { Size } from '../../utils/types/Size';

function optionColor(state) {
  if (state.isDisabled) return stylesheetExports.dark200;

  if (state.isFocused || state.isSelected) return stylesheetExports.blue;

  return stylesheetExports.dark500;
}

function optionBackgroundColor(state) {
  if (state.isSelected && !state.isMulti) return stylesheetExports.blue;

  if (state.isFocused) return stylesheetExports.blueLight;

  return stylesheetExports.white;
}

export const selectStyle = (size: Size): StylesConfig => {
  return {
    clearIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? stylesheetExports.blue
        : stylesheetExports.dark500,
      '&:hover': {
        color: state.isFocused
          ? stylesheetExports.blue
          : stylesheetExports.gray900,
      },
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled
        ? stylesheetExports.gray
        : stylesheetExports.white,
      borderColor: state.isDisabled
        ? stylesheetExports.gray300
        : stylesheetExports.blueGray500,
      boxShadow: 'none',
      alignContent: 'center',
      height:
        size === 'sm' ? stylesheetExports.heightSm : stylesheetExports.heightMd,
      minHeight:
        size === 'sm' ? stylesheetExports.heightSm : stylesheetExports.heightMd,
      padding: size === 'sm' ? '4px 8px' : '8px 12px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? stylesheetExports.blue
        : stylesheetExports.dark500,
      '&:hover': {
        color: state.isFocused
          ? stylesheetExports.blue
          : stylesheetExports.gray900,
      },
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%',
      alignSelf: 'auto',
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'normal',
      textOverflow: 'ellipsis',
      '&:hover': {
        color: optionColor(state),
        backgroundColor: stylesheetExports.blueLight,
      },
      color: state.isDisabled
        ? stylesheetExports.dark200
        : state.isMulti
        ? stylesheetExports.dark500
        : base.color,
      backgroundColor: optionBackgroundColor(state),
    }),
    placeholder: (base) => ({
      ...base,
      color: stylesheetExports.dark200,
      fontWeight: stylesheetExports.fontWeightRegular,
    }),
    singleValue: (base, state) => ({
      ...base,
      margin: 0,
      color: state.isDisabled
        ? stylesheetExports.gray700
        : stylesheetExports.dark500,
    }),
    valueContainer: (base) => ({
      ...base,
      alignSelf: 'center',
      padding: '0px',
      margin: 0,
      gap: '4px',
    }),
    menuList: (base) => ({
      ...base,
      color: stylesheetExports.dark500,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 102, // when opened needs to be be above all components, except modals and alerts.
    }),
  };
};
