import { defaultTheme } from '../themes/defaultTheme';
import { HeadingNode } from '@lexical/rich-text';
import { ListNode, ListItemNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { TextNode } from 'lexical';
import { VariableNode } from '../nodes/VariableNode';

export const editorConfig = {
  namespace: 'ScreenloopTextEditor',
  theme: defaultTheme,
  onError(error) {
    throw error;
  },
  nodes: [
    AutoLinkNode,
    HeadingNode,
    LinkNode,
    ListNode,
    ListItemNode,
    TextNode,
    VariableNode,
  ],
};
