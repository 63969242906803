import React from 'react';
import JobRequisitionCard from '../index';
import { CardEntry } from '../CardEntry';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { shortFormatDateMonthYear } from '../../../../../../utils/timeFormat';
import { IconLink } from '../../../../../../components/IconLink';
import { TextPanel } from '../../../../../../components/TextPanel';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { humanize } from '../../../../../../utils/humanize';
import scrollbarStyles from '../styles.module.scss';

interface PropTypes {
  jobRequisition: JobRequisition;
}

function Description(props: { description?: string }) {
  const description = props.description ? (
    props.description
  ) : (
    <div
      className={
        'h-100 d-flex flex-column align-items-center justify-content-center'
      }
    >
      <h5 className={'fw-bold'}>No requisition description</h5>
      <p>The requisition owner provided no description.</p>
    </div>
  );

  return (
    <div>
      <span className='me-auto fs-5'>Description</span>
      <TextPanel
        text={description}
        className={classNames(
          'mt-2',
          'overflow-scroll',
          styles['description-text-panel'],
          scrollbarStyles['hide-scrollbar'],
        )}
      />
    </div>
  );
}

export default function RequisitionInfoCard(props: PropTypes) {
  return (
    <JobRequisitionCard title={'Requisition'}>
      <CardEntry
        label={'Requisition ID'}
        value={props.jobRequisition.uid}
        className={'mt-3'}
        copy={true}
      />
      <CardEntry
        label={'Requisition Owner'}
        value={props.jobRequisition.ownerUser.name}
      />
      <hr className={'my-1'} />
      {props.jobRequisition.plannedStartDate && (
        <CardEntry
          label={'Planned Start'}
          value={shortFormatDateMonthYear(
            props.jobRequisition.plannedStartDate,
          )}
        />
      )}
      <CardEntry
        label={'Reason'}
        value={humanize(props.jobRequisition.reason)}
      />
      <hr className={'my-1'} />
      {props.jobRequisition.attachmentUrl?.length > 0 && (
        <>
          <IconLink
            text={'View Attachment'}
            className={'fs-5'}
            iconClassName={'bi-box-arrow-up-right text-blue'}
            textClassName={'text-blue'}
            downloadUrl={props.jobRequisition.attachmentUrl}
          />
          <hr className={'my-1'} />
        </>
      )}
      <Description description={props.jobRequisition.description} />
    </JobRequisitionCard>
  );
}
