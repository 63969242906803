import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Icon } from '../../../Icon';

interface PropTypes {
  classNames?: string;
  onAddStage: () => void;
}

export function AddStageContainer(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center align-items-center h-100',
        styles.container,
        props.classNames,
      )}
      onClick={props.onAddStage}
    >
      <Icon name='bi-plus-circle' className='text-info fs-1' />
      <span className='fw-semibold fs-3 text-info mt-3'>Add Stage</span>
    </div>
  );
}
