import React from 'react';
import { PropTypes as ActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { IconSpan } from '../../../../../components/IconSpan';
import { Dropdown } from '../../../../../components/Dropdown';

interface PropTypes {
  id: number;
  editUrl: string;
  viewUrl: string;
}

function view(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = props.viewUrl),
    buttonChild: (
      <IconSpan
        spanText='View'
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className='text-blue'
      />
    ),
  };
}

function editAction(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = props.editUrl),
    buttonChild: (
      <IconSpan
        spanText='Edit'
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className='text-blue'
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const menuActions = [view(props), editAction(props)];

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass='dropdown-menu-end'
      menuActions={menuActions}
    />
  );
}
