import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SetupHeaderButtonType,
  SetupPageHeader,
} from '../../../../../../components/SetupPageHeader';
import { replaceFinalSegment } from '../../../../../../utils/url';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { useStateMachine } from 'little-state-machine';
import { FormState } from 'react-hook-form';
import { JobDetails } from '../../../../../../services/applicant_tracking/JobService/EditJobDto';

interface PropTypes {
  formState: FormState<CreateJobDto>;
  jobDetails: JobDetails;
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  onCancel: () => void;
  onNavigate: () => void;
  isEditFlow: boolean;
}

export function JobPostPageHeader(props: PropTypes) {
  const navigate = useNavigate();
  const { state } = useStateMachine();
  const firstItems = [
    {
      title: 'Previous',
      type: 'Navigate',
      action: () => {
        props.onNavigate();
        props.setSetupStep(props.setupStep - 1, state.setupJob);
        navigate(replaceFinalSegment('job_details'));
      },
      disabled: !props.formState.isValid,
    },
  ] as SetupHeaderButtonType[];

  const nextButton = {
    title: 'Next',
    type: 'Navigate',
    action: () => {
      props.onNavigate();
      props.setSetupStep(props.setupStep + 1, state.setupJob);
      navigate(replaceFinalSegment('application_form'));
    },
    disabled: !props.formState.isValid,
  } as SetupHeaderButtonType;

  const saveButtonText =
    props.isEditFlow && props.jobDetails?.status === 'published'
      ? 'Save and Publish'
      : 'Save and Continue';

  if (props.isEditFlow) {
    firstItems.push(nextButton);
  }

  return (
    <SetupPageHeader
      title='Job Post'
      firstItems={firstItems}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: saveButtonText,
          type: 'Save',
          disabled: !props.formState.isValid,
        },
      ]}
    />
  );
}
