import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { LabelledSelectFormHook } from '../../../../../../../components/LabelledSelectFormHook';
import { UserService } from '../../../../../../../services/v1/UserService';
import { UserList } from '../../../../../../../entities/v1/User';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
}

export function RequisitionOwner(props: PropTypes) {
  const required = true;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    UserService.list({ pageSize: 1000 }).then((response: UserList) => {
      setOptions(
        response.users.map((user) => ({
          label: user.name,
          value: user.id.toString(),
        })),
      );
    });
  }, []);

  return (
    <Col xs={3}>
      <LabelledSelectFormHook<JobRequisitionDto>
        label='Requisition Owner'
        placeholder='Select owner'
        formHookContext={{
          controllerName: 'ownerUserId',
          formControl: props.formControl,
          required: required,
        }}
        options={options}
      />
    </Col>
  );
}
