import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Chip } from '../../../../../components/Chip';

interface PropTypes {
  categoryCount: number;
  totalResponses: number;
  category: string;
}

export function ResponsePercentage(props: PropTypes) {
  if (props.totalResponses <= 0) {
    return null;
  }
  return (
    <Row
      className={classNames(
        'fs-6',
        'fw-normal',
        'text-dark-primary',
        'gx-0',
        'mb-1',
        'align-items-center',
      )}
    >
      <Col sm={3} className={classNames('fw-bold')}>
        <Chip
          className={classNames(
            'bg-light-info fw-semibold text-primary fs-6 px-2h py-1',
          )}
        >
          {Math.round(100 * (props.categoryCount / props.totalResponses))}%
        </Chip>
      </Col>
      <Col sm={2} />
      <Col sm={7}>{`${props.category}`}</Col>
    </Row>
  );
}
