import React from 'react';
import prospectImage from '../../../../../images/mailer-survey-request-new-prospect.png';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Button } from 'reactstrap';

interface PropTypes {
  url: string;
  onClose: (succeeded?: boolean) => void;
}

export function ConnectEmailIntegrationModal(props: PropTypes) {
  const handleConnectEmail = () => {
    window.location.href = props.url;
  };

  return (
    <div className='d-flex flex-column align-items-center'>
      <h1>You need to connect your email</h1>
      <img
        src={prospectImage}
        alt='Screenloop'
        className={classNames(styles['main-image'], 'my-6h')}
      />
      <div className='row justify-content-center text-center'>
        <span>
          To be able to send emails through Screenloop, click the button <br />
          below and connect your email.
        </span>
      </div>
      <div className='d-flex justify-content-center text-center mt-4 gap-3'>
        <Button color={'secondary'} onClick={() => props.onClose()}>
          Close
        </Button>
        <Button color={'primary'} onClick={handleConnectEmail}>
          Connect Email
        </Button>
      </div>
    </div>
  );
}
