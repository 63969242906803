import { useEffect, useReducer, useState } from 'react';
import {
  Action,
  initSections,
  reducer,
} from '../../../survey_engine/SurveyTemplates/dispatcher';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import React from 'react';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { FIELD_CONFIGS, fetchTemplate, areSectionsSavable } from '../data';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { InterviewKitTemplateService } from '../../../../services/v1/applicant_tracking/InterviewKitTemplateService';

interface PropTypes {
  id: number;
}

export default function EditInterviewKitTemplatePage(props: PropTypes) {
  const [name, setName] = useState<string>('');
  const [sections, dispatch] = useReducer(reducer, null, initSections);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function load() {
      const template = await fetchTemplate(props.id.toString());
      setName(template.name);
      dispatch({ type: 'reset', newState: template.sections });
      setIsLoading(false);
    }

    load();
  }, [props.id]);

  function isSavable() {
    if (!name.trim()) return false;
    if (!areSectionsSavable(sections)) return false;

    return true;
  }

  async function handleSave() {
    setIsLoading(true);

    await InterviewKitTemplateService.update(props.id, {
      name,
      sections: sections.map((section) => ({
        id: section.sId,
        name: section.name,
        questions: section.fields.map((field) => ({
          id: field.serverId,
          title: field.title,
          fieldType: field.fieldType,
          max: field.max,
          aiFillEnabled: field.aiFillEnabled,
        })),
      })),
    });

    window.location.href =
      getRedirectUriQueryParam() ??
      '/organization_settings/interview_questions';
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <Body
        isSavable={isSavable()}
        name={name}
        onName={setName}
        sections={sections}
        dispatch={dispatch}
        onSave={handleSave}
      />
    </>
  );
}

function Body(props: {
  isSavable: boolean;
  name: string;
  onName: (value: string) => void;
  sections: SectionData[];
  dispatch: React.Dispatch<Action>;
  onSave: () => void;
}) {
  return (
    <div className='container'>
      <PageHeader
        title={props.name}
        backUrl='/organization_settings/interview_questions'
      />
      <Card>
        <CardHeader
          title='Edit Interview Questions'
          isSavable={props.isSavable}
          onSaveClick={props.onSave}
          cancelUrl='/organization_settings/interview_questions'
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput
              name={props.name}
              placeholder='Insert interview questions name'
              onName={props.onName}
            />
          </div>
        </div>
        <SectionList
          fieldConfigs={FIELD_CONFIGS}
          singleTypeSections={true}
          sections={props.sections}
          dispatch={props.dispatch}
          title='Questions'
          subtitle='Define and manage your interview questions.'
          addFieldButtonText='Add Question'
          fieldDeleteTooltip='Remove Question'
          defaultFieldType='text'
          columns={[
            {
              fieldName: 'text',
              className: 'col',
              label: 'Questions',
              helperText: 'Maximum 255 characters',
              placeholder: 'Insert Question',
            },
          ]}
        />
      </Card>
    </div>
  );
}
