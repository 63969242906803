import classNames from 'classnames';
import React from 'react';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { JobStageApproval } from './JobStageApproval';
import styles from './styles.module.scss';
import { JobStageHeader } from './JobStageHeader';
import { JobStageSubHeader } from './JobStageSubHeader';
import { JobStageApplications } from './JobStageApplications';
import { MoveCandidateProps } from '../../components/MoveCandidateStageModal';
import { User } from '../../../../entities/User';
import { HiringMember } from '../../../../entities/HiringMember';
import { useDroppable } from '@dnd-kit/core';
import { PipelineApplication } from '../../../../entities/applicant_tracking/PipelineApplication';
import { JobState } from '../../../../entities/JobDetails';
import { AlertObject } from '../../../../components/Alert';
import { JobStageBulkReviewButton } from './JobStageBulkReviewButton';
import { AccountIntegration } from '../../../../entities/AccountIntegration';

interface PropTypes {
  applications: PipelineApplication[];
  emailAccountIntegration: AccountIntegration;
  jobPostId: number;
  jobStageId: number;
  passthroughRate?: number;
  currentHiringMember?: HiringMember;
  currentUser: User;
  jobStageName: string;
  jobStatus: JobState;
  bulkReviewEnabled?: boolean;
  offerEnabled?: boolean;
  onMoveCandidate?: (action?: MoveCandidateProps) => void;
  setAlert: (alert: AlertObject) => void;
  reloadJobStages: () => Promise<void>;
}

const HIRED_STAGE = 'Hired';

function isNotHiredStage(jobStageName: string): boolean {
  return jobStageName.toLowerCase() !== HIRED_STAGE.toLowerCase();
}

export function JobStageLane(props: PropTypes) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.jobStageName,
  });
  const jobStageApplications = props.applications.filter(
    (application: PipelineApplication) =>
      application.jobStage.name === props.jobStageName,
  );

  return (
    <CenteredLayout
      heightClass={classNames('h-100', styles['lane'])}
      innerContainerClassName={classNames(
        'd-flex flex-column h-100 bg-white rounded-3 pb-4',
        isOver && styles['hover-border'],
      )}
      innerref={setNodeRef}
    >
      <JobStageHeader name={props.jobStageName} />
      <JobStageSubHeader candidatesNumber={jobStageApplications.length} />
      {props.bulkReviewEnabled &&
        jobStageApplications.length > 0 &&
        isNotHiredStage(props.jobStageName) && (
          <JobStageBulkReviewButton applications={jobStageApplications} />
        )}
      {isNotHiredStage(props.jobStageName) && (
        <JobStageApproval passthroughRate={props.passthroughRate} />
      )}
      <JobStageApplications
        applications={jobStageApplications}
        currentHiringMember={props.currentHiringMember}
        currentUser={props.currentUser}
        emailAccountIntegration={props.emailAccountIntegration}
        jobStatus={props.jobStatus}
        offerEnabled={props.offerEnabled}
        onMoveCandidate={props.onMoveCandidate}
        reloadJobStages={props.reloadJobStages}
        setAlert={props.setAlert}
      />
    </CenteredLayout>
  );
}
