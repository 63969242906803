import React, { useEffect, useState } from 'react';
import { FormState, useForm } from 'react-hook-form';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { CandidateEmailInput } from '../CandidateEmailInput';
import { CandidateNameInput } from '../CandidateNameInput';
import { CandidatePhoneInput } from '../CandidatePhoneInput';
import { CandidateLinkedinInput } from '../CandidateLinkedinInput';
import { CandidateService } from '../../../../services/applicant_tracking/CandidateService';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { FormErrorMessage } from '../../../../components/FormErrorMessage';
import { LocationInput } from '../LocationInput';
import { SingleAttachmentInputFormField } from '../../../../components/SingleAttachmentInputFormField';
import { Col, Row } from 'reactstrap';
import { ParsedResumesService } from '../../../../services/v1/applicant_tracking/ParsedResumesService';
import { ResumeParserInputBody } from '../../../../components/SingleAttachmentInputFormField/ResumeParserInputBody';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

interface PropTypes {
  onSuccessfulSubmit: (
    candidate: Candidate,
    e: React.SyntheticEvent,
    dto: CandidateDto,
  ) => void;
  headerFactory: (formState: FormState<CandidateDto>) => React.ReactNode;
  candidateDto?: CandidateDto;
  isEditing?: boolean;
  resumeParserEnabled?: boolean;
}

export default function CandidateForm(props: PropTypes) {
  const {
    handleSubmit,
    control,
    register,
    formState,
    trigger,
    setError,
    getValues,
    setValue,
    watch,
  } = useForm<CandidateDto>({
    defaultValues: props.candidateDto,
    mode: 'onTouched',
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(data: CandidateDto, e: React.SyntheticEvent) {
    try {
      const candidate = await CandidateService.upsertCandidate(
        data,
        !!props.isEditing,
      );
      props.onSuccessfulSubmit(candidate, e, { ...data, id: candidate.id });
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }
  }

  const watchResumeContent = watch('resumeContent');
  useEffect(() => {
    if (!props.resumeParserEnabled) return;
    if (!watchResumeContent) return;
    if (
      formState.defaultValues.resumeContent != null &&
      !formState.dirtyFields.resumeContent
    )
      return;

    setIsLoading(true);

    ParsedResumesService.create({ base64File: watchResumeContent }).then(
      (parsedResume) => {
        setValue('firstName', parsedResume.firstName);
        setValue('lastName', parsedResume.lastName);
        setValue('phoneNumber', parsedResume.phone);
        setValue('email', parsedResume.email);
        setValue('linkedinUrl', parsedResume.linkedinUrl);

        setValue('academicDegree', parsedResume.educationLevel);
        setValue('company', parsedResume.currentCompany);
        setValue('jobTitle', parsedResume.jobTitle);
        setValue('yearsExperience', parsedResume.yearsExperience);
        setValue('portfolioUrl', parsedResume.portfolioUrl);

        setValue('location', {
          country: parsedResume.locationCountryCode,
          state: parsedResume.locationStateCode,
          city: parsedResume.locationCity,
          address: null,
          name: parsedResume.locationName,
        });

        setValue('resumeId', parsedResume.applicationResumeId, {
          shouldTouch: true,
          shouldValidate: true,
        });

        setIsLoading(false);
      },
    );
  }, [watchResumeContent]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <LoadingSpinner showBackdrop />}
      <FormErrorMessage error={formState.errors} />
      {props.headerFactory(formState)}
      {props.resumeParserEnabled && (
        <Row className='mt-4'>
          <Col xs={6}>
            <SingleAttachmentInputFormField<CandidateDto>
              getValues={getValues}
              setValue={setValue}
              formRegister={register}
              headerClassNames='fw-semibold'
              controllerContentName='resumeContent'
              controllerFileName='resumeFilename'
              fieldName={'Resume / Curriculum Vitae'}
              hideHeader={true}
              allowedFormats={['.pdf', '.doc', '.docx']}
              maxFileSizeMb={10}
              isRequired={false}
              disabled={false}
              Body={ResumeParserInputBody}
            />
          </Col>
        </Row>
      )}
      <CandidateNameInput errors={formState.errors} formRegister={register} />
      <CandidatePhoneInput
        control={control}
        formRegister={register}
        errors={formState.errors}
      />
      <CandidateEmailInput
        errors={formState.errors}
        formRegister={register}
        trigger={trigger}
      />
      <CandidateLinkedinInput formRegister={register} />
      <LocationInput control={control} />
    </form>
  );
}
