import { Monologue } from '../entities/v1/interview_intelligence/Monologue';

export function findMonologueByOffset(
  monologues: Monologue[],
  offset: number,
): Monologue {
  return monologues.find((m) => m.startOffsetMs >= offset);
}
export function findSentenceByOffset(
  monologues: Monologue[],
  offset: number,
): string {
  return findMonologueByOffset(monologues, offset)?.sentence;
}
