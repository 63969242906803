import React from 'react';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { BackArrow } from '../../../../../components/BackArrow';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { longFormatDate } from '../../../../../utils/timeFormat';
import { PageTitle } from '../../../../../components/PageTitle';
import { getReferrer } from '../referrers';
import { JobStateBadge } from '../../../../JobPage/TopNavbar/JobStatePill';
import { JobHeaderBadge } from '../../../../JobPage/TopNavbar/JobHeaderBadge';

interface PropTypes {
  jobDetails?: JobDetails;
  jobFormDTO?: CreateJobDto;
}

export function EditJobHeader(props: PropTypes) {
  return (
    <div
      id='jobs'
      className='d-flex g-3 mb-4 justify-content-between align-items-center'
    >
      <div className='d-flex me-auto align-items-center'>
        <BackArrow url={getReferrer()} />
        <PageTitle
          text={
            <div>
              <span>Edit/ </span>
              <span className='text-dark-200'>{props.jobFormDTO.name}</span>
            </div>
          }
        />
        <JobHeaderBadge
          text={props.jobDetails.departmentName}
          classNames='mx-2h bg-white text-primary'
        />
        <JobStateBadge state={props.jobDetails.status} />
      </div>
      <div className='fs-5 text-dark-200 me-3'>
        Last updated at {longFormatDate(props.jobDetails.updatedAt)}
      </div>
    </div>
  );
}
