import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Icon, PropTypes as IconProptypes } from '../Icon';
import { LoadingSpinnerIcon } from '../LoadingSpinnerIcon';

interface PropTypes {
  spanText: ReactNode;
  icon: IconProptypes;
  className?: string;
  spanClassName?: string;
  position?: 'start' | 'end';
  isLoading?: boolean;
}

function RenderIcon(props: PropTypes) {
  const isLoading = props.isLoading ?? false;

  return isLoading ? <LoadingSpinnerIcon /> : <Icon {...props.icon} />;
}

export function IconSpan(props: PropTypes) {
  const position = props.position || 'start';

  return (
    <div
      className={classNames('d-flex', 'align-items-center', props.className)}
    >
      {position == 'start' && <RenderIcon {...props} />}
      <span
        className={classNames(
          {
            'ms-2': position == 'start',
            'me-2': position == 'end',
          },
          props.spanClassName,
        )}
      >
        {props.spanText}
      </span>
      {position == 'end' && <RenderIcon {...props} />}
    </div>
  );
}
