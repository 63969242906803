import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Transcript } from '../../../components/Interview/Transcript';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import { Interview } from '../../../entities/Interview';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { Controls } from '../Controls';
import {
  PropTypes as MediaPlayerPropTypes,
  MediaPlayer,
} from '../../../components/MediaPlayer';
import { useResizeDetector } from 'react-resize-detector';
import { SpanTooltip } from '../../../components/SpanTooltip';
import styles from './styles.module.scss';

interface PropTypes {
  interview: Interview;
  startTime: number;
  isPlaying: boolean;
  playTimestamp: number;
  onStartTime: (number: number) => void;
  onPlaying: (flag: boolean) => void;
  onSelectTime: (time: number) => void;
  onChangePlayTimestamp: (time: number) => void;
}

export function VideoControlsPanel(props: PropTypes) {
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  const [fullScreenEnabled, setFullScreenEnabled] = useState<boolean>(false);

  const skipBackward = useCallback(
    () => props.onStartTime(props.playTimestamp - 10000),
    [props.playTimestamp],
  );
  const skipForward = useCallback(
    () => props.onStartTime(props.playTimestamp + 10000),
    [props.playTimestamp],
  );
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);

  useQueryParam('t', (t) => {
    if (t === null) return;

    const timestamp = parseInt(t, 10);
    props.onStartTime(timestamp);
    props.onChangePlayTimestamp(timestamp);
  });

  const [isTranscriptVisible, setIsTranscriptVisibleVisible] = useState(false);
  const displayTranscript = useCallback(
    () => setIsTranscriptVisibleVisible(true),
    [],
  );
  const displayVideo = useCallback(
    () => setIsTranscriptVisibleVisible(false),
    [],
  );

  const play = useCallback(() => props.onPlaying(true), []);
  const pause = useCallback(() => props.onPlaying(false), []);

  const setVolumeAndMuted = useCallback((volume: number, muted: boolean) => {
    setVolume(volume);
    setMuted(muted);
  }, []);

  const [findString, setFindString] = useState('');
  const [monologueSearchOccurences, setMonologueSearchOccurrences] = useState(
    [],
  );
  const [selectedSearchOccurence, setSelectedSearchOccurence] = useState({
    monologue: undefined, // Monologue that holds the selected mark.
    mark: undefined, // Selected mark, in the monologue.
    global: undefined, // Selected mark, across all monologues.
  });

  function handleGoToSearchOccurence(inc: number) {
    let monologue = selectedSearchOccurence.monologue;
    let mark = selectedSearchOccurence.mark + inc;
    let global = selectedSearchOccurence.global + inc;

    if (mark < 0) {
      for (monologue = monologue - 1; monologue >= 0; --monologue) {
        if (monologueSearchOccurences[monologue] > 0) break;
      }

      if (monologue === -1) {
        for (
          monologue = monologueSearchOccurences.length - 1;
          monologue >= 0;
          --monologue
        ) {
          if (monologueSearchOccurences[monologue] > 0) break;
        }

        global = monologueSearchOccurences.reduce((acc, c) => acc + c, 0) - 1;
      }

      mark = monologueSearchOccurences[monologue] - 1;
    } else if (mark >= monologueSearchOccurences[monologue]) {
      monologue = monologueSearchOccurences.findIndex(
        (c, i) => c > 0 && i > monologue,
      );

      if (monologue === -1) {
        monologue = monologueSearchOccurences.findIndex((c) => c > 0);
        global = 0;
      }

      mark = 0;
    }

    setSelectedSearchOccurence({ monologue, mark, global });
  }

  function handleUpdateMonologueSearchOccurences(index: number, count: number) {
    monologueSearchOccurences[index] = count;
    setMonologueSearchOccurrences(monologueSearchOccurences);
    const monologue = monologueSearchOccurences.findIndex((c) => c > 0);
    setSelectedSearchOccurence({
      monologue,
      mark: 0,
      global: monologue < 0 ? -1 : 0,
    });
  }

  function handleFindString(findString: string) {
    setFindString(findString);
    if (findString !== '') setIsTranscriptVisibleVisible(true);
  }

  const mediaProps: MediaPlayerPropTypes = {
    isPlaying: props.isPlaying,
    volume: volume,
    muted: muted,
    media: props.interview.media,
    playbackRate: playbackRate,
    fullScreen: fullScreenEnabled,
    playerOptions: {
      fill: !isTranscriptVisible,
      fluid: isTranscriptVisible,
      aspectRatio: '16:9',
      controls: fullScreenEnabled,
      preload: 'auto',
      playbackRates: [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5],
    },
    startTime: props.startTime,
    onPlayTimestampChange: props.onChangePlayTimestamp,
    onPlayingChange: props.onPlaying,
    onVolumeChange: setVolumeAndMuted,
    onFullscreenChange: setFullScreenEnabled,
  };

  const [validHeight, setValidHeight] = useState(null);
  const { height, ref } = useResizeDetector();

  useEffect(() => {
    if (!isTranscriptVisible) {
      setValidHeight(height);
    }
  }, [height]);

  return (
    <div
      className={classNames('d-flex flex-column card rounded-5 shadow-sm mb-4')}
    >
      <div className='flex-grow-1 app-card-body w-100'>
        <div className='my-3 mx-4 d-flex justify-content-between align-items-center'>
          <h4 className='mb-0 fw-bold'>{props.interview.stage}</h4>
          <div
            className={classNames(
              styles[
                isTranscriptVisible
                  ? 'full-screen-disabled-button'
                  : 'full-screen-button'
              ],
              'px-1',
            )}
          >
            <SpanTooltip
              className='min-w-16'
              text={
                <a
                  href='#fullscreen'
                  className={classNames(
                    'bi',
                    'bi-fullscreen',
                    'fs-6',
                    isTranscriptVisible ? 'text-blue-200' : 'text-blue',
                  )}
                  onClick={() => setFullScreenEnabled(true)}
                />
              }
              tooltipText='Fullscreen'
            />
          </div>
        </div>
        <DeprecatedPanel
          className={classNames('h-100', {
            'bg-transparent': !isTranscriptVisible,
          })}
          additionalInnerContainerClassNames='h-100'
        >
          <Row className='row align-items-end h-100'>
            <Col
              className={classNames('col-12', {
                'd-none': !isTranscriptVisible,
              })}
            >
              <Transcript
                interview={props.interview}
                playTimestamp={props.playTimestamp}
                findString={findString}
                activeMonologueOffset={-400}
                selectedSearchOccurence={selectedSearchOccurence}
                onSelectTimestamp={props.onSelectTime}
                maxHeightPx={validHeight}
                onUpdateMonologueSearchOccurences={
                  handleUpdateMonologueSearchOccurences
                }
              />
            </Col>
            <Col
              className={classNames({
                'd-none': isTranscriptVisible,
              })}
            >
              <div ref={ref}>
                <MediaPlayer {...mediaProps} />
              </div>
            </Col>
          </Row>
        </DeprecatedPanel>
      </div>
      <div className='px-0 mx-4 my-2'>
        <Controls
          isPlaying={props.isPlaying}
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          isTranscriptVisible={isTranscriptVisible}
          searchOccurrences={monologueSearchOccurences.reduce(
            (acc, c) => acc + c,
            0,
          )}
          selectedSearchOccurenceIndex={selectedSearchOccurence.global}
          onPressPlay={play}
          onPressPause={pause}
          onSkipBackward={skipBackward}
          onSkipForward={skipForward}
          onVolumeChange={setVolumeAndMuted}
          onPlaybackRateChange={setPlaybackRate}
          onDisplayTranscript={displayTranscript}
          onDisplayVideo={displayVideo}
          onFindString={handleFindString}
          onGoToPreviousSearchOccurence={() => handleGoToSearchOccurence(-1)}
          onGoToNextSearchOccurence={() => handleGoToSearchOccurence(+1)}
          onFullScreen={setFullScreenEnabled}
        />
      </div>
    </div>
  );
}
