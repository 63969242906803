import { Role } from '../../entities/Role';
import { SurveyQuestionCondition } from '../../entities/survey_engine/SurveyQuestionCondition';
import { SurveyQuestionFieldType } from '../../entities/survey_engine/SurveyQuestionFieldType';

function isRatingValid(
  answerValue: number,
  condition: SurveyQuestionCondition,
): boolean {
  return (
    answerValue !== null &&
    answerValue >= condition.min_value &&
    answerValue <= condition.max_value
  );
}

function isMultiOptionValid(
  answerOption: number[] | Role[],
  condition: SurveyQuestionCondition,
): boolean {
  return (
    answerOption.length === 1 &&
    answerOption[0] === condition.questionFieldOptionId
  );
}

export function setQuestionVisible(
  type: string,
  answerValue: string | number,
  condition: SurveyQuestionCondition,
  answerOption: number[] | Role[],
): boolean {
  if (type === SurveyQuestionFieldType.Rating) {
    return isRatingValid(answerValue as number, condition);
  } else if (type === SurveyQuestionFieldType.MultiOptions) {
    return isMultiOptionValid(answerOption, condition);
  }

  return false;
}
