import React, { useEffect } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from '../TriggerRowDropdown';
import { Trigger } from '../../../../../../../../entities/applicant_tracking/Trigger';

export interface PropTypes {
  counter: number;
  trigger: Trigger;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

export function PushToHrisTriggerRow(props: PropTypes) {
  const hiredStageOption: SelectOption = {
    value: 'hired',
    label: 'Hired',
  };

  useEffect(() => {
    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'push_to_hris',
      },
      props.counter,
    );
  }, []);

  return (
    <TriggerRowDropdown>
      <LabelledSelect
        options={[hiredStageOption]}
        selected={hiredStageOption}
        disabled={true}
        labelClassName='fs-5'
        label={'If the Candidate Status'}
        onChange={() => {}}
      />
    </TriggerRowDropdown>
  );
}
