import React, { useEffect, useState } from 'react';
import { ApprovalFlowService } from '../../../../services/v1/applicant_tracking/ApprovalFlowService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ApprovalFlowBody } from '../components/ApprovalFlowBody';
import {
  ApprovalAssignedTo,
  ApprovalFlow,
  ApprovalFlowStep,
  ListApprovalFlowsMetadata,
  RequisitionType,
} from '../../../../entities/applicant_tracking/ApprovalFlow';
import { Card } from '../../../../components/Card';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { ViewOrEditHeader } from '../components/ViewOrEditHeader';
import {
  disabledDepartmentIds,
  disabledLocationIds,
  isApprovalFlowSavable,
  loadApprovalFlow,
  loadExistingFlowMetadata,
  mapAssignedLocationOrDepartmentIds,
  mapDataToAPIRequest,
} from '../utils';
import { ApprovalReviewModal } from '../components/ApprovalReviewModal';

interface PropTypes {
  id: number;
}

export default function EditApprovalFlowPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewModalOpen, setReviewModalOpen] = useState<boolean>(false);
  const [existingFlows, setExistingFlows] =
    useState<ListApprovalFlowsMetadata>(null);
  const [approvalFlow, setApprovalFlow] = useState<ApprovalFlow>();
  const [name, setName] = useState<string>();
  const [assignedTo, setAssignedTo] = useState<ApprovalAssignedTo>();
  const [assignedLocationOrDepartmentIds, setAssignedLocationOrDepartmentIds] =
    useState<number[]>([]);
  const [approvalSteps, setApprovalSteps] = useState<ApprovalFlowStep[]>([]);
  const [requisitionType, setRequisitionType] = useState<RequisitionType>();

  useEffect(() => {
    loadApprovalFlow(props.id).then((approvalFlow) => {
      setApprovalFlow(approvalFlow);
      setName(approvalFlow.name);
      setAssignedTo(approvalFlow.assignedTo);
      setApprovalSteps(approvalFlow.steps);
      setRequisitionType(approvalFlow.requisitionType);
      setAssignedLocationOrDepartmentIds(
        mapAssignedLocationOrDepartmentIds(approvalFlow),
      );

      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    requisitionType &&
      loadExistingFlowMetadata(requisitionType).then(setExistingFlows);
  }, [requisitionType]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isSavable = () =>
    isApprovalFlowSavable(
      name,
      assignedTo,
      existingFlows?.organizationFlowId &&
        existingFlows?.organizationFlowId !== props.id,
      assignedLocationOrDepartmentIds,
      approvalSteps,
    );

  async function handleSave() {
    setIsLoading(true);
    await ApprovalFlowService.update(
      mapDataToAPIRequest(
        name,
        assignedTo,
        requisitionType,
        assignedLocationOrDepartmentIds,
        approvalSteps,
        props.id,
      ),
    );

    window.location.href =
      getRedirectUriQueryParam() ?? '/organization_settings/approval_flows';
  }

  return (
    <>
      <div className='container'>
        <ViewOrEditHeader approvalFlow={approvalFlow} />
        <Card>
          <CardHeader
            title='Approval Flow'
            saveText='Review and Save'
            titleClassNames='mb-0'
            classNames='align-items-center'
            isSavable={isSavable()}
            onSaveClick={() => setReviewModalOpen(true)}
            cancelUrl='/organization_settings/approval_flows'
          />
          <ApprovalFlowBody
            editingMode
            name={name}
            assignedTo={assignedTo}
            requisitionType={requisitionType}
            approvalSteps={approvalSteps}
            assignedLocationOrDepartmentIds={assignedLocationOrDepartmentIds}
            disabledLocationIds={disabledLocationIds(
              approvalFlow,
              existingFlows?.assignedLocationIds,
            )}
            disabledDepartmentIds={disabledDepartmentIds(
              approvalFlow,
              existingFlows?.assignedDepartmentIds,
            )}
            organizationFlowExists={!!existingFlows?.organizationFlowId}
            onName={setName}
            setApprovalSteps={setApprovalSteps}
            onAssignedToChange={(e) => {
              setAssignedTo(e);
              setAssignedLocationOrDepartmentIds([]);
            }}
            setAssignedLocationOrDepartmentIds={
              setAssignedLocationOrDepartmentIds
            }
          />
        </Card>
      </div>
      {reviewModalOpen && (
        <ApprovalReviewModal
          isActivelyUsed={approvalFlow.isActivelyUsed}
          steps={approvalSteps}
          onCancel={() => setReviewModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
}
