import React from 'react';
import { Container } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import {
  HorizontalStepper,
  ItemPropTypes,
} from '../../../../../components/HorizontalStepper';
import { EditJobHeader } from './EditJobHeader';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';

interface PropTypes {
  stepperItems: ItemPropTypes[];
  setupStep: number;
  isEditFlow: boolean;
  jobDetails?: JobDetails;
  jobFormDTO?: CreateJobDto;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
}

export function SetupJobPageLayout(props: PropTypes) {
  return (
    <Container>
      {props.isEditFlow ? (
        <EditJobHeader
          jobDetails={props.jobDetails}
          jobFormDTO={props.jobFormDTO}
        />
      ) : (
        <h1 className={'mb-4'}>Add Job</h1>
      )}

      <HorizontalStepper
        items={props.stepperItems}
        activeIndex={props.setupStep}
      />

      <Outlet />
    </Container>
  );
}
