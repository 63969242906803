import React from 'react';
import { Dropdown } from '../../../../../components/Dropdown';
import { IconSpan } from '../../../../../components/IconSpan';
import { PropTypes as ActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { RejectedReasonService } from '../../../../../services/v1/applicant_tracking/RejectedReasonService';
import { appendFlashMessage } from '../../../../../utils/url';
import { AlertType } from '../../../../../components/Alert';

interface PropTypes {
  id: number;
  global: boolean;
  editEnabled: boolean;
  editUrl: string;
  viewUrl: string;
}

const REDIRRECT_URL = '/organization_settings/reject_reasons';

function view(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = props.viewUrl),
    buttonChild: (
      <IconSpan
        spanText={'View'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function editAction(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = props.editUrl),
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function _deleteAction(props: PropTypes): ActionPropTypes {
  const deleteRejectedReason = async () => {
    try {
      await RejectedReasonService.destroy(props.id);
      window.location.href = appendFlashMessage(
        REDIRRECT_URL,
        'The reject reason was successfully deleted!',
      );
    } catch (e) {
      window.location.href = appendFlashMessage(
        REDIRRECT_URL,
        'Could not delete reject reason. Please try again later.',
        AlertType.Danger,
      );
    }
  };

  return {
    action: deleteRejectedReason,
    buttonChild: (
      <IconSpan
        spanText={'Delete'}
        icon={{ name: 'bi-trash', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const menuActions = [view(props)];

  if (props.editEnabled) {
    menuActions.push(editAction(props));
    // menuActions.push(deleteAction(props));
  }

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
