import React, { useEffect, useState } from 'react';
import { CandidateTopChoicePanels } from '../CandidateTopChoicePanels';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { CandidateAnalytics } from '../../../../entities/candidate_experience/CandidateAnalytics';
import moment from 'moment';
import { DateRange } from '../../../../components/DateRangePicker';
import { handlePDFPageBreak } from '../../../../utils/pdfHandler';
import { AnalyticsService } from '../../../../services/candidate_experience/AnalyticsService';
import { SurveyMetricsAndNpsPanels } from './SurveyMetricsAndNpsPanels';
import ProcessPanel from './ProcessPanel';

interface PropTypes {
  filterStates: FilterStates;
  moreFiltersShown: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
  exportToPDF: boolean;
  setAnalyticsEmpty: (isEmpty: boolean) => void;
  organizationName: string;
  urlFilters?: string;
  preRenderedAnalytics?: CandidateAnalytics;
  pulseStandardNpsCalculation: boolean;
}

function generateFilterStates(
  analytics: CandidateAnalytics,
  urlFilters: string,
) {
  return {
    benchmarkType: {
      selected: {
        value: urlFilters['benchmark_type'],
        label: analytics.cps_benchmark.name,
      },
    },
    dateRange: {
      start: moment(urlFilters['start_date']),
      end: moment(urlFilters['end_date']),
      range: urlFilters['date_range'] as DateRange,
    },
  };
}

function hasAnalytics(analytics: CandidateAnalytics) {
  return Object.keys(analytics).length > 0 && analytics.cps.value !== null;
}

function getFilterStates(props: PropTypes) {
  return props.exportToPDF
    ? generateFilterStates(props.preRenderedAnalytics, props.urlFilters)
    : props.filterStates;
}

function setPdfExportUseEffect() {
  useEffect(() => {
    handlePDFPageBreak();
  });
}

function setAnalyticsUseEffect(props: PropTypes, setAnalytics) {
  useEffect(() => {
    setAnalytics(null);
    const filterSelector = props.filterStates.filterSelector.selected.value;

    AnalyticsService.get(
      filterSelector,
      props.filterStates.jobs.selected.map((option) => option.value),
      props.filterStates.departments.selected.map((option) => option.value),
      props.filterStates.hiringManagers.selected.map((option) => option.value),
      props.filterStates.recruiters.selected.map((option) => option.value),
      props.filterStates.locations.selected.map((option) => option.value),
      props.filterStates.surveyTemplates.selected.map((option) => option.value),
      props.filterStates.benchmarkType.selected.value,
      props.filterStates.dateRange,
      props.filterStates.ethnicities.selected.map((option) => option.value),
      props.filterStates.genders.selected.map((option) => option.value),
      props.filterStates.ageRanges.selected.map((option) => option.value),
    ).then((analytics) => {
      setAnalytics(analytics);

      hasAnalytics(analytics)
        ? props.setAnalyticsEmpty(false)
        : props.setAnalyticsEmpty(true);
    });
  }, [props.filterStates]);
}

export default function OverviewAnalytics(props: PropTypes) {
  const [analytics, setAnalytics] = useState<CandidateAnalytics>(
    props.preRenderedAnalytics || null,
  );
  const filterStates = getFilterStates(props);

  props.exportToPDF
    ? setPdfExportUseEffect()
    : setAnalyticsUseEffect(props, setAnalytics);

  return (
    <>
      <div className='flex-column gap-4 mt-3'>
        <SurveyMetricsAndNpsPanels
          npsTitle='CNPS'
          npsDescription="Candidate Net Promoter Score (CNPS) is the candidate's
          score of the overall process experience."
          npsPromotersText='Promoters'
          npsNeutralsText='Neutrals'
          npsDetractorsText='Detractors'
          analytics={analytics}
          dateRange={filterStates.dateRange}
          exportToPDF={props.exportToPDF}
          benchmarkLabel={filterStates.benchmarkType.selected.label}
          standardNpsCalculation={props.pulseStandardNpsCalculation}
          filterStates={filterStates}
        />
        <ProcessPanel
          questionAverages={analytics?.question_averages}
          benchmarkType={filterStates.benchmarkType.selected}
        />
        {analytics && (
          <CandidateTopChoicePanels
            analytics={analytics}
            benchmarkType={filterStates.benchmarkType.selected}
          />
        )}
      </div>
    </>
  );
}
