import React from 'react';
import { LabelledToggle } from '../../../../../components/LabelledToggle';
import { Control } from 'react-hook-form';
import { UpsertStagePlanTemplateDto } from '../../DTOs/UpsertStagePlanTemplateDto';

interface PropTypes {
  controllerName: string;
  disabled: boolean;
  formControl: Control<UpsertStagePlanTemplateDto, any>;
  title: string;
  subTitle: string;
  tooltip?: string;
}

export function CustomizationStageToggle(props: PropTypes) {
  return (
    <div className='d-flex mt-4 gap-2 flex-column'>
      <div className='d-flex align-items-center'>
        <label className='fs-5 fw-semibold me-2'>{props.title}</label>
        <LabelledToggle
          checkedLabel='Yes'
          disabled={props.disabled}
          formHookContext={{
            controllerName: props.controllerName,
            formControl: props.formControl,
          }}
          inputName={props.controllerName}
          labelPlacement='end'
          tooltip={props.tooltip}
          size='sm'
          uncheckedLabel='No'
          labelClassnames='text-dark-200'
        />
      </div>
      <span className='fs-6 text-muted'>{props.subTitle}</span>
    </div>
  );
}
