import React from 'react';
import { StarRating } from '../../../../../../components/StarRating';

interface PropTypes {
  value: number;
}

export function AverageRating(props: PropTypes) {
  return (
    <span>
      {props.value && (
        <div>
          <div className='d-flex fs-1 fw-bold mb-2 align-items-center'>
            {props.value}&nbsp;
            <div className='fs-6 fw-normal text-dark-200'>AVG Score</div>
          </div>
          <StarRating
            id='rating'
            rating={props.value}
            editable={false}
            fillColorClass='text-rating-color'
          />
        </div>
      )}
    </span>
  );
}
