import React, { MouseEventHandler, ReactNode } from 'react';
import { IconSpan } from '../IconSpan';
import { Icon } from '../Icon';
import { Button } from 'reactstrap';
import { HorizontalPlacement } from '../../utils/types/Placement';
import { Size } from '../../utils/types/Size';
import { SpanTooltip } from '../SpanTooltip';

interface PropTypes {
  color?: string;
  disabled?: boolean;
  position?: HorizontalPlacement;
  size?: Size;
  type?: 'submit' | 'reset' | 'button' | undefined;
  iconName: string;
  iconClass?: string;
  buttonClass?: string;
  iconTextClass?: string;
  textClass?: string;
  buttonText?: ReactNode;
  dataTestId?: string;
  isLoading?: boolean;
  tooltipText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function ButtonWithoutTooltip(props: PropTypes) {
  return (
    <Button
      color={props.color}
      disabled={props.disabled}
      className={props.buttonClass}
      size={props.size}
      type={props.type ?? 'button'}
      data-testid={props.dataTestId}
      onClick={props.onClick}
    >
      {props.buttonText != null ? (
        <IconSpan
          spanText={props.buttonText}
          icon={{
            name: props.iconName,
            className: props.iconClass,
          }}
          className={props.iconTextClass}
          spanClassName={props.textClass}
          position={props.position}
          isLoading={props.isLoading}
        />
      ) : (
        <Icon name={props.iconName} className={props.iconClass} />
      )}
    </Button>
  );
}

export function IconButton(props: PropTypes) {
  return (
    <>
      {props.tooltipText ? (
        <SpanTooltip
          text={<ButtonWithoutTooltip {...props} />}
          tooltipText={props.tooltipText}
        />
      ) : (
        <ButtonWithoutTooltip {...props} />
      )}
    </>
  );
}
