import React, { useEffect, useState } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { humanize } from '../../../../../../../../utils/humanize';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';
import { JobStageTemplate } from '../../../../../../../../entities/applicant_tracking/JobStageTemplate';
import { TriggerRowDropdown } from '../TriggerRowDropdown';
import { LabelledMultiSelect } from '../../../../../../../../components/LabelledMultiSelect';
import {
  SendEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';

interface PropTypes {
  emailTemplates: EmailTemplate[];
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  planTemplate?: PlanTemplate;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

const ApplicationStatusOptions = ['active', 'hired', 'rejected'];

function mapEmailTemplateToSelectOption(
  emailTemplates: EmailTemplate[],
  selectedValue?: string,
): SelectOption[] {
  return emailTemplates.map((emailTemplate: EmailTemplate) => {
    return {
      value: emailTemplate.id.toString(),
      label: emailTemplate.name,
      selected: emailTemplate.id.toString() === selectedValue,
    };
  });
}

function mapApplicationStatusToSelectOption(
  selectedValues?: string[],
): SelectOption[] {
  return ApplicationStatusOptions.map((status: string) => {
    return {
      value: status,
      label: humanize(status),
      selected: selectedValues?.some((v) => v === status),
    };
  });
}

function mapIntToSelectOption(selectedValue?: string): SelectOption[] {
  return [...Array(8).keys()].map((value: number) => {
    return {
      value: value.toString(),
      label: value.toString(),
      selected: value.toString() === selectedValue,
    };
  });
}

function mapStagesToSelectOption(
  planTemplate?: PlanTemplate,
  selectedValues?: string[],
): SelectOption[] {
  return (
    planTemplate?.jobStages.map((jobStage: JobStageTemplate) => {
      return {
        value: jobStage.jobStageId.toString(),
        label: jobStage.name,
        selected: selectedValues?.some(
          (v) => v === jobStage.jobStageId.toString(),
        ),
      };
    }) || []
  );
}

function initTriggerOptions(props: PropTypes): {
  initEmailTemplateOptions: SelectOption[];
  initStatusOptions: SelectOption[];
  initStageOptions: SelectOption[];
  initDelayOptions: SelectOption[];
} {
  const trigger = props.trigger as SendEmailTrigger;

  return {
    initEmailTemplateOptions: mapEmailTemplateToSelectOption(
      props.emailTemplates,
      trigger.emailTemplateId?.toString(),
    ),
    initStatusOptions: mapApplicationStatusToSelectOption(
      trigger.applicationStatuses,
    ),
    initStageOptions: mapStagesToSelectOption(
      props.planTemplate,
      trigger.stageIds?.map((v) => v.toString()),
    ),
    initDelayOptions: mapIntToSelectOption(
      trigger.delayDays?.toString() || '0',
    ),
  };
}

export function SendEmailTriggerRow(props: PropTypes) {
  const {
    initEmailTemplateOptions,
    initStatusOptions,
    initStageOptions,
    initDelayOptions,
  } = initTriggerOptions(props);

  const [emailTemplateOptions, setEmailTemplateOptions] = useState(
    initEmailTemplateOptions,
  );

  const [statusOptions, setStatusOptions] = useState(initStatusOptions);
  const [stageOptions, setStageOptions] = useState(initStageOptions);
  const [delayOptions, setDelayOptions] = useState(initDelayOptions);

  useEffect(() => {
    setEmailTemplateOptions(initEmailTemplateOptions);
    setStatusOptions(initStatusOptions);
    setStageOptions(initStageOptions);
    setDelayOptions(initDelayOptions);
  }, [props.formData.length]);

  const updateTrigger = () =>
    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'send_email',
        emailTemplateId: parseInt(
          emailTemplateOptions.find((v) => v.selected)?.value,
        ),
        applicationStatuses: statusOptions
          .filter((v) => v.selected)
          .map((v) => v.value),
        stageIds: stageOptions
          .filter((v) => v.selected)
          .map((v) => parseInt(v.value)),
        delayDays: parseInt(delayOptions.find((v) => v.selected)?.value ?? ''),
      },
      props.counter,
    );

  useEffect(updateTrigger, [
    emailTemplateOptions,
    statusOptions,
    stageOptions,
    delayOptions,
  ]);

  useEffect(() => {
    props.planTemplate &&
      setStageOptions(
        mapStagesToSelectOption(
          props.planTemplate,
          (props.trigger as SendEmailTrigger).stageIds?.map((v) =>
            v.toString(),
          ),
        ),
      );
  }, [props.planTemplate]);

  return (
    <>
      <TriggerRowDropdown>
        <LabelledSelect
          options={emailTemplateOptions}
          selected={emailTemplateOptions.find((v: SelectOption) => v.selected)}
          mandatory={true}
          labelClassName='fs-5'
          label={'Email Template'}
          placeholder='Select template'
          onChange={(options) =>
            setEmailTemplateOptions(
              mapEmailTemplateToSelectOption(
                props.emailTemplates,
                options.value,
              ),
            )
          }
        />
      </TriggerRowDropdown>
      <TriggerRowDropdown>
        <LabelledMultiSelect
          options={statusOptions}
          selected={statusOptions.filter((v: SelectOption) => v.selected)}
          labelClassName='fs-5'
          label={'If the Candidate status'}
          manySelectedText={`${
            statusOptions.filter((v) => v.selected).length
          } selected statuses`}
          singleSelectedText='1 selected status'
          placeholder='Select candidate status'
          min={1}
          hasSelectAll
          selectAllLabel='All Candidate Statuses'
          onChange={(options) =>
            setStatusOptions(
              mapApplicationStatusToSelectOption(options.map((o) => o.value)),
            )
          }
        />
      </TriggerRowDropdown>
      <TriggerRowDropdown>
        <LabelledMultiSelect
          options={stageOptions}
          selected={stageOptions.filter((v: SelectOption) => v.selected)}
          labelClassName='fs-5'
          label={'If the Stage'}
          placeholder='Select stage'
          singleSelectedText='1 selected stage'
          manySelectedText={`${
            stageOptions.filter((v) => v.selected).length
          } selected stages`}
          min={1}
          hasSelectAll
          selectAllLabel='All Stages'
          onChange={(options) =>
            setStageOptions(
              mapStagesToSelectOption(
                props.planTemplate,
                options.map((o) => o.value),
              ),
            )
          }
        />
      </TriggerRowDropdown>
      <TriggerRowDropdown>
        <LabelledSelect
          options={delayOptions}
          selected={delayOptions.find((v: SelectOption) => v.selected)}
          labelClassName='fs-5'
          label={'Delay Days'}
          placeholder='Select days'
          onChange={(o) => setDelayOptions(mapIntToSelectOption(o.value))}
        />
      </TriggerRowDropdown>
    </>
  );
}
