import React from 'react';
import classNames from 'classnames';
import { Table } from 'reactstrap';
import { MetricCard } from '../../../components/MetricCard';
import tableStyles from '../styles.module.scss';
import { OverviewChip } from '../../../components/OverviewChip';
import { Chip } from '../../../../../components/Chip';

export interface SessionOverview {
  id: number;
  name: string;
  views: number;
  rating: number;
  comments: number;
}

interface PropTypes {
  bestSessions: SessionOverview[];
}

function BestSessionRow(props: { session: SessionOverview }) {
  return (
    <tr>
      <td className='d-flex'>
        <Chip className='bg-gray text-truncate mw-100 fw-semibold px-2'>
          <a
            href={`/training/sessions/${props.session.id}`}
            className='text-info'
          >
            {props.session.name}
          </a>
        </Chip>
      </td>
      <td className='text-center'>
        <OverviewChip iconClass='bi-eye' value={props.session.views} />
      </td>
      <td className='text-center'>
        <OverviewChip iconClass='bi-chat' value={props.session.comments} />
      </td>
      <td className='text-center'>
        <OverviewChip
          classNames='bg-light-info'
          iconClass='bi-star'
          value={
            props.session.rating != null
              ? props.session.rating.toFixed(1)
              : 'N/A'
          }
        />
      </td>
    </tr>
  );
}

export function OverviewBestSessionsMetric(props: PropTypes) {
  return (
    <MetricCard
      title='Best Sessions'
      tooltip="5 top rated sessions according to users' feedback. Additionally, displays the number of sessions views and the number of comments included on each session."
    >
      <Table
        borderless
        className={classNames('table-fixed', tableStyles['overview-table'])}
      >
        <thead className='text-nowrap'>
          <tr className='fs-6 text-center'>
            <th className='fw-normal ps-0 py-0 col-7'></th>
            <th className='fw-normal py-0 col-auto'>Views</th>
            <th className='fw-normal py-0 col-auto'>Comments</th>
            <th className='fw-normal py-0 col-auto'>Rating</th>
          </tr>
        </thead>
        <tbody className={tableStyles['table-body']}>
          {props.bestSessions.map((session, idx) => (
            <BestSessionRow key={idx} session={session} />
          ))}
        </tbody>
      </Table>
    </MetricCard>
  );
}
