import classNames from 'classnames';
import React from 'react';
import { SpanTooltip } from '../SpanTooltip';

const MENTION_SPLIT_MATCHER = /@\[[^[]*\]\([0-9]+\)/;
const MENTION_INFO_MATCHER = /@\[([^[]*)\]\(([0-9]+)\)/;

interface PropTypes {
  text: string;
  contextText?: string;
  className?: string;
  mentionClassName?: string;
  mentionTransformer?: (value: string) => string;
}

interface Mention {
  id: number;
  name: string;
}

interface NoteTextPart {
  type: 'text' | 'context' | 'mention';
  id?: number;
  value: string;
}

const extractMentions = (text: string): Mention[] => {
  const regexp = new RegExp(MENTION_INFO_MATCHER, 'g');
  const mentions = [];
  for (
    let match = regexp.exec(text);
    match !== null;
    match = regexp.exec(text)
  ) {
    mentions.push({ id: parseInt(match[2], 10), name: match[1] });
  }
  return mentions;
};

const mergeMentionsAndParts = (
  mentions: Mention[],
  parts: string[],
): NoteTextPart[] => {
  const merged = [];
  while (mentions.length > 0 || parts.length > 0) {
    const mention = mentions.shift();
    const part = parts.shift();
    if (part !== undefined) merged.push({ type: 'text', value: part });
    if (mention !== undefined)
      merged.push({ type: 'mention', id: mention.id, value: mention.name });
  }
  return merged;
};

const renderTextPart = (
  textPart: NoteTextPart,
  index: number,
  customClassName: string,
  mentionTransformer: (value: string) => string,
  contextTextLength?: number,
) => {
  if (textPart.type === 'text') {
    let text = textPart.value;

    if (text.length + contextTextLength > 235) {
      text = text.substring(0, 235 - contextTextLength).trim() + '...';
      return (
        <SpanTooltip
          key={index}
          text={text}
          tooltipText={textPart.value}
          trigger='hover'
          tooltipStyle={{ maxWidth: '350px' }}
        />
      );
    } else {
      return <span key={index}>{textPart.value}</span>;
    }
  }

  return (
    <span key={index} className={customClassName || `text-blue`}>
      {mentionTransformer
        ? mentionTransformer(textPart.value)
        : `@${textPart.value}`}
    </span>
  );
};

const isEmpty = (text?: string) => {
  return text == null || text.length === 0;
};

export const NoteText = React.memo(function NoteText(props: PropTypes) {
  if (typeof props.text !== 'string') return null;

  const mentions = extractMentions(props.text);
  const parts = props.text.split(MENTION_SPLIT_MATCHER);
  const merged = mergeMentionsAndParts(mentions, parts);
  const contextTextSize = props.contextText?.length || 0;
  return (
    <>
      {!isEmpty(props.contextText) && (
        <span className='text-muted'>{`${props.contextText} `}</span>
      )}
      <span
        className={classNames(props.className || 'text-primary')}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {merged.map((v, i) =>
          renderTextPart(
            v,
            i,
            props.mentionClassName,
            props.mentionTransformer,
            contextTextSize,
          ),
        )}
      </span>
    </>
  );
});
