import React from 'react';
import { Icon } from '../../../../../../components/Icon';
import { Chip } from '../../../../../../components/Chip';
import { IconSpan } from '../../../../../../components/IconSpan';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  className: string;
  text: string;
  icon: string;
  scorecardSize?: number;
  completed?: boolean;
}

function ActionItemContext(props: PropTypes) {
  if (props.completed) {
    return <Icon className={props.className} name='bi-check-lg' />;
  } else if (props.scorecardSize === 1) {
    return null;
  }

  return (
    <Chip className='bg-light-danger text-primary' size='sm'>
      {props.scorecardSize}
    </Chip>
  );
}

export function ActionItemLink(props: PropTypes) {
  return (
    <div
      className={classNames(
        styles['action-item'],
        !props.completed && props.scorecardSize > 1
          ? styles['action-item-padding-with-badge']
          : 'py-2',
        'px-2h rounded-1',
      )}
    >
      <div
        className={classNames(
          styles['action-item-line-height'],
          'd-flex justify-content-between',
        )}
      >
        <IconSpan
          spanText={props.text}
          icon={{ name: props.icon }}
          className={classNames(props.className, 'fw-normal')}
        />
        <ActionItemContext {...props} />
      </div>
    </div>
  );
}
