import React, { useContext } from 'react';
import { Control } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SetupJobContext } from '../../index';

interface PropTypes {
  formControl: Control<CreateJobDto>;
}

export const DEFAULT_MAX_JOB_OPENINGS = 100;

// Generates options from 1 to max
export function buildJobOpeningsOptions(
  maxJobOpenings?: number,
): SelectOption[] {
  return Array.from(
    { length: maxJobOpenings ?? DEFAULT_MAX_JOB_OPENINGS },
    (_, i) => (i + 1).toString(),
  ).map((num: string) => {
    return { value: num, label: num };
  });
}

export function JobOpeningsInput(props: PropTypes) {
  const setupJobContext = useContext(SetupJobContext);
  const maxJobOpenings = setupJobContext.jobRequisition?.openings;
  const required = maxJobOpenings != null;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Job Openings'}
          placeholder={'Select number of openings'}
          formHookContext={{
            controllerName: 'jobOpenings',
            formControl: props.formControl,
            required: required,
          }}
          options={buildJobOpeningsOptions(maxJobOpenings)}
        />
      </Col>
    </Row>
  );
}
