export function applicationStatusClass(name: string): string {
  switch (name) {
    case 'active':
      return 'text-white bg-success';
    case 'archived':
      return 'text-white bg-danger';
    case 'referral':
      return 'text-primary bg-light-info';
    case 'draft':
      return 'text-primary bg-light-warning';
    case 'hired':
      return 'text-primary bg-light-success';
    case 'rejected':
      return 'text-primary bg-light-danger';
  }
}
