import React, { useState } from 'react';
import { BackArrow } from '../../../../../components/BackArrow';
import { PageTitle } from '../../../../../components/PageTitle';
import { JobRequisition } from '../../../../../entities/v1/applicant_tracking/JobRequisition';
import { StatusChip } from './StatusChip';
import { User } from '../../../../../entities/User';
import { OwnerOrAdminActions } from './Actions/OwnerOrAdminActions';
import { ApproverActions } from './Actions/ApproverActions';
import { DeclinedAction } from './Actions/DeclinedAction';
import {
  buildSteps,
  getCurrentStepOrder,
} from '../../../../../utils/applicant_tracking/jobRequisition';
import { Alert, AlertObject } from '../../../../../components/Alert';

const BACK_URL = '/applicant_tracking/job_requisitions';

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  jobRequisitionV2FfEnabled: boolean;
  setLoadingState: (loading: boolean) => void;
  reloadJobRequisition: () => void;
}

function isUserAbleToEdit(props: PropTypes): boolean {
  return (
    props.currentUser.isAdmin ||
    props.currentUser.id === props.jobRequisition.ownerUserId
  );
}

function isApprover(props: PropTypes): boolean {
  const approvalSteps = buildSteps(props.jobRequisition.approvers);
  const currentStepOrder = getCurrentStepOrder(approvalSteps);

  const approver = props.jobRequisition.approvers.find(
    (approver) => approver.user.id === props.currentUser.id,
  );
  if (!approver) {
    return false;
  }

  return isUserAbleToEdit(props) ? approver.order === currentStepOrder : true;
}

function HeaderActions(
  props: PropTypes & { setAlertMessage: (message: AlertObject) => void },
) {
  if (props.jobRequisition.status === 'declined') {
    return <DeclinedAction {...props} />;
  }

  if (isApprover(props)) {
    return (
      <ApproverActions {...props} setAlertMessage={props.setAlertMessage} />
    );
  }

  if (isUserAbleToEdit(props)) {
    return (
      <OwnerOrAdminActions {...props} setAlertMessage={props.setAlertMessage} />
    );
  }
}

export function Header(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState<AlertObject>(null);

  return (
    <>
      {alertMessage && (
        <Alert
          type={alertMessage.type}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage.message}
        </Alert>
      )}
      <div className='d-flex align-items-center mb-4 justify-content-between'>
        <div className='d-flex align-items-center'>
          <BackArrow url={BACK_URL} />
          <PageTitle
            text={`${props.jobRequisition.name} - Requisition Summary`}
          />
          <StatusChip status={props.jobRequisition.status} />
        </div>
        <HeaderActions {...props} setAlertMessage={setAlertMessage} />
      </div>
    </>
  );
}
