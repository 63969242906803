import React from 'react';
import { Select, SelectOption } from '../../../../../components/Select';

interface PropTypes {
  label: string;
  options: SelectOption[];
  value: string;
  name: string;
  mandatory?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  onInputChange: (name: string, value: unknown) => void;
}

export function SimpleSelectField(props: PropTypes) {
  return (
    <div>
      <label className='form-label'>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
      <Select
        options={props.options}
        isClearable={props.isClearable}
        selected={props.options.find((option) => option.value === props.value)}
        onChange={(option) => props.onInputChange(props.name, option?.value)}
        placeholder={props.placeholder}
      />
    </div>
  );
}
