import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { capitalize } from 'humanize-plus';
import { Chip } from '../../../../../../../../components/Chip';
import {
  ListCandidate,
  ListCandidateApplication,
} from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { applicationStatusClass } from '../../../../../../../../utils/applicationStatusClass';
import {
  appendRedirectUri,
  removeTrailingSlashes,
} from '../../../../../../../../utils/url';
import { HrisEmployee } from '../../../../../../../../entities/applicant_tracking/HrisEmployees';
import { AvatarLink } from '../../../../../../../../components/AvatarLink';
import { getHrisLogo } from '../../../../../../../../utils/hris';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import { Icon } from '../../../../../../../../components/Icon';

interface PropTypes {
  candidate: ListCandidate;
  hrisEmployee?: HrisEmployee;
  application: ListCandidateApplication;
}

interface ApplicationBadgePropTypes {
  application: ListCandidateApplication;
}

function Badge(props: { status: string; statusClass: string }) {
  return (
    <Chip className={classNames('ms-2', 'fw-semibold', props.statusClass)}>
      {capitalize(props.status)}
    </Chip>
  );
}

function ApplicationBadge(props: ApplicationBadgePropTypes) {
  if (['archived', 'active'].includes(props.application.status)) return null;

  return (
    <Badge
      status={props.application.status}
      statusClass={applicationStatusClass(props.application.status)}
    />
  );
}

function NameWithLink(props: { application_id: number; name: string }) {
  const [candidateUrl, setCandidateUrl] = useState<string>('');

  function buildCandidateUrl() {
    return appendRedirectUri(
      `${removeTrailingSlashes(window.location.origin)}/applications/${
        props.application_id
      }`,
    );
  }

  useEffect(() => {
    setCandidateUrl(buildCandidateUrl());
  }, [window.location.href]);

  return (
    <a href={candidateUrl} className='text-truncate'>
      {props.name}
    </a>
  );
}

export function Name(props: PropTypes) {
  const hrisLogo = getHrisLogo(props.hrisEmployee?.provider);

  return (
    <div className='d-flex align-items-center fs-5'>
      <div className='fw-semibold text-truncate'>
        <div className='d-flex align-items-center'>
          {props.candidate.application != null ? (
            <>
              <NameWithLink
                application_id={props.candidate.application.id}
                name={props.candidate.displayName}
              />
              {props.candidate.redacted && (
                <SpanTooltip
                  className='ms-2'
                  text={
                    <Icon className='text-blue-200 fs-6' name='bi-file-lock2' />
                  }
                  tooltipText='Candidate information has been redacted to reduce bias.'
                />
              )}
              {hrisLogo && (
                <AvatarLink
                  name={hrisLogo}
                  type={'photo'}
                  size='sm'
                  url={props.hrisEmployee?.externalUrl}
                  className={'ms-2'}
                />
              )}
              <ApplicationBadge application={props.application} />
            </>
          ) : (
            <>
              <span className='text-truncate'>
                {props.candidate.displayName}
              </span>
              <Badge
                status={'draft'}
                statusClass={applicationStatusClass('draft')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
