import classNames from 'classnames';
import { Chip } from '../../../../components/Chip';
import React from 'react';

interface PropTypes {
  classNames: string;
  text: React.ReactNode;
}

export function CandidateHeaderBadge(props: PropTypes) {
  return (
    <Chip className={classNames('fw-semibold', props.classNames)}>
      {props.text}
    </Chip>
  );
}
