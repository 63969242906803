import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { VcsProvider } from '../../../../enums/IntegrationProvider/VcsProvider';

export interface UpsertInterviewTemplateRequest {
  id?: number;
  name: string;
  participantUserIds: number[];
  organizerUserId: number;
  meetingDuration: number;
  meetingProvider?: VcsProvider;
  scheduleFromHours: number;
  dateRange: number;
  timezone: string;
}

export class InterviewTemplateService {
  public static upsert(
    interview_template: UpsertInterviewTemplateRequest,
  ): Promise<void> {
    if (interview_template.id) {
      return this.update(interview_template);
    }

    return this.create(interview_template);
  }

  public static create(
    interview_template: UpsertInterviewTemplateRequest,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/interview_templates`,
      snakecaseKeys({ interview_template }),
    );
  }

  public static update(
    interview_template: UpsertInterviewTemplateRequest,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/interview_templates/${interview_template.id}`,
      snakecaseKeys({ interview_template }),
    );
  }
}
