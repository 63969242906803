import classNames from 'classnames';
import React from 'react';
import { ChangeHandler, RefCallBack } from 'react-hook-form';

export interface InputLabelPropTypes {
  labelComponent?: JSX.Element;
  label?: React.ReactNode;
  labelSpanClasses?: string;
  mandatory?: boolean;
}

export interface PropTypes {
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: ChangeHandler;
  checkboxRef?: RefCallBack;
  name: string;
  label?: React.ReactNode;
  labelClasses?: string;
  labelSpanClasses?: string;
  labelInputClasses?: string;
  labelComponent?: JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
}

function Label(props: InputLabelPropTypes) {
  if (props.labelComponent != null) {
    return props.labelComponent;
  } else if (props.label != null) {
    return (
      <label className={classNames('ps-2 fs-6', props.labelSpanClasses)}>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
    );
  } else {
    return null;
  }
}

export function CheckboxButton(props: PropTypes) {
  return (
    <div
      className={classNames('d-flex align-items-center', props.labelClasses)}
    >
      <input
        ref={props.checkboxRef}
        onBlur={props.onBlur}
        className={classNames('form-check-input mt-0', props.labelInputClasses)}
        type='checkbox'
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      />
      <Label
        label={props.label}
        labelSpanClasses={props.labelSpanClasses}
        labelComponent={props.labelComponent}
        mandatory={props.mandatory}
      />
    </div>
  );
}
