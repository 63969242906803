import { FilterData } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { ApiService } from '../../../ApiService';

export class FilterService {
  public static show(): Promise<FilterData> {
    return ApiService.get<FilterData>(
      '/api/v1/applicant_tracking/analytics/filters',
      {},
    );
  }
}
