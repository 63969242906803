import React from 'react';
import { QuestionAverage } from '../../../../../entities/candidate_experience/QuestionAverage';
import { QuestionAveragesPanel } from '../../../shared_panels/QuestionAveragesPanel';
import { SelectOption } from '../../../../../components/Select';

interface PropTypes {
  questionAverages: QuestionAverage[];
  benchmarkType: SelectOption;
}

export default function ProcessPanel(props: PropTypes) {
  return (
    <QuestionAveragesPanel
      questionAverages={props.questionAverages}
      benchmarkType={props.benchmarkType}
      title='Process'
      isLoading={isLoading(props.questionAverages)}
    />
  );
}

function isLoading(questionAverages: QuestionAverage[]) {
  return questionAverages === undefined || questionAverages.length === 0;
}
