import sjcl from 'sjcl';

const CHIP_COLORS = [
  'light-info',
  'light-warning',
  'light-success',
  'light-danger',
  'blue-gray-light',
];

export function jobStageClass(name: string) {
  if (name == null) return '';
  const index = parseInt(
    sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(name)),
    16,
  );
  return `bg-${CHIP_COLORS[index % CHIP_COLORS.length]} text-primary`;
}
