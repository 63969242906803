import React, { useState } from 'react';
import { Candidate } from '../../../../../../entities/applicant_tracking/Candidate';
import { User } from '../../../../../../entities/User';
import { LabelledAvatar } from '../../../../../../components/LabelledAvatar';
import classNames from 'classnames';
import { Chip } from '../../../../../../components/Chip';
import { AddParticipant } from './AddParticipant';
import { InterviewerParticipantRow } from '././InterviewerParticipantRow';
import { Alert, AlertType } from '../../../../../../components/Alert';
import { InterviewerParticipant } from '../../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  candidate?: Candidate;
  participants: InterviewerParticipant[];
  setParticipants: (participants: InterviewerParticipant[]) => void;
  availableParticipants: User[];
  candidateView: boolean;
  onChangingMeetingOrganizer: (participant: InterviewerParticipant) => void;
  allowChangingMeetingOrganizer: boolean;
  allowIgnoreParticipantCalendar: boolean;
  disableAlert?: boolean;
  isRequired?: boolean;
  titleClassName?: string;
  isReadOnly?: boolean;
}

export function InterviewParticipants(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(AlertType.Success);

  const onChangingHost = (participant: InterviewerParticipant) => {
    props.participants.find(
      (participant) => participant.isMeetingOrganizer,
    ).isMeetingOrganizer = false;
    participant.isMeetingOrganizer = true;

    props.onChangingMeetingOrganizer(participant);

    setAlertType(AlertType.Success);
    setAlertMessage(
      <>
        <p>The meeting host was successfully updated.</p>
        <p>
          Once the meeting is scheduled you won’t be able to change the host.
        </p>
      </>,
    );
  };

  const onIgnoringCalendar = (participant: InterviewerParticipant) => {
    const ignored = !participant.ignoreCalendar;
    participant.ignoreCalendar = !participant.ignoreCalendar;

    // create clone so the date time picker rerenders
    props.setParticipants([...props.participants]);

    setAlertType(AlertType.Success);

    if (ignored) {
      setAlertMessage(
        <p>{participant.user.name} calendar has been ignored.</p>,
      );
    } else {
      setAlertMessage(
        <p>{participant.user.name} calendar has been considered.</p>,
      );
    }
  };

  return (
    <>
      {!props.disableAlert && alertMessage && (
        <Alert
          type={alertType}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
          className={'mt-0'}
        >
          {alertMessage}
        </Alert>
      )}
      <div data-testid='participants' className='mt-3'>
        <div className={'mb-2h'}>
          <label
            className={classNames(
              props.titleClassName || 'text-dark-500',
              'd-inline',
              'mb-2',
              'fs-5',
            )}
          >
            Participants
          </label>
          {props.isRequired && (
            <span className='d-inline invalid-feedback ps-1'>*</span>
          )}
        </div>
        <div>
          {props.candidate && (
            <div data-testid='candidate' className='d-flex'>
              <LabelledAvatar
                name={props.candidate.name}
                label={props.candidate.name}
              />
              {!props.candidateView && (
                <Chip
                  className={classNames(
                    'bg-light-info',
                    'text-primary',
                    'ms-2h',
                    'w-10',
                  )}
                >
                  Candidate
                </Chip>
              )}
            </div>
          )}
          {props.participants.map((participant) => (
            <InterviewerParticipantRow
              key={participant.user.id}
              participant={participant}
              participants={props.participants}
              candidateView={props.candidateView}
              setParticipants={props.setParticipants}
              onChangingHost={onChangingHost}
              onIgnoringCalendar={onIgnoringCalendar}
              allowChangingMeetingOrganizer={
                props.allowChangingMeetingOrganizer
              }
              allowIgnoreParticipantCalendar={
                props.allowIgnoreParticipantCalendar
              }
              isReadOnly={props.isReadOnly}
            />
          ))}
        </div>
        {!props.candidateView && !props.isReadOnly && (
          <AddParticipant
            availableParticipants={props.availableParticipants}
            participants={props.participants}
            setParticipants={props.setParticipants}
          />
        )}
      </div>
    </>
  );
}
