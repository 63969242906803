import React, { useContext, useState } from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { PlanTemplateService } from '../../../../services/applicant_tracking/PlanTemplateService';
import { UpsertPlanTemplateDto } from '../DTOs/UpsertPlanTemplateDto';
import {
  mapJobStagesToStagePlanTemplates,
  processJobStageIndexes,
} from '../../../../utils/applicant_tracking/planTemplate';
import { JobWorkflowEditContext, JobWorkflowEditContextProps } from '..';
import { FormBody } from './FormBody';
import { CardHeader } from './CardHeader';
import { getRedirectUriQueryParam } from '../../../../utils/url';

interface PropTypes {
  setShowAddStage: (showAddStage: boolean) => void;
}

const handleSubmit = async (
  data: UpsertPlanTemplateDto,
  pageContext: JobWorkflowEditContextProps,
) => {
  pageContext.setIsLoading(true);
  const stagePlanTemplates = processJobStageIndexes(
    mapJobStagesToStagePlanTemplates(pageContext.jobStages),
  );

  try {
    await PlanTemplateService.upsert({
      id: pageContext.planTemplate?.id,
      name: data.name,
      stages: stagePlanTemplates,
      stageMoves: pageContext.stageMoves,
    });

    window.location.href =
      getRedirectUriQueryParam() ?? '/organization_settings/job_workflow';
  } catch (e: unknown) {
    handleFormErrors(e, pageContext.setError);
    pageContext.setIsLoading(false);
  }
};

export function PlanTemplateBody(props: PropTypes) {
  const pageContext = useContext(JobWorkflowEditContext);
  const [isValidWorkflow, setIsValidWorkflow] = useState<boolean>(
    pageContext.isValid,
  );

  const onSubmit = pageContext.handleSubmit((data) =>
    handleSubmit(data, pageContext),
  );

  return (
    <form onSubmit={onSubmit}>
      <CardHeader
        pageContext={pageContext}
        isValid={isValidWorkflow}
        onCancel={() => history.back()}
      />
      <DeprecatedPanel
        additionalInnerContainerClassNames='p-4'
        className='mt-4'
      >
        <FormBody
          setShowAddStage={props.setShowAddStage}
          setIsValidWorkflow={setIsValidWorkflow}
        />
      </DeprecatedPanel>
    </form>
  );
}
