import React, { useEffect, useRef, useState } from 'react';
import {
  AlertMessage,
  ListCandidatesPageHeader,
} from './ListCandidatesPageHeader';
import { ListCandidatesPageBody } from './ListCandidatesPageBody';
import {
  ListCandidates,
  ListCandidate,
} from '../../../../entities/applicant_tracking/ListCandidate';
import {
  FilterStates,
  appendFilterStatesToCurrentTab,
  parseCurrentFilterState,
  resetPageIfFiltersChanged,
} from './FilterStates';
import { Job } from '../../../../entities/applicant_tracking/Job';
import { JobStageTemplate } from '../../../../entities/applicant_tracking/JobStageTemplate';
import ListPageEmptyState from '../../ListPageEmptyState';
import { ListCandidateService } from '../../../../services/applicant_tracking/ListCandidatesService';
import { User } from '../../../../entities/User';
import { Alert, AlertObject } from '../../../../components/Alert';
import { HrisEmployeesService } from '../../../../services/applicant_tracking/HrisEmployeesService';
import {
  HrisEmployee,
  HrisEmployees,
} from '../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { EmailAccountIntegration } from '../../../../entities/EmailAccountIntegration';

interface PropTypes {
  alertMessage: AlertMessage;
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  hrisProvider: HrisProvider;
  jobs: Job[];
  jobStages: JobStageTemplate[];
  policies: CandidatePolicies;
  totalCandidates: number;
}

export interface CandidatePolicies {
  addCandidateEnabled: boolean;
}

export type ListCandidatesFilterTab =
  | 'all'
  | 'active'
  | 'hired'
  | 'rejected'
  | 'draft';

export default function ListCandidatesPage(props: PropTypes) {
  const requestsCount = useRef(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCandidates, setSelectedCandidates] = useState<ListCandidate[]>(
    [],
  );
  const [totalFilteredCandidates, setTotalFilteredCandidates] =
    useState<number>(0);
  const [candidates, setCandidates] = useState<ListCandidate[]>([]);
  const [hrisEmployees, setHrisEmployees] = useState<HrisEmployee[]>([]);
  const jobOptions = props.jobs.map((j) => ({
    label: j.name,
    value: j.id.toString(),
  }));
  const jobStageOptions = props.jobStages.map((js) => ({
    label: js.name,
    value: js.id.toString(),
  }));
  const [filterStates, setFilterStates] = useState<FilterStates>(
    parseCurrentFilterState(jobOptions, jobStageOptions),
  );
  const [previousFilterStates, setPreviousFilterStates] =
    useState<FilterStates>(filterStates);
  const [alert, setAlert] = useState<AlertObject>(null);

  const loadCandidates = () => {
    const currentRequestCount = ++requestsCount.current;
    setIsLoading(true);

    ListCandidateService.list(filterStates).then((response: ListCandidates) => {
      const { candidates, entriesCount } = response;
      const applicationIds = candidates.map((c) => c.application?.id);
      if (currentRequestCount != requestsCount.current) return;

      setCandidates(candidates);
      setTotalFilteredCandidates(entriesCount);

      applicationIds.length > 0 &&
        HrisEmployeesService.list({ applicationIds }).then(
          (response: HrisEmployees) => {
            setHrisEmployees(response.employees);
          },
        );

      setIsLoading(false);
    });
  };

  useEffect(() => {
    resetPageIfFiltersChanged(previousFilterStates, filterStates);
    setPreviousFilterStates(filterStates);
    loadCandidates();
    appendFilterStatesToCurrentTab(filterStates);
  }, [filterStates]);

  const reloadPage = () => {
    setSelectedCandidates([]);
    loadCandidates();
  };

  return props.totalCandidates === 0 ? (
    <ListPageEmptyState
      title={'No candidates added yet'}
      text={'Create your first candidate to start using it on your ATS.'}
      addButtonText={'Add Candidate'}
      onClickHref={'/applicant_tracking/candidate_wizard'}
      disabled={!props.policies.addCandidateEnabled}
    />
  ) : (
    <>
      {alert?.message && (
        <Alert
          type={alert.type}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      <ListCandidatesPageHeader
        alertMessage={props.alertMessage}
        currentUser={props.currentUser}
        candidates={candidates}
        selectedCandidates={selectedCandidates}
        addCandidateEnabled={props.policies.addCandidateEnabled}
        emailAccountIntegration={props.emailAccountIntegration}
        reloadPage={reloadPage}
        setAlert={setAlert}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
      />
      <ListCandidatesPageBody
        candidates={candidates}
        currentUser={props.currentUser}
        emailAccountIntegration={props.emailAccountIntegration}
        filterStates={filterStates}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
        isLoading={isLoading}
        selectedCandidates={selectedCandidates}
        setSelectedCandidates={setSelectedCandidates}
        totalFilteredCandidates={totalFilteredCandidates}
        onFilterStatesUpdate={setFilterStates}
        reloadPage={reloadPage}
        setAlert={setAlert}
      />
    </>
  );
}
