import React, { useRef } from 'react';
import { Col } from 'reactstrap';
import { Chip } from '../../../../../../../../components/Chip';
import { StarRating } from '../../../../../../../../components/StarRating';
import { useResizeDetector } from 'react-resize-detector';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import classNames from 'classnames';

interface PropTypes {
  id: string;
  editable: boolean;
  fillColorClass?: string;
  name: string;
  rating?: number;
  preview?: boolean;
  onChangeRating?: (rating: number) => void;
}

export function ScorecardAttributeValue(props: PropTypes) {
  const textRef = useRef(null);
  const { width, ref } = useResizeDetector();
  // The badge padding is taken into account to calculate the overflow.
  const badgePadding = 24;

  return (
    <div className='d-flex flex-nowrap justify-content-between align-items-center'>
      <Col xs='8'>
        <div ref={ref}>
          <Chip className='bg-light-info text-primary mw-100' size='md'>
            <div
              className={classNames('text-truncate', {
                'text-dark-500': props.preview,
              })}
              ref={textRef}
            >
              {textRef?.current?.scrollWidth + badgePadding > width ? (
                <SpanTooltip
                  text={props.name}
                  tooltipStyle={{ maxWidth: '300px' }}
                  placement={'top-start'}
                />
              ) : (
                props.name
              )}
            </div>
          </Chip>
        </div>
      </Col>
      <Col className='d-flex justify-content-end' xs='4'>
        <StarRating
          id={props.id}
          editable={props.editable}
          rating={props.rating}
          setRating={props.onChangeRating}
          fillColorClass={props.fillColorClass}
        />
      </Col>
    </div>
  );
}
