import classNames from 'classnames';
import React from 'react';
import { DeprecatedPanel } from '../DeprecatedPanel';
import UndrawEmptyState from '../../images/undraw_empty_state.svg';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';

interface PropTypes {
  header?: JSX.Element;
  title: string;
  cardless?: boolean;
  text?: JSX.Element | string;
  excludeImage?: boolean;
  imageSrc?: string;
  imageClassNames?: string;
  homepageLink?: boolean;
  classNames?: string;
  innerMostClassName?: string;
  actionButton?: React.ReactNode;
  titleClassName?: string;
  textFontSize?: string;
}

function BodyEmptyState(props: PropTypes) {
  const excludeImage = props.excludeImage || false;

  return (
    <>
      {!excludeImage && (
        <img
          className={classNames(
            'img-fluid',
            'w-80',
            styles.illustration,
            props.imageClassNames,
          )}
          src={props.imageSrc || UndrawEmptyState}
        />
      )}

      <h2 className={classNames('mb-3', styles.title, props.titleClassName)}>
        {props.title}
      </h2>
      <div className={classNames(props.textFontSize, 'fs-4')}>{props.text}</div>
      {props.actionButton}
    </>
  );
}

function EmptyStateContainer(props: PropTypes) {
  return (
    <>
      {props.header && <div className='p-4'>{props.header}</div>}
      <div
        className={classNames(
          'col-6',
          'mx-auto',
          'text-center',
          'my-5',
          props.classNames,
          styles.container,
        )}
      >
        <BodyEmptyState {...props} />
      </div>
      {props.homepageLink && (
        <Button color='primary' onClick={() => (window.location.href = '/')}>
          Back to the homepage
        </Button>
      )}
    </>
  );
}

export function EmptyState(props: PropTypes) {
  return props.cardless ? (
    <EmptyStateContainer {...props} />
  ) : (
    <DeprecatedPanel innerMostClassName={props.innerMostClassName}>
      <EmptyStateContainer {...props} />
    </DeprecatedPanel>
  );
}
