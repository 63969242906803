import React from 'react';
import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { JobRequisitionDto } from '../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { Name } from './Inputs/Name';
import { RequisitionId } from './Inputs/RequisitionId';
import { Row } from 'reactstrap';
import { RequisitionOwner } from './Inputs/RequisitionOwner';
import { StartDate } from './Inputs/StartDate';
import { Reason } from './Inputs/Reason';
import { DocumentAttachment } from './Inputs/DocumentAttachment';
import { Description } from './Inputs/Description';
import { HorizontalDivider } from '../../../../../components/HorizontallDivider';
import { Department } from './Inputs/Department';
import { Location } from './Inputs/Location';
import { HiringManager } from './Inputs/HiringManager';
import { SalaryRange } from './Inputs/SalaryRange';
import { JobOpenings } from './Inputs/JobOpenings';
import { EmploymentType } from './Inputs/EmploymentType';
import { WorkingModel } from './Inputs/WorkingModel';
import { SalaryCurrency } from './Inputs/SalaryCurrency';

interface PropTypes {
  formState: FormState<JobRequisitionDto>;
  formRegister: UseFormRegister<JobRequisitionDto>;
  formControl?: Control<JobRequisitionDto>;
  formWatch: UseFormWatch<JobRequisitionDto>;
  getValues?: UseFormGetValues<JobRequisitionDto>;
  setValue?: UseFormSetValue<JobRequisitionDto>;
  formResetField: (controllerName: string) => void;
  reValidate: UseFormTrigger<JobRequisitionDto>;
}

export function JobRequisitionForm(props: PropTypes) {
  return (
    <>
      <Row className='mb-4 gx-3'>
        <Name formRegister={props.formRegister} />
        <RequisitionId formRegister={props.formRegister} />
      </Row>
      <Row className='mb-4 gx-3'>
        <RequisitionOwner formControl={props.formControl} />
        <StartDate
          formControl={props.formControl}
          formResetField={props.formResetField}
        />
      </Row>
      <Row className='mb-4'>
        <Reason formControl={props.formControl} />
      </Row>
      <Row className='mb-4'>
        <DocumentAttachment
          getValues={props.getValues}
          setValue={props.setValue}
          formRegister={props.formRegister}
        />
      </Row>
      <Row className='mb-4'>
        <Description
          formControl={props.formControl}
          getValues={props.getValues}
          formRegister={props.formRegister}
        />
      </Row>
      <HorizontalDivider classNames='my-4' />
      <h3>Job Details</h3>
      <div className='fs-6 text-dark-200 mb-4'>
        Define and configure job details
      </div>
      <Row className='mb-4 gx-3'>
        <Department formControl={props.formControl} />
        <EmploymentType
          formResetField={props.formResetField}
          formControl={props.formControl}
        />
      </Row>
      <Row className='mb-4 gx-3'>
        <HiringManager formControl={props.formControl} />
        <JobOpenings formControl={props.formControl} />
      </Row>
      <Row className='mb-4 gx-3'>
        <Location formControl={props.formControl} />
        <WorkingModel
          formResetField={props.formResetField}
          formControl={props.formControl}
        />
      </Row>
      <Row className='mb-4 gx-3'>
        <SalaryRange formRegister={props.formRegister} />
        <SalaryCurrency
          setValue={props.setValue}
          formResetField={props.formResetField}
          formControl={props.formControl}
        />
      </Row>
    </>
  );
}
