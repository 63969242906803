import React, { useEffect, useState } from 'react';
import { SurveyMetricsTitle } from './SurveyMetricsTitle';
import { AnalyticsPanel } from '../../AnalyticsPanel';
import { SurveyMetrics } from '../../../entities/survey_engine/SurveyMetrics';
import { SurveyMetricsContent } from './SurveyMetricsContent';
import { LazyContent } from '../LazyContent';
import { FilterStates } from '../../candidate_experience/Filters/filterState';
import { SurveyMetricsService } from '../../../services/candidate_experience/SurveyMetricsService';

interface PropTypes {
  // Either the surveyMetrics (for sync rendering) or the filterStates (for
  // async rendering) need to be passed.
  surveyMetrics?: SurveyMetrics;
  filterStates?: Partial<FilterStates>;
  benchmarkLabel: string;
  isLoading?: boolean;
}

export function SurveyMetricsPanel(props: PropTypes) {
  const [data, setData] = useState<SurveyMetrics>(null);

  useEffect(() => {
    setData(null);

    if (!props.filterStates) {
      setData(props.surveyMetrics);
      return;
    }

    SurveyMetricsService.get(
      props.filterStates.filterSelector?.selected?.value,
      props.filterStates.jobs?.selected?.map((option) => option.value),
      props.filterStates.departments?.selected?.map((option) => option.value),
      props.filterStates.hiringManagers?.selected?.map(
        (option) => option.value,
      ),
      props.filterStates.recruiters?.selected?.map((option) => option.value),
      props.filterStates.locations?.selected?.map((option) => option.value),
      props.filterStates.surveyTemplates?.selected?.map(
        (option) => option.value,
      ),
      props.filterStates.benchmarkType?.selected?.value,
      props.filterStates.dateRange,
      props.filterStates.ethnicities?.selected?.map((option) => option.value),
      props.filterStates.genders?.selected?.map((option) => option.value),
      props.filterStates.ageRanges?.selected?.map((option) => option.value),
    ).then((analytics) => {
      setData(analytics);
    });
  }, [props.filterStates]);

  return (
    <AnalyticsPanel
      title={
        <SurveyMetricsTitle
          surveyMetrics={data}
          benchmarkLabel={props.benchmarkLabel}
        />
      }
    >
      <LazyContent isLoading={!data} isEmpty={data?.totalSent === 0}>
        <SurveyMetricsContent surveyMetrics={data} />
      </LazyContent>
    </AnalyticsPanel>
  );
}
