import React from 'react';
import classNames from 'classnames';
import { Chip } from '../../../../components/Chip';

export function JobHeaderBadge(props: {
  text: React.ReactNode;
  classNames: string;
}) {
  return (
    <Chip className={classNames('fw-semibold', props.classNames)}>
      {props.text}
    </Chip>
  );
}
