import React, { useEffect, useState } from 'react';
import { User } from '../../../../../entities/User';
import { ScorecardService } from '../../../../../services/v1/applicant_tracking/ScorecardService';
import { ListScorecard } from '../../../../../entities/applicant_tracking/Scorecard';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { EmptyStateContainer } from './EmptyStateContainer';
import { ScorecardListPanel } from '../ScorecardListPanel';

interface PropTypes {
  currentUser: User;
  applicationId: number;
  viewAllScorecards: boolean;
  ownScorecardsParam?: boolean;
  statusParam?: string;
}

export function Body(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listScorecards, setListScorecards] = useState<ListScorecard>(null);

  useEffect(() => {
    (async () => {
      const scorecards = await ScorecardService.list({
        applicationId: props.applicationId,
        status: props.statusParam,
        ownScorecards: props.ownScorecardsParam,
        expand: [
          'user',
          'interview_stage',
          'survey',
          'survey.sections',
          'survey.sections.questions',
          'survey.answers',
        ],
      });
      setListScorecards(scorecards);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      {listScorecards?.entriesCount === 0 ? (
        <EmptyStateContainer />
      ) : (
        <ScorecardListPanel
          scorecards={listScorecards?.scorecards || []}
          currentUser={props.currentUser}
          viewAllScorecards={props.viewAllScorecards}
        />
      )}
    </>
  );
}
