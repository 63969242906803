import React, { useEffect, useState } from 'react';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';
import { appendQueryParam, getRedirectUriQueryParam } from '../../../utils/url';
import { IconSpan } from '../../../components/IconSpan';
import { ApplicationsContainer } from './ApplicationsContainer';
import { getApplicationIdsToBulkReview } from '../../../utils/applicant_tracking/bulkApplicationReview';

export type BULK_REVIEW_NAVIGATION_TYPE = 'rejected' | 'moved' | 'skipped';

function prepareRedirectUri(
  uri: string,
  rejectedCount: number,
  movedToNextStageCount: number,
  skippedCount: number,
): string {
  uri = appendQueryParam(uri, 'rejected', `${rejectedCount}`);
  uri = appendQueryParam(uri, 'moved', `${movedToNextStageCount}`);
  uri = appendQueryParam(uri, 'skipped', `${skippedCount}`);
  return uri;
}

export default function BulkApplicationReview() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [preSelectedRejectReasonId, setPreSelectedRejectReasonId] =
    useState<number>(null);
  const [rejectedCount, setRejectedCount] = useState<number>(0);
  const [movedToNextStageCount, setMovedToNextStageCount] = useState<number>(0);
  const [exitedApplicationId, setExitedApplicationId] = useState<number>(null);

  const applicationIds = getApplicationIdsToBulkReview();

  const handleClose = () => {
    window.location.href = prepareRedirectUri(
      getRedirectUriQueryParam(),
      rejectedCount,
      movedToNextStageCount,
      applicationIds.length - rejectedCount - movedToNextStageCount,
    );
  };

  const handleNext = (navType: BULK_REVIEW_NAVIGATION_TYPE) => {
    switch (navType) {
      case 'rejected':
        setRejectedCount((prevCount) => prevCount + 1);
        break;
      case 'moved':
        setMovedToNextStageCount((prevCount) => prevCount + 1);
        break;
    }
    setExitedApplicationId(applicationIds[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  useEffect(() => {
    if (currentIndex + 1 > applicationIds.length) {
      handleClose();
    }
  }, [currentIndex]);

  return (
    <>
      {alert && (
        <Alert
          clearable
          type={alert.type ?? AlertType.Success}
          autoClearTimeout={4000}
          onClose={() => {
            setAlert(null);
          }}
        >
          <IconSpan
            icon={{ name: 'bi-info-circle' }}
            spanText={alert.message}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <ApplicationsContainer
        applicationIds={applicationIds}
        currentIndex={currentIndex}
        preSelectedRejectReasonId={preSelectedRejectReasonId}
        exitedApplicationId={exitedApplicationId}
        onClose={handleClose}
        setAlert={setAlert}
        setPreSelectedRejectReasonId={setPreSelectedRejectReasonId}
        onNext={handleNext}
      />
    </>
  );
}
