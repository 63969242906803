import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  title: string;
  whitelabelEnabled: boolean;
}

export function JobPostCardTitle(props: PropTypes) {
  const titleTextClass = props.whitelabelEnabled ? 'text-primary' : 'text-info';

  return (
    <h3
      className={classNames(
        'mb-0',
        'text-truncate',
        'text-start',
        titleTextClass,
      )}
    >
      {props.title}
    </h3>
  );
}
