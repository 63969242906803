import React from 'react';
import { Chip } from '../../../../Chip';
import { formatAsPercentage } from '../../../../../utils/formatAsPercentage';
import classNames from 'classnames';

interface PropTypes {
  npsRatio: number;
  npsText: string;
}

export function NpsChip(props: PropTypes) {
  return (
    <>
      <dd className='col-4 fw-bold'>
        <Chip
          className={classNames(
            'bg-light-info fw-semibold text-primary fs-6 me-2 px-3 py-2',
          )}
        >
          {formatAsPercentage(props.npsRatio)}%
        </Chip>
      </dd>
      <dd className='col-8 fw-normal fs-6 d-flex align-items-center'>
        {props.npsText}
      </dd>
    </>
  );
}
