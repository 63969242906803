import React, { useReducer } from 'react';
import { useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import {
  Action,
  initSections,
  reducer,
} from '../../../survey_engine/SurveyTemplates/dispatcher';
import { InterviewKitTemplateService } from '../../../../services/v1/applicant_tracking/InterviewKitTemplateService';
import { FIELD_CONFIGS, areSectionsSavable, fetchTemplate } from '../data';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { StartingPoint } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { InterviewKitTemplateStartingPointSelector } from '../InterviewKitTemplateStartingPointSelector';

export default function NewInterviewKitTemplatePage() {
  // State
  const [name, setName] = useState<string>('');
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);
  const [sections, dispatch] = useReducer(reducer, null, () =>
    initSections('text'),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Derived State
  function isSavable() {
    if (!name.trim()) return false;
    if (startingPoint === undefined) return false;
    if (!areSectionsSavable(sections)) return false;

    return true;
  }

  // Handlers
  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;

    if (typeof startingPoint === 'string' && startingPoint === 'FROM_SCRATCH') {
      dispatch({ type: 'reset', newState: initSections('text') });
      return;
    }

    setIsLoading(true);

    const template = await fetchTemplate(startingPoint.value);
    dispatch({ type: 'reset', newState: template.sections });

    setIsLoading(false);
  }

  async function handleSave() {
    setIsLoading(true);

    await InterviewKitTemplateService.create({
      name,
      sections: sections.map((section) => ({
        name: section.name,
        questions: section.fields.map((field) => ({
          title: field.title,
          fieldType: field.fieldType,
          max: field.max,
          aiFillEnabled: field.aiFillEnabled,
        })),
      })),
    });

    window.location.href =
      getRedirectUriQueryParam() ??
      '/organization_settings/interview_questions';
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <Body
        isSavable={isSavable()}
        name={name}
        onName={setName}
        startingPoint={startingPoint}
        onStartingPointChange={handleStartingPointChange}
        sections={sections}
        dispatch={dispatch}
        onSave={handleSave}
      />
    </>
  );
}

function Body(props: {
  isSavable: boolean;
  name: string;
  onName: (value: string) => void;
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
  sections: SectionData[];
  dispatch: React.Dispatch<Action>;
  onSave: () => void;
}) {
  return (
    <div className='container'>
      <PageHeader
        title='Add Interview Question'
        backUrl='/organization_settings/interview_questions'
      />
      <Card>
        <CardHeader
          title='Interview Questions'
          isSavable={props.isSavable}
          onSaveClick={props.onSave}
          cancelUrl='/organization_settings/interview_questions'
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput
              name={props.name}
              onName={props.onName}
              placeholder='Insert interview questions name'
            />
            <InterviewKitTemplateStartingPointSelector
              startingPoint={props.startingPoint}
              onStartingPointChange={props.onStartingPointChange}
            />
          </div>
        </div>
        <SectionList
          fieldConfigs={FIELD_CONFIGS}
          singleTypeSections={true}
          sections={props.sections}
          dispatch={props.dispatch}
          title='Questions'
          subtitle='Define and manage your interview questions.'
          addFieldButtonText='Add Question'
          fieldDeleteTooltip='Remove Question'
          defaultFieldType='text'
          columns={[
            {
              fieldName: 'text',
              className: 'col',
              label: 'Questions',
              helperText: 'Maximum 255 characters',
              placeholder: 'Insert Question',
            },
          ]}
        />
      </Card>
    </div>
  );
}
