import moment from 'moment';
import { DateFilter } from '../components/DateRangePicker';

interface DatedFilter {
  dateRange: DateFilter;
}

export function readHash<T extends DatedFilter>(defaultFilter: T): T {
  if (window.location.hash === '') return defaultFilter;
  const decoded = atob(window.location.hash.substring(1));
  const parsed: T = JSON.parse(decoded);
  parsed.dateRange.start = moment(parsed.dateRange.start);
  parsed.dateRange.end = moment(parsed.dateRange.end);
  return parsed;
}

export function writeHash<T extends DatedFilter>(filterState: T) {
  const hash = btoa(JSON.stringify(filterState));
  window.location.hash = hash;
}
