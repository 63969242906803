import { NavLink } from 'reactstrap';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { JobPageTab } from '../JobPageTab';
import { Chip } from '../../../components/Chip';

export function JobDetailsNavLink(props: {
  dataTestid: string;
  activeTab: JobPageTab;
  tab: string;
  tabTitle: string;
  newBadge?: boolean;
  handleClick: (tab: string) => void;
}) {
  return (
    <NavLink
      data-testid={props.dataTestid}
      href='#'
      active={props.activeTab === props.tab}
      className={classNames(
        'text-sm-center fw-bold',
        props.activeTab === props.tab
          ? styles.borderBottomDarkPrimary
          : styles.borderBottomTransparent,
      )}
      onClick={() => props.handleClick(props.tab)}
    >
      {props.tabTitle}
      {props.newBadge && <Chip className='ms-3 bg-info'>New</Chip>}
    </NavLink>
  );
}
