import classNames from 'classnames';
import React from 'react';
import { IconSpan } from '../../../../../components/IconSpan';
import { Size } from '../../../../../utils/types/Size';
import { Button } from 'reactstrap';

interface PropTypes {
  color: string;
  size: Size;
  previewMode?: boolean;
  hidden?: boolean;
  className?: string;
  onClick: () => void;
}

export function BackToCareersPageButton(props: PropTypes) {
  const previewMode = props.previewMode ?? false;

  return (
    <Button
      color={props.color}
      size={props.size}
      className={classNames(props.className, { invisible: props.hidden })}
      type={'button'}
      disabled={previewMode}
      onClick={props.onClick}
    >
      <IconSpan
        spanText={'Back to Careers Page'}
        icon={{ name: 'bi-arrow-left' }}
        position={'start'}
      />
    </Button>
  );
}
