import React from 'react';
import classNames from 'classnames';
import { Chip } from '../../../../../components/Chip';
import { JobRequisitionStatus } from '../../../../../entities/v1/applicant_tracking/JobRequisition';

export function StatusChip(props: { status: JobRequisitionStatus }) {
  let statusClassName: string;
  let statusText: string;

  switch (props.status.toString()) {
    case JobRequisitionStatus.Approved.toString():
      statusClassName = 'bg-success';
      statusText = 'Approved';
      break;
    case JobRequisitionStatus.Completed.toString():
      statusClassName = 'bg-blue';
      statusText = 'Completed';
      break;
    case JobRequisitionStatus.Declined.toString():
      statusClassName = 'bg-danger';
      statusText = 'Declined';
      break;
    case JobRequisitionStatus.Pending.toString():
      statusClassName = 'bg-orange text-primary';
      statusText = 'Awaiting for approval';
      break;
  }

  if (statusText == null) return;

  return (
    <Chip className={classNames('fw-semibold', 'mx-3', statusClassName)}>
      {statusText}
    </Chip>
  );
}
