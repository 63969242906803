import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { Col, Row } from 'reactstrap';
import {
  TopChoices,
  TopChoicesChartType,
} from '../../shared_panels/TopChoices';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import React from 'react';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../PageBreak';

export function DemandTopChoices(props: {
  candidateDemand: QuestionTopChoices;
}) {
  if (props.candidateDemand.top_choices.length === 0) return null;

  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        <Col xs='12'>
          <TopChoices
            title='Candidate Demand'
            subtitle={props.candidateDemand.question_text}
            topChoices={props.candidateDemand.top_choices}
            fillColor={stylesheetExports.blue500}
            type={TopChoicesChartType.Bar}
          />
        </Col>
      </Row>
    </>
  );
}
