import React, { useState } from 'react';
import { LabelledSelect } from '../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../components/Select';
import { EmailTemplate } from '../../../../../../../entities/EmailTemplate';
import { SendEmailTriggerRow } from './SendEmailTriggerRow';
import { PlanTemplate } from '../../../../../../../entities/applicant_tracking/PlanTemplate';
import { IconButton } from '../../../../../../../components/IconButton';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { Trigger } from '../../../../../../../entities/applicant_tracking/Trigger';
import { ApplicationFormTemplate } from '../../../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { AutoRejectTriggerRow } from './AutoRejectTriggerRow';
import { HrisProvider } from '../../../../../../../enums/IntegrationProvider/HrisProvider';
import { getHrisName } from '../../../../../../../utils/hris';
import { PushToHrisTriggerRow } from './PushToHrisTriggerRow';

interface PropTypes {
  counter: number;
  planTemplate: PlanTemplate;
  emailTemplates: EmailTemplate[];
  applicationFormTemplate: ApplicationFormTemplate;
  trigger: Trigger;
  triggers: Trigger[];
  jobLocation: string;
  setTriggers: (triggers: Trigger[]) => void;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
  disabled?: boolean;
  hrisProvider?: HrisProvider;
}

function showPushToHrisOption(
  selectedTriggers: Trigger[],
  currentTrigger: Trigger,
  hrisProvider?: HrisProvider,
): boolean {
  if (!hrisProvider) {
    return false;
  }

  const pushToHrisTrigger = selectedTriggers.find(
    (t) => t.type === 'push_to_hris',
  );

  // only show if it was not previously selected
  return !(!currentTrigger.id && pushToHrisTrigger);
}

function buildTriggerOptions(
  selectedTriggers: Trigger[],
  currentTrigger: Trigger,
  hrisProvider?: HrisProvider,
): SelectOption[] {
  const options = [
    {
      value: 'auto_reject',
      label: 'Auto-reject',
    },
    {
      value: 'send_email',
      label: 'Send Email',
    },
  ];

  if (showPushToHrisOption(selectedTriggers, currentTrigger, hrisProvider)) {
    options.splice(1, 0, {
      value: 'push_to_hris',
      label: `Export to ${getHrisName(hrisProvider)}`,
    });
  }

  return options;
}

function removeFromArray<T>(array: T[], index: number): T[] {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
}

function mapToSelectOption(
  selectedTriggers: Trigger[],
  currentTrigger: Trigger,
  selectedValue?: string,
  hrisProvider?: HrisProvider,
): SelectOption[] {
  return buildTriggerOptions(
    selectedTriggers,
    currentTrigger,
    hrisProvider,
  ).map((v: SelectOption) => {
    return {
      value: v.value,
      label: v.label,
      selected: v.value === selectedValue,
    };
  });
}

export function TriggerRow(props: PropTypes) {
  const [options, setOptions] = useState(
    mapToSelectOption(
      props.triggers,
      props.trigger,
      props.trigger.type,
      props.hrisProvider,
    ),
  );

  return (
    <div className='d-flex flex-nowrap justify-content-start mb-4'>
      <div className='row w-100 gx-3'>
        <TriggerRowDropdown>
          <LabelledSelect
            options={options}
            selected={options.find((v) => v.selected)}
            labelClassName='fs-5'
            label={`Trigger ${props.counter + 1}`}
            placeholder='Select trigger'
            disabled={props.disabled}
            onChange={(o) =>
              setOptions(
                mapToSelectOption(
                  props.triggers,
                  props.trigger,
                  o.value,
                  props.hrisProvider,
                ),
              )
            }
          />
        </TriggerRowDropdown>
        {options.find((value) => value.selected)?.value === 'auto_reject' && (
          <AutoRejectTriggerRow
            applicationFormTemplate={props.applicationFormTemplate}
            counter={props.counter}
            trigger={props.trigger}
            formData={props.triggers}
            jobLocation={props.jobLocation}
            onUpdateTrigger={props.onUpdateTrigger}
          />
        )}
        {options.find((value) => value.selected)?.value === 'push_to_hris' && (
          <PushToHrisTriggerRow
            trigger={props.trigger}
            counter={props.counter}
            onUpdateTrigger={props.onUpdateTrigger}
          />
        )}
        {options.find((value) => value.selected)?.value === 'send_email' && (
          <SendEmailTriggerRow
            emailTemplates={props.emailTemplates}
            planTemplate={props.planTemplate}
            counter={props.counter}
            trigger={props.trigger}
            formData={props.triggers}
            onUpdateTrigger={props.onUpdateTrigger}
          />
        )}
        {options.find((value) => value.selected) && (
          <div className='align-self-end col-md-auto'>
            <IconButton
              color='borderless'
              iconName={'bi-trash'}
              onClick={() =>
                props.setTriggers(
                  removeFromArray(props.triggers, props.counter),
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
