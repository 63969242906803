import React, { useEffect, useState } from 'react';
import { RejectedReasonService } from '../../../../services/v1/applicant_tracking/RejectedReasonService';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { appendRedirectUri } from '../../../../utils/url';
import { FormHeader } from '../components/FormHeader';
import { ReasonsInput } from '../components/ReasonsInput';
import { RejectReason } from '../../../../entities/applicant_tracking/RejectReason';
import { IconSpan } from '../../../../components/IconSpan';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { Chip } from '../../../../components/Chip';
import classNames from 'classnames';
import styles from '../styles.module.scss';

const REDIRECT_URI = '/organization_settings/reject_reasons';

interface PropTypes {
  id: number;
  default: boolean;
}

export default function ShowRejectedReasonPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rejectedReason, setRejectedReason] = useState<RejectReason>(null);

  useEffect(() => {
    async function load() {
      const rejectReason = await RejectedReasonService.show(props.id);
      setRejectedReason(rejectReason);
      setIsLoading(false);
    }

    load();
  }, [props.id]);

  if (isLoading) {
    return <LoadingSpinner showBackdrop />;
  }

  return (
    <div className='container'>
      <PageHeader title={rejectedReason.name} backUrl={REDIRECT_URI} />
      <Card className={classNames(styles['card'])}>
        <SetupPageHeader
          title='Reject Reason'
          firstItems={[]}
          intermediateItems={[
            {
              title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
              type: 'Navigate',
              action: () =>
                (window.location.href = appendRedirectUri(
                  `/organization_settings/reject_reasons/${props.id}/edit`,
                )),
              disabled: props.default,
            },
          ]}
        />
        <div className='fw-semibold fs-5 mt-4 mb-1'>Rejection type</div>
        <div>
          <Chip size='sm' className='bg-light-danger text-primary mt-1'>
            {rejectedReason.rejectedBy === 'organization'
              ? 'We rejected them'
              : 'They rejected us'}
          </Chip>
        </div>
        <FormHeader title='Rejection reason' />
        <ReasonsInput
          readonly
          singleInput
          reasons={[rejectedReason.name]}
          duplicates={[]}
        />
      </Card>
    </div>
  );
}
