import React from 'react';
import { Chip } from '../../../../../components/Chip';
import { RedactedEntry } from '../RedactedEntry';

interface PropTypes {
  label: string;
  redacted?: boolean;
  value?: string;
  valueLabel?: string;
}

export function Entry(props: PropTypes) {
  if (props.value == null || props.value == '') return null;

  return (
    <div className='d-flex align-items-center'>
      <span className='me-auto'>{props.label}</span>
      {props.redacted ? (
        <RedactedEntry label={props.value} />
      ) : (
        <Chip className='text-primary bg-gray fw-semibold fs-6'>
          {props.value}
        </Chip>
      )}
    </div>
  );
}
