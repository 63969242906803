import { ApiService } from '../../ApiService';
import { HiringMember } from '../../../entities/applicant_tracking/HiringMember';

export class HiringMemberService {
  public static list(): Promise<HiringMember[]> {
    return ApiService.get<HiringMember[]>(
      '/api/applicant_tracking/hiring_members',
    );
  }
}
