import React from 'react';
import classNames from 'classnames';
import { Chip } from '../../../../../../components/Chip';
import { CopyButton } from '../../../../../../components/CopyButton';

interface PropTypes {
  label: string;
  value: string;
  className?: string;
  copy?: boolean;
}

export function CardEntry(props: PropTypes) {
  if (props.value == null || props.value == '') return null;

  return (
    <div className={classNames('d-flex align-items-center', props.className)}>
      <span className='me-auto fs-5'>{props.label}</span>
      <Chip className='text-primary bg-gray fw-semibold fs-6'>
        {props.value}
      </Chip>
      {props.copy && (
        <CopyButton
          textToBeCopied={props.value}
          tooltipTextOnClick={'Requisition ID copied!'}
          hideText={true}
          size={'lg'}
          buttonClass={'px-2h py-0'}
        />
      )}
    </div>
  );
}
