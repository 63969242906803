import { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CandidatePanel } from './CandidatePanel';
import { ApplicationService } from '../../../../services/ApplicationService';
import { Application } from '../../../../entities/Application';
import { DetailedPanel } from './DetailedPanel';
import { AlertObject } from '../../../../components/Alert';
import { BULK_REVIEW_NAVIGATION_TYPE } from '..';
import { RedactedResumeService } from '../../../../services/v1/applicant_tracking/RedactedResumeService';

interface PropTypes {
  currentIndex: number;
  loadedIndex: number;
  applicationId: number;
  totalApplications: number;
  preSelectedRejectReasonId: number;
  exited: boolean;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  onClose: () => void;
  setAlert: (alert: AlertObject) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
}

export function ApplicationPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState<Application>(null);
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [applicationResumeUrl, setApplicationResumeUrl] =
    useState<string>(null);

  if (!props.applicationId) {
    return null;
  }

  useEffect(() => {
    (async () => {
      const loadedApplication = await ApplicationService.get(
        props.applicationId,
      );
      setApplication(loadedApplication);
      setIsLoading(false);

      if (loadedApplication.applicationResume != null) {
        if (
          loadedApplication.applicationResume.url == null &&
          loadedApplication.redacted
        ) {
          const resume = await RedactedResumeService.show(
            loadedApplication.applicationResume?.id,
          );

          setApplicationResumeUrl(resume.url);
        } else {
          setApplicationResumeUrl(loadedApplication.applicationResume.url);
        }
      }

      setIsLoadingResume(false);
    })();
  }, []);

  if (props.loadedIndex > 1 || props.exited) {
    return null;
  }

  if (isLoading) {
    return (
      <div className='h-100 w-100'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className='h-100 d-flex overflow-hidden'>
      <div className={classNames(styles['candidate-panel'])}>
        <CandidatePanel application={application} />
      </div>
      <div className='border-start w-100'>
        <DetailedPanel
          application={application}
          resumeUrl={applicationResumeUrl}
          isLoadingResume={isLoadingResume}
          index={props.currentIndex}
          {...props}
        />
      </div>
    </div>
  );
}
