import React from 'react';
import { CandidateHeaderBadge } from '../CandidateHeaderBadge';
import { appendRedirectUri } from '../../../../utils/url';
import { IconSpan } from '../../../../components/IconSpan';

interface PropTypes {
  jobId: number;
  jobName: string;
}

export function CandidatePageHeaderJobInfo(props: PropTypes) {
  return (
    <CandidateHeaderBadge
      text={
        <a
          className='text-info'
          onClick={() =>
            (window.location.href = appendRedirectUri(`/jobs/${props.jobId}`))
          }
        >
          <IconSpan
            icon={{ className: 'fs-4', name: 'bi-briefcase' }}
            spanText={props.jobName}
          />
        </a>
      }
      classNames='ms-2h bg-white'
    />
  );
}
