import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import { Control, useWatch } from 'react-hook-form';
import { UpsertStagePlanTemplateDto } from '../../DTOs/UpsertStagePlanTemplateDto';
import { SelectOption } from '../../../../../components/Select';
import { InterviewKitTemplate } from '../../../../../entities/applicant_tracking/InterviewKitTemplate';
import styles from '../styles.module.scss';
import { PreviewButton } from '../../../../../components/PreviewButton';

const FIELD_NAME = 'interviewKitTemplateId';

interface PropTypes {
  control: Control<UpsertStagePlanTemplateDto, void>;
  interviewKitTemplates: InterviewKitTemplate[];
  enabled: boolean;
  formResetField: (controllerName: string, options: any) => void;
}

function mapInterviewKitTemplatesToOptions(
  interviewKitTemplates: InterviewKitTemplate[],
): SelectOption[] {
  return interviewKitTemplates.map(
    (interviewKitTemplate: InterviewKitTemplate) => {
      return {
        value: interviewKitTemplate.id.toString(),
        label: interviewKitTemplate.name,
      };
    },
  );
}

export function InterviewKitTemplateInput(props: PropTypes) {
  const [selectedTemplate, setSelectedTemplate] =
    useState<InterviewKitTemplate>(null);
  const selectedTemplateId = useWatch({
    control: props.control,
    name: FIELD_NAME,
  });

  useEffect(() => {
    if (selectedTemplateId) {
      const selectedTemplate = props.interviewKitTemplates.find(
        (interviewKitTemplate) => interviewKitTemplate.id == selectedTemplateId,
      );
      setSelectedTemplate(selectedTemplate);
    } else {
      setSelectedTemplate(null);
    }
  }, [selectedTemplateId]);

  return (
    <div className='d-flex'>
      <Row className={classNames('mt-4', styles['input-fields'])}>
        <FormFieldHeader
          fieldName='Interview Questions'
          isRequired={props.enabled}
          classNames='fw-semibold mb-3 pb-1'
          fontSize='fs-3'
        />
        <SelectFormHook
          disabled={!props.enabled}
          placeholder={'Select questions'}
          options={mapInterviewKitTemplatesToOptions(
            props.interviewKitTemplates,
          )}
          formHookContext={{
            controllerName: FIELD_NAME,
            formControl: props.control,
            required: props.enabled,
          }}
          formResetField={props.formResetField}
        />
      </Row>
      <div className='align-self-end'>
        {selectedTemplate && (
          <PreviewButton
            className='ms-2'
            onClick={() =>
              window.open(
                `/organization_settings/interview_questions/${selectedTemplateId}/preview`,
                '_blank',
              )
            }
          />
        )}
      </div>
    </div>
  );
}
