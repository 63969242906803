import { ListCandidate } from '../../../../../../entities/applicant_tracking/ListCandidate';

export function checkIfSelectedCandidatesHaveSameStatus(
  selectedCandidates: ListCandidate[],
): boolean {
  if (selectedCandidates.length === 0) return false;

  const firstStatus = selectedCandidates[0].application.status;
  return selectedCandidates.every((c) => c.application.status === firstStatus);
}

export function checkIfAnySelectedCandidatesAreRedacted(
  selectedCandidates: ListCandidate[],
): boolean {
  return selectedCandidates.some((c) => c.redacted);
}
