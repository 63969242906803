import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  allowedFormats: string[];
  hideAllowedFormats?: boolean;
  maxFileSizeMb: number;
}

export function AttachmentInputFooter(props: PropTypes) {
  const hideFormat = props.hideAllowedFormats ?? false;

  return (
    <div
      className={classNames(
        'd-flex',
        'justify-content-between',
        'text-dark-200',
        'mt-2',
      )}
    >
      {!hideFormat && (
        <small>
          {'Allowed file formats: ' + props.allowedFormats.join(', ')}
        </small>
      )}
      <small>{`Max file size: ${props.maxFileSizeMb}MB`}</small>
    </div>
  );
}
