import React from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { Candidate } from '../../../../../entities/applicant_tracking/Candidate';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import CandidateForm from '../../CandidateForm';
import { mergeCandidateApplications } from '../../utils/mergeCandidateApplications';

interface PropTypes {
  candidate: Candidate;
  dto?: CandidateDto;
  onSuccess: (c: Candidate, dto: CandidateDto) => void;
  onStep: (n: number) => void;
  resumeParserEnabled?: boolean;
}

export function CandidateProfileStep(props: PropTypes) {
  function handleSuccessfulSubmit(
    candidate: Candidate,
    e: React.SyntheticEvent,
    dto: CandidateDto,
  ) {
    const nativeEvent = e.nativeEvent as any;
    const button = nativeEvent.submitter.value;

    props.onSuccess(candidate, dto);
    if (button === 'save_as_draft') {
      window.location.href = '/candidates';
    } else if (button === 'save_and_continue') {
      props.onStep(1);
    }
  }

  return (
    <CandidateForm
      onSuccessfulSubmit={handleSuccessfulSubmit}
      headerFactory={(formState) => (
        <FormHeader formState={formState} redirectUrl='/candidates' />
      )}
      candidateDto={
        props.dto || mergeCandidateApplications(props.candidate, [])
      }
      resumeParserEnabled={props.resumeParserEnabled}
    />
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  redirectUrl: string;
}) {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <h2>Candidate Profile</h2>
        <div className='ms-auto d-flex gap-3'>
          <Button
            type='submit'
            value='save_as_draft'
            disabled={!props.formState.isValid}
            className='text-info border-info'
          >
            Save as Draft
          </Button>
          <VerticalDivider />
          <Button
            color='danger'
            onClick={() => (window.location.href = props.redirectUrl)}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            value='save_and_continue'
            color='primary'
            disabled={!props.formState.isValid}
          >
            Save and Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
