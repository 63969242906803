import React, { useState } from 'react';
import { EmailTemplate } from '../../../../../entities/EmailTemplate';
import { Col } from 'reactstrap';
import { RadioInputFormField } from '../../../../../components/RadioInputFormField';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import { UpsertEmailTemplateDto } from '../../UpsertEmailTemplateDto';
import { Control } from 'react-hook-form';
import { SelectOption } from '../../../../../components/Select';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto, any>;
  emailTemplates: EmailTemplate[];
}

function mapEmailTemplatesToOptions(
  emailTemplates: EmailTemplate[],
): SelectOption[] {
  return emailTemplates.map((emailTemplate: EmailTemplate) => {
    return {
      value: emailTemplate.id.toString(),
      label: emailTemplate.name,
    };
  });
}

export function ReferredTemplateInput(props: PropTypes) {
  const [referredTemplate, setReferredTemplate] = useState<string>('custom');

  return (
    <>
      <Col xs={3}>
        <RadioInputFormField
          options={[
            {
              value: 'custom',
              label: 'Custom',
            },
            {
              value: 'template',
              label: 'Based on template',
            },
          ]}
          selectedValue={referredTemplate}
          onSelectInput={setReferredTemplate}
        />
      </Col>
      <Col xs={3}>
        <SelectFormHook
          disabled={referredTemplate === 'custom'}
          placeholder={'Select template'}
          options={mapEmailTemplatesToOptions(props.emailTemplates)}
          formHookContext={{
            controllerName: 'emailTemplateReferenceId',
            formControl: props.control,
            required: false,
          }}
        />
      </Col>
    </>
  );
}
