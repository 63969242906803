import { PlanTemplate } from '../../entities/applicant_tracking/PlanTemplate';
import { StagePlanTemplate } from '../../entities/applicant_tracking/StagePlanTemplate';
import { UpsertPlanTemplateDto } from '../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/UpsertPlanTemplateDto';
import { JobStageTemplate } from '../../entities/applicant_tracking/JobStageTemplate';
import { JobStageTemplateInput } from '../../containers/PlanTemplates/SetupPlanTemplatePage';
import { PlanTemplateService } from '../../services/applicant_tracking/PlanTemplateService';

export function mapJobStagesToStagePlanTemplates(
  jobStages: JobStageTemplateInput[],
): StagePlanTemplate[] {
  return jobStages?.map((stage) => ({
    id: stage.id,
    name: stage.name,
    order: stage.index,
    interviewsCount: stage.interviewsCount,
    interviewKitTemplateId: stage.interviewKitTemplate?.id,
    scorecardTemplateId: stage.scorecardTemplate?.id,
    redactionEnabled: stage.redactionEnabled,
    offerEnabled: stage.offerEnabled,
    bulkReviewEnabled: stage.bulkReviewEnabled,
    uniqueKey: stage.uniqueKey,
  }));
}

export function mapJobStageToJobStageInput(
  jobStages: JobStageTemplate[],
): JobStageTemplateInput[] {
  return jobStages
    ?.sort((a, b) => a.order - b.order)
    .map((stage, index) => ({
      index,
      uniqueKey: window.crypto.randomUUID(),
      ...stage,
    }));
}

export function buildUpsertPlanTemplateDto(
  planTemplate?: PlanTemplate,
  jobStages?: JobStageTemplateInput[],
): UpsertPlanTemplateDto {
  return {
    id: planTemplate?.id,
    name: planTemplate?.name,
    stagePlanTemplates: mapJobStagesToStagePlanTemplates(jobStages),
  };
}

export function processJobStageIndexes(jobStages: StagePlanTemplate[]) {
  let interviewStagesCount = 0;

  return jobStages.map((stage, index) => {
    if (stage.interviewKitTemplateId) {
      interviewStagesCount++;
    }

    return {
      ...stage,
      order: index + interviewStagesCount,
    };
  });
}

export async function getReferenceTemplate(
  referenceTemplateId?: number,
): Promise<PlanTemplate> {
  if (!referenceTemplateId) {
    return null;
  }

  return await PlanTemplateService.show(referenceTemplateId);
}
