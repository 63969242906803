import React from 'react';
import { OrganicPost } from '../../../../../../../entities/applicant_tracking/jobboards/OrganicPost';
import { Chip } from '../../../../../../../components/Chip';
import { HorizontalDivider } from '../../../../../../../components/HorizontallDivider';
import styles from './styles.module.scss';
import defaultJobBoardImage from '../../../../../../../images/empty-state-email.png';
import classNames from 'classnames';

interface PropTypes {
  hasCompanyIdFilled: boolean;
  index: number;
  organicPost: OrganicPost;
}

function shouldAddOpacity(needsAction: boolean, hasCompanyIdFilled: boolean) {
  if (!needsAction) {
    return false;
  }

  return needsAction && !hasCompanyIdFilled;
}

export function OrganicPostRow(props: PropTypes) {
  return (
    <>
      {props.index > 0 && <HorizontalDivider />}
      <div className='d-flex align-items-center justify-content-between'>
        <div
          className='d-flex gap-4 align-items-center'
          style={{ height: '50px' }}
        >
          <div className={classNames('text-center')}>
            <img
              className={classNames(
                styles['product-image'],
                shouldAddOpacity(
                  props.organicPost.needsAction,
                  props.hasCompanyIdFilled,
                ) && 'opacity-50',
              )}
              src={props.organicPost.imageUrl || defaultJobBoardImage}
            />
          </div>
          <h3
            className={classNames(
              'mb-0',
              shouldAddOpacity(
                props.organicPost.needsAction,
                props.hasCompanyIdFilled,
              ) && 'opacity-50',
            )}
          >
            {props.organicPost.name}
          </h3>
          {!props.hasCompanyIdFilled && props.organicPost.needsAction && (
            <Chip className='text-primary bg-orange'>Company ID required</Chip>
          )}
        </div>
      </div>
    </>
  );
}
