import React from 'react';
import { Application } from '../../../../entities/Application';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { getHrisLogo } from '../../../../utils/hris';
import { CandidateDetails } from './CandidateDetails';
import { ApplicationDetails } from './ApplicationDetails';
import { Card } from '../../../../components/Card';

interface PropTypes {
  application: Application;
  setIsLoading: (v: boolean) => void;
}

export function CandidatePanel(props: PropTypes) {
  const hrisLogo = getHrisLogo(props.application.hrisEmployee?.provider);

  return (
    <Card className={classNames('p-4 h5 mb-0', styles.panel)}>
      <div className='d-flex flex-column'>
        <CandidateDetails application={props.application} hrisLogo={hrisLogo} />
        <ApplicationDetails
          application={props.application}
          setIsLoading={props.setIsLoading}
        />
      </div>
    </Card>
  );
}
