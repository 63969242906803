import React from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

interface PropTypes {
  previewMode: boolean;
  disabled: boolean;
}

export function ApplicationFormApplyButton(props: PropTypes) {
  const disabled = props.previewMode || props.disabled;

  return (
    <div className={classNames('d-flex', 'justify-content-center', 'w-100')}>
      <Button
        color={'primary'}
        size='lg'
        className={classNames('text-center', 'my-4')}
        type={'submit'}
        disabled={disabled}
      >
        Apply Now
      </Button>
    </div>
  );
}
