import React from 'react';
import { Nav } from 'reactstrap';
import classNames from 'classnames/bind';
import { CandidateTab } from '../CandidateTab';
import { CandidatePulseNavLink } from '../../../components/UnderlinedNavLink';

interface PropTypes {
  currentTab: CandidateTab;
  applicantTrackingEnabled: boolean;
  interviewIntelligenceEnabled: boolean;
  referenceCheckEnabled: boolean;
  backgroundCheckEnabled: boolean;
  redactedApplication: boolean;
  handleTabClick: (tab: CandidateTab) => void;
}

interface Tab {
  candidateTab: CandidateTab;
  name: string;
}

function getEnabledTabs(
  applicantTrackingEnabled: boolean,
  interviewIntelligenceEnabled: boolean,
  referenceCheckEnabled: boolean,
  backgroundCheckEnabled: boolean,
  redactedApplication: boolean,
): Tab[] {
  // The new tabs should be added by order
  const enabledTabs = [];

  if (applicantTrackingEnabled) {
    enabledTabs.push({ candidateTab: 'profile', name: 'Profile' });
    enabledTabs.push({ candidateTab: 'activity', name: 'Activity' });
  }

  if (redactedApplication) {
    return enabledTabs;
  }

  if (interviewIntelligenceEnabled) {
    enabledTabs.push({ candidateTab: 'interviews', name: 'Interviews' });
  }

  if (referenceCheckEnabled) {
    enabledTabs.push({ candidateTab: 'success', name: 'Reference Checks' });
  }

  if (backgroundCheckEnabled) {
    enabledTabs.push({
      candidateTab: 'background_checks',
      name: 'Background Checks',
    });
  }

  return enabledTabs;
}

function CandidateTab(props: {
  tab: Tab;
  enabledTabs: Tab[];
  currentTab: CandidateTab;
  handleTabClick: (tab: CandidateTab) => void;
}) {
  if (props.enabledTabs.indexOf(props.tab) < 0) return null;

  return (
    <CandidatePulseNavLink
      dataTestid={props.tab.candidateTab}
      tab={props.tab.candidateTab}
      handleClick={props.handleTabClick}
      tabTitle={props.tab.name}
      activeTab={props.currentTab}
    />
  );
}

export function CandidateTabs(props: PropTypes) {
  const enabledTabs = getEnabledTabs(
    props.applicantTrackingEnabled,
    props.interviewIntelligenceEnabled,
    props.referenceCheckEnabled,
    props.backgroundCheckEnabled,
    props.redactedApplication,
  );

  return (
    <div className='d-flex my-4'>
      <div className='me-auto' data-testid='candidate-page-tabs'>
        <Nav className={classNames('flex-wrap app-nav-tabs bg-transparent')}>
          {' '}
          {enabledTabs.map((tab) => (
            <CandidateTab
              key={tab.name}
              tab={tab}
              enabledTabs={enabledTabs}
              currentTab={props.currentTab}
              handleTabClick={props.handleTabClick}
            />
          ))}
        </Nav>
      </div>
    </div>
  );
}
