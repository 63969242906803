import { getCurrencySymbol } from '../../../utils/productDetails';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { ProductPriceCurrency, Wallet } from '@vonq/hapi-elements-types';
import React from 'react';
import { Chip } from '../../../../../../components/Chip';
import { IconButton } from '../../../../../../components/IconButton';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';
import { JobState } from '../../../../../../entities/JobDetails';

interface PropTypes {
  billingPortalLink: string;
  cartAmount: number;
  cartSize: number;
  hasLogo: boolean;
  hasSubdomain: boolean;
  formIsValid: boolean;
  jobStatus: JobState;
  wallet: Wallet;
  onPay: () => void;
  onClose: () => void;
}

function billingInfoButtonText(hasBillingDetails: boolean) {
  if (hasBillingDetails) {
    return 'Edit Billing Information';
  }

  return 'Add Billing Information';
}

function tootipText(
  hasBillingDetails: boolean,
  hasLogo: boolean,
  hasProducts: boolean,
  hasSubdomain: boolean,
  formIsValid: boolean,
  publishedJob: boolean,
) {
  const messages = [];

  if (!publishedJob) {
    messages.push('You can only proceed once the job has been Published.');
  }

  if (!hasBillingDetails && !formIsValid) {
    messages.push('Some job posting fields and billing details are missing.');
  } else if (!hasBillingDetails) {
    messages.push('Some billing details are missing.');
  } else if (!formIsValid) {
    messages.push('Some job posting fields are missing.');
  }

  if (!hasSubdomain) {
    messages.push(
      'The subdomain for your organization is missing. Please contact the support team.',
    );
  }

  if (!hasLogo) {
    messages.push(
      'The company logo is missing. Upload your logo on your organization settings.',
    );
  }

  if (!hasProducts) {
    messages.push('Please add at least one product to the cart.');
  }

  if (messages.length > 0) {
    if (messages.length == 1) {
      return messages[0];
    }

    return (
      <ul className='ps-3 mb-0'>
        {messages.map((message: string, index: number) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    );
  }

  return 'Review and Pay';
}

function CartAlertMessages(props: {
  formIsValid: boolean;
  billingInfo: boolean;
}) {
  if (props.formIsValid && props.billingInfo) {
    return null;
  }

  return (
    <div className='d-flex flex-column gap-2h mx-4'>
      {!props.billingInfo && (
        <AlertMessage
          hasOverlay={false}
          icon={{ name: 'bi-info-circle' }}
          className='fs-5 fw-semibold bg-yellow-light w-100'
          text={'Your Account Billing Information is missing.'}
        />
      )}
      {!props.formIsValid && (
        <AlertMessage
          hasOverlay={false}
          icon={{ name: 'bi-info-circle' }}
          className='fs-5 fw-semibold bg-light-danger w-100'
          text={'Some job post mandatory fields are missing.'}
        />
      )}
    </div>
  );
}

function CartAmount(props: {
  cartAmount: number;
  currency: ProductPriceCurrency;
}) {
  return (
    <div className='d-flex flex-nowrap justify-content-between py-4 px-5h bg-light-info'>
      <span className='fs-5 fw-bold'>Order Total</span>
      <Chip className='bg-white text-primary'>{`${getCurrencySymbol(
        props.currency,
      )}${props.cartAmount}`}</Chip>
    </div>
  );
}

function disablePayAndReview(props: PropTypes) {
  return (
    props.wallet == null ||
    !props.formIsValid ||
    !props.wallet?.has_billing_details ||
    !props.hasLogo ||
    !props.hasSubdomain ||
    props.cartSize === 0 ||
    props.jobStatus != 'published'
  );
}

function CartButtons(props: PropTypes) {
  const payAndReviewDisabled = disablePayAndReview(props);

  return (
    <div className='d-flex flex-nowrap justify-content-between mx-4'>
      <IconButton
        buttonText={billingInfoButtonText(props.wallet?.has_billing_details)}
        color={props.wallet?.has_billing_details ? 'borderless' : 'secondary'}
        disabled={props.billingPortalLink == null || props.wallet == null}
        iconName='bi-cash-coin'
        onClick={() => {
          window.open(props.billingPortalLink, '_blank', 'noopener,noreferrer');
          props.onClose();
        }}
      />
      <SpanTooltip
        text={
          <IconButton
            buttonText='Review And Pay'
            color='primary'
            disabled={payAndReviewDisabled}
            iconName='bi-cart-check'
            onClick={() => {
              props.onClose();
              props.onPay();
            }}
          />
        }
        tooltipText={tootipText(
          props.wallet?.has_billing_details,
          props.hasLogo,
          props.cartSize > 0,
          props.hasSubdomain,
          props.formIsValid,
          props.jobStatus === 'published',
        )}
      />
    </div>
  );
}

export function CartFooter(props: PropTypes) {
  return (
    <div className='d-flex flex-column my-3 gap-2h'>
      <CartAlertMessages
        formIsValid={props.formIsValid}
        billingInfo={props.wallet?.has_billing_details}
      />
      <CartAmount
        cartAmount={props.cartAmount}
        currency={props.wallet.balance.currency}
      />
      <CartButtons {...props} />
    </div>
  );
}
