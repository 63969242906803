import React from 'react';
import { User } from '../../../../../../../../entities/User';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

function memberName(currentUser: User, scorecardUser: User) {
  if (scorecardUser?.id === currentUser.id) {
    return 'My Own';
  }

  return scorecardUser?.name;
}

export function ScorecardBlockTitle(props: PropTypes) {
  return (
    <>
      {!props.viewAllScorecards ? (
        <div className='fw-semibold fs-3 me-3'>
          {props.scorecard.interviewStage?.name} Scorecard
        </div>
      ) : (
        <>
          <div className='fw-semibold fs-3 me-3'>
            {memberName(props.currentUser, props.scorecard.user)} Scorecard
          </div>
          <div className='rounded-pill bg-light-warning text-primary rounded-4 px-3 fs-6 me-2'>
            {props.scorecard.interviewStage?.name}
          </div>
        </>
      )}
    </>
  );
}
