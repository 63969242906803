import { useState } from 'react';
import { renderDropdownAction } from '..';
import { AlertType } from '../../../components/Alert';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { InterviewCandidateUserService } from '../../../services/applicant_tracking/InterviewCandidateUserService';
import { MINUTES_IN_DAY, Timedelta } from '../../../utils/timedelta';
import { shouldActionBeVisible } from './index';

function isSendEmailActionEnabled(interview: InterviewListItem) {
  if (!interview.interviewCandidateUser) return false;

  const emailSent = !!interview.interviewCandidateUser.emailSentAt;

  return (
    !emailSent ||
    Timedelta.timeTillDate(
      interview.interviewCandidateUser.emailSentAt,
    ).toMins() < -MINUTES_IN_DAY
  );
}

export function sendEmailReminder(
  interview: InterviewListItem,
  setAlertMessage: (message: string) => void,
  setAlertType: (type: AlertType) => void,
) {
  const [sendEmailActionEnabled, setSendEmailActionEnabled] = useState<boolean>(
    isSendEmailActionEnabled(interview),
  );

  const onSendEmailReminder = async () => {
    try {
      await InterviewCandidateUserService.sendSelfScheduleEmail(
        interview.interviewCandidateUser.id,
      );
      setAlertMessage('The email has been successfully sent to the candidate.');
      setAlertType(AlertType.Success);
      setSendEmailActionEnabled(false);
    } catch (error) {
      setAlertMessage('The email could not be sent to the candidate.');
      setAlertType(AlertType.Danger);
    }
  };

  if (
    !shouldActionBeVisible(interview) ||
    interview.interviewCandidateUser?.isExpired
  )
    return null;

  return renderDropdownAction(
    'Send reminder to candidate',
    'bi-envelope',
    !sendEmailActionEnabled,
    onSendEmailReminder,
  );
}
