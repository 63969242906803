import React from 'react';
import { Chip } from '../../../../../../../../components/Chip';
import { ListCandidateApplication } from '../../../../../../../../entities/applicant_tracking/ListCandidate';

interface PropTypes {
  application?: ListCandidateApplication;
}

export function PendingActions(props: PropTypes) {
  if (props.application == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <Chip className='text-primary bg-light-danger py-1 px-2h fs-6 fw-semibold'>
      {props.application.pendingActions > 0
        ? `${props.application.pendingActions} pending`
        : 'No actions'}
    </Chip>
  );
}
