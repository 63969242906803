import React from 'react';
import classNames from 'classnames';
import { Chip } from '../../../../../../../../components/Chip';
import { ListCandidateApplication } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { jobStageClass } from '../../../../../../../../utils/jobStageClass';
import { getDurationInMaxUnit } from '../../../../../../../../utils/duration';

interface PropTypes {
  application?: ListCandidateApplication;
}

export function Stages(props: PropTypes) {
  if (props.application == null || props.application.currentStage.name == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  const stageName = props.application.currentStage.name;
  const timeInStage = getDurationInMaxUnit(
    props.application.currentStage.millisInStage,
    ['minutes', 'hours', 'days', 'months'],
  );

  return (
    <div className='d-flex align-items-center'>
      <Chip
        className={classNames(
          'py-1 px-2h fs-6 fw-semibold',
          jobStageClass(stageName),
        )}
      >
        {stageName}
      </Chip>
      <small className='text-muted text-truncate ms-2'>
        {timeInStage.value != null &&
          `${timeInStage.value} ${timeInStage.unit}`}
      </small>
    </div>
  );
}
