import momentTimezone, { Moment } from 'moment-timezone';
import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';
import { QueryPeriod } from '../EventSlot';

const DEFAULT_AVAILABILITY_START_HOUR = 7;
const DEFAULT_AVAILABILITY_END_TIME = [19, 0, 0]; // hour, minutes, seconds
const MANAGED_AVAILABILITY_END_TIME = [23, 59, 59]; // hour, minutes, seconds
const MAX_QUERY_PERIOD_ELEMENTS = 50;
const FIRST_SLOT_DELAY_MINUTES = 15;

export interface TimeConstraint {
  startTime: Date;
  endTime: Date;
}

function buildQueryPeriod(
  moment: Moment,
  dayConstraints: TimeConstraint[],
  timezone: string,
  withManagedAvailability: boolean,
) {
  let startPeriodMoment = moment.clone().utc();

  if (startPeriodMoment.dayOfYear() !== momentTimezone.utc().dayOfYear()) {
    startPeriodMoment.tz(timezone).set({
      hour: withManagedAvailability ? 0 : DEFAULT_AVAILABILITY_START_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }

  if (startPeriodMoment < momentTimezone.utc()) {
    startPeriodMoment = momentTimezone.utc();
  }

  const endPeriodMoment = startPeriodMoment.clone();
  const endTime = withManagedAvailability
    ? MANAGED_AVAILABILITY_END_TIME
    : DEFAULT_AVAILABILITY_END_TIME;
  endPeriodMoment.tz(timezone).set({
    hour: endTime[0],
    minutes: endTime[1],
    seconds: endTime[2],
  });

  const result: QueryPeriod[] = [];
  let currentIntervalStart = startPeriodMoment.clone();

  if (dayConstraints) {
    for (const constraint of dayConstraints) {
      if (
        constraint.startTime > currentIntervalStart.toDate() &&
        constraint.startTime > startPeriodMoment.toDate()
      ) {
        result.push({
          start: momentTimezone
            .max(currentIntervalStart, startPeriodMoment)
            .utc()
            .format(),
          end: momentTimezone.utc(constraint.startTime.toUTCString()).format(),
        });
      }
      currentIntervalStart = momentTimezone.utc(
        constraint.endTime.toUTCString(),
      );
    }
  }

  if (currentIntervalStart < endPeriodMoment) {
    result.push({
      start: momentTimezone
        .max(currentIntervalStart, startPeriodMoment)
        .utc()
        .format(),
      end: endPeriodMoment.utc().format(),
    });
  }

  return result;
}

export function buildQueryPeriods(
  startDate: Date,
  startHours: number,
  searchDays: number,
  timeConstraints: TimeConstraint[],
  participants: InterviewerParticipant[],
  timezone: string,
) {
  let queryPeriods: QueryPeriod[] = [];

  const withManagedAvailability = participants.some(
    (p) => p.user.schedulingUserIntegration?.availabilities?.length > 0,
  );

  const startMoment = momentTimezone(startDate).utc();

  if (startHours == 0) startMoment.add(FIRST_SLOT_DELAY_MINUTES, 'minutes');
  else startMoment.add(startHours, 'hours');

  const endMoment = startMoment.clone().add(searchDays, 'days');

  while (startMoment < endMoment) {
    // Ignore weekends
    if (startMoment.weekday() !== 0 && startMoment.weekday() !== 6) {
      const dayConstraints = timeConstraints
        ?.filter(
          (v) =>
            momentTimezone.utc(v.startTime.toUTCString()).dayOfYear() ===
            startMoment.dayOfYear(),
        )
        .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      queryPeriods = queryPeriods.concat(
        buildQueryPeriod(
          startMoment.clone(),
          dayConstraints,
          timezone,
          withManagedAvailability,
        ),
      );
    }
    startMoment.add(1, 'day');
  }

  return queryPeriods.filter(Boolean).slice(0, MAX_QUERY_PERIOD_ELEMENTS);
}
