import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterStates } from '../../../../components/candidate_experience/Filters/filterState';
import {
  setFilters,
  generateQueryString,
} from '../../../../components/candidate_experience/Filters/urlHelper';
import { PageWideSpinner } from '../../../../components/PageWideSpinner';
import { BenchmarkType } from '../../../../entities/candidate_experience/BenchmarkType';
import { HiringManagerAnalytics } from '../../../../entities/candidate_experience/HiringManagerAnalytics';
import { HiringManagerAnalyticsService } from '../../../../services/candidate_experience/HiringManagerAnalyticsService';
import { HiringManagerPulseAnalytics } from './HiringManagerPulseAnalytics';

interface PagePropTypes {
  benchmark_types: BenchmarkType[];
  activeTab: string;
}

export default function HiringManagerPulseTab(props: PagePropTypes) {
  const initFilters = {
    benchmarkTypes: props.benchmark_types,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterStates, setFilterStates] = useState<FilterStates>(
    setFilters(initFilters, searchParams),
  );
  const [analytics, setAnalytics] = useState<HiringManagerAnalytics>(null);

  function handleApplyFilter(filterStates: FilterStates) {
    setFilterStates(filterStates);
  }

  useEffect(() => {
    setAnalytics(null);

    const filterSelector = filterStates.filterSelector.selected.value;
    setSearchParams(generateQueryString(filterStates));

    HiringManagerAnalyticsService.get(
      filterSelector,
      filterStates.jobs.selected.map((option) => option.value),
      filterStates.departments.selected.map((option) => option.value),
      filterStates.benchmarkType.selected.value,
      filterStates.dateRange,
    ).then((analytics) => setAnalytics(analytics));
  }, [filterStates]);

  if (analytics === null) {
    return <PageWideSpinner />;
  }

  return (
    <HiringManagerPulseAnalytics
      analytics={analytics}
      filterStates={filterStates}
      onApplyFilter={handleApplyFilter}
      showMoreFilters={false}
    />
  );
}
