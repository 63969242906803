import React from 'react';
import { LabelledToggle } from '../../../../../components/LabelledToggle';

interface PropTypes {
  checked: boolean;
  disabled: boolean;
  tooltip?: string;
  onToggle: (state: boolean) => void;
}

export function InterviewToggleInput(props: PropTypes) {
  return (
    <div className='d-flex mt-4 align-items-center'>
      <label className='fs-5 fw-semibold me-2h'>
        Does this stage include an interview?
      </label>
      <LabelledToggle
        checked={props.checked}
        checkedLabel='Yes'
        disabled={props.disabled}
        tooltip={props.tooltip}
        uncheckedLabel='No'
        labelPlacement='end'
        inputName='SetupPlanTemplate'
        size='sm'
        onChange={() => props.onToggle(!props.checked)}
        labelClassnames='text-dark-200'
      />
    </div>
  );
}
