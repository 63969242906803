import React from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';

interface PropTypes {
  editLocationUrl: string;
}

export default function RowActionsDropdown(props: PropTypes) {
  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-5 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={[
        {
          action: () => (window.location.href = props.editLocationUrl),
          buttonChild: (
            <IconSpan
              spanText={'Edit'}
              icon={{ name: 'bi-pencil', className: 'fs-4' }}
              className={'text-blue'}
            />
          ),
        },
      ]}
    />
  );
}
