import React, { useState } from 'react';
import { IconButton } from '../../../../components/IconButton';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { ActivityContent } from '../../ActivityContent';
import { Application } from '../../../../entities/Application';
import { NoteModal } from '../../../../components/ApplicantTracking/NoteModal';
import { User } from '../../../../entities/User';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { NoteService } from '../../../../services/applicant_tracking/NoteService';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}

export function LatestActivityPanel(props: PropTypes) {
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);

  if (props.application.latestFeed == null) return null;

  const onAddNote = async (note: string) => {
    await NoteService.create({
      applicationId: props.application.id,
      note: note,
    });
  };

  return (
    <DeprecatedPanel className='p-4 h5 mb-0'>
      <>
        <div className='d-flex align-items-center mb-4'>
          <h3 className='mb-0 me-auto'>Latest Activity</h3>
          <IconButton
            buttonText='Add note'
            color='borderless'
            iconName='bi-file-earmark-text'
            onClick={() => setIsNoteModalOpen(true)}
          />
        </div>
        <NoteModal
          mode='Add'
          onSubmit={onAddNote}
          isOpen={isNoteModalOpen}
          onClose={(succeeded: boolean) => {
            setIsNoteModalOpen(false);
            succeeded && props.reloadApplication();
          }}
        />
        <ActivityContent
          application={props.application}
          currentUser={props.currentUser}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
          applicationFeeds={props.application.latestFeed}
          emailAccountIntegration={props.emailAccountIntegration}
          applicationStylesPath={props.applicationStylesPath}
        />
      </>
    </DeprecatedPanel>
  );
}
