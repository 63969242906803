import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { IconChip } from '../../../../components/IconChip';
import { User } from '../../../../entities/training/User';

interface PropTypes {
  users: User[];
}

function getMaxWidthClass(usersLength: number) {
  switch (usersLength) {
    case 2:
      return 'mw-45';
    case 3:
      return 'mw-30';
    default:
      return 'mw-100';
  }
}

export function AssignedUsers(props: PropTypes) {
  return (
    <Row className={classNames('flex-nowrap', 'g-0', 'gap-2', 'w-100')}>
      {props.users.map((user) => (
        <IconChip
          key={user.id}
          className={classNames(
            user.completed ? 'bg-light-success' : 'bg-light-warning',
            'text-primary',
            'd-flex',
            'col',
            'col-auto',
            getMaxWidthClass(props.users.length),
          )}
          iconClass={user.completed && 'text-primary bi bi-check-lg'}
          iconPosition='right'
        >
          <div className='text-truncate'>{user.name}</div>
        </IconChip>
      ))}
    </Row>
  );
}
