import React, { useEffect, useState } from 'react';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { ApprovalStep } from './ApprovalStep';
import { User, UserList } from '../../../../../entities/v1/User';
import { UserService } from '../../../../../services/v1/UserService';
import { SelectOption } from '../../../../../components/Select';
import { Header } from './Header';
import { AddApprovalStepButton } from '../AddApprovalStepButton';

interface PropTypes {
  steps: ApprovalFlowStep[];
  readOnly?: boolean;
  setSteps: (steps: ApprovalFlowStep[]) => void;
}

export const MAX_STEPS_PER_FLOW = 5;

export function createNewApprovalFlowStep(order: number): ApprovalFlowStep {
  return {
    order: order,
    approvers: [{ user: null }],
  };
}

function buildUserOptions(users: User[]): SelectOption[] {
  return users.map((user) => ({
    label: user.name,
    value: user.id.toString(),
  }));
}

export function ApprovalSteps(props: PropTypes) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    UserService.list({ pageSize: 1000 }).then((response: UserList) => {
      setUsers(response.users);
    });
  }, []);

  const addNewStep = () => {
    props.setSteps([
      ...props.steps,
      createNewApprovalFlowStep(props.steps.length + 1),
    ]);
  };
  const removeStep = (index: number) => {
    const newSteps = props.steps.filter((_, i) => i !== index);
    props.setSteps(newSteps);
  };

  const onUpdateStep = (index: number, step: ApprovalFlowStep) => {
    const newSteps = [...props.steps];
    newSteps[index] = step;
    props.setSteps(newSteps);
  };

  return (
    <div>
      <Header
        steps={props.steps}
        readOnly={props.readOnly}
        onClick={addNewStep}
      />
      {props.steps.map((step, index) => (
        <ApprovalStep
          key={index}
          index={index}
          step={step}
          users={users}
          readOnly={props.readOnly}
          userOptions={buildUserOptions(users)}
          onChange={(step) => onUpdateStep(index, step)}
          onDelete={() => removeStep(index)}
        />
      ))}
      {!props.readOnly && (
        <div className='d-flex justify-content-center'>
          <AddApprovalStepButton
            borderless
            steps={props.steps}
            onClick={addNewStep}
          />
        </div>
      )}
    </div>
  );
}
