import React, { ReactNode } from 'react';
import { Chip } from '../../../../components/Chip';
import { LabelledToggle } from '../../../../components/LabelledToggle';
import classNames from 'classnames';
import { RequisitionSettingType } from '..';
import { capitalize } from 'humanize-plus';
import { SpanTooltip } from '../../../../components/SpanTooltip';

interface PropTypes {
  title: string;
  description: string | ReactNode;
  enabled: boolean;
  disabled?: boolean;
  type?: RequisitionSettingType;
  useYesNoTags?: boolean;
  onChange: (value: boolean) => void;
}

function ChipText(enabled: boolean, useYesNoTags: boolean) {
  if (useYesNoTags) {
    return enabled ? 'Yes' : 'No';
  }
  return enabled ? 'Enabled' : 'Disabled';
}

function HelperLink(props: { type: string }) {
  if (props.type === 'job') {
    return (
      <>
        Define a{' '}
        <a
          href='/organization_settings/approval_flows/new?requisition_type=job'
          className='fw-bold text-info'
        >
          Job Approval Flow
        </a>{' '}
        first.
      </>
    );
  } else if (props.type === 'offer') {
    return (
      <>
        Define a{' '}
        <a
          href='/organization_settings/approval_flows/new?requisition_type=offer'
          className='fw-bold text-info'
        >
          Offer Approval Flow
        </a>{' '}
        first.
      </>
    );
  }
}

function tooltipText(type: string) {
  return `Before enabling it, you need to define at least one ${capitalize(
    type,
  )} Approval Flow.`;
}

function Toggle(props: PropTypes) {
  return (
    <LabelledToggle
      inputName={props.title}
      checked={props.enabled}
      labelPlacement='start'
      disabled={props.disabled}
      labelClassnames='fs-5 me-2h'
      checkedLabel={props.useYesNoTags ? 'Yes' : 'Enabled'}
      uncheckedLabel={props.useYesNoTags ? 'No' : 'Disabled'}
      onChange={(e) => props.onChange(e.currentTarget.checked)}
    />
  );
}

export function SettingRow(props: PropTypes) {
  const badgeClass = props.enabled ? 'bg-light-success' : 'bg-light-danger';

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='fs-5'>
        <div className='d-flex mb-2 align-items-center'>
          <div className='fw-semibold me-2'>{props.title}</div>
          <Chip
            size='sm'
            className={classNames(badgeClass, 'text-primary fw-semibold')}
          >
            {ChipText(props.enabled, props.useYesNoTags)}
          </Chip>
        </div>
        <div>
          {props.description}{' '}
          {props.disabled && <HelperLink type={props.type} />}
        </div>
      </div>
      {props.disabled && props.type ? (
        <SpanTooltip
          tooltipText={tooltipText(props.type)}
          text={<Toggle {...props} />}
          placement='top-end'
        />
      ) : (
        <Toggle {...props} />
      )}
    </div>
  );
}
