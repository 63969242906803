import React from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { Chip } from '../../../../components/Chip';
import {
  Application,
  BriefApplication,
} from '../../../../entities/Application';
import classNames from 'classnames';
import { capitalize } from '../../../../utils/capitalize';
import { applicationStatusClass } from '../../../../utils/applicationStatusClass';
import { jobStageClass } from '../../../../utils/jobStageClass';
import { badgeColor } from '../PendingActionsPanel';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { ActionItem } from '../../../../entities/ActionItem';
import stylesheetExports from '../../../../stylesheets/export.module.scss';

interface PropTypes {
  application: Application;
}

function filteredActionItems(actionItems: ActionItem[]): ActionItem[] {
  return actionItems.filter((item) => item.completed !== true);
}

function BriefApplication(props: {
  application: BriefApplication;
  selected: boolean;
}) {
  const actionItemsLength = filteredActionItems(
    props.application.actionItems,
  ).length;
  return (
    <div
      className={classNames('rounded rounded-2 p-3 pe-2', {
        'bg-light-info': props.selected,
      })}
    >
      <div className='d-flex justify-content-between mb-2'>
        <h5 className='me-auto'>{props.application.job.name}</h5>
        <SpanTooltip
          text={
            <Chip
              className={classNames(
                'text-primary mb-2',
                `bg-${badgeColor(actionItemsLength)}`,
              )}
              size='sm'
            >
              <i className='bi bi-info-circle text-blue me-1'></i>
              {actionItemsLength}
            </Chip>
          }
          tooltipText={`${actionItemsLength} Pending Actions`}
          tooltipStyle={{
            backgroundColor: stylesheetExports.darkPrimary,
            opacity: 0.95,
          }}
        />
      </div>
      <div className='d-flex gap-2'>
        <div
          className={classNames(
            'badge fs-6 fw-semibold rounded-pill',
            jobStageClass(props.application.jobStageName),
          )}
        >
          {props.application.jobStageName}
        </div>
        <div
          className={classNames(
            'badge fs-6 fw-semibold rounded-pill text-primary',
            applicationStatusClass(props.application.status),
          )}
        >
          {capitalize(props.application.status)}
        </div>
      </div>
    </div>
  );
}

export function JobApplicationsPanel(props: PropTypes) {
  if (props.application.candidate.applications == null) {
    return null;
  }

  const applications = [...props.application.candidate.applications].sort(
    (a, b) => +b.appliedAt - +a.appliedAt,
  );

  return (
    <DeprecatedPanel className='p-4 h5 mt-3 mb-0'>
      <>
        <div className='d-flex mb-3 justify-content-between'>
          <h3 className='mb-0 me-auto'>Job applications</h3>
          <span>
            <Chip className='text-primary bg-light-info me-2' size='sm'>
              <i className='bi bi-briefcase me-1'></i>
              {applications.length}
            </Chip>
          </span>
        </div>
        {applications.map((currentApplication) => (
          <BriefApplication
            key={currentApplication.id}
            application={currentApplication}
            selected={currentApplication.id === props.application.id}
          />
        ))}
      </>
    </DeprecatedPanel>
  );
}
