import React from 'react';
import { CandidateHeaderBadge } from '../CandidateHeaderBadge';
import { IconSpan } from '../../../../components/IconSpan';
import { getHrisName } from '../../../../utils/hris';
import { Employee } from '../../../../entities/Employee';

interface PropTypes {
  hrisEmployee?: Employee;
}

export function CandidatePageHeaderEmployeeExternalURLInfo(props: PropTypes) {
  if (!props.hrisEmployee?.externalUrl) return null;

  return (
    <CandidateHeaderBadge
      text={
        <a
          className='text-info'
          href={props.hrisEmployee.externalUrl}
          target='_blank'
          rel='noreferrer'
        >
          <IconSpan
            icon={{ name: 'bi-box-arrow-up-right' }}
            spanText={`View in ${getHrisName(props.hrisEmployee.provider)}`}
          />
        </a>
      }
      classNames='ms-2h bg-white'
    />
  );
}
