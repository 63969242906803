import React from 'react';

interface PropTypes {
  name: string;
  value: boolean;
  onChange: (v: boolean) => void;
  trueLabel: string;
  falseLabel: string;
  disabled?: boolean;
  className?: string;
}

export function BooleanInput(props: PropTypes) {
  const trueId = `${props.name}-true`;
  const falseId = `${props.name}-false`;

  return (
    <div className={props.className}>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          id={trueId}
          value='true'
          name={props.name}
          onChange={() => props.onChange(true)}
          checked={props.value === true}
          disabled={props.disabled}
        />
        <label className='form-check-label' htmlFor={trueId}>
          {props.trueLabel}
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          id={falseId}
          value='false'
          name={props.name}
          onChange={() => props.onChange(false)}
          checked={props.value === false}
          disabled={props.disabled}
        />
        <label className='form-check-label' htmlFor={falseId}>
          {props.falseLabel}
        </label>
      </div>
    </div>
  );
}
