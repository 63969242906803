import classNames from 'classnames';
import React from 'react';
import { badgeColor } from '..';
import { Chip } from '../../../../../components/Chip';
import { LabelledAvatar } from '../../../../../components/LabelledAvatar';
import { SpanTooltip } from '../../../../../components/SpanTooltip';
import { UserGroup } from './HiringTeamActionsItems';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';

function TootlipBadge(props: UserGroup) {
  return (
    <SpanTooltip
      text={
        <Chip
          className={classNames(
            'text-primary',
            `bg-${badgeColor(props.actionItems.length)}`,
          )}
        >
          <i className='bi bi-info-circle text-blue me-1'></i>
          {props.actionItems.length}
        </Chip>
      }
      tooltipText={
        <div>
          <div className='fs-5'>{props.actionItems.length} Pending Actions</div>
          <ul className='ps-3'>
            {props.actionItems.map((actionItem, idx) => (
              <li key={idx}>{actionItem.actionText}</li>
            ))}
          </ul>
        </div>
      }
      tooltipStyle={{
        backgroundColor: stylesheetExports.darkPrimary,
        opacity: 0.95,
      }}
    />
  );
}

export function HiringTeamRow(props: UserGroup) {
  return (
    <>
      <div className='d-flex my-2 justify-content-between'>
        <LabelledAvatar
          type={props.user.userPhotoUrl ? 'photo' : 'initials'}
          name={props.user.userPhotoUrl || props.user.name}
          label={props.user.name}
          labelClassName='fs-6 fw-semibold'
        />
        <TootlipBadge {...props} />
      </div>
    </>
  );
}
