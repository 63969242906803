import React, { useState } from 'react';
import { JobStageContainer } from './JobStageContainer';
import {
  useSensors,
  useSensor,
  DragEndEvent,
  DndContext,
  DragOverlay,
  PointerSensor,
  DragOverEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { AddStageContainer } from './AddStageContainer';
import { JobStageTemplateInput } from '../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { HorizontalCarousel } from '../../HorizontalCarousel';
import { PlanTemplate } from '../../../entities/applicant_tracking/PlanTemplate';
import { StageMoveDTO } from '../../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/StageMoveDTO';

interface PropTypes {
  viewMode: boolean;
  jobStages: JobStageTemplateInput[];
  planTemplate: PlanTemplate;
  focusedJobStage?: JobStageTemplateInput;
  disableViewingNonInterviewStages?: boolean;
  hideScrollbar?: boolean;
  jobStageHeaderClassName?: string;
  stageMoves?: StageMoveDTO[];
  onAddStage: () => void;
  setFocusedJobStage: (stage: JobStageTemplateInput) => void;
  setJobStages: (jobStages: JobStageTemplateInput[]) => void;
  onDeleteStage?: (index: number, moveDestinationStageName?: string) => void;
}

function moveArrayItem(stages: JobStageTemplateInput[], event: DragEndEvent) {
  const { active, over } = event;

  const oldIndex = stages.findIndex((stage) => stage.uniqueKey === active.id);
  const newIndex = stages.findIndex((stage) => stage.uniqueKey === over.id);

  return arrayMove(stages, oldIndex, newIndex).map((stage, index) => ({
    ...stage,
    index,
  }));
}

function Carousel(props: {
  stages: JobStageTemplateInput[];
  viewMode: boolean;
  jobStages: JobStageTemplateInput[];
  planTemplate: PlanTemplate;
  focusedJobStage?: JobStageTemplateInput;
  disableViewingNonInterviewStages?: boolean;
  hideScrollbar?: boolean;
  jobStageHeaderClassName?: string;
  stageMoves?: StageMoveDTO[];
  setFocusedJobStage: (stage: JobStageTemplateInput) => void;
  onAddStage: () => void;
  setJobStages: (jobStages: JobStageTemplateInput[]) => void;
  onDeleteStage?: (index: number, moveDestinationStageName?: string) => void;
}) {
  const elements = props.stages.map(
    (stage: JobStageTemplateInput, index: number) => (
      <JobStageContainer
        key={index}
        jobStageTemplate={stage}
        viewMode={props.viewMode}
        isFocused={props.focusedJobStage?.uniqueKey === stage.uniqueKey}
        disableViewingNonInterviewStages={
          props.disableViewingNonInterviewStages
        }
        jobStages={props.jobStages}
        planTemplate={props.planTemplate}
        headerClassName={props.jobStageHeaderClassName}
        stageMoves={props.stageMoves}
        onEdit={() => props.setFocusedJobStage(stage)}
        onDelete={(moveDestinationStageName) =>
          props.onDeleteStage?.(stage.index, moveDestinationStageName)
        }
        setFocusedJobStage={props.setFocusedJobStage}
      />
    ),
  );

  if (!props.viewMode) {
    elements.push(<AddStageContainer onAddStage={props.onAddStage} />);
  }

  return (
    <HorizontalCarousel
      elements={elements}
      buttonColor='gray'
      border={false}
      allowHorizontalScroll={true}
      hideScrollbar={props.hideScrollbar}
      innerClassNames='pb-4'
      scrollWidth={256} // 240 (width) + 16 (margin)
    />
  );
}

export function JobStagesList(props: PropTypes) {
  const [dragElement, setDragElement] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const updateStages = (event: DragOverEvent | DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over?.id) {
      return props.setJobStages(moveArrayItem(props.jobStages, event));
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    updateStages(event);
    setDragElement(null);
  };

  const handleDragOver = (event: DragOverEvent) => {
    setDragElement(event?.active?.data?.current);
    updateStages(event);
  };

  return (
    <div className='mt-4'>
      <DndContext
        sensors={sensors}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setDragElement(null)}
      >
        <SortableContext items={props.jobStages} strategy={rectSortingStrategy}>
          <Carousel
            {...props}
            stages={props.jobStages}
            onAddStage={props.onAddStage}
          />
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
          {dragElement ? (
            <JobStageContainer draggedOver {...dragElement} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
