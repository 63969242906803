import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../../../components/Chip';
import { Trend } from '../../../../../../../components/survey_engine/Trend';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  overallAverage: number;
  trend: number;
}

function getOverallAverageBackgroundColor(overallAverage: number) {
  if (overallAverage >= 5.1) {
    return 'overall-average-bg-success';
  } else if (overallAverage >= 2.6) {
    return 'overall-average-bg-warning';
  } else {
    return 'overall-average-bg-danger';
  }
}

export function OverallAverage(props: PropTypes) {
  const backgroundColor = getOverallAverageBackgroundColor(
    props.overallAverage,
  );
  return (
    <td className={classNames(styles['table-data-cell-border-bottom'])}>
      <div className='d-flex justify-content-end'>
        <div
          className={classNames(
            styles['avg-trend-cell'],
            'd-flex justify-content-between align-items-center',
          )}
        >
          <div>
            <Chip
              className={classNames(
                styles[backgroundColor],
                'fw-semibold text-white fs-6',
              )}
            >
              {Math.round(props.overallAverage * 10) / 10}
            </Chip>
          </div>
          <Trend trend={props.trend} className='fs-6 text-nowrap ps-1' />
        </div>
      </div>
    </td>
  );
}
