import { DisconnectModal } from '../DisconnectModal';
import { Button } from 'reactstrap';
import React, { useState } from 'react';
import { UserIntegration } from '../../../../entities/UserIntegration';
import { Chip } from '../../../../components/Chip';

interface PropTypes {
  integration: UserIntegration;
  integrationIcon: JSX.Element;
  modalTitle: string;
  modalContent: string;
  connectedMessage?: string;
  disconnectedMessage?: string;
  selectionMessage?: string;
  authorizeUrl: string;
  onDisconnect: () => void;
}

export function AccountIntegrationRow(props: PropTypes) {
  const [modalOpen, setModalOpen] = useState(false);
  const isConnected = props.integration?.connectionStatus == 'connected';

  async function onConfirmDisconnect() {
    props.onDisconnect();
    setModalOpen(false);
  }

  return (
    <div className='d-flex align-items-center'>
      <DisconnectModal
        title={props.modalTitle}
        content={props.modalContent}
        onCancel={() => setModalOpen(false)}
        onConfirm={onConfirmDisconnect}
        isOpen={modalOpen}
      />
      <div className='d-flex align-items-center gap-3 me-auto'>
        {props.integrationIcon}
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <h5 className='mb-0'>
              {isConnected ? props.connectedMessage : props.disconnectedMessage}
            </h5>
            {isConnected && (
              <Chip className='ms-4 text-primary bg-light-success fw-semibold'>
                Connected
              </Chip>
            )}
          </div>
          {props.selectionMessage && (
            <small className='text-muted mt-2'>{props.selectionMessage}</small>
          )}
        </div>
      </div>
      {isConnected ? (
        <Button color='secondary' onClick={() => setModalOpen(true)}>
          Disconnect
        </Button>
      ) : (
        <Button
          color='primary'
          onClick={() => (window.location.href = props.authorizeUrl)}
        >
          Connect
        </Button>
      )}
    </div>
  );
}
