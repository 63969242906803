import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { JobStage } from '../../../../entities/JobStage';
import { Select, SelectOption } from '../../../../components/Select';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { JobStageService } from '../../../../services/JobStageService';
import { Chip } from '../../../../components/Chip';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { MoveCandidateProps } from '.';

interface ModalProps {
  applicationId: number;
  candidateName: string;
  currentStage: string;
  jobId: string;
  onClose: (action?: MoveCandidateProps) => void;
}

function buildOptions(
  options: JobStage[],
  currentStage: string,
): SelectOption[] {
  return options
    .sort((a, b) => a.order - b.order)
    .map((option, index) => ({
      label: `${index + 1}. ${option.name}`,
      value: option.id.toString(),
      isDisabled: currentStage === option.name,
      subLabel: currentStage === option.name && (
        <Chip className='ms-3 text-primary bg-gray fw-semibold'>Current</Chip>
      ),
      classNames: 'justify-content-start',
    }));
}

export function ModalBody(props: ModalProps) {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [jobStages, setJobStages] = useState<JobStage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    JobStageService.list(props.jobId).then((jobStages) => {
      setJobStages(jobStages);
      setIsLoading(false);
    });
  }, []);

  function getJobStageById(id: number): JobStage {
    return jobStages.find((stage) => stage.id === id);
  }

  const moveCandidateStage = async () => {
    const selectedJobStage = getJobStageById(selectedOption);

    try {
      await ApplicationService.moveStage(
        props.applicationId,
        selectedJobStage.id,
      );

      props.onClose({
        candidateName: props.candidateName,
        jobStageName: selectedJobStage.name,
        state: 'Success',
      });
    } catch (error) {
      props.onClose({
        candidateName: props.candidateName,
        jobStageName: selectedJobStage.name,
        state: 'Error',
      });
      console.error(error);
    }
  };

  const options = buildOptions(jobStages, props.currentStage);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='mb-2 fs-5'>
            To which stage do you want to move{' '}
            <span className='fw-bold'>{props.candidateName}</span>?
          </div>
          <Select
            options={options}
            placeholder='Select stage'
            selected={options.find(
              (o: SelectOption) => selectedOption?.toString() === o.value,
            )}
            onChange={(option: SelectOption) =>
              setSelectedOption(parseInt(option.value))
            }
          />
          <div className='d-flex mt-5 justify-content-end'>
            <Button
              color='danger'
              className='me-2'
              onClick={() => props.onClose()}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={selectedOption == null}
              onClick={moveCandidateStage}
            >
              Move
            </Button>
          </div>
        </>
      )}
    </>
  );
}
