import React, { useEffect } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { CheckboxPillGroupFormHook } from '../../../../components/CheckboxPillGroupFormHook';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';
import { AVAILABLE_CURRENCIES } from '../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  salaryInputColSize?: number;
  inputGroupClassNames?: string;
  containerClassNames?: string;
  formHeaderClassNames?: string;
  formControl: Control<CandidateDto>;
  errors: FieldErrors;
  disabled?: boolean;
  shouldRender: boolean;
  required: boolean;
  watch: UseFormWatch<CandidateDto>;
  formResetField: (controllerName: string) => void;
  formRegister: UseFormRegister<CandidateDto>;
  setValue?: UseFormSetValue<any>;
  width?: number;
  label?: string;
}

interface SalaryCurrencyPropTypes extends PropTypes {
  required: boolean;
}

function SalaryAndCurrency(props: SalaryCurrencyPropTypes) {
  const disabled = props.disabled ?? false;

  useEffect(() => {
    if (!props.watch('salaryCurrency') && !!props.watch('salaryExpectation')) {
      props.setValue('salaryCurrency', 'gbp');
    }
  }, [props.watch('salaryExpectation')]);

  return (
    <Row className={classNames(props.inputGroupClassNames)}>
      <Col xs={props.salaryInputColSize || 6}>
        <input
          className={classNames('form-control', 'fs-5', {
            'is-invalid': props.errors.salaryExpectation,
          })}
          type={'text'}
          inputMode={'numeric'}
          placeholder='e.g. 12,000'
          {...props.formRegister('salaryExpectation', {
            onChange: (e) => {
              const onlyDigits = e.target.value.replace(/\D/g, '');
              const withCommas = onlyDigits.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              );
              props.setValue('salaryExpectation', withCommas);
            },
            required: props.required,
            pattern: {
              value: /^([1-9][0-9]{0,2})((,[0-9]{3})*|[0-9]*)([.][0-9]{2})?$/,
              message: 'Salary must be a number',
            },
          })}
          disabled={disabled}
        />
        {props.errors?.salaryExpectation?.message && (
          <small className='text-danger fw-normal'>
            {props.errors?.salaryExpectation?.message}
          </small>
        )}
      </Col>
      <Col xs='auto'>
        <CheckboxPillGroupFormHook<CandidateDto>
          formHookContext={{
            controllerName: 'salaryCurrency',
            formControl: props.formControl,
            required: props.required,
          }}
          childrenClassNames={'px-3 py-1 ms-2'}
          resetField={props.formResetField}
          childrenProps={AVAILABLE_CURRENCIES}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

export function ApplicationSalaryInput(props: PropTypes) {
  if (!props.shouldRender) return null;

  const label = props.label ?? 'Expected Annual Salary';

  return (
    <Row className={classNames(props.containerClassNames)}>
      <Col xs={props.width}>
        <FormFieldHeader
          fieldName={label}
          isRequired={props.required}
          classNames={classNames(props.formHeaderClassNames)}
        />
        <SalaryAndCurrency {...props} label={label} />
      </Col>
    </Row>
  );
}
