import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { PreviewJobPost } from '../../../../entities/applicant_tracking/PreviewJobPost';
import { CompanyInfo } from '../../../../entities/CompanyInfo';
import JobPostPageHeader from './JobPostPageHeader';
import { ApplicationFormTemplate } from '../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { ApplicationFormTab } from './ApplicationFormTab';
import { StateMachineProvider } from 'little-state-machine';
import { OverviewTab } from './OverviewTab';
import { Source } from '../../../../entities/applicant_tracking/Source';
import { UnpublishedJobEmptyState } from './UnpublishedJobEmptyState';
import { LogoWithBackgroundHeader } from '../../../Headers/LogoWithBackgroundHeader';
import { getQueryParam } from '../../../../utils/url';
import { parseJobBoardParams } from '../../../../utils/applicant_tracking/unified_job_boards/vonq/parse_careers_query_params';
import { CareersService } from '../../../../services/applicant_tracking/CareersService';
import { ParsedResumesService } from '../../../../services/v1/applicant_tracking/ParsedResumesService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

export type JobPostPageTab = 'overview' | 'application_form';

interface PropTypes {
  previewMode: boolean;
  previewJobPost: PreviewJobPost;
  applicationFormTemplate?: ApplicationFormTemplate;
  candidateConsentHtml?: string;
  sources: Source[];
  jobPostHtmlString?: string;
  companyInfo?: CompanyInfo;
  activeTab?: JobPostPageTab;
  enableApplicationFormAutofill: boolean;
}

function maxWidth(activeTab: string): string | undefined {
  if (activeTab === 'overview') return '800px';
  if (activeTab === 'application_form') return '600px';
}

function queryStringTab(): JobPostPageTab | null {
  return getQueryParam('tab') as JobPostPageTab;
}

function defaultTab(viewSelectedTab?: JobPostPageTab) {
  return queryStringTab() ?? viewSelectedTab ?? 'overview';
}

export default function JobPostPage(props: PropTypes) {
  const [activeTab, setActiveTab] = useState<JobPostPageTab>(
    defaultTab(props.activeTab),
  );
  const [isLoading, setIsLoading] = useState(false);
  const jobBoardParams = parseJobBoardParams();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      const previousUrl = window.location.href;
      if (window.location.search.includes('tab=')) {
        const tab = queryStringTab();
        setActiveTab(tab as JobPostPageTab);
      } else {
        e.preventDefault();
        window.location.href = previousUrl;
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const urlTab = queryStringTab();
    if (urlTab != activeTab) {
      const url = new URL(window.location.href);
      url.searchParams.set('tab', activeTab);

      history.pushState(null, '', url.toString());
    }
  }, [activeTab]);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    resetField,
    watch,
    formState,
    trigger,
  } = useForm<any>({
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit(async (data: any) => {
    const returnUrl = location.pathname;

    try {
      setIsLoading(true);

      await CareersService.submitApplication(
        props.companyInfo?.subdomain,
        props.previewJobPost.jobPostId,
        {
          name: data.atsApplicationFormName,
          email: data.atsApplicationFormEmail,
          phoneNumber: data.phoneNumber,
          linkedinUrl: data.atsApplicationFormLinkedinUrl,
          resumeContent: data.atsApplicationFormResumeContent,
          resumeFilename: data.atsApplicationFormResumeFilename,
          resumeId: data.resumeId,
          coverLetterContent: data.atsApplicationFormCoverLetterContent,
          coverLetterFilename: data.atsApplicationFormCoverLetterFilename,
          portfolioUrl: data.atsApplicationFormPortfolioUrl,
          salaryExpectation: data.salaryExpectation,
          salaryCurrency: data.salaryCurrency,
          candidateConsented: data.candidateConsented,
          sourceId: data.sourceId,
          externalJobBoardSourceId: jobBoardParams?.source,
          externalJobBoardCampaignId: jobBoardParams?.campaign,
          externalJobBoardProvider: jobBoardParams?.provider,
          hasWorkingVisa: data.hasWorkingVisa,
          address: data.location?.address,
          city: data.location?.city,
          state: data.location?.state,
          country: data.location?.country,
          academicDegree: data.academicDegree,
          yearsExperience: data.yearsExperience,
          company: data.atsApplicationFormCompany,
          jobTitle: data.atsApplicationFormJobTitle,
          customQuestionAnswers: data.customQuestionAnswers,
        },
      );

      setIsLoading(false);

      window.location.href = `${returnUrl}/submit/success`;
    } catch (e) {
      console.error(e);
      window.location.href = `${returnUrl}/submit/error`;
    }
  });

  const watchParserResumeContent = watch('parserResumeContent');
  const watchResumeContent = watch('resumeContent');

  useEffect(() => {
    if (!watchParserResumeContent) return;

    setIsLoading(true);

    setValue(
      'atsApplicationFormResumeContent',
      getValues('parserResumeContent'),
    );
    setValue(
      'atsApplicationFormResumeFilename',
      getValues('parserResumeFilename'),
    );

    ParsedResumesService.create({
      base64File: watchParserResumeContent,
      subdomain: props.companyInfo?.subdomain,
    })
      .then((parsedResume) => {
        setValue(
          'atsApplicationFormName',
          `${parsedResume.firstName} ${parsedResume.lastName}`,
        );
        setValue('phoneNumber', parsedResume.phone);
        setValue('atsApplicationFormEmail', parsedResume.email);
        setValue('atsApplicationFormLinkedinUrl', parsedResume.linkedinUrl);

        setValue('academicDegree', parsedResume.educationLevel);
        setValue('atsApplicationFormCompany', parsedResume.currentCompany);
        setValue('atsApplicationFormJobTitle', parsedResume.jobTitle);
        setValue('yearsExperience', parsedResume.yearsExperience);
        setValue('atsApplicationFormPortfolioUrl', parsedResume.portfolioUrl);

        setValue('location', {
          country: parsedResume.locationCountryCode,
          state: parsedResume.locationStateCode,
          city: parsedResume.locationCity,
          address: null,
          name: parsedResume.locationName,
        });

        setValue('resumeId', parsedResume.applicationResumeId, {
          shouldValidate: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [watchParserResumeContent]);

  useEffect(() => {
    setValue('resumeId', null);
  }, [watchResumeContent]);

  return (
    <StateMachineProvider>
      {isLoading && <LoadingSpinner showBackdrop />}
      <CenteredLayout heightClass='h-100'>
        <LogoWithBackgroundHeader
          companyInfo={props.companyInfo}
          whitelabelEnabled={props.companyInfo?.logoUrl != null}
        />
        {!props.previewMode && props.previewJobPost.status !== 'published' ? (
          <UnpublishedJobEmptyState />
        ) : (
          <>
            <JobPostPageHeader
              previewMode={props.previewMode}
              previewJobPost={props.previewJobPost}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              companyInfo={props.companyInfo}
            />
            <form
              onSubmit={onSubmit}
              className={classNames('text-start', 'mx-auto')}
              style={{ maxWidth: maxWidth(activeTab) }}
            >
              {activeTab == 'overview' && (
                <OverviewTab
                  previewMode={props.previewMode}
                  jobPostHtmlString={props.jobPostHtmlString}
                  setActiveTab={setActiveTab}
                />
              )}
              {activeTab === 'application_form' && (
                <ApplicationFormTab
                  previewJobPost={props.previewJobPost}
                  sources={props.sources}
                  applicationFormTemplate={props.applicationFormTemplate}
                  candidateConsentHtml={props.candidateConsentHtml}
                  previewMode={props.previewMode}
                  formState={formState}
                  formRegister={register}
                  getValues={getValues}
                  setValue={setValue}
                  formControl={control}
                  formResetField={resetField}
                  formWatch={watch}
                  formStateValid={formState.isValid}
                  errors={formState.errors}
                  disableSource={jobBoardParams != null}
                  reValidate={trigger}
                  enableApplicationFormAutofill={
                    props.enableApplicationFormAutofill
                  }
                />
              )}
            </form>
          </>
        )}
      </CenteredLayout>
    </StateMachineProvider>
  );
}
