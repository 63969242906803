import { ApplicationFeedComment } from './ApplicationFeedComment';
import { ApplicationHistory } from './ApplicationHistory';
import { Email } from './Email';
import { Trigger } from './applicant_tracking/Trigger';

export enum ApplicationFeedType {
  // Generic
  Note = 'note',
  Applied = 'applied',
  StageMove = 'stage_move',
  ProfileUpdate = 'profile_update',
  Rejection = 'rejection',
  Unrejection = 'unrejection',
  ScorecardFill = 'scorecard_fill',
  ScorecardEdit = 'scorecard_edit',
  Details = 'details',
  Offer = 'offer',
  OfferRejected = 'offer_rejected',
  OfferAccepted = 'offer_accepted',
  OfferRevoked = 'offer_revoked',
  // HRIS
  PushedToHris = 'pushed_to_hris',
  // Interview Scheduling
  InterviewSchedule = 'interview_schedule',
  InterviewScheduleByCandidate = 'interview_schedule_by_candidate',
  InterviewReschedule = 'interview_reschedule',
  InterviewCancel = 'interview_cancel',
  InterviewComplete = 'interview_complete',
  InterviewSelfSchedule = 'interview_self_schedule',
  // Pulse
  PulseSurveyShare = 'pulse_survey_share',
  PulseSurveySent = 'pulse_survey_sent',
  PulseSurveyCompleted = 'pulse_survey_completed',
  // Success
  RcSurveyShared = 'rc_survey_shared',
  RcSurveySent = 'rc_survey_sent',
  RcSurveyCompleted = 'rc_survey_completed',
  RcSelfAssessmentCompleted = 'rc_self_assessment_completed',
  // Background Check
  BackgroundCheckInitiated = 'background_check_initiated',
  BackgroundCheckCompleted = 'background_check_completed',
  // Email
  EmailSent = 'email_sent',
  EmailReceived = 'email_received',
  // Triggers
  TriggerEmailSent = 'trigger_email_sent',
  TriggerRejection = 'trigger_rejection',
}

export interface ApplicationFeed {
  id: number;
  applicationId?: number;
  entryType: ApplicationFeedType;
  authorId?: number;
  authorName?: string;
  jobName?: string;
  createdAt: Date;
  comments: ApplicationFeedComment[];
  editedAt?: Date;
  deletedAt?: Date;
}

export interface ApplicationFeedInterview {
  id: number;
  interviewStage: { id: number; name: string };
}

export interface ApplicationFeedScorecard {
  id: number;
  interview?: ApplicationFeedInterview;
}

export interface ApplicationFeedEmail extends ApplicationFeed {
  email: Email;
}

export interface History extends ApplicationFeed {
  applicationHistory: ApplicationHistory;
}

export interface Interview extends ApplicationFeed {
  interview: ApplicationFeedInterview;
}

export interface Note extends ApplicationFeed {
  note: string;
}

export interface OfferFeed extends ApplicationFeed {
  authorEmail: string;
  email: Email;
  note: string;
  revokeOfferPermitted: boolean;
  offerId?: number;
  offerRejectReasons?: string[];
}

export interface ScorecardFill extends ApplicationFeed {
  scorecard: ApplicationFeedScorecard;
}

export type Simple = ApplicationFeed;

export interface PulseSurveyActions extends ApplicationFeed {
  ceSurveyName: string;
}

export interface TriggerActions extends ApplicationFeed {
  trigger: Trigger;
}
