import classNames from 'classnames';
import React from 'react';
import styles from '../../TableBody/styles.module.scss';
import { Chip } from '../../../../../../../components/Chip';

interface PropTypes {
  autoRecording: boolean;
}

export function AutoRecordingDataCell(props: PropTypes) {
  return (
    <td className={classNames('pb-3', styles['table-data-cell-border-bottom'])}>
      <Chip
        className={classNames(
          'fw-semibold text-primary fs-6',
          props.autoRecording ? 'bg-light-success' : 'bg-light-danger',
        )}
      >
        {props.autoRecording ? 'Enabled' : 'Disabled'}
      </Chip>
    </td>
  );
}
