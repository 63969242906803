import {
  Campaign,
  EmploymentType,
  SalaryPeriod,
  Wallet,
} from '@vonq/hapi-elements-types';
import { WindowWithHapi } from '..';
import { JobDetails } from '../../../../entities/JobDetails';
import { Organization } from '../../../../entities/Organization';
import { User } from '../../../../entities/User';
import { JobPostingDetailsForm } from '../JobBoardsTabUI/JobPostingDetails';

function mapIndustryAndCategory(data: {
  id: number | string;
  description: string;
}) {
  return { description: data.description };
}

function salaryIndication(form: JobPostingDetailsForm) {
  if (!form.salaryRangeTo || !form.salaryCurrency) {
    return {};
  }

  return {
    period: form.salaryPeriod as SalaryPeriod,
    range: {
      from: form.salaryRangeFrom,
      to: form.salaryRangeTo,
      currency: form.salaryCurrency,
    },
  };
}

export async function createCampaign(
  form: JobPostingDetailsForm,
  jobDetails: JobDetails,
  organization: Organization,
  user: User,
  wallet: Wallet,
  window: WindowWithHapi,
): Promise<Campaign> {
  const campaignForm = {
    companyId: organization.id.toString(),
    currency: wallet.balance.currency,
    recruiterInfo: {
      id: user.id,
      name: user.name,
      emailAddress: user.email,
    },
    campaignName: form.campaignName,
    postingDetails: {
      title: form.title,
      description: form.editorStateHtml,
      organization: {
        name: organization.name,
        companyLogo: organization.organizationInfo?.logoUrl,
      },
      workingLocation: {
        addressLine1: form.address1,
        addressLine2: form.address2,
        postcode: form.postcode,
        city: form.city,
        country: form.country,
        allowsRemoteWork: jobDetails.locationType !== 'onsite',
      },
      contactInfo: {
        name: form.contactName,
        phoneNumber: form.contactPhone,
        emailAddress: form.contactEmail,
      },
      yearsOfExperience: form.yearsOfExperience,
      employmentType: EmploymentType.permanent,
      weeklyWorkingHours: {
        from: form.weeklyHoursFrom,
        to: form.weeklyHoursTo,
      },
      salaryIndication: salaryIndication(form),
      jobPageUrl: form.jobPostUrl,
      applicationUrl: form.applicationFormUrl,
    },
    targetGroup: {
      educationLevel: [form.educationLevel],
      seniority: [form.seniority],
      industry: [mapIndustryAndCategory(form.industry)],
      jobCategory: [mapIndustryAndCategory(form.jobCategory)],
    },
    orderedProducts:
      // @ts-expect-error the vonq documentation is not updated with the value method.
      window.hapi.campaign.state.campaignForm.value.orderedProducts,
    labels: { jobId: jobDetails.id.toString() },
    paymentMethod:
      window.hapi.orderJourney.utils.paymentMethodKeys.directCharge,
    walletId: wallet.id,
  };

  // @ts-expect-error the vonq documentation is not updated with the value method.
  return await window.hapi.campaign.api.orderCampaign(campaignForm);
}
