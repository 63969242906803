import React, { useCallback } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { Interview } from '../../../../../entities/applicant_tracking/Interview';
import ScheduleInterviewPage from '../index';
import { InterviewService } from '../../../../../services/applicant_tracking/InterviewService';
import { EventSlot } from '../../../../../components/cronofy/CronofyDatePicker/EventSlot';
import { Timedelta } from '../../../../../utils/timedelta';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { InterviewerParticipant } from '../../../../../entities/applicant_tracking/InterviewerParticipant';
import { appendCurrentRedirectUri } from '../../../../../utils/url';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  meetingOrganizer: User;
  interview: Interview;
  application: Application;
  availableParticipants: User[];
  meetingProviders: VcsProvider[];
}

export default function RescheduleInterview(props: PropTypes) {
  const onConfirm = useCallback(
    async (
      eventSlot: EventSlot,
      participants: InterviewerParticipant[],
      meetingProvider: string,
    ) => {
      try {
        const interview = await InterviewService.update(
          props.interview.id,
          participants.map((p) => p.user.id),
          eventSlot.slot.start,
          eventSlot.slot.end,
          eventSlot.tzid,
          meetingProvider,
        );

        window.location.href = appendCurrentRedirectUri(
          `/applicant_tracking/interviews/${interview.id}/schedule_success`,
        );
      } catch (e) {
        window.location.href = appendCurrentRedirectUri(
          `/applicant_tracking/interviews/schedule_error?application_id=${props.interview.application.id}`,
        );
      }
    },
    [],
  );

  const participantIds = props.interview.interviewers.map(
    (interviewHiringMember) => interviewHiringMember.hiringMember.user.id,
  );

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.meetingOrganizer}
      applicationId={props.application.id}
      title='Reschedule your interview'
      description='At least one participant needs to have the calendar connection'
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      candidate={props.application.candidate}
      participants={props.availableParticipants.filter(
        (user) => participantIds.indexOf(user.id) >= 0,
      )}
      availableParticipants={props.availableParticipants}
      interviewStage={props.interview.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.interview.meetingProvider}
      durationMinutes={Timedelta.fromMs(props.interview.duration).toMins()}
      onConfirm={onConfirm}
      candidateView={false}
      selfScheduleView={false}
      allowChangingMeetingOrganizer={false}
      allowIgnoreParticipantCalendar={true}
      interview={props.interview}
    />
  );
}
