import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { BackArrow } from '../../../../components/BackArrow';
import { Card } from '../../../../components/Card';
import { Chip } from '../../../../components/Chip';
import { IconSpan } from '../../../../components/IconSpan';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PageTitle } from '../../../../components/PageTitle';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { CandidateService } from '../../../../services/applicant_tracking/CandidateService';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { applicationStatusClass } from '../../../../utils/applicationStatusClass';
import { humanize } from '../../../../utils/humanize';
import CandidateForm from '../CandidateForm';
import { mergeCandidateApplications } from '../utils/mergeCandidateApplications';

interface PropTypes {
  candidateId: string;
}

export default function EditCandidatePage(props: PropTypes) {
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get('redirect_uri');

  const [candidate, setCandidate] = useState<Candidate>(null);

  const [candidateDto, setCandidateDto] = useState<CandidateDto>(null);

  useEffect(() => {
    CandidateService.get(props.candidateId).then((candidate) => {
      setCandidate(candidate);
      setCandidateDto(mergeCandidateApplications(candidate, []));
    });
  }, []);

  async function handleSuccessfulSubmit() {
    window.location.href = redirectUrl;
  }

  if (candidate == null) return <LoadingSpinner />;

  return (
    <div className='d-flex flex-column'>
      <PageHeader candidate={candidate} redirectUrl={redirectUrl} />
      <Card className='mt-4'>
        <CandidateForm
          onSuccessfulSubmit={handleSuccessfulSubmit}
          headerFactory={(formState) => (
            <FormHeader
              candidate={candidate}
              formState={formState}
              redirectUrl={redirectUrl}
            />
          )}
          candidateDto={candidateDto}
          isEditing={true}
        />
      </Card>
    </div>
  );
}

function PageHeader(props: { candidate: Candidate; redirectUrl: string }) {
  return (
    <div className='d-flex align-items-center'>
      <div className='me-auto d-flex align-items-center'>
        <BackArrow url={props.redirectUrl} />
        <PageTitle
          text={`${props.candidate.firstName} ${props.candidate.lastName}`}
        />
        <Chip
          className={classNames(
            'fw-semibold',
            applicationStatusClass(props.candidate.status),
            'ms-2h',
          )}
        >
          {humanize(props.candidate.status)}
        </Chip>
      </div>
      <div className='fs-5 text-muted'>
        Last Updated at{' '}
        {moment(props.candidate.modifiedAt).format('MMMM DD, YYYY, hh:mm A')}
      </div>
    </div>
  );
}

function FormHeader(props: {
  candidate: Candidate;
  formState: FormState<CandidateDto>;
  redirectUrl: string;
}) {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <h2>Edit Candidate Profile</h2>
        <div className='ms-auto d-flex gap-2'>
          <Button
            color='danger'
            onClick={() => (window.location.href = props.redirectUrl)}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            disabled={!props.formState.isValid}
          >
            Save and Close
          </Button>
        </div>
      </div>
      {props.candidate.applicationIds.length > 1 && (
        <div className='mt-4 bg-light-warning py-2 px-3 rounded align-self-start'>
          <IconSpan
            spanText='Editing the candidate profile will have an impact on all applications from this candidate.'
            icon={{ name: 'bi-info-circle' }}
          />
        </div>
      )}
    </div>
  );
}
