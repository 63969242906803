import React from 'react';
import { User } from '../../../../../entities/User';
import { ActionItem } from '../../../../../entities/v1/applicant_tracking/ActionItem';

interface PropTypes {
  actionItem: ActionItem;
  currentUser: User;
}

export function ActionItemBody(props: PropTypes) {
  const scorecardURL = props.actionItem.completed
    ? `/applications/${props.actionItem.applicationId}/scorecards?show_scorecard_id=${props.actionItem.actionIntentValue}`
    : `/applicant_tracking/scorecards/${props.actionItem.actionIntentValue}`;

  switch (props.actionItem.intent) {
    case 'fill_scorecard':
      return (
        <span>
          needs to{' '}
          {props.currentUser.id === props.actionItem.hiringMember.userId ? (
            <a className='text-blue' href={scorecardURL}>
              fill-in the interview scorecard
            </a>
          ) : (
            <span className='fw-bold'>fill-in the interview scorecard</span>
          )}
          {props.actionItem?.application?.candidate?.name && (
            <> for {props.actionItem?.application?.candidate?.name}</>
          )}
        </span>
      );
    case 'schedule_interview':
      return (
        <span>
          needs to schedule the {props.actionItem.actionIntentValue} interview
          {props.actionItem?.application?.candidate?.name && (
            <>
              {' '}
              with{' '}
              <a href={`/applications/${props.actionItem.applicationId}`}>
                <span className='text-blue'>
                  {props.actionItem?.application?.candidate?.name}
                </span>
              </a>
            </>
          )}
        </span>
      );
  }
}
