import React from 'react';
import { Button } from 'reactstrap';
import { IconSpan } from '../../../../../../components/IconSpan';

interface ModalProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export function ModalBody(props: ModalProps) {
  return (
    <>
      <div className='mb-1 fs-5 fw-semibold'>
        <div className='bg-light-warning mb-3'>
          <IconSpan
            icon={{
              name: 'bi-info-circle',
            }}
            spanClassName='ms-2 ps-1'
            className='my-1 px-3 py-2'
            spanText='This action will delete auto-reject job triggers.'
          />
        </div>
        <div className='fs-4 mt-1'>
          Are you sure you want to change the application form?
        </div>
      </div>
      <div className='d-flex mt-4 pt-2 justify-content-end'>
        <Button color='danger' className='me-2' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button onClick={props.onSuccess} color='primary'>
          Change
        </Button>
      </div>
    </>
  );
}
