import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import * as CronofyElements from 'cronofy-elements';
import { CronofyOptions } from '../CronofyOptions';
import { EventSlot } from './EventSlot';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { Interview } from '../../../entities/applicant_tracking/Interview';
import { InterviewerParticipant } from '../../../entities/applicant_tracking/InterviewerParticipant';
import { buildAvailabilityQuery } from './utils/buildAvailabilityQuery';
import { waitForElement } from '../../../utils/waitForElement';
import { NoSlotsEmptyState } from './NoSlotsEmptyState';

const TYPE_NO_SLOTS_FOUND = 'no_slots_found';

interface PropTypes {
  applicationInterviews: InterviewListItem[];
  interview?: Interview;
  options: CronofyOptions;
  participants: InterviewerParticipant[];
  startDate: Date;
  durationMinutes: number;
  timezone: string;
  searchDays?: number;
  startHours: number;
  onConfirm: (event: EventSlot) => void;
  setSlotsAvailable: (b: boolean) => void;
}

function buildOptions(props: PropTypes) {
  const onConfirm = (eventSlot: EventSlot) => {
    if (eventSlot.type === TYPE_NO_SLOTS_FOUND) {
      props.setSlotsAvailable(false);

      const cronofyRightColumn = document.querySelector(
        'div.cronofy__column-right',
      );

      waitForElement('div.cronofy__no-slots', 1500, cronofyRightColumn).then(
        (element) => {
          if (element) {
            element.classList.add('h-100');

            const root = createRoot(element);
            root.render(<NoSlotsEmptyState />);
          }
        },
      );

      return;
    }

    const button = document.querySelector(
      'button.cronofy__confirm-button',
    ) as HTMLButtonElement;

    if (button) button.disabled = true;

    props.onConfirm(eventSlot);
  };

  const availabilityQuery = buildAvailabilityQuery(
    props.options,
    props.participants,
    props.startDate,
    props.startHours,
    props.durationMinutes,
    props.applicationInterviews,
    props.timezone,
    props.searchDays,
    props.interview,
  );

  return {
    element_token: props.options.elementToken,
    target_id: 'cronofy-date-time-picker',
    availability_query: availabilityQuery,
    tzid: props.timezone,
    styles: {
      prefix: 'cronofy',
      colors: {
        buttonConfirm: '#08243E',
        buttonText: '#08243E',
        buttonActive: '#08243E',
        buttonActiveText: '#FFFFFF',
        buttonHover: '#08243E',
        buttonTextHover: '#FFFFFF',
      },
    },
    callback: (event) => onConfirm(event.notification),
    data_center: props.options.dataCenter,
  };
}

export function CronofyDatePicker(props: PropTypes) {
  const [element, setElement] = useState(null);

  const options = buildOptions({ ...props });

  useEffect(() => {
    if (!element) {
      setElement(CronofyElements.DateTimePicker(options));
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.update(options);
    }
  }, [options]);

  return <div id='cronofy-date-time-picker'></div>;
}
