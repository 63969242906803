import classNames from 'classnames';
import React from 'react';
import { Select, SelectOption } from '../Select';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  /** Options available to be selected. */
  options: SelectOption[];
  /** Selected option. */
  selected: SelectOption;
  /** Label. */
  label: string;
  /** Indicates if the selected option is clearable. Defaults to true */
  isClearable?: boolean;
  /** Placeholder for the selected value */
  placeholder?: string;
  /** Called when the input changes, with the newly selected option. */
  onChange: (newOption: SelectOption) => void;
  /** Called when the input is cleared. */
  onClear?: () => void;
  /** Classes to apply to the wrapping element. */
  className?: string;
  /** Classes to apply to the label of this component. */
  labelClassName?: string;
  testId?: string;
  /** It will appear a * to tell that the field is mandatory. */
  mandatory?: boolean;
  /** Size of the select component. Valid values: small or medium. Default: medium. */
  size?: Size;
  disabled?: boolean;
  isLoading?: boolean;
}

export function LabelledSelect(props: PropTypes) {
  return (
    <div className={classNames(props.className)}>
      <div className={'mb-2 d-flex align-items-end'}>
        <label className={classNames('me-auto', props.labelClassName)}>
          {props.label}
          {props.mandatory && <span className='ps-1 text-danger'>*</span>}
        </label>
      </div>
      <Select
        testId={props.testId}
        options={props.options}
        selected={props.selected}
        isClearable={props.isClearable}
        size={props.size}
        placeholder={props.placeholder}
        onChange={(option) => props.onChange(option)}
        onClear={props?.onClear}
        disabled={props.disabled}
        isLoading={props.isLoading}
      />
    </div>
  );
}
