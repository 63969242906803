import snakecaseKeys from 'snakecase-keys';
import { InterviewCandidateUser } from '../../../entities/applicant_tracking/InterviewCandidateUser';
import { ApiService } from '../../ApiService';
import { Interview } from '../../../entities/applicant_tracking/Interview';

export class InterviewCandidateUserService {
  public static createSelfSchedule(
    applicationId: number,
    interviewStageId: number,
    interviewerUserIds: number[],
    meetingProvider: string,
    durationMinutes: number,
    dateRange: number,
    scheduleFromHours: number,
    timezoneId: string,
    meetingOrganizerId: number,
  ): Promise<InterviewCandidateUser> {
    return ApiService.post<InterviewCandidateUser>(
      `/api/applicant_tracking/interview_candidate_users`,
      snakecaseKeys({
        applicationId: applicationId,
        interviewStageId: interviewStageId,
        interviewerUserIds: interviewerUserIds,
        meetingProvider: meetingProvider,
        durationMinutes: durationMinutes,
        dateRange: dateRange,
        scheduleFromHours: scheduleFromHours,
        timezoneId: timezoneId,
        meetingOrganizerId: meetingOrganizerId,
      }),
    );
  }

  public static selfSchedule(
    interviewCandidateUserSecureId: string,
    startTime: string,
    endTime: string,
    timezoneId: string,
  ): Promise<Interview> {
    return ApiService.post<Interview>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserSecureId}/schedule`,
      snakecaseKeys({
        startTime: startTime,
        endTime: endTime,
        timezoneId: timezoneId,
      }),
    );
  }

  public static sendSelfScheduleEmail(
    interviewCandidateUserId: number,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserId}/send_email`,
    );
  }

  public static update(
    interviewCandidateUserId: number,
    meetingOrganizerUserId: number,
    interviewerUserIds: number[],
    meetingProvider: string,
    durationMinutes: number,
    dateRange: number,
    scheduleFromHours: number,
    timezoneId: string,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/applicant_tracking/interview_candidate_users/${interviewCandidateUserId}`,
      snakecaseKeys({
        meetingOrganizerId: meetingOrganizerUserId,
        interviewerUserIds: interviewerUserIds,
        meetingProvider: meetingProvider,
        durationMinutes: durationMinutes,
        dateRange: dateRange,
        scheduleFromHours: scheduleFromHours,
        timezoneId: timezoneId,
      }),
    );
  }
}
