import React from 'react';
import { EmptyState } from '../../../../../../components/EmptyState';

export function EmptyStateContainer() {
  return (
    <EmptyState
      title='No scorecards were filled'
      text='We were not able to find this scorecard filled by you.'
      actionButton={
        <div className='d-flex justify-content-center'>
          <div className='px-1 bg-info mt-4 rounded-1 fs-4'>
            <div className='px-2 py-2 '>
              <a href='javascript:history.back()' className='text-white'>
                Go back
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}
