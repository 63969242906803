import React, { useEffect, useState } from 'react';
import { CandidatePageHeaderInfo } from '../../../../CandidatePage/CandidatePageHeader/CandidatePageHeaderInfo';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/v1/applicant_tracking/Application';
import { ApplicationService } from '../../../../../services/v1/ApplicationService';

interface PropTypes {
  currentUser: User;
  applicationId: number;
}

export function Header(props: PropTypes) {
  const [application, setApplication] = useState<Application>(null);

  useEffect(() => {
    (async () => {
      const application = await ApplicationService.show(props.applicationId, [
        'candidate',
        'job',
        'source',
      ]);
      setApplication(application);
    })();
  }, []);

  return (
    <>
      <div className='d-flex align-items-center mb-4'>
        <CandidatePageHeaderInfo
          applicantTrackingEnabled={true}
          application={application}
        />
      </div>
    </>
  );
}
