import React, { useState } from 'react';
import { PageTitle } from '../../../../components/PageTitle';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { Card } from '../../../../components/Card';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { DataPrivacySettings } from '../../../../entities/v1/DataPrivacySettings';
import { DataPrivacySettingsService } from '../../../../services/v1/DataPrivacySettings';
import { useForm } from 'react-hook-form';
import {
  QueryClientProvider,
  UseMutationResult,
  useMutation,
  useQuery,
} from 'react-query';
import { queryClient } from '../../../../config/queryClient';
import { Alert, AlertType } from '../../../../components/Alert';
import { CandidateConsent } from './CandidateConsent';

export interface DataPrivacySettingsForm {
  candidateConsentHtml: string;
  candidateConsentEditorState: string;
}

export default function DataPrivacyPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <Title />
      <Holder />
    </QueryClientProvider>
  );
}

function Holder() {
  const [alert, setAlert] = useState(false);

  const { isLoading, data } = useQuery({
    queryKey: ['dataPrivacySettings'],
    queryFn: () => DataPrivacySettingsService.show(),
    retry: false,
  });
  const mutation = useMutation<void, void, DataPrivacySettingsForm>({
    mutationFn: (data) => DataPrivacySettingsService.update(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dataPrivacySettings'] });
      setAlert(true);
    },
  });

  return (
    <div>
      <Card>
        {isLoading ? (
          <div className='my-5'>
            <LoadingSpinner />
          </div>
        ) : (
          <Body
            isLoading={isLoading}
            settings={data}
            mutation={mutation}
            alert={alert}
            onAlert={setAlert}
          />
        )}
      </Card>
    </div>
  );
}

function Title() {
  return (
    <div className='d-flex align-items-center mb-4'>
      <PageTitle text='Data Privacy' />
    </div>
  );
}

interface BodyPropTypes {
  isLoading: boolean;
  settings: DataPrivacySettings;
  mutation: UseMutationResult<void, void, DataPrivacySettingsForm, unknown>;
  alert: boolean;
  onAlert: (v) => void;
}

function Body(props: BodyPropTypes) {
  const form = useForm<DataPrivacySettingsForm>({
    mode: 'onChange',
    defaultValues: props.settings,
  });

  function onSubmit(data: DataPrivacySettingsForm) {
    props.mutation.mutate(data);
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      {props.alert && (
        <Alert
          clearable
          type={AlertType.Success}
          autoClearTimeout={4000}
          onClose={() => props.onAlert(false)}
        >
          Successfully updated the Data Privacy settings.
        </Alert>
      )}
      <CardHeader isSavable={form.formState.isValid} />
      <CandidateConsent form={form} />
    </form>
  );
}

function CardHeader(props: { isSavable: boolean }) {
  return (
    <SetupPageHeader
      title='Candidate Data'
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isSavable,
          action: () => {},
        },
      ]}
      classNames='align-items-center mb-4'
      titleClassNames='mb-0'
    />
  );
}
