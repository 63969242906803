import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { BodyWrapper } from './BodyWrapper';
import { Footer } from './Footer';
import { OfferLetterTemplate } from '../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { ConnectEmailIntegrationModal } from '../../../CandidatePage/CandidateActions/SendEmailModal/ConnectEmailIntegrationModal';
import { appendQueryParam, buildUrl } from '../../../../utils/url';
import { SelectOption } from '../../../../components/Select';
import { getCurrencySymbol } from './BodyWrapper/OfferSummary';
import { OfferService } from '../../../../services/v1/applicant_tracking/OfferService';
import { Offer } from '../../../../entities/v1/applicant_tracking/Offer';
import { AlertObject, AlertType } from '../../../../components/Alert';
import { ApiError } from '../../../../services/ApiService/errors/ApiError';
import classNames from 'classnames';
import { getNounWithPossessiveCase } from '../../../../utils/grammar';
import { AttachmentDto } from '../../../CandidatePage/CandidateActions/SendEmailModal/AttachmentDto';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import {
  MAX_EMAIL_FILES_SIZE_MB,
  removeFilesIfLimitExceeded,
} from '../../../../utils/emails';

interface PropTypes {
  applicationId: number;
  authorizeUrl: string;
  hasEmailConnected: boolean;
  isOpen: boolean;
  to: string;
  onClose: (succedded?: boolean) => void;
  setAlert: (alert: AlertObject) => void;
}

export interface OfferForm {
  attachments: AttachmentDto[];
  to: string;
  offerLetterTemplate?: OfferLetterTemplate;
  startingDate?: string;
  salary?: number;
  salaryCurrency?: string;
  equity?: number;
  workingHours?: number;
  cc?: SelectOption[];
  bcc?: SelectOption[];
  email?: string;
  emailJson?: string;
  subject?: string;
  subjectJson?: string;
}

const ATTACHMENT_MAX_FILES = 6;
const ATTACHMENT_MAX_SIZE_MB = 10;

const INITIAL_FORM = {
  attachments: [],
  to: null,
  offerLetterTemplate: null,
  startingDate: null,
  salary: null,
  salaryCurrency: null,
  equity: null,
  workingHours: null,
  cc: null,
  bcc: null,
  email: null,
  emailJson: null,
  subject: null,
  subjectJson: null,
};

function salaryWithCurrency(salary?: number, salaryCurrency?: string) {
  if (salary == null) {
    return null;
  }

  return `${getCurrencySymbol(salaryCurrency)}${salary}`;
}

function handlePreview(form: OfferForm, applicationId: number) {
  const previewUrl = buildUrl(
    `${window.location.origin}/applicant_tracking/offer_letter_templates/${form.offerLetterTemplate?.id}/preview`,
    [
      {
        key: 'salary',
        value: salaryWithCurrency(form.salary, form.salaryCurrency),
      },
      { key: 'equity', value: form.equity?.toString() },
      { key: 'start_date', value: form.startingDate },
      { key: 'working_hours', value: form.workingHours?.toString() },
      {
        key: 'application_id',
        value: applicationId.toString(),
      },
    ],
  );

  window.open(previewUrl, '_blank', 'noopener,noreferrer');
}

async function handleSubmit(
  form: OfferForm,
  applicationId: number,
  onClose: (succedded?: boolean) => void,
  setAlert: (alert: AlertObject) => void,
) {
  await OfferService.create({
    applicationId: applicationId,
    emailHtml: form.email,
    offerLetterTemplateId: form.offerLetterTemplate.id,
    subjectText: form.subject,
    to: form.to,
    bcc: form.bcc?.map((c: SelectOption) => c.value),
    cc: form.cc?.map((c: SelectOption) => c.value),
    equity: form.equity,
    salary: salaryWithCurrency(form.salary, form.salaryCurrency),
    startingDate: form.startingDate,
    workingHours: form.workingHours,
    attachments: form.attachments,
  })
    .then((offer: Offer) => {
      setAlert({
        message: (
          <span>
            <b>
              {getNounWithPossessiveCase(
                offer.application?.candidate?.name || 'Candidate',
              )}
            </b>{' '}
            Offer for <b>{offer.application?.job?.name || 'the job'}</b> was
            successfully sent!
          </span>
        ),
        type: AlertType.Success,
      });
      onClose(true);
    })
    .catch((e: ApiError) => {
      setAlert({
        message: e.body?.message || 'It was not possible to send the offer',
        type: AlertType.Danger,
      });
      onClose(false);
    });
}

function handleAttachmentChange(
  filesContent: FileContent[],
  setAttachments: (attachments: AttachmentDto[]) => void,
) {
  const attachmentsDto: AttachmentDto[] = filesContent.map(
    (fileContent: FileContent) => {
      return {
        filename: fileContent.name,
        contentBase64: fileContent.content,
      };
    },
  );

  setAttachments(attachmentsDto);
}

export function SendOfferModal(props: PropTypes) {
  const [form, setForm] = useState<OfferForm>(INITIAL_FORM);
  const [step, setStep] = useState<number>(0);
  const [validStep, setValidStep] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const bodyIdentifier = 'offer-email-body';

  const handleAttachments = (attachments: AttachmentDto[]) =>
    setForm({
      ...form,
      attachments: attachments,
    });

  const [
    openFileSelector,
    { filesContent, errors, removeFileByIndex, plainFiles },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: ATTACHMENT_MAX_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: { max: ATTACHMENT_MAX_FILES },
  });

  useEffect(() => {
    removeFilesIfLimitExceeded(
      form.attachments.length,
      plainFiles,
      errors,
      removeFileByIndex,
      MAX_EMAIL_FILES_SIZE_MB,
    );

    handleAttachmentChange(filesContent, handleAttachments);

    setIsValid(validStep);
  }, [filesContent, validStep]);

  useEffect(() => {
    const emailBodyContainer = document.querySelector(`#${bodyIdentifier}`);
    if (emailBodyContainer) {
      emailBodyContainer.scrollTop = emailBodyContainer.scrollHeight;
    }
  }, [form.attachments]);

  return (
    <CloseableModal
      size={step === 3 ? 'md' : 'lg'}
      isOpen={props.isOpen}
      className='modal-dialog-centered'
      onClose={props.onClose}
      headerTitle={step === 3 ? 'Offer Summary' : 'Send Offer'}
      bodyClassName={classNames({ 'p-0': props.hasEmailConnected && step < 3 })}
      bodyChildren={
        props.hasEmailConnected ? (
          <BodyWrapper
            elementIdentifier={bodyIdentifier}
            applicationId={props.applicationId}
            form={form}
            to={props.to}
            step={step}
            setForm={setForm}
            setValidStep={setValidStep}
            files={plainFiles}
            removeAttachmentFileByIndex={removeFileByIndex}
          />
        ) : (
          <ConnectEmailIntegrationModal
            url={appendQueryParam(
              props.authorizeUrl,
              'state',
              window.location.href,
            )}
            onClose={() => {}}
          />
        )
      }
      footerClassName={step == 3 && 'd-flex justify-content-between'}
      footerChildren={
        props.hasEmailConnected && (
          <Footer
            files={plainFiles}
            isValid={isValid}
            step={step}
            setStep={setStep}
            onClose={props.onClose}
            onSubmit={() =>
              handleSubmit(
                form,
                props.applicationId,
                props.onClose,
                props.setAlert,
              )
            }
            onPreview={() => handlePreview(form, props.applicationId)}
            openFileSelector={openFileSelector}
            attachmentErrors={errors}
            maxFiles={ATTACHMENT_MAX_FILES}
            maxFileSizeMb={ATTACHMENT_MAX_SIZE_MB}
          />
        )
      }
    />
  );
}
