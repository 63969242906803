import React from 'react';
import classNames from 'classnames';

export function CareersPageFooter() {
  return (
    <footer className={classNames('footer mt-auto py-4 bg-white')}>
      <span className='fs-4 text-info'>
        Powered by{' '}
        <a
          target='_blank'
          className='text-info fw-semibold'
          href='https://www.screenloop.com/'
          rel='noreferrer'
        >
          Screenloop
        </a>
      </span>
    </footer>
  );
}
