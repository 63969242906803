import { FileError } from 'use-file-picker';

export const MAX_EMAIL_FILES_SIZE_MB = 20;

export const EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME = 'email_files_size_exceeded';

export function isFilesSizeLimitExceeded(
  files: File[],
  maxFilesSumSizeMb?: number,
) {
  if (maxFilesSumSizeMb == null || files.length == 0) {
    return false;
  }

  const sumFileSizeBytes: number = files
    .map((f) => f.size)
    .reduce((a, b) => a + b);

  return sumFileSizeBytes > maxFilesSumSizeMb * 1000000;
}

export function removeFilesIfLimitExceeded(
  numberOfFilesUploaded: number,
  newFiles: File[],
  errors: FileError[],
  removeFileByIndex: (i: number) => void,
  maxFilesSumSizeMb?: number,
) {
  const filesSizeLimitExceeded = isFilesSizeLimitExceeded(
    newFiles,
    maxFilesSumSizeMb,
  );

  if (filesSizeLimitExceeded) {
    errors.push({
      name: EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME,
      plainFile: undefined,
      fileSizeToolarge: true,
    });

    let removedFiles = 0;
    while (removedFiles < newFiles.length - numberOfFilesUploaded) {
      removeFileByIndex(numberOfFilesUploaded);

      removedFiles++;
    }
  }
}
