import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ApplicationPendingActions } from '../../../../entities/applicant_tracking/analytics/ApplicationPendingActions';
import { Col, Row } from 'reactstrap';
import { Chip } from '../../../../components/Chip';
import { Link } from '../../../../components/Link';
import { ApplicationPendingActionsService } from '../../../../services/applicant_tracking/analytics/ApplicationPendingActionsService';
import { TitledPanel } from '../../../applicant_tracking/Analytics/Widgets/TitledPanel';
import { InfoTooltip } from '../../../../components/InfoTooltip';
import { CardTitle } from '../../../../components/Card';

interface PropTypes {
  height: string;
}

export function PendingActionsPanel(props: PropTypes) {
  return (
    <TitledPanel
      title={<CardTitle>Pending Actions</CardTitle>}
      height={props.height}
    >
      <LazyContent />
    </TitledPanel>
  );
}

function LazyContent() {
  const [data, setData] = useState<ApplicationPendingActions[]>(null);

  useEffect(() => {
    ApplicationPendingActionsService.index().then((pendingActions) =>
      setData(pendingActions),
    );
  }, []);

  if (!data) return <LoadingSpinner />;

  if (data.length === 0) return <EmptyState />;

  return <Content data={data} />;
}

function Content(props: { data: ApplicationPendingActions[] }) {
  return (
    <div className={'mt-2'}>
      <HeaderRow />
      {props.data.map((candidate, index) => (
        <CandidateRow key={index} data={candidate} />
      ))}
    </div>
  );
}

function HeaderRow() {
  return (
    <Row className={classNames('mb-3', 'w-100')}>
      <Col lg={4}>
        <span className={'fs-6 text-dark-200'}>Candidates</span>
      </Col>
      <Col lg={5}>
        <span className={'fs-6 text-dark-200'}>Jobs</span>
      </Col>
      <Col className='text-truncate' lg={3}>
        <InfoTooltip className={'fs-6'}>
          Number of pending action points for each candidate.
        </InfoTooltip>
        <span className={'ms-1 fs-6 text-dark-200'}>Actions</span>
      </Col>
    </Row>
  );
}

function CandidateRow(props: { data: ApplicationPendingActions }) {
  return (
    <Row className={classNames('mb-2', 'w-100', 'align-items-center')}>
      <Col lg={4}>
        <CandidateLink
          text={props.data.candidateName}
          applicationId={props.data.applicationId}
        />
      </Col>
      <Col lg={5}>
        <div
          className={
            'fs-6 text-primary text-truncate mw-100 align-items-center'
          }
        >
          {props.data.jobName}
        </div>
      </Col>
      <Col lg={3}>
        <Chip className={'text-primary bg-light-danger fs-6'}>
          {props.data.pendingActionsCount} pending
        </Chip>
      </Col>
    </Row>
  );
}

function CandidateLink(props: { text: string; applicationId: number }) {
  return (
    <Link
      href={`/applications/${props.applicationId}`}
      textClassNames={'fs-6 text-info text-truncate'}
    >
      {props.text}
    </Link>
  );
}

function EmptyState() {
  return (
    <div className='h-100 flex-fill d-flex align-items-center justify-content-center fw-bold'>
      No pending actions
    </div>
  );
}
