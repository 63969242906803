import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../components/Chip';
import { IconChip } from '../../../../../components/IconChip';
import {
  Session,
  SessionEvaluationStatus,
} from '../../../../../entities/training/Session';

interface PropTypes {
  className?: string;
  session: Session;
}

const ABSOLUTE_POSITION_CLASSES = classNames(
  'd-flex',
  'top-0',
  'end-0',
  'gap-2',
);

function isPendingEvaluation(status: SessionEvaluationStatus) {
  return (
    status === SessionEvaluationStatus.Submitted ||
    status === SessionEvaluationStatus.Pending
  );
}

function PendingEvaluationChip() {
  return (
    <IconChip className={classNames('bg-pin', 'text-white')}>
      Pending Evaluation
    </IconChip>
  );
}

function CompletedChip() {
  return (
    <IconChip
      className={classNames('bg-success', 'text-white')}
      iconClass='bi bi-check-lg'
      iconPosition='right'
    >
      Completed
    </IconChip>
  );
}

function ComingSoonChip() {
  return (
    <Chip className={classNames('bg-gray-900', 'text-white')}>Coming soon</Chip>
  );
}

function AssignedChip() {
  return <Chip className={classNames('bg-warning')}>Assigned to you</Chip>;
}

export function SessionStatusChip(props: PropTypes) {
  return (
    <div className={classNames(ABSOLUTE_POSITION_CLASSES, props.className)}>
      {isPendingEvaluation(props.session.evaluation.status) && (
        <PendingEvaluationChip />
      )}
      {props.session.completed ? (
        <CompletedChip />
      ) : props.session.comingSoon ? (
        <ComingSoonChip />
      ) : (
        props.session.assigned && <AssignedChip />
      )}
    </div>
  );
}
