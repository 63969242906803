import snakecaseKeys from 'snakecase-keys';
import { UpsertEmailTemplateDto } from '../../containers/EmailTemplates/SetupEmailTemplatePage/UpsertEmailTemplateDto';
import { EmailTemplate } from '../../entities/EmailTemplate';
import { ApiService } from '../ApiService';

export class EmailTemplateService {
  public static upsert(
    props: UpsertEmailTemplateDto,
    subjectText: string,
    html: string,
  ): Promise<EmailTemplate> {
    if (props.id == null) {
      return ApiService.post<EmailTemplate>(
        '/api/email_templates',
        snakecaseKeys({
          ...props,
          subjectText: subjectText,
          templateHtml: html,
        }),
      );
    } else {
      return ApiService.put<EmailTemplate>(
        `/api/email_templates/${props.id}`,
        snakecaseKeys({
          ...props,
          subjectText: subjectText,
          templateHtml: html,
        }),
      );
    }
  }

  public static list(): Promise<EmailTemplate[]> {
    return ApiService.get<EmailTemplate[]>(`/api/email_templates`);
  }

  public static replaceVariables(
    emailTemplateId: number,
    applicationId: number,
  ): Promise<EmailTemplate> {
    return ApiService.put<EmailTemplate>(
      `/api/email_templates/${emailTemplateId}/replace_email_variables`,
      {
        application_id: applicationId,
      },
    );
  }
}
