import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export function JobStageHeader(props: { name: string }) {
  return (
    <h4
      className={classNames(
        'p-4',
        'mb-0',
        'text-truncate',
        'text-start',
        'bg-white',
        'fw-bold',
        'border-bottom',
        styles['pipeline-job-stage-header'],
      )}
    >
      {props.name}
    </h4>
  );
}
