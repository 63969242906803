import React from 'react';
import { MultiPaginatedSelect } from '../../../Select/MultiPaginatedSelect';
import { SelectOption } from '../filterState';
import { Countable, Paginatable } from '../../../../entities/Pagination';

export interface CandidatePulseFilterResult extends Paginatable, Countable {
  options: SelectOption[];
}

interface PropTypes<IsMulti extends boolean = false> {
  label: string;
  selected: SelectOption[];
  onChange: (newValue: SelectOption[]) => void;
  loadOptions: (
    search: string,
    page: number,
  ) => Promise<CandidatePulseFilterResult>;
  isMulti?: IsMulti;
  placeholder?: string;
  max?: number;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

export function CandidatePulseFilter<IsMulti extends boolean = false>(
  props: PropTypes<IsMulti>,
) {
  async function loadOptions(
    search: string,
    _loadedOptions: unknown,
    { page }: AdditionalType,
  ) {
    const result = await props.loadOptions(search, page);

    let changeSelected = false;

    const selected = props.selected.map((selected) => {
      const selectedOptionLoaded = result.options.find(
        (option) => selected.value == option.value && selected !== option,
      );

      changeSelected ||= !!selectedOptionLoaded;

      return selectedOptionLoaded ?? selected;
    });

    if (changeSelected) {
      props.onChange(selected);
    }

    return {
      options: result.options,
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <>
      <div className='px-1 mb-2 d-flex flex-nowrap align-items-end'>
        <label className='me-auto text-truncate'>{`${props.label}s`}</label>
        {props.max && (
          <small className='text-muted text-nowrap'>Max. {props.max}</small>
        )}
      </div>
      <MultiPaginatedSelect
        additional={defaultAdditional}
        isMulti={true}
        value={props.selected}
        loadOptions={loadOptions}
        onChange={(opts) => {
          if (!props.max || opts.length <= props.max) {
            props.onChange(opts as SelectOption[]);
          }
        }}
        debounceTimeout={300}
        placeholder={`All ${props.label}s`}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </>
  );
}
