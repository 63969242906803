import React from 'react';
import { Chip } from '../../../../../../Chip';
import { Icon } from '../../../../../../Icon';

interface PropTypes {
  jobName: string;
  departmentName: string;
  applicationsCount: number;
}

export function JobCard(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between align-items-center border rounded-2 border-blue-gray-500 fw-semibold fs-6 mb-3 py-3'>
      <div className='ms-4'>
        <span className='fs-5 me-2'>{props.jobName}</span>
        <Chip className='bg-light-info text-primary'>
          {props.departmentName}
        </Chip>
      </div>
      <div className='me-5'>
        <Icon name='bi-arrow-right' className='me-2' />
        <Chip className='bg-orange ms-2 text-primary'>
          {props.applicationsCount} Candidates
        </Chip>
      </div>
    </div>
  );
}
