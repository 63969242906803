import React from 'react';
import classNames from 'classnames';
import { IconChip } from '../../../../components/IconChip';

interface PropTypes {
  classNames?: string;
  iconClass?: string;
  value: string | number;
}

export function OverviewChip(props: PropTypes) {
  return (
    <IconChip
      className={classNames(
        'bg-gray',
        'fw-semibold',
        'text-dark-500',
        'px-2',
        props.classNames,
      )}
      iconPosition='left'
      iconClass={props.iconClass && `text-blue-gray-700 bi ${props.iconClass}`}
    >
      {props.value}
    </IconChip>
  );
}
