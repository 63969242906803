import React from 'react';
import { Row } from 'reactstrap';
import {
  Control,
  FieldErrors,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { SurveySection } from '../../../../../../entities/survey_engine/SurveySection';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { FormFieldHeader } from '../../../../../../components/FormFieldHeader';
import { QuestionInput } from './QuestionInput';
import { ApplicationSalaryInput } from '../../../../Applications/ApplicationSalaryInput';
import { HasWorkingVisaInput } from '../../../../Applications/HasWorkingVisaInput';
import { PreviewJobPost } from '../../../../../../entities/applicant_tracking/PreviewJobPost';
import { SourceInput } from '../../../../Applications/SourceInput';
import { Source } from '../../../../../../entities/applicant_tracking/Source';
import { replaceMustacheVariable } from '../../../../../../utils/mustache';
import { YearsExperienceInput } from '../../../../Applications/YearsExperienceInput';
import { AcademicDegreeInput } from '../../../../Applications/AcademicDegreeInput';

interface PropTypes {
  previewJobPost: PreviewJobPost;
  section: SurveySection;
  previewMode: boolean;
  sources: Source[];
  formState: FormState<any>;
  formRegister: UseFormRegister<any>;
  control?: Control<any, any>;
  getValues?: UseFormGetValues<any>;
  setValue?: UseFormSetValue<any>;
  formResetField: (controllerName: string) => void;
  formWatch: UseFormWatch<any>;
  errors: FieldErrors;
  disableSource: boolean;
}

interface PresetQuestionFieldProps extends Omit<PropTypes, 'section'> {
  question: SurveyQuestion;
}

function PresetQuestionField(props: PresetQuestionFieldProps) {
  switch (props.question.tag) {
    case 'ats_application_form_salary_expectation':
      return (
        <ApplicationSalaryInput
          label={props.question.title}
          containerClassNames={'fs-5 fw-semibold'}
          formHeaderClassNames={'px-0'}
          inputGroupClassNames={'px-0'}
          salaryInputColSize={6}
          formControl={props.control}
          watch={props.formWatch}
          formRegister={props.formRegister}
          formResetField={props.formResetField}
          errors={props.errors}
          disabled={props.previewMode}
          required={props.question.mandatory}
          setValue={props.setValue}
          shouldRender
        />
      );
    case 'ats_application_form_has_working_visa':
      return (
        <HasWorkingVisaInput
          label={replaceMustacheVariable(
            props.question.title,
            'country',
            props.previewJobPost.locationCountry,
          )}
          formRegister={props.formRegister}
          formControl={props.control}
          shouldRender
          disabled={props.previewMode}
          required={props.question.mandatory}
        />
      );
    case 'ats_application_form_source':
      return (
        <SourceInput
          shouldRender
          label={props.question.title}
          sources={props.sources}
          formControl={props.control}
          disabled={props.previewMode}
          required={props.question.mandatory}
        />
      );
    case 'ats_application_form_academic_degree':
      return (
        <AcademicDegreeInput
          label={props.question.title}
          required={props.question.mandatory}
          formControl={props.control}
          disabled={props.previewMode}
        />
      );
    case 'ats_application_form_years_experience':
      return (
        <YearsExperienceInput
          label={props.question.title}
          required={props.question.mandatory}
          formControl={props.control}
          disabled={props.previewMode}
        />
      );
  }
}

function renderPresetQuestionField(question: SurveyQuestion) {
  return [
    'ats_application_form_salary_expectation',
    'ats_application_form_has_working_visa',
    'ats_application_form_source',
    'ats_application_form_academic_degree',
    'ats_application_form_years_experience',
  ].includes(question.tag);
}

export function ApplicationFormSection(props: PropTypes) {
  return (
    <div key={props.section.id}>
      <div className='py-3'>
        <h3 className='my-0'>{props.section.sectionName}</h3>
        {props.section.sectionDescription != null && (
          <label className='fs-5 text-dark-200'>
            {props.section.sectionDescription}
          </label>
        )}
      </div>
      {props.section.questions.map((question: SurveyQuestion) => {
        if (
          question.tag === 'ats_application_form_source' &&
          props.disableSource
        ) {
          return null;
        } else if (renderPresetQuestionField(question)) {
          return (
            <div className='mb-3' key={`${props.section.id}-${question.id}`}>
              <PresetQuestionField question={question} {...props} />
            </div>
          );
        }

        return (
          <Row
            key={`${props.section.id}-${question.id}`}
            className='mx-0 mb-3 fs-5 fw-semibold'
          >
            <FormFieldHeader
              fieldName={question.title}
              isRequired={question.mandatory}
              classNames={'px-0 mb-1'}
            />
            <QuestionInput
              question={question}
              disabled={props.previewMode}
              formState={props.formState}
              formRegister={props.formRegister}
              getValues={props.getValues}
              setValue={props.setValue}
              control={props.control}
            />
          </Row>
        );
      })}
    </div>
  );
}
