import React from 'react';
import classNames from 'classnames';
import { RatingOptions } from '../../../../../InterviewPage/RatingModal/RatingOptions';
import { RateOption } from '../../../../../../entities/RateOption';
import styles from './styles.module.scss';

interface PropTypes {
  rating?: string;
  readonly?: boolean;
  setRating?: (rating: string) => void;
}

export function ratingOptions(): RateOption[] {
  return [
    {
      icon: 'bi-x-circle-fill',
      title: 'Definitely Not',
      value: 'definitely_no',
      color: 'red-500',
    },
    {
      icon: 'bi-hand-thumbs-down-fill',
      title: 'No',
      value: 'no',
      color: 'red-500',
    },
    {
      icon: 'bi-hand-thumbs-up-fill',
      title: 'Yes',
      value: 'yes',
      color: 'success-500',
    },
    {
      icon: 'bi-star-fill',
      title: 'Strong Yes',
      value: 'definitely_yes',
      color: 'success-500',
    },
  ];
}

export function ScorecardScore(props: PropTypes) {
  return (
    <>
      <div className='my-4 text-center fw-semibold'>
        Did the candidate pass the interview?
      </div>
      <RatingOptions
        ratingOptionClass={classNames(
          styles['rating-option'],
          'bg-blue-gray-light',
          'text-dark-200',
        )}
        ratingOptions={ratingOptions()}
        activeOption={ratingOptions().find(
          (option: RateOption) => option.value === props?.rating,
        )}
        readonly={props.readonly}
        setActiveOption={(option: RateOption) =>
          props.setRating?.(option.value)
        }
      />
    </>
  );
}
