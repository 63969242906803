import React from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { MentionSuggestion } from './MentionSuggestion';
import styles from './styles.module.scss';
import { useUsers } from '../../queries/v1/useUsers';
import { UserList } from '../../entities/v1/User';

interface PropTypes {
  name?: string;
  value: string;
  className?: string;
  placeholder?: string;
  autofocus?: boolean;
  required?: boolean;
  singleLine?: boolean;
  trigger?: string;
  appendSpaceOnAdd?: boolean;
  inputRef?: React.Ref<HTMLTextAreaElement> | React.Ref<HTMLInputElement>;
  onBlur?: (event: any) => void;
  onChange?: (value: string) => void;
}

const buildUserOptions = (userList: UserList): SuggestionDataItem[] => {
  return userList?.users
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map((user) => ({
      id: user.id,
      display: user.name,
    }));
};

export const NoteInput = React.memo(function NoteInput(props: PropTypes) {
  const { data: users } = useUsers({ page: 1, pageSize: 1000 });

  return (
    <MentionsInput
      name={props.name}
      value={props.value}
      onBlur={props.onBlur}
      className={`mention-input ${styles.mentionsInput} ${
        props.className || ''
      }`}
      data-testid='interview-marker-note-text'
      placeholder={props.placeholder || 'Insert note here.'}
      autoComplete='off'
      required={props.required}
      singleLine={props.singleLine}
      autoFocus={props.autofocus}
      onChange={(v) => props.onChange(v.target.value)}
      inputRef={props.inputRef}
    >
      <Mention
        trigger='@'
        data={buildUserOptions(users)}
        className={styles.mention}
        appendSpaceOnAdd={props.appendSpaceOnAdd !== false}
        displayTransform={(_id, display) => `@${display}`}
        renderSuggestion={(
          suggestion,
          search,
          highlightDisplay,
          index,
          focused,
        ) => (
          <MentionSuggestion
            suggestion={suggestion}
            search={search}
            highlightDisplay={highlightDisplay}
            index={index}
            focused={focused}
          />
        )}
      />
    </MentionsInput>
  );
});
