import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';

export enum AlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
}

export class AlertObject {
  message: string | React.ReactNode;
  type: AlertType;
}

interface PropTypes {
  type: AlertType;
  clearable?: boolean;
  autoClearTimeout?: number;
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

function hideAlert(
  setDisplay: (display: boolean) => void,
  onClose?: () => void,
) {
  onClose && onClose();
  setDisplay(false);
}

export function Alert(props: PropTypes) {
  const [display, setDisplay] = useState(props.children != null);

  if (props.autoClearTimeout) {
    useEffect(() => {
      const timeId = setTimeout(
        () => hideAlert(setDisplay, props.onClose),
        props.autoClearTimeout,
      );

      return () => {
        clearTimeout(timeId);
      };
    }, []);
  }

  return (
    <>
      {display && (
        <div
          id='status-alert'
          className={classNames(
            'alert fade show pe-0',
            'alert-' + props.type,
            styles.alert,
            props.className || 'mt-4',
            props.clearable && 'alert-dismissible',
          )}
          role='alert'
        >
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>{props.children}</div>
            {props.clearable && (
              <Button
                type='button'
                color='transparent'
                className={classNames('btn-close px-3', styles['close-button'])}
                data-bs-dismiss='alert'
                data-dismiss='alert'
                aria-label='Close'
                onClick={() => hideAlert(setDisplay, props.onClose)}
              ></Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
