import classNames from 'classnames';
import React from 'react';
import { Link } from '../Link';

export function IconLink(props: {
  text: string;
  onClick?: () => void;
  className?: string;
  linkClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  iconClassName: string;
  downloadUrl?: string;
}) {
  return (
    <div className={classNames('d-flex align-items-center', props.className)}>
      <i className={classNames('me-2', props.iconClassName)} />
      <Link
        onClick={props.onClick}
        classNames={props.linkClassName}
        textClassNames={props.textClassName}
        disabled={props.disabled}
        href={props.downloadUrl}
        download={!!props.downloadUrl}
      >
        {props.text}
      </Link>
    </div>
  );
}
