import classNames from 'classnames';
import React from 'react';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  className?: string;
  children: React.ReactNode;
  size?: Size;
}

function chipSizeClasses(size: Size) {
  switch (size) {
    case 'sm':
      return 'fs-6 px-2 py-1';
    case 'md':
      return 'fs-5 px-2h py-2';
    case 'lg':
      return 'fs-4 px-3 py-2h';
    default:
      return 'fs-6 px-2 py-1';
  }
}

export function Chip(props: PropTypes) {
  return (
    <small
      className={classNames(
        'badge',
        'rounded-pill',
        'fw-normal',
        props.className,
        props.size && chipSizeClasses(props.size),
      )}
    >
      {props.children}
    </small>
  );
}
