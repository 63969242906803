import React, { useEffect, useMemo, useRef } from 'react';
import { JobStageTemplateInput } from '../../../../../../PlanTemplates/SetupPlanTemplatePage';
import { JobStagesList } from '../../../../../../../components/ApplicantTracking/JobStagesList';
import { HorizontalDivider } from '../../../../../../../components/HorizontallDivider';
import { mapJobStageToJobStageInput } from '../../../../../../../utils/applicant_tracking/planTemplate';
import { PlanTemplate } from '../../../../../../../entities/applicant_tracking/PlanTemplate';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  planTemplate: PlanTemplate;
  focusedJobStage: JobStageTemplateInput;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
}

export function PipelineDetail(props: PropTypes) {
  const ref = useRef(null);

  const jobStages = useMemo(
    () => mapJobStageToJobStageInput(props.planTemplate.jobStages),
    [props.planTemplate],
  );

  useEffect(() => {
    if (!props.focusedJobStage) return;

    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [props.focusedJobStage]);

  return (
    <>
      <HorizontalDivider classNames='my-4 py-1' />
      <div
        ref={ref}
        className={classNames(
          'fw-semibold fs-3 mb-2',
          styles['template-title'],
        )}
      >
        {props.planTemplate.name}
      </div>
      <div className='fs-6 text-dark-200'>
        Select stage to view or edit questions and scorecards
      </div>
      <JobStagesList
        viewMode
        hideScrollbar
        disableViewingNonInterviewStages
        onAddStage={null}
        setJobStages={null}
        focusedJobStage={props.focusedJobStage}
        jobStageHeaderClassName='my-3'
        setFocusedJobStage={props.setFocusedJobStage}
        jobStages={jobStages}
        planTemplate={props.planTemplate}
      />
    </>
  );
}
