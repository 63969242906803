import React from 'react';
import { ActionsPendingDetail } from './ActionsPendingDetail';
import { NameAndDaysDetail } from './NameAndDaysDetail';
import { MoveCandidateProps } from '../../components/MoveCandidateStageModal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { JobState } from '../../../../entities/JobDetails';
import { HiringMember } from '../../../../entities/HiringMember';
import { User } from '../../../../entities/User';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { PipelineApplication } from '../../../../entities/applicant_tracking/PipelineApplication';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: PipelineApplication;
  currentHiringMember?: HiringMember;
  currentStage: string;
  currentUser: User;
  daysOnStage: number;
  emailAuthorizeUrl: string;
  jobStatus: JobState;
  name: string;
  offerEnabled?: boolean;
  profileLink: string;
  onMoveCandidate?: (action?: MoveCandidateProps) => void;
  setAlert: (alert: AlertObject) => void;
  reloadJobStages: () => Promise<void>;
}

export function CandidateCard(props: PropTypes) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: props.application.id,
      data: props,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  if (isDragging) {
    return;
  }

  return (
    <div
      className={classNames(
        'border border-white bg-gray px-3',
        styles['candidate-card'],
      )}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <NameAndDaysDetail
        application={props.application}
        currentHiringMember={props.currentHiringMember}
        currentStage={props.currentStage}
        currentUser={props.currentUser}
        daysOnStage={props.daysOnStage}
        emailAuthorizeUrl={props.emailAuthorizeUrl}
        jobStatus={props.jobStatus}
        name={props.name}
        profileLink={props.profileLink}
        offerEnabled={props.offerEnabled}
        onMoveCandidate={props.onMoveCandidate}
        setAlert={props.setAlert}
        reloadJobStages={props.reloadJobStages}
      />
      <ActionsPendingDetail
        actionsPending={props.application.totalPendingActions}
        daysOnStage={props.daysOnStage}
      />
    </div>
  );
}
