import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';
import { setQuestionVisible } from './setQuestionVisible';

export function getSurveyQuestionsWithConditionsVisibility(
  questions: SurveyQuestion[],
  answers: SurveyQuestionAnswer[],
) {
  const questionIds = answers.map((answer) => answer.question_id);

  return questions
    .filter((question) => question.conditions?.length > 0)
    .map((question) => {
      const condition = question.conditions.find((condition) =>
        questionIds.includes(condition.condition_question_id),
      );
      const answerValue = answers.find(
        (answer) => answer.question_id === condition.condition_question_id,
      ).fields[0].value;
      const answerOption = answers.find(
        (answer) => answer.question_id === condition.condition_question_id,
      ).fields[0].option_ids;
      return {
        questionVisible: setQuestionVisible(
          question.type,
          answerValue,
          condition,
          answerOption,
        ),
        dependentQuestionId: condition.dependent_question_id,
      };
    });
}
