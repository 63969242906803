import React from 'react';
import { Col, Row } from 'reactstrap';
import { NavLinkTabs } from '../../../../../../components/NavLinkTabs';
import { SelectOption } from '../../../../../../components/Select';
import { JobSelect } from './JobSelect';
import { JobStagesSelect } from './JobStagesSelect';
import { DateRangePicker } from '../../../../../../components/DateRangePicker';
import { humanize } from '../../../../../../utils/humanize';
import { ListCandidatesFilterTab } from '../..';
import { FilterStates } from '../../FilterStates';

interface PropTypes {
  filterStates: FilterStates;
  onFilterStatesUpdate: (fs: FilterStates) => void;
}

function RightFilters(props: PropTypes) {
  return (
    <Row className='col-7 justify-content-end'>
      <Col xs={4}>
        <DateRangePicker
          clearable
          dateFilter={props.filterStates.dateRange}
          onChange={(start, end, range) => {
            const dateRange = props.filterStates.dateRange;
            if (
              dateRange?.start !== start ||
              dateRange?.end !== end ||
              dateRange?.range !== range
            ) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                dateRange: {
                  start: start,
                  end: end,
                  range: range,
                },
              });
            }
          }}
          className={'w-100'}
        />
      </Col>
      <Col xs={3}>
        <JobSelect
          options={props.filterStates.job.all}
          selected={props.filterStates.job?.selected ?? null}
          onChange={(opt: SelectOption) => {
            if (props.filterStates.job?.selected?.value !== opt?.value) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                ...{ job: { ...props.filterStates.job, selected: opt } },
              });
            }
          }}
        />
      </Col>
      <Col xs={3}>
        <JobStagesSelect
          options={props.filterStates.jobStage.all}
          selected={props.filterStates.jobStage?.selected ?? null}
          onChange={(opt: SelectOption) => {
            if (props.filterStates.jobStage?.selected?.value !== opt?.value) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                ...{
                  jobStage: { ...props.filterStates.jobStage, selected: opt },
                },
              });
            }
          }}
        />
      </Col>
    </Row>
  );
}

export function ListCandidatesFilters(props: PropTypes) {
  return (
    <>
      <div className='d-flex justify-content-between px-4 pt-4 mb-4'>
        <NavLinkTabs<ListCandidatesFilterTab>
          navigationId={'candidates-table-tab'}
          activeTab={props.filterStates.tab ?? 'all'}
          setActiveTab={(at) => {
            if (props.filterStates.tab !== at) {
              props.onFilterStatesUpdate({ ...props.filterStates, tab: at });
            }
          }}
          tabTypeMapping={['active', 'hired', 'rejected', 'draft', 'all'].map(
            (tab: ListCandidatesFilterTab) => ({
              text: humanize(tab),
              tab: tab,
            }),
          )}
        />
        <RightFilters {...props} />
      </div>
    </>
  );
}
