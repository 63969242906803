import classNames from 'classnames';
import React from 'react';
import {
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';
import { ClearIcon } from '../../ClearIcon';

export interface PropTypes<E> {
  classNames?: string;
  allowedFormats: string[];
  controllerContentName: string;
  controllerFileName: string;
  value?: PathValue<E, Path<E>>;
  disabled?: boolean;
  isRequired?: boolean;
  clear: () => void;
  formRegister: UseFormRegister<E>;
  openFileSelector: () => void;
  setValue: UseFormSetValue<E>;
}

function placeholder(allowedFormats: string[]) {
  if (allowedFormats?.length === 1) {
    return `Choose file (${allowedFormats.join(',')})`;
  }

  return 'Choose file';
}

function resetFileData<E>(
  controllerContentName: string,
  controllerFileName: string,
  clear: () => void,
  setValue: UseFormSetValue<E>,
) {
  clear();
  setValue(controllerContentName as Path<E>, undefined, {
    shouldValidate: true,
  });
  setValue(controllerFileName as Path<E>, undefined, {
    shouldValidate: true,
  });
}

function InputBody<E>(props: PropTypes<E>) {
  return (
    <span
      className={classNames(
        'd-flex form-control fs-5 justify-content-between flex-nowrap',
        styles.placeholder,
        props.disabled ? 'bg-gray' : 'bg-white',
      )}
    >
      <input
        type='text'
        className={classNames(
          'w-100',
          'no-border',
          { [styles['pointer-cursor']]: !props.disabled },
          styles['placeholder-input'],
          { 'bg-gray': props.disabled },
        )}
        onClick={() => props.openFileSelector()}
        readOnly
        placeholder={placeholder(props.allowedFormats)}
        {...props.formRegister(props.controllerFileName as Path<E>, {
          required: props.isRequired,
        })}
        disabled={props.disabled}
      />
      {props.value != null && (
        <ClearIcon
          classNames={styles['pointer-cursor']}
          onClick={() =>
            resetFileData(
              props.controllerContentName,
              props.controllerFileName,
              props.clear,
              props.setValue,
            )
          }
        ></ClearIcon>
      )}
    </span>
  );
}

export function AttachmentInputBody<E>(props: PropTypes<E>) {
  return (
    <div className={classNames('input-group', props.classNames)}>
      <input
        type='hidden'
        {...props.formRegister(props.controllerContentName as Path<E>, {
          required: props.isRequired,
        })}
        disabled={props.disabled}
      />
      <InputBody {...props} />
      <Button
        size='md'
        className={classNames(
          styles['browser-button'],
          'text-primary',
          'bg-blue-gray-200',
        )}
        disabled={props.disabled}
        onClick={() => props.openFileSelector()}
      >
        Browse
      </Button>
    </div>
  );
}
