import React from 'react';
import { ApprovalFlowStep } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Col, Row } from 'reactstrap';
import { OrderCircle } from '../OrderCircle';
import { Approver } from '../Approver';

interface PropTypes {
  step: ApprovalFlowStep;
  totalSteps: number;
  index: number;
  approversBreakLine?: boolean;
  isCurrentStep?: boolean;
  declinedStepOrder?: number;
}

function stepText(order: number, totalSteps: number) {
  if (totalSteps === 1) return 'Must be approved by:';
  if (order === totalSteps) return 'Last Approval';

  switch (order) {
    case 1:
      return 'Must be approved first by:';
    case 2:
      return 'Second Approval';
    case 3:
      return 'Third Approval';
    case 4:
      return 'Fourth Approval';
    default:
      return '';
  }
}

export function Step(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex',
        props.step.order !== props.totalSteps && 'mb-2',
        props.step.order !== 1 && 'mt-3',
      )}
    >
      <div className='d-flex flex-column align-items-center'>
        <div className='mb-2'>
          <OrderCircle
            step={props.step}
            isCurrentStep={props.isCurrentStep}
            declinedStepOrder={props.declinedStepOrder}
          />
        </div>
        {props.step.order !== props.totalSteps && (
          <VerticalDivider classNames='flex-grow-1' />
        )}
      </div>
      <div className='ms-5h'>
        <div className={classNames('fs-5 text-dark-200 mt-2h')}>
          {stepText(props.step.order, props.totalSteps)}
        </div>
        <Row
          className={classNames(
            'd-flex flex-wrap mt-3 align-items-center w-90 mx-0',
            styles['approvers-row'],
          )}
        >
          {props.step.approvers.map((approver, index) => (
            <React.Fragment key={index}>
              <Col
                className={classNames(
                  props.approversBreakLine ? 'col-12' : 'col-auto',
                )}
              >
                <Approver
                  approver={approver}
                  currentStepOrder={props.step.order}
                  declinedStepOrder={props.declinedStepOrder}
                />
              </Col>
              {props.step.approvers.length - 1 !== index && (
                <Col
                  className={classNames(
                    'col-auto',
                    'px-0',
                    props.approversBreakLine && 'mx-2h',
                  )}
                >
                  <small className='text-dark-200'>OR</small>
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </div>
    </div>
  );
}
