import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';

const MAX_LENGTH = 255;

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
  getValues: UseFormGetValues<JobRequisitionDto>;
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function Description(props: PropTypes) {
  const required = false;

  const description = useWatch({
    control: props.formControl,
    name: 'description',
  });

  return (
    <Col xs={6}>
      <div className='d-flex justify-content-between'>
        <FormFieldHeader
          fieldName='Description'
          isRequired={required}
          classNames='fw-semibold mb-0'
          fontSize='fs-5'
        />
        <div className='fs-6 text-dark-200'>
          {description?.length ?? 0}/{MAX_LENGTH}
        </div>
      </div>
      <textarea
        className={`form-control fs-5 ${
          description?.length > MAX_LENGTH && 'is-invalid'
        }`}
        placeholder='Insert description'
        rows={4}
        {...props.formRegister('description', {
          required: required,
          maxLength: {
            value: MAX_LENGTH,
            message: `Description should be under ${MAX_LENGTH} characters`,
          },
        })}
      />
    </Col>
  );
}
