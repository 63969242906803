import React, { useState } from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';
import { SendReminderModal } from '../ShowJobRequisitionPage/Header/Actions/SendReminderModal';
import { appendQueryParam } from '../../../../utils/url';

interface PropTypes {
  id: number;
  jobRequisitionUrl: string;
  editAccess: boolean;
  sendReminderEnabled: boolean;
}

function buildSendReminderRedirectUri(actionStatus: boolean) {
  let url = '/applicant_tracking/job_requisitions/flash_redirect';

  url = appendQueryParam(url, 'type', actionStatus ? 'success' : 'danger');
  url = appendQueryParam(
    url,
    'message',
    actionStatus
      ? 'The job requisition email reminder was successfully sent!'
      : 'Failed to send the job requisition email reminder',
  );

  return url;
}

function buildMenuAction(
  action: () => void,
  label: string,
  iconName: string,
  enabled: boolean,
) {
  if (!enabled) return null;

  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={label}
        icon={{ name: iconName, className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function viewButton(jobRequisitionUrl: string) {
  return buildMenuAction(
    () => (window.location.href = jobRequisitionUrl),
    'View',
    'bi-eye',
    true,
  );
}

function editButton(jobRequisitionUrl: string) {
  return buildMenuAction(
    () => (window.location.href = jobRequisitionUrl + '/edit'),
    'Edit',
    'bi-pencil',
    true,
  );
}

function sendReminderButton(onClick: (value: boolean) => void) {
  return buildMenuAction(
    () => onClick(true),
    'Send Reminder',
    'bi-envelope',
    true,
  );
}

export default function RowActionsDropdown(props: PropTypes) {
  const [isSendReminderModalOpen, setIsSendReminderModalOpen] = useState(false);

  const onSendReminderModalClose = (value: boolean) => {
    setIsSendReminderModalOpen(false);

    if (value !== undefined && value !== null) {
      window.location.href = buildSendReminderRedirectUri(value);
    }
  };

  return (
    <>
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'fs-4 text-info',
        }}
        menuPlacementClass={'dropdown-menu-end'}
        menuActions={[
          viewButton(props.jobRequisitionUrl),
          props.editAccess && editButton(props.jobRequisitionUrl),
          props.sendReminderEnabled &&
            sendReminderButton(setIsSendReminderModalOpen),
        ].filter(Boolean)}
      />
      {isSendReminderModalOpen && (
        <SendReminderModal
          jobRequisitionId={props.id}
          onClose={onSendReminderModalClose}
        />
      )}
    </>
  );
}
