import { EmployeeEvalSurvey } from '../../entities/survey_engine/EmployeeEvalSurvey';
import { HiringManagerEmployeeEvalSurvey } from '../../entities/survey_engine/HiringManagerEmployeeEvalSurvey';
import { StartPanelFields } from '../../entities/survey_engine/StartPanelFields';

function isHiringManagerEmployeeEvalSurvey(
  survey: HiringManagerEmployeeEvalSurvey | EmployeeEvalSurvey,
) {
  return (survey as HiringManagerEmployeeEvalSurvey).partOfTeam != null;
}

export function startPanelFields(
  survey: HiringManagerEmployeeEvalSurvey | EmployeeEvalSurvey,
): StartPanelFields {
  let name: string;
  if (isHiringManagerEmployeeEvalSurvey(survey)) {
    name = (survey as HiringManagerEmployeeEvalSurvey).employee.name;
  } else {
    name = 'yourself';
  }

  return {
    title: `Thank you for taking the time to tell us more about ${name}!`,
    notes: [
      {
        icon: 'bi-clock',
        text: 'We estimate that completing this survey will take you less than 5 minutes.',
      },
      {
        icon: 'bi-lock',
        text: 'Please be honest and thoughtful when answering these questions. ',
      },
    ],
    footerText:
      'We take security and privacy seriously. All of the data we collect is completely secure and confidential.',
  };
}
