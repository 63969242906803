const formatNumberForTime = (time: number) => {
  if (time >= 10) return time.toFixed();
  return `0${time.toFixed()}`;
};

export const formatTime = (timeInMilliseconds: number) => {
  const seconds = Math.trunc((timeInMilliseconds / 1000) % 60);
  const minutes = Math.floor((timeInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((timeInMilliseconds / (1000 * 60 * 60)) % 24);

  const hoursDisplay = hours == 0 ? '' : `${hours}:`;
  const minutesDisplay = formatNumberForTime(minutes);
  const secondsDisplay = seconds < 10 ? formatNumberForTime(seconds) : seconds;

  return `${hoursDisplay}${minutesDisplay}:${secondsDisplay}`;
};

export const convertDurationFormatToMs = (time: string) => {
  const [seconds, minutes, hours] = time.split(':').map(Number).reverse();

  const hasHours = hours != null;

  const totalMilliseconds =
    ((hasHours ? hours * 3600 : 0) + minutes * 60 + seconds) * 1000;

  return totalMilliseconds;
};

export type TimeGranularity = 'secs' | 'mins' | 'hours';

export const humanizeTime = (timeInSeconds: number, useShortUnits = false) => {
  const isNegative = timeInSeconds < 0;

  timeInSeconds = Math.abs(timeInSeconds);
  const secs = Math.floor(timeInSeconds);
  const mins = Math.floor(secs / 60);
  const hours = Math.floor(mins / 60);

  let result: string;
  if (mins == 0 && hours === 0) {
    result = useShortUnits
      ? `${secs} s`
      : secs === 1
      ? `${secs} sec`
      : `${secs} secs`;
  } else if (hours === 0 && Math.abs(secs % 60) === 0) {
    result = useShortUnits
      ? `${mins} m`
      : mins === 1
      ? `${mins} min`
      : `${mins} mins`;
  } else if (hours === 0) {
    result = `${mins}m ${Math.abs(secs % 60)}s`;
  } else {
    result = `${hours}h ${Math.abs(mins % 60)}m`;
  }

  return `${isNegative ? '-' : ''}${result}`;
};

export const humanizeTimeExcludingSeconds = (
  timeInSeconds: number,
  useShortUnits = false,
) => {
  const isNegative = timeInSeconds < 0;

  timeInSeconds = Math.abs(timeInSeconds);
  const secs = Math.floor(timeInSeconds);
  const mins = Math.floor(secs / 60);
  const hours = Math.floor(mins / 60);

  let result: string;
  if (hours === 0) {
    result = useShortUnits
      ? `${mins}m`
      : mins === 1
      ? `${mins} min`
      : `${mins} mins`;
  } else {
    result = `${hours}h ${Math.abs(mins % 60)}m`;
  }

  return `${isNegative ? '-' : ''}${result}`;
};

export function addMillisecondsToTime(
  startTime: string,
  durationInMilliseconds: number,
): string {
  const startDateTime: Date = new Date(startTime);
  const endDateTime: Date = new Date(
    startDateTime.getTime() + durationInMilliseconds,
  );

  return endDateTime.toISOString();
}

export const shortFormatDateWithHours = (date: string | Date) => {
  const dateStr = shortFormatDate(date);
  const hourStr = dateToHours(date);

  return `${dateStr}, ${hourStr}`;
};

export function shortFormatDateMonthYear(date: string | Date): string {
  const options: Intl.DateTimeFormatOptions = { month: 'short' };
  const month = new Date(date).toLocaleDateString('en-US', options);
  const year = new Date(date).getFullYear().toString().slice(-2);

  return `${month}/${year}`;
}

export function shortFormatDate(date: string | Date): string {
  if (!date) {
    return;
  }

  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export const longFormatDate = (date: string | Date) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const dateToHours = (date: string | Date) => {
  const newDate = new Date(date);
  const hours = newDate.getHours().toString().padStart(2, '0');
  const minutes = newDate.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const formatDateToDDMMYYYY = (date: string | Date, delimiter = '/') => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}${delimiter}${formattedMonth}${delimiter}${d.getFullYear()}`;
};
