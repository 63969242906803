import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FormFieldHeader } from '../FormFieldHeader';

interface PropTypes {
  registerReturn: UseFormRegisterReturn;
  fieldName?: string;
  placeholder?: string;
  isRequired?: boolean;
  classNames?: string;
  headerClassNames?: string;
  maxLength?: number;
  rows?: number;
  initialValue?: string;
  disabled?: boolean;
}

function recalculateCharacters(event: ChangeEvent<HTMLTextAreaElement>) {
  return event.target.value.length;
}

export function TextAreaInputFormField(props: PropTypes) {
  const [textAreaCount, setTextAreaCount] = useState(
    props.initialValue?.length || 0,
  );

  return (
    <>
      {props.fieldName && (
        <FormFieldHeader
          fieldName={props.fieldName}
          isRequired={props.isRequired}
          classNames={props.headerClassNames}
        />
      )}
      <textarea
        className={classNames('form-control', props.classNames)}
        placeholder={props.placeholder}
        {...props.registerReturn}
        onChange={(e) => {
          setTextAreaCount(recalculateCharacters(e));
          props.registerReturn.onChange(e);
        }}
        disabled={props.disabled}
        rows={props.rows}
        maxLength={props.maxLength}
      />
      {props.maxLength && (
        <small className='mt-2 d-flex justify-content-end text-dark-200'>
          {textAreaCount}/{props.maxLength}
        </small>
      )}
    </>
  );
}
