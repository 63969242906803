import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { DataPrivacySettingsForm } from '.';
import { RichTextEditor } from '../../../../components/RichTextEditor';
import { AreaRichTextComposer } from '../../../../components/RichTextEditor/composers/AreaRichTextComposer';

export function CandidateConsent(props: {
  form: UseFormReturn<DataPrivacySettingsForm, any>;
}) {
  return (
    <div>
      <h4 className='fw-semibold'>Candidate Consent</h4>
      <p className='text-dark-200 fw-normal fs-5'>
        Customise the candidate consent information displayed on your
        application forms.
      </p>
      <Editor form={props.form} />
    </div>
  );
}

function Editor(props: { form: UseFormReturn<DataPrivacySettingsForm, any> }) {
  return (
    <div className='w-50'>
      <RichTextEditor<DataPrivacySettingsForm>
        formHookContext={{
          controllerName: 'candidateConsentEditorState',
          formControl: props.form.control,
        }}
        render={(field) => (
          <AreaRichTextComposer
            defaultEditorState={field.value}
            size='xs'
            onChangeJson={(value: string) => field.onChange(value)}
            onChangeHtml={(html: string) =>
              props.form.setValue('candidateConsentHtml', html)
            }
          />
        )}
      />
    </div>
  );
}
