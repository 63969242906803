import React from 'react';
import { Label } from 'reactstrap';
import { RadioPill } from '../../../../../../components/RadioPill';
import { JobPostingDetailsForm } from '..';
import { CheckboxPillGroup } from '../../../../../../components/CheckboxPillGroup';

interface PropTypes {
  form: JobPostingDetailsForm;
  mandatory?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function SalaryCurrency(props: PropTypes) {
  const field = 'salaryCurrency';

  return (
    <div>
      <Label>
        Salary Currency{' '}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </Label>
      <div className='d-flex fs-5'>
        <CheckboxPillGroup
          onChange={(ev: React.FormEvent<HTMLInputElement>) =>
            props.onInputChange(field, ev.currentTarget.value)
          }
          resetValue={() => props.onInputChange(field, null)}
          selectedValue={props.form.salaryCurrency}
        >
          {['GBP', 'EUR', 'USD', 'JPY'].map((currency: string) => {
            return (
              <RadioPill
                key={currency}
                id={currency}
                label={currency}
                value={currency}
              />
            );
          })}
        </CheckboxPillGroup>
      </div>
    </div>
  );
}
