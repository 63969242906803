import React, { useEffect, useState } from 'react';
import { Control, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../components/Select';
import { JobPageGroupHeader } from '../../JobPageGroupHeader';
import { ApplicationFormTemplateBody } from './ApplicationFormTemplateBody';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { ApplicationFormTemplateList } from '../../../../../../entities/v1/applicant_tracking/ApplicationFormTemplateList';
import { ApplicationFormTemplate } from '../../../../../../entities/v1/applicant_tracking/ApplicationFormTemplate';
import { fetchTemplate } from '../../../../ApplicationFormTemplates/data';

interface PropTypes {
  applicationFormTemplates: ApplicationFormTemplateList;
  watch: UseFormWatch<CreateJobDto>;
  register: UseFormRegister<CreateJobDto>;
  formControl: Control<CreateJobDto>;
}

function mapApplicationFormTemplateToOption(
  aft: ApplicationFormTemplate,
): SelectOption {
  return {
    value: aft.id.toString(),
    label: aft.surveyTemplate.name,
  };
}

export function ApplicationFormBody(props: PropTypes) {
  const watchTemplateId = props.watch('applicationFormTemplateId');
  const applicationFormTemplateOptions: SelectOption[] =
    props.applicationFormTemplates.applicationFormTemplates.map((aft) =>
      mapApplicationFormTemplateToOption(aft),
    );

  const [selectedTemplate, setSelectedTemplate] = useState({
    id: null,
    name: '',
    default: true,
    candidateConsentEnabled: false,
    sections: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(!!watchTemplateId);

  async function load() {
    if (watchTemplateId) {
      setIsLoading(true);
      const template = await fetchTemplate(watchTemplateId);
      setSelectedTemplate(template);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    load();
  }, [watchTemplateId]);

  return (
    <>
      <JobPageGroupHeader
        title='Form Template'
        description='Select the template to start with'
        mandatory={true}
        lastElement={true}
      >
        <Row>
          <Col xs='3'>
            <LabelledSelectFormHook<CreateJobDto>
              label={'Default Templates'}
              placeholder={'Select template'}
              formHookContext={{
                controllerName: 'applicationFormTemplateId',
                formControl: props.formControl,
                required: true,
              }}
              options={applicationFormTemplateOptions}
            />
          </Col>
        </Row>
      </JobPageGroupHeader>
      {watchTemplateId != null && (
        <ApplicationFormTemplateBody
          applicationFormTemplate={selectedTemplate}
          isLoading={isLoading}
          register={props.register}
        />
      )}
    </>
  );
}
