import React, { CSSProperties } from 'react';
import classNames from 'classnames';

export interface PropTypes {
  name: string;
  className?: string;
  title?: string;
  dataToggle?: string;
  dataBsPlacement?: string;
  dataBsDelay?: string;
  topStyle?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export function Icon(props: PropTypes) {
  return (
    <i
      className={classNames(
        'bi',
        { 'top-0': !props.topStyle },
        props.name,
        props.className,
      )}
      onClick={props.onClick}
      style={{
        top: props.topStyle,
        ...props.style,
      }}
      title={props.title}
      data-toggle={props.dataToggle}
      data-bs-placement={props.dataBsPlacement}
      data-bs-delay={props.dataBsDelay}
    />
  );
}
