import React from 'react';
import { Controller, ControllerRenderProps, Path } from 'react-hook-form';
import {
  ArrayContext,
  ControllerContext,
  updateArrayAttributeAt,
} from '../../utils/reactHookForms';
import { Select } from '../Select';
import { SelectOption } from '../Select';
import { ActionMeta } from 'react-select';

interface PropTypes<ControlType> {
  placeholder: string;
  formResetField?: (controllerName: string, options: any) => void;
  visibleOptions?: SelectOption[];
  options: SelectOption[];
  formHookContext?: ControllerContext<ControlType>;
  arrayContext?: ArrayContext;
  clearable?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

export function SelectFormHook<ControlType>(props: PropTypes<ControlType>) {
  if (props.formHookContext == null) return null;

  const onChange = (
    field: ControllerRenderProps<any, any>,
    option: SelectOption,
  ) => {
    if (props.arrayContext != null) {
      const metadata = props.arrayContext?.getArrayMetadata();
      const updated = updateArrayAttributeAt(
        props.arrayContext.arrayData,
        metadata,
        option,
      );
      props.arrayContext.updateArrayData(updated);
    }

    field.onChange(option?.value);
  };

  const onClear = () => {
    props.formResetField?.(props.formHookContext.controllerName, {
      defaultValue: null,
    });
  };

  return (
    <Controller
      control={props.formHookContext.formControl}
      name={props.formHookContext.controllerName as Path<ControlType>}
      rules={{ required: props.formHookContext.required }}
      render={({ field }) => (
        <Select
          options={props.visibleOptions ?? props.options}
          disabled={props.disabled}
          selected={props.options?.find(
            (option) => field.value == option.value,
          )}
          onChange={(option: SelectOption, action: ActionMeta<string>) => {
            switch (action.action) {
              case 'clear':
                onClear();
                break;
              default:
                onChange(field, option);
                break;
            }
          }}
          placeholder={props.placeholder}
          className={'fs-5'}
          isClearable={props.clearable ?? true}
          isLoading={props.isLoading}
        />
      )}
    />
  );
}
