import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Chip } from '../../../../../components/Chip';
import styles from './styles.module.scss';

interface PropTypes {
  candidatesNumber?: number;
}

interface BodyPropTypes {
  classNames: string;
  children: JSX.Element;
}

function SubHeaderBody(props: BodyPropTypes) {
  return (
    <div
      className={classNames(
        'px-4 fw-semibold fs-5 d-flex justify-content-between border-bottom',
        props.classNames,
      )}
    >
      {props.children}
    </div>
  );
}

export function JobStageSubHeader(props: PropTypes) {
  return (
    <SubHeaderBody
      classNames={classNames(
        styles['sub-header-height'],
        props.candidatesNumber > 0 ? 'bg-light-success' : 'bg-light-warning',
      )}
    >
      {props.candidatesNumber > 0 ? (
        <Fragment>
          <span>Active Candidates</span>
          <Chip
            className={classNames(
              styles['chip-height'],
              'bg-success',
              'fw-semibold',
              'fs-6',
            )}
          >
            {props.candidatesNumber}
          </Chip>
        </Fragment>
      ) : (
        <span>No Candidates</span>
      )}
    </SubHeaderBody>
  );
}
