import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {
  Application,
  ApplicationStatus,
} from '../../../../entities/Application';
import {
  ListScorecard,
  Scorecard,
} from '../../../../entities/applicant_tracking/Scorecard';
import { NoteModal } from '../../../../components/ApplicantTracking/NoteModal';
import { PropTypes as DropdownActionPropTypes } from '../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { User } from '../../../../entities/User';
import { UnrejectCandidateStageModal } from '../UnrejectCandidate';
import { Chip } from '../../../../components/Chip';
import { appendRedirectUri } from '../../../../utils/url';
import { NoteService } from '../../../../services/applicant_tracking/NoteService';
import { ScorecardService } from '../../../../services/v1/applicant_tracking/ScorecardService';
import { AlertObject } from '../../../../components/Alert';
import { PushToHrisModal } from '../PushToHrisModal';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { getHrisName } from '../../../../utils/hris';
import { RejectCandidateModal } from './RejectCandidateModal';
import { SendOfferModal } from '../../../applicant_tracking/Offers/SendOfferModal';

interface PropTypes {
  application: Application;
  authorizeUrl: string;
  disabled: boolean;
  sendEmailEnabled: boolean;
  newApplicationPermitted: boolean;
  sendOfferPermitted: boolean;
  user: User;
  onClose: (succeeded: boolean) => void;
  onShareCNPSSurvey: (flag: boolean) => void;
  reloadApplication: () => void;
  setAlert: (alert: AlertObject) => void;
  setIsEmailModalOpen: (open: boolean) => void;
}

function renderSendOffer(
  onClick: () => void,
  sendOfferPermitted: boolean,
): DropdownActionPropTypes {
  if (!sendOfferPermitted) return null;

  return {
    action: onClick,
    buttonChild: (
      <IconSpan
        spanText={'Send Offer'}
        icon={{ name: 'bi-file-earmark-medical', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function renderAddJobApplication(
  application: Application,
  newApplicationPermitted: boolean,
): DropdownActionPropTypes {
  if (!newApplicationPermitted || application.redacted) return null;

  return {
    action: () => {
      const url = new URL(
        '/applicant_tracking/applications/new',
        window.location.origin,
      );
      url.searchParams.append(
        'candidate_id',
        application.candidate.id.toString(),
      );
      window.location.href = url.toString();
    },
    buttonChild: (
      <IconSpan
        spanText={'Add Job Application'}
        icon={{ name: 'bi-plus-circle', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function renderEditCandidateProfile(
  application: Application,
  disabled: boolean,
): DropdownActionPropTypes {
  if (disabled || application.redacted) return null;

  return {
    action: () => {
      window.location.href = appendRedirectUri(
        `/applicant_tracking/candidates/${application.candidate.id}/edit`,
      );
    },
    buttonChild: (
      <IconSpan
        spanText={'Edit Candidate Profile'}
        icon={{ name: 'bi-person', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function FillInScorecard(props: { scorecardSize: number }) {
  if (props.scorecardSize <= 1) {
    return <span>Fill in scorecard</span>;
  }

  return (
    <span>
      <span>Fill in scorecard</span>
      <Chip className='bg-danger text-white ms-3'>{props.scorecardSize}</Chip>
    </span>
  );
}

async function handleViewScorecards(props: {
  applicationId: number;
  setIsLoadingFillScorecard: (isLoading: boolean) => void;
}) {
  try {
    await upsertApplicationScorecards({
      applicationId: props.applicationId,
      setIsLoadingFillScorecard: props.setIsLoadingFillScorecard,
    });
  } catch (e) {
    console.warn(e);
  } finally {
    window.location.href = `/applications/${props.applicationId}/scorecards`;
  }
}

function viewScorecards(
  application: Application,
  setIsLoadingFillScorecard: (isLoading: boolean) => void,
  totalScorecards?: number,
): DropdownActionPropTypes {
  if (application.redacted || totalScorecards === null || totalScorecards === 0)
    return null;

  return {
    action: async () =>
      await handleViewScorecards({
        applicationId: application.id,
        setIsLoadingFillScorecard,
      }),
    buttonChild: (
      <IconSpan
        spanText={'View Scorecards'}
        icon={{ name: 'bi-clipboard', className: 'fs-5' }}
        className='text-info'
      />
    ),
  };
}

function viewInterviewKit(application: Application): DropdownActionPropTypes {
  if (application.nextInterviewStageId == null || application.redacted) {
    return null;
  }

  const mostRecentInterviewWithKit = application.interviews.find(
    (i) =>
      i.stageId === application.nextInterviewStageId && !!i.interviewKitUrl,
  );

  if (!mostRecentInterviewWithKit) {
    return null;
  }

  return {
    action: () =>
      (window.location.href = mostRecentInterviewWithKit.interviewKitUrl),
    buttonChild: (
      <IconSpan
        spanText={'Interview Kit'}
        icon={{ name: 'bi-credit-card-2-front', className: 'fs-5' }}
        className='text-info'
      />
    ),
  };
}

async function upsertApplicationScorecards(props: {
  applicationId: number;
  setIsLoadingFillScorecard: (isLoading: boolean) => void;
}) {
  props.setIsLoadingFillScorecard(true);

  try {
    await ScorecardService.batchCreate({ applicationId: props.applicationId });
  } finally {
    props.setIsLoadingFillScorecard(false);
  }
}

async function handleFillInScorecard(props: {
  applicationId: number;
  selfPendingScorecards: Scorecard[];
  setIsLoadingFillScorecard: (isLoading: boolean) => void;
}) {
  await upsertApplicationScorecards({
    applicationId: props.applicationId,
    setIsLoadingFillScorecard: props.setIsLoadingFillScorecard,
  });

  if (props.selfPendingScorecards.length === 1) {
    window.location.href = `/applicant_tracking/scorecards/${props.selfPendingScorecards[0].id}`;
  } else {
    window.location.href = `/applications/${props.applicationId}/scorecards?own_scorecards=true`;
  }
}

function fillInScorecard(
  application: Application,
  user: User,
  totalOwnPendingScorecardActions: number,
  isLoadingFillScorecard: boolean,
  setIsLoadingFillScorecard: (isLoading: boolean) => void,
  pendingScorecards?: Scorecard[],
): DropdownActionPropTypes {
  if (pendingScorecards == null || application.redacted) return null;

  const selfPendingScorecards = pendingScorecards.filter(
    (scorecard: Scorecard) => scorecard.userId === user.id,
  );

  if (selfPendingScorecards.length === 0) return null;

  return {
    action: async () =>
      await handleFillInScorecard({
        applicationId: application.id,
        selfPendingScorecards,
        setIsLoadingFillScorecard,
      }),
    buttonChild: (
      <IconSpan
        spanText={
          <FillInScorecard scorecardSize={totalOwnPendingScorecardActions} />
        }
        icon={{ name: 'bi-clipboard-check', className: 'fs-5' }}
        className={'text-info'}
        isLoading={isLoadingFillScorecard}
      />
    ),
  };
}

function addNote(onClick: () => void): DropdownActionPropTypes {
  return {
    action: () => onClick(),
    buttonChild: (
      <IconSpan
        spanText={'Add note'}
        icon={{ name: 'bi-file-earmark-text', className: 'fs-5' }}
        className='text-info'
      />
    ),
  };
}

function renderRejectCandidate(
  onClick: () => void,
  disabled: boolean,
  application: Application,
): DropdownActionPropTypes {
  if (disabled || application.status == ApplicationStatus.Rejected) return null;

  return {
    action: () => onClick(),
    buttonChild: (
      <IconSpan
        spanText={'Reject Candidate'}
        icon={{ name: 'bi-file-x', className: 'fs-5', topStyle: '2px' }}
        className={'text-info'}
      />
    ),
  };
}

function renderUnrejectCandidate(
  onClick: () => void,
  disabled: boolean,
  application: Application,
): DropdownActionPropTypes {
  if (disabled || application.status != ApplicationStatus.Rejected) return null;

  return {
    action: onClick,
    buttonChild: (
      <IconSpan
        spanText={'Unreject Candidate'}
        icon={{ name: 'bi-file-plus', className: 'fs-5', topStyle: '2px' }}
        className={'text-info'}
      />
    ),
  };
}

function renderShareCNPSSurvey(
  onClick: () => void,
  application: Application,
): DropdownActionPropTypes {
  if (
    application.redacted ||
    !(application.company_info.pulse_enabled && application.enabled_trigger)
  ) {
    return null;
  }

  return {
    action: () => onClick(),
    buttonChild: (
      <IconSpan
        spanText={'Share cNPS Survey'}
        icon={{ name: 'bi-share', className: 'fs-5' }}
        className='text-info'
      />
    ),
  };
}

export function renderPushToHris(
  onClick: () => void,
  redacted: boolean,
  status?: ApplicationStatus | string,
  hrisEmployeeExternalUrl?: string,
  allowedHrisProvider?: HrisProvider,
): DropdownActionPropTypes {
  if (
    !allowedHrisProvider ||
    status?.toString() != ApplicationStatus.Hired.toString() ||
    hrisEmployeeExternalUrl ||
    redacted
  )
    return null;

  return {
    action: () => onClick(),
    buttonChild: (
      <IconSpan
        spanText={`Export to ${getHrisName(allowedHrisProvider)}`}
        icon={{ name: 'bi-journal-plus', className: 'fs-5', topStyle: '2px' }}
        className={'text-info'}
      />
    ),
  };
}

function menuActions(
  application: Application,
  disabled: boolean,
  newApplicationPermitted: boolean,
  sendOfferPermitted: boolean,
  setIsRejectModalOpen: (state: boolean) => void,
  setUnrejectModalOpen: (state: boolean) => void,
  setOfferModalOpen: (state: boolean) => void,
  setIsNoteModalOpen: (state: boolean) => void,
  setIsPushToHrisModalOpen: (state: boolean) => void,
  onShareCNPSSurvey: (flag: boolean) => void,
  user: User,
  totalOwnPendingScorecardActions: number,
  pendingScorecards?: ListScorecard,
  totalScorecards?: number,
): DropdownActionPropTypes[] {
  const [isLoadingFillScorecard, setIsLoadingFillScorecard] =
    useState<boolean>(false);

  return [
    renderAddJobApplication(application, newApplicationPermitted),
    renderEditCandidateProfile(application, disabled),
    addNote(() => setIsNoteModalOpen(true)),
    viewInterviewKit(application),
    renderRejectCandidate(
      () => setIsRejectModalOpen(true),
      disabled,
      application,
    ),
    renderUnrejectCandidate(
      () => setUnrejectModalOpen(true),
      disabled,
      application,
    ),
    viewScorecards(application, setIsLoadingFillScorecard, totalScorecards),
    fillInScorecard(
      application,
      user,
      totalOwnPendingScorecardActions,
      isLoadingFillScorecard,
      setIsLoadingFillScorecard,
      pendingScorecards?.scorecards,
    ),
    renderShareCNPSSurvey(() => onShareCNPSSurvey(true), application),
    renderPushToHris(
      () => setIsPushToHrisModalOpen(true),
      application.redacted,
      application.status,
      application.hrisEmployee?.externalUrl,
      application.allowedHrisProvider,
    ),
    renderSendOffer(() => setOfferModalOpen(true), sendOfferPermitted),
  ].filter((action) => action != null);
}

export function DropdownActions(props: PropTypes) {
  const [totalScorecards, setTotalScorecards] = useState<number>(null);
  const totalOwnPendingScorecardActions =
    props.application.actionItems.filter((ai) => {
      return (
        ai.hiringMember.userId === props.user.id &&
        ai.intent === 'fill_scorecard' &&
        ai.completed === false
      );
    })?.length || 0;
  const [pendingScorecards, setPendingScorecards] =
    useState<ListScorecard>(null);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [isUnrejectModalOpen, setUnrejectModalOpen] = useState<boolean>(false);
  const [offerModalOpen, setOfferModalOpen] = useState<boolean>(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);
  const [isPushToHrisModalOpen, setIsPushToHrisModalOpen] =
    useState<boolean>(false);

  const handleRejectModalClose = (sendEmail: boolean) => {
    setIsRejectModalOpen(false);

    if (sendEmail) {
      props.setIsEmailModalOpen(true);
    }
  };

  const handleOfferModalClose = (succeeded: boolean) => {
    setOfferModalOpen(false);
    props.onClose(succeeded);
  };

  const handleUnrejectModalClose = (succeeded: boolean) => {
    setUnrejectModalOpen(false);
    props.onClose(succeeded);
  };

  const handleNoteModalClose = (succeeded: boolean) => {
    setIsNoteModalOpen(false);
    props.onClose(succeeded);
  };

  const handlePushToHrisModalClose = (succeeded: boolean) => {
    setIsPushToHrisModalOpen(false);
    props.onClose(succeeded);
  };

  const onAddNote = async (note: string) => {
    await NoteService.create({
      applicationId: props.application.id,
      note: note,
    });
  };

  useEffect(() => {
    (async () => {
      Promise.all([
        ScorecardService.list({ applicationId: props.application.id }),
        ScorecardService.list({
          applicationId: props.application.id,
          status: 'pending',
        }),
      ])
        .then(([scorecards, pendingScorecards]) => {
          setPendingScorecards(pendingScorecards);
          setTotalScorecards(scorecards.entriesCount);
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, []);

  return (
    <>
      <Dropdown
        buttonClassName={classNames(
          'btn btn-secondary border-blue bg-white',
          styles['action-button'],
          styles['dropdown-button'],
        )}
        buttonIcon={{
          name: 'bi-three-dots-vertical',
          className: 'text-info',
          topStyle: '-2px',
        }}
        menuPlacementClass={'dropdown-menu-end'}
        menuActions={menuActions(
          props.application,
          props.disabled,
          props.newApplicationPermitted,
          props.sendOfferPermitted,
          setIsRejectModalOpen,
          setUnrejectModalOpen,
          setOfferModalOpen,
          setIsNoteModalOpen,
          setIsPushToHrisModalOpen,
          props.onShareCNPSSurvey,
          props.user,
          totalOwnPendingScorecardActions,
          pendingScorecards,
          totalScorecards,
        )}
      />
      <RejectCandidateModal
        application={props.application}
        isOpen={isRejectModalOpen}
        onClose={handleRejectModalClose}
        sendEmailEnabled={props.sendEmailEnabled}
        user={props.user}
        reloadApplication={props.reloadApplication}
      />
      <UnrejectCandidateStageModal
        application={props.application}
        isOpen={isUnrejectModalOpen}
        onClose={handleUnrejectModalClose}
      />
      <SendOfferModal
        applicationId={props.application.id}
        authorizeUrl={props.authorizeUrl}
        hasEmailConnected={props.user.hasEmailConnected}
        isOpen={offerModalOpen}
        to={props.application.candidate.email}
        setAlert={props.setAlert}
        onClose={handleOfferModalClose}
      />
      <NoteModal
        mode='Add'
        onSubmit={onAddNote}
        isOpen={isNoteModalOpen}
        onClose={handleNoteModalClose}
      />
      <PushToHrisModal
        applicationIds={[props.application.id]}
        allowedHrisProvider={props.application.allowedHrisProvider}
        isOpen={isPushToHrisModalOpen}
        onClose={handlePushToHrisModalClose}
        setAlert={props.setAlert}
        candidateName={props.application.candidate.name}
        jobName={props.application.job.name}
      />
    </>
  );
}
