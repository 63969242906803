import { getLocationString } from '../../../../components/LocationSelect';
import { Application } from '../../../../entities/applicant_tracking/Application';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';

export function mergeCandidateApplications(
  candidate?: Candidate,
  applications?: Application[],
): CandidateDto {
  const application = applications?.[0];

  const resumeContent = application?.applicationResume;
  let resumeFilename;
  if (resumeContent != null) {
    resumeFilename = 'CV.pdf';
  }

  const coverLetterContent = application?.applicationCoverLetter;
  let coverLetterFilename;
  if (coverLetterContent != null) {
    coverLetterFilename = 'CoverLetter.pdf';
  }

  let referrerId, referrerEmail;
  if (application?.referrerId) {
    referrerId = application?.referrerId;
  } else {
    referrerEmail = application?.referrerEmail;
  }

  const locationName = getLocationString(candidate);

  return {
    id: candidate?.id,
    firstName: candidate?.firstName,
    lastName: candidate?.lastName,
    email: candidate?.email,
    phoneNumber: candidate?.phoneNumber,
    address: candidate?.address,
    location: {
      name: locationName,
      address: candidate?.address,
      city: candidate?.city,
      state: candidate?.stateCode,
      country: candidate?.countryCode,
    },
    candidateId: candidate?.id,
    status: candidate?.status,
    resumeContent,
    resumeFilename,
    coverLetterContent,
    coverLetterFilename,
    company: application?.currentCompany,
    jobTitle: application?.currentTitle,
    yearsExperience: application?.experience,
    academicDegree: application?.academicDegree,
    linkedinUrl: candidate?.linkedinUrl,
    portfolioUrl: application?.portfolioUrl,
    jobPosts: applications?.map((app) => app.jobPost.id),
    salaryExpectation: application?.expectedSalary,
    salaryCurrency: application?.expectedSalaryCurrency,
    referralKnowledge: application?.referralKnowledge,
    referrerId: referrerId,
    referrerEmail: referrerEmail,
    sourceId: application?.sourceId?.toString(),
    hasWorkingVisa: application?.hasWorkingVisa,
    candidateDetail: application?.details,
  };
}
