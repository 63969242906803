import React from 'react';
import { Col, Input } from 'reactstrap';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { CheckboxPill } from '../../../../../../../components/CheckboxPill';

interface PropTypes {
  bccEnabled: boolean;
  ccEnabled: boolean;
  to: string;
  setBccEnabled: (flag: boolean) => void;
  setCcEnabled: (flag: boolean) => void;
}

export function To(props: PropTypes) {
  return (
    <div className='d-flex flex-nowrap align-items-center'>
      <Col xs='10'>
        <SpanTooltip
          text={
            <Input
              defaultValue={props.to}
              disabled
              className='fs-5 fw-normal form-control'
              type='text'
            />
          }
          tooltipText={props.to}
          tooltipStyle={{ maxWidth: '400px' }}
        />
      </Col>
      <Col className='text-end' xs='2'>
        <CheckboxPill
          id='cc'
          label='Cc'
          value='cc'
          selectedValue={props.ccEnabled && 'cc'}
          resetValue={() => props.setCcEnabled(false)}
          onChange={() => props.setCcEnabled(!props.ccEnabled)}
        />
        <CheckboxPill
          id='bcc'
          label='Bcc'
          value='bcc'
          selectedValue={props.bccEnabled && 'bcc'}
          resetValue={() => props.setBccEnabled(false)}
          onChange={() => props.setBccEnabled(!props.bccEnabled)}
        />
      </Col>
    </div>
  );
}
