import React, { useEffect } from 'react';
import { EmailTemplate } from '../../../../../../entities/EmailTemplate';
import { UpdateStatePlugin } from '../../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { AreaRichTextComposer } from '../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { hasData } from '../../../../../../components/RichTextEditor/validation';

interface PropTypes {
  emailTemplate?: EmailTemplate;
  json?: string;
  onChangeHtml: (html: string) => void;
  onChangeJson?: (html: string) => void;
  setIsValid: (valid: boolean) => void;
}

export function EmailRichTextEditor(props: PropTypes) {
  useEffect(() => {
    props.setIsValid(hasData(props.json));
  }, [props.json]);

  return (
    <div>
      <AreaRichTextComposer
        defaultEditorState={props.json}
        externalPlugins={[
          <UpdateStatePlugin
            key={1}
            editorState={props.emailTemplate?.template}
            showEmptyUpdate={props.json == null}
          />,
        ]}
        onChangeJson={props.onChangeJson}
        onChangeHtml={props.onChangeHtml}
        size='sm'
      />
    </div>
  );
}
