import React, { useState } from 'react';
import { RejectedReasonService } from '../../../../services/v1/applicant_tracking/RejectedReasonService';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import {
  appendQueryParam,
  getQueryParam,
  getRedirectUriQueryParam,
} from '../../../../utils/url';
import { RejectedByPills } from '../components/RejectedByPills';
import {
  RejectedBy,
  RejectedReason,
} from '../../../../entities/v1/applicant_tracking/RejectedReason';
import { FormHeader } from '../components/FormHeader';
import { ReasonsInput } from '../components/ReasonsInput';
import { Alert, AlertType } from '../../../../components/Alert';
import { IconSpan } from '../../../../components/IconSpan';
import classNames from 'classnames';
import styles from '../styles.module.scss';

const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/reject_reasons';
const SUCCESSFUL_ALERT_MESSAGE_SINGLE =
  'The reject reason was successfully created!';
const SUCCESSFUL_ALERT_MESSAGE_MULTIPLE =
  'The reject reasons were successfully created!';
const DUPLICATES_FOUND_ALERT_MESSAGE =
  'This rejection reason already exists. Change the name before proceeding.';
export const REJECTED_REASON_MAX_CHARACTERS = 65;

export default function NewRejectedReasonPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>(['']);
  const [rejectedBy, setRejectedBy] = useState<RejectedBy>(
    (getQueryParam('rejected_by') as RejectedBy) ?? 'organization',
  );
  const [duplicates, setDuplicates] = useState<string[]>([]);
  const [alertActive, setAlertActive] = useState<boolean>(false);

  function isSavable() {
    return (
      reasons.length > 0 &&
      reasons.every((reason) => reason.length > 0) &&
      duplicates.every((duplicate) => !reasons.includes(duplicate))
    );
  }

  async function handleSave() {
    setIsLoading(true);
    const trimmedReasons = reasons.map((reason) => reason.trim());
    setReasons(trimmedReasons);
    try {
      await RejectedReasonService.batchCreate(rejectedBy, trimmedReasons);
      window.location.href = appendQueryParam(
        REDIRECT_URI,
        'flash',
        reasons.length === 1
          ? SUCCESSFUL_ALERT_MESSAGE_SINGLE
          : SUCCESSFUL_ALERT_MESSAGE_MULTIPLE,
      );
    } catch (error) {
      const response = error.body;
      if (response.code !== 'duplicate_rejected_reasons_error') throw error;

      setDuplicates(response.body.map((reason: RejectedReason) => reason.name));
      setAlertActive(true);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable
          autoClearTimeout={4000}
          onClose={() => setAlertActive(false)}
        >
          <IconSpan
            spanText={DUPLICATES_FOUND_ALERT_MESSAGE}
            icon={{ name: 'bi-info-circle' }}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <div className='container'>
        <PageHeader title='Add Reject Reason' backUrl={REDIRECT_URI} />
        <Card className={classNames(styles['card'])}>
          <CardHeader
            title='Reject Reason'
            isSavable={isSavable()}
            onSaveClick={handleSave}
            cancelUrl={REDIRECT_URI}
          />
          <div className='fw-semibold fs-5 mt-4 mb-1'>Rejection type</div>
          <RejectedByPills
            rejectedBy={rejectedBy}
            setRejectedBy={setRejectedBy}
          />
          <FormHeader
            mandatory
            title='Rejection reasons'
            constraint={`Maximum ${REJECTED_REASON_MAX_CHARACTERS} characters`}
          />
          <ReasonsInput
            reasons={reasons}
            duplicates={duplicates}
            setReasons={setReasons}
          />
        </Card>
      </div>
    </>
  );
}
