import { Campaign, CampaignStatus } from '@vonq/hapi-elements-types';
import { CampaignRow } from '../../CampaignRow';
import React from 'react';
import { Chip } from '../../../../../../../components/Chip';
import { getPrice } from '../../../../utils/productDetails';
import classNames from 'classnames';
import { humanize } from '../../../../../../../utils/humanize';

interface PropTypes {
  campaign: Campaign;
}

function getStatusColour(status: CampaignStatus) {
  switch (status) {
    case 'online':
      return 'bg-success';
    case 'offline':
      return 'bg-danger';
    case 'in progress':
      return 'bg-blue';
    case 'not processed':
      return 'bg-warning';
  }
}

export function CampaignHeader(props: PropTypes) {
  return (
    <CampaignRow
      highlightName={true}
      name={props.campaign.campaignName}
      updatedAt={props.campaign.createdOn}
      cost={
        <Chip className='bg-gray-900 text-white'>
          {getPrice([props.campaign.totalPrice], props.campaign.currency, 100)}
        </Chip>
      }
      status={
        <Chip
          className={classNames(
            'text-white',
            getStatusColour(props.campaign.status),
          )}
        >
          {humanize(props.campaign.status)}
        </Chip>
      }
    />
  );
}
