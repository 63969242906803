import React, { useEffect, useState } from 'react';
import { TitledPanel } from '../../../applicant_tracking/Analytics/Widgets/TitledPanel';
import { InfoTooltip } from '../../../../components/InfoTooltip';
import { TopSourcingChannels } from '../../../../entities/applicant_tracking/analytics/TopSourcingChannels';
import { TopSourcingChannelsService } from '../../../../services/applicant_tracking/analytics/TopSourcingChannelsService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { Table } from 'reactstrap';
import { Chip } from '../../../../components/Chip';
import { Doughnut } from 'react-chartjs-2';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { CardTitle } from '../../../../components/Card';
import { TruncateText } from '../../../../components/TruncateText';

interface PropTypes {
  height: string;
}

export function TopSourcingChannelsPanel(props: PropTypes) {
  return (
    <TitledPanel title={<Title />} height={props.height}>
      <LazyContent />
    </TitledPanel>
  );
}

function Title() {
  return (
    <div className='hstack gap-2'>
      <CardTitle>Top Sourcing Channels</CardTitle>
      <InfoTooltip>
        Number of applied candidates and the pass-through rate for the top 5
        sourcing channels.
      </InfoTooltip>
    </div>
  );
}

function LazyContent() {
  const [data, setData] = useState<TopSourcingChannels>(null);

  useEffect(() => {
    TopSourcingChannelsService.show().then((topSourcingChannels) =>
      setData(topSourcingChannels),
    );
  }, []);

  if (!data) return <LoadingSpinner />;

  if (data.topSources.length === 0) return <EmptyState />;

  return <Content data={data} />;
}

function EmptyState() {
  return (
    <div className='flex-fill d-flex align-items-center justify-content-center fw-bold'>
      No data available yet
    </div>
  );
}

function Content(props: { data: TopSourcingChannels }) {
  return (
    <div className='d-flex h-100 gap-3'>
      <Chart data={props.data} />
      <EntryTable data={props.data} />
    </div>
  );
}

function Chart(props: { data: TopSourcingChannels }) {
  const labels = props.data.topSources.map((topSource) => topSource.name);
  const values = props.data.topSources.map(
    (topSource) => topSource.applicationsCount,
  );

  const options = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: {
        bodyFont: {
          size: 10,
        },
        displayColors: false,
        callbacks: {
          label: function (ctx) {
            return ctx.label;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          stylesheetExports.chartBlue,
          stylesheetExports.chartGreen,
          stylesheetExports.chartOrange,
          stylesheetExports.chartPink,
          '#FFE898',
        ],
        hoverOffset: 0,
      },
    ],
  };

  return (
    <div className='align-self-center' style={{ maxWidth: '120px' }}>
      <Doughnut options={options} data={data} />
    </div>
  );
}

function EntryTable(props: { data: TopSourcingChannels }) {
  return (
    <Table borderless size='sm' className='fs-6 mb-0 align-self-start'>
      <thead>
        <tr>
          <th />
          <th className='fw-normal fs-6 text-center'>Applied</th>
          <th className='fw-normal fs-6 text-center'>PTR</th>
        </tr>
      </thead>
      <tbody className='text-primary'>
        {props.data.topSources.map((topSource) => (
          <tr key={topSource.name}>
            <td className='fw-semibold'>
              <TruncateText tooltip={true} maxLines={2} text={topSource.name} />
            </td>
            <td className='text-center'>
              <Chip className='bg-gray text-primary fs-6'>
                {topSource.applicationsCount}
              </Chip>
            </td>
            <td className='text-center'>
              <Chip className='bg-light-success text-primary fs-6'>
                {(topSource.passThroughRate * 100).toFixed(0)}%
              </Chip>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
