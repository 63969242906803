import Humanize from 'humanize-plus';
import { replaceAll } from './replaceAll';

export const humanize = (str?: string) => {
  if (str == null) {
    return null;
  }

  return Humanize.titleCase(replaceAll(str.toLowerCase(), '_', ' '));
};
