import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../../../../../components/Chip';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import { humanize } from '../../../../../../../../../utils/humanize';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function Role(props: PropTypes) {
  return (
    <td className={classNames('pb-4', styles['table-data-cell-border-bottom'])}>
      <Chip className='bg-gray fw-normal text-primary fs-5'>
        {humanize(props.hiring_member.role)}
      </Chip>
    </td>
  );
}
