import React, { useState } from 'react';
import { HorizontalCarousel } from '../../../../components/HorizontalCarousel';
import { JobStage } from '../../../../entities/JobStage';
import { User } from '../../../../entities/User';
import { CandidateCard } from '../CandidateCard';
import { JobDetails } from '../../../../entities/JobDetails';
import { JobStageLane } from '../JobStageLane';
import { AlertObject } from '../../../../components/Alert';
import { alertManager } from '../../components/MoveCandidateStageModal/alertManager';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { MoveCandidateProps } from '../../components/MoveCandidateStageModal';
import { PipelineApplication } from '../../../../entities/applicant_tracking/PipelineApplication';
import { AccountIntegration } from '../../../../entities/AccountIntegration';

interface PropTypes {
  applications: PipelineApplication[];
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobDetails: JobDetails;
  jobStages: JobStage[];
  setAlert: (alert: AlertObject) => void;
  reloadJobStages: () => Promise<void>;
}

function HorizontalCarouselPanel(
  props: PropTypes & {
    handleCandidateStageMove: (action: MoveCandidateProps) => void;
  },
) {
  return (
    <HorizontalCarousel
      elements={props.jobStages
        ?.sort((a: JobStage, b: JobStage) => a.order - b.order)
        .map((jobStage: JobStage, index: number) => (
          <JobStageLane
            key={index}
            jobPostId={props.jobDetails.jobPosts[0].id}
            jobStageId={jobStage.id}
            passthroughRate={jobStage.passthroughRate}
            applications={props.applications}
            currentHiringMember={props.jobDetails.hiringMembers.find(
              (v) => v.userId === props.currentUser.id,
            )}
            currentUser={props.currentUser}
            jobStageName={jobStage.name}
            jobStatus={props.jobDetails.status}
            onMoveCandidate={props.handleCandidateStageMove}
            setAlert={props.setAlert}
            reloadJobStages={props.reloadJobStages}
            bulkReviewEnabled={jobStage.bulkReviewEnabled}
            offerEnabled={jobStage.offerEnabled}
            emailAccountIntegration={props.emailAccountIntegration}
          />
        ))}
      classNames='ps-2 pb-5'
      buttonColor='white'
      border={false}
      allowHorizontalScroll={true}
      scrollWidth={266} // 250 (width) + 16 (margin)
    />
  );
}

export function JobPipelineBoard(props: PropTypes) {
  const [dragElement, setDragElement] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // 5px - minimum distance to start dragging (To differentiate from click event)
      },
    }),
  );

  const handleCandidateStageMove = async (action: MoveCandidateProps) => {
    await props.reloadJobStages();
    alertManager(action, props.setAlert);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    if (event.over && event.active) {
      const applicationToMove = props.applications.find(
        (application: PipelineApplication) =>
          application.id === event.active.id,
      );
      const droppedStageName = event.over.id.toString();
      if (applicationToMove.jobStage.name === droppedStageName) {
        // If the user drops the card in the same stage, do nothing
        return;
      }

      applicationToMove.daysInCurrentJobStage = 0;
      applicationToMove.jobStage.name = droppedStageName;

      const jobStageId = props.jobStages.find(
        (stage) => stage.name === droppedStageName,
      )?.id;
      await ApplicationService.moveStage(applicationToMove.id, jobStageId);

      handleCandidateStageMove({
        candidateName: applicationToMove.candidate.name,
        jobStageName: applicationToMove.jobStage.name,
        state: 'Success',
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={setDragElement}
      onDragEnd={handleDragEnd}
    >
      <HorizontalCarouselPanel
        {...props}
        handleCandidateStageMove={handleCandidateStageMove}
      />
      <DragOverlay>
        {dragElement && <CandidateCard {...dragElement.active.data.current} />}
      </DragOverlay>
    </DndContext>
  );
}
