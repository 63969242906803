import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { CheckboxButton } from '../../../../../../../../components/CheckboxButton';
import { CreateJobDto } from '../../../../../../../../services/applicant_tracking/JobService/CreateJobDto';

interface PropTypes {
  register: UseFormRegister<CreateJobDto>;
  disabled: boolean;
  checked?: boolean;
}

export function ApplicationFormConsentButton(props: PropTypes) {
  const { onChange, onBlur, name, ref } = props.register(
    'applicationFormTemplateConsent',
  );
  return (
    <CheckboxButton
      label={'Request candidate consent'}
      onBlur={onBlur}
      name={name}
      checkboxRef={ref}
      onChange={(ev: React.FormEvent<HTMLInputElement>) => {
        onChange(ev ?? null);
      }}
      checked={props.checked ?? false}
      disabled={props.disabled}
    />
  );
}
