import React from 'react';
import { StarRating } from '../../../../../../../../components/StarRating';
import { calcScorecardAverage } from '../../../../../../../../utils/applicant_tracking/calcScorecardAverage';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';

interface PropTypes {
  scorecard: Scorecard;
}

export function AverageRating(props: PropTypes) {
  const averageScore =
    Math.round(calcScorecardAverage(props.scorecard) * 10) / 10;

  const shouldDisplayScore = averageScore !== 0 && !Number.isNaN(averageScore);

  return (
    <>
      {shouldDisplayScore && (
        <div className='d-flex fs-6 align-items-center'>
          AVG Score&nbsp;<span className='fw-bold me-2'>{averageScore}</span>
          <StarRating
            id='average'
            editable={false}
            rating={averageScore}
            fillColorClass='text-rating-color'
          />
        </div>
      )}
    </>
  );
}
