import React, { useState } from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { Chip } from '../../../../components/Chip';
import { Application } from '../../../../entities/Application';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import classNames from 'classnames';
import { User } from '../../../../entities/User';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { JobHiringMemberRole } from '../../../../entities/JobHiringMemberRole';
import { EmptyState } from '../../../../components/EmptyState';
import { FilterByPills, FILTER_TYPE } from './FilterByPills';
import { HiringTeamActionsItems } from './HiringTeam/HiringTeamActionsItems';
import { ListActionItems } from './ListActionItems';

interface PropTypes {
  application: Application;
  currentUser: User;
  jobHiringMemberRole?: JobHiringMemberRole;
}

export const badgeColor = (count: number): string => {
  if (count <= 1) {
    return 'gray';
  } else if (count <= 3) {
    return 'light-warning';
  } else {
    return 'light-danger';
  }
};

export function PendingActionsEmptyState() {
  return (
    <EmptyState
      excludeImage={true}
      classNames='col-12'
      textFontSize='fs-5'
      titleClassName='fs-4'
      title={'No actions required yet'}
      text={'Candidate related actions will appear when available.'}
    />
  );
}

export function PendingActionsPanel(props: PropTypes) {
  const [filterBy, setFilterBy] = useState<FILTER_TYPE>('self');

  const showScheduleInterviewItem =
    (props.currentUser.isAdmin || props.jobHiringMemberRole === 'recruiter') &&
    props.application.actionItems.filter(
      (actionItem) => actionItem.intent === 'schedule_interview',
    ).length > 0;

  const pendingActionItems = props.application.actionItems.filter(
    (actionItem) => actionItem.completed !== true,
  );

  const actionsCount = pendingActionItems.length;

  return (
    <DeprecatedPanel className='p-4 h5'>
      <>
        <div className='d-flex mb-3'>
          <h3 className='mb-0 me-auto'>Pending Actions</h3>
          <SpanTooltip
            text={
              <Chip
                className={classNames(
                  'text-primary',
                  `bg-${badgeColor(actionsCount)}`,
                )}
                size='sm'
              >
                <i className='bi bi-info-circle text-blue me-1'></i>
                {actionsCount}
              </Chip>
            }
            tooltipText={`${actionsCount} Pending Actions`}
            tooltipStyle={{
              backgroundColor: stylesheetExports.darkPrimary,
              opacity: 0.95,
            }}
          />
        </div>
        <FilterByPills selectedValue={filterBy} onChange={setFilterBy} />
        {filterBy === 'self' ? (
          <ListActionItems
            {...props}
            filterBy={filterBy}
            showScheduleInterviewItem={showScheduleInterviewItem}
          />
        ) : (
          <HiringTeamActionsItems
            actionItems={props.application.actionItems}
            currentUser={props.currentUser}
          />
        )}
      </>
    </DeprecatedPanel>
  );
}
