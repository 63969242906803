import React from 'react';
import { Chip } from '../../../../components/Chip';
import classNames from 'classnames';
import { JobState } from '../../../../entities/JobDetails';
import { humanize } from '../../../../utils/humanize';
import styles from './styles.module.scss';

interface PropTypes {
  state: JobState;
}

export function JobStateBadge(props: PropTypes) {
  return (
    <Chip data-testid='job-state' className={classNames(styles[props.state])}>
      {humanize(props.state)}
    </Chip>
  );
}
