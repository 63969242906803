import { GroupedOption } from '../../../../../../../../components/GroupedReactSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { ApplicationFormTemplate } from '../../../../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { AutoRejectTrigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { DEFAULT_FIELD_CONFIGS } from '../../../../../../ApplicationFormTemplates/data';

const defaultFormQuestions = ['has_working_visa'];
const CUSTOM_QUESTIONS_LABEL = 'Custom Fields';
const DEFAULT_QUESTIONS_LABEL = 'Default Application Fields';

function defaultQuestionLabelMapper(question: string, jobLocation: string) {
  switch (question) {
    case 'has_working_visa':
      return `Do you have legal right to work in ${jobLocation}?`;
    default:
      return question;
  }
}

function getDefaultBooleanQuestionTags() {
  return DEFAULT_FIELD_CONFIGS.filter(
    (field) => field.tag != null && field.fieldType === 'boolean',
  ).map((field) => field.tag);
}

function getBooleanTypeQuestions(
  applicationFormTemplate: ApplicationFormTemplate,
) {
  const defaultBooleanQuestionTags = getDefaultBooleanQuestionTags();

  return applicationFormTemplate.template.sections.reduce(
    (questionsWithBoolean, section) => {
      const booleanQuestions = section.questions
        .filter((question) =>
          question.fields.some((field) => field.type === 'boolean'),
        )
        .filter(
          (question) => !defaultBooleanQuestionTags.includes(question.tag),
        );

      return questionsWithBoolean.concat(booleanQuestions);
    },
    [],
  );
}

function mapApplicationFormTemplateToSelectOptions(
  applicationFormTemplate: ApplicationFormTemplate,
  selectedValue?: string,
  disabledValues?: string[],
): SelectOption[] {
  if (!applicationFormTemplate) return [];

  return getBooleanTypeQuestions(applicationFormTemplate).map((question) => {
    return {
      value: question.id.toString(),
      label: question.title,
      selected: selectedValue === question.id.toString(),
      disabled: disabledValues?.includes(question.id.toString()),
    } as SelectOption;
  });
}

function mapDefaultQuestions(
  jobLocation: string,
  selectedValue?: string,
  disabledValues?: string[],
) {
  return defaultFormQuestions.map((question) => {
    return {
      value: question,
      label: defaultQuestionLabelMapper(question, jobLocation),
      selected: selectedValue === question,
      disabled: disabledValues?.includes(question),
    } as SelectOption;
  });
}

export function mapGroupedSelectOptions(
  applicationFormTemplate: ApplicationFormTemplate,
  jobLocation: string,
  selectedValue?: string,
  disabledValues?: string[],
) {
  return [
    {
      label: DEFAULT_QUESTIONS_LABEL,
      options: mapDefaultQuestions(jobLocation, selectedValue, disabledValues),
    },
    {
      label: CUSTOM_QUESTIONS_LABEL,
      options: mapApplicationFormTemplateToSelectOptions(
        applicationFormTemplate,
        selectedValue,
        disabledValues,
      ),
    },
  ];
}

export function answerSelectOptions(selectedValue?: boolean): SelectOption[] {
  return [
    {
      value: 'yes',
      label: 'Yes',
      selected: selectedValue,
    },
    {
      value: 'no',
      label: 'No',
      selected: selectedValue != undefined && !selectedValue,
    },
  ];
}

export function selectedAnswerFromOptions(
  options: SelectOption[],
): boolean | undefined {
  const selectedValue = options.find((option) => option.selected)?.value;
  if (selectedValue == undefined) return undefined;

  return selectedValue === 'yes';
}

export function selectedQuestionFromGroupedOptions(
  questionOptions: GroupedOption[],
  label: string,
) {
  return questionOptions
    .find((category) => category.label === label)
    .options.find((option) => option.selected)?.value;
}

export function selectedCustomQuestionId(questionOptions: GroupedOption[]) {
  return parseInt(
    selectedQuestionFromGroupedOptions(questionOptions, CUSTOM_QUESTIONS_LABEL),
  );
}

export function selectedDefaultQuestionField(questionOptions: GroupedOption[]) {
  return selectedQuestionFromGroupedOptions(
    questionOptions,
    DEFAULT_QUESTIONS_LABEL,
  );
}

const triggerSelectedWithApplicationField = (
  a: AutoRejectTrigger,
  b: AutoRejectTrigger,
) => a.applicationField && a.applicationField === b.applicationField;

const triggerSelectedWithQuestionId = (
  a: AutoRejectTrigger,
  b: AutoRejectTrigger,
) => a.questionId === b.questionId;

const notCurrentTrigger = (
  trigger: AutoRejectTrigger,
  currentTrigger: AutoRejectTrigger,
) =>
  !(
    triggerSelectedWithApplicationField(trigger, currentTrigger) ||
    triggerSelectedWithQuestionId(trigger, currentTrigger)
  );

export const getSelectedQuestions = (
  triggers: AutoRejectTrigger[],
  currentTrigger: AutoRejectTrigger,
) =>
  triggers
    .filter((trigger) => notCurrentTrigger(trigger, currentTrigger))
    .map(
      (trigger) => trigger.applicationField || trigger.questionId?.toString(),
    );
