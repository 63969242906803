import React from 'react';
import { Header } from '../Header';
import { Button } from 'reactstrap';

interface PropTypes {
  isSaveEnabled: boolean;
  onSave: () => void;
}

export function EditHeader(props: PropTypes) {
  return (
    <Header
      saveButton={
        <SaveButton isSaveEnabled={props.isSaveEnabled} onSave={props.onSave} />
      }
      descriptionText={
        <>
          <small>
            To set your availability click on the time slots to make them
            available or unavailable.
          </small>
          <small>
            You can also click and drag to select multiple time slots.
          </small>
        </>
      }
    />
  );
}

function SaveButton(props: { isSaveEnabled: boolean; onSave: () => void }) {
  return (
    <Button
      color={'primary'}
      type={'submit'}
      onClick={props.onSave}
      disabled={!props.isSaveEnabled}
    >
      Save Availability
    </Button>
  );
}
