import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { HealthBar } from '../../../../components/HealthBar';
import { TopChoice } from '../../../../entities/candidate_experience/TopChoice';
import { formatAsPercentage } from '../../../../utils/formatAsPercentage';
import { AnalyticsPanel } from '../../../../components/AnalyticsPanel';
import { CircleChart } from '../../../../components/survey_engine/CircleChart';
import { DEFAULT_RATING_LEGEND } from '../../../../components/survey_engine/RatingLegend';

export enum TopChoicesChartType {
  Circle,
  Bar,
}

interface PropTypes {
  title: string;
  subtitle: string;
  topChoices: TopChoice[];
  fillColor: string;
  type: TopChoicesChartType;
}

function TopChoiceCircleChart(props: { choice: TopChoice; fillColor: string }) {
  const weight = parseFloat(props.choice.weight);

  return (
    <div className='h-100 d-flex flex-column'>
      <CircleChart
        valueAsPercentage={Math.trunc(weight * 100)}
        text={`${Math.trunc(weight * 100)}%`}
        color={props.fillColor}
        labelText={props.choice.name}
        labelClassName='label text-primary'
        rating_legend={DEFAULT_RATING_LEGEND}
      />
    </div>
  );
}

function TopChoiceBarChart(props: { choice: TopChoice; fillColor: string }) {
  const weight = parseFloat(props.choice.weight);

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex align-items-center px-4'>
        <h2 className='mb-0 me-3' style={{ color: props.fillColor }}>
          {formatAsPercentage(weight)}%
        </h2>
        <HealthBar value={weight} colors={{ 0.0: props.fillColor }} />
      </div>
      <div className='text-center mt-2'>
        <h3
          className={classNames('mb-0', 'label', 'text-primary')}
          style={{ color: props.fillColor }}
        >
          {props.choice.name}
        </h3>
      </div>
    </div>
  );
}

export function TopChoices(props: PropTypes) {
  return (
    <AnalyticsPanel
      title={<div className={classNames('mb-0', 'fw-bold')}>{props.title}</div>}
      subtitle={<small className='text-muted'>{props.subtitle}</small>}
    >
      <Row className='h-100 gx-7 py-2 px-4 justify-content-center'>
        {props.topChoices.map((choice) => (
          <Col key={choice.name} xs={4} className='col-xxxl-3 px-2'>
            {props.type === TopChoicesChartType.Circle ? (
              <TopChoiceCircleChart
                choice={choice}
                fillColor={props.fillColor}
              />
            ) : (
              <TopChoiceBarChart choice={choice} fillColor={props.fillColor} />
            )}
          </Col>
        ))}
      </Row>
    </AnalyticsPanel>
  );
}
