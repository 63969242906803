import React, { useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { CheckboxPillGroupFormHook } from '../../../../../../../components/CheckboxPillGroupFormHook';
import { AVAILABLE_CURRENCIES } from '../../../../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  setValue: UseFormSetValue<JobRequisitionDto>;
  formControl: Control<JobRequisitionDto>;
  formResetField: (controllerName: string) => void;
}

export function SalaryCurrency(props: PropTypes) {
  const required = false;

  const salaryRangeFrom = useWatch({
    control: props.formControl,
    name: 'salaryRangeFrom',
  });
  const salaryRangeTo = useWatch({
    control: props.formControl,
    name: 'salaryRangeTo',
  });
  const currency = useWatch({
    control: props.formControl,
    name: 'salaryRangeCurrency',
  });

  useEffect(() => {
    if ((salaryRangeFrom || salaryRangeTo) && !currency) {
      props.setValue?.('salaryRangeCurrency', 'gbp');
    }
  }, [salaryRangeFrom, salaryRangeTo]);

  return (
    <Col xs={3}>
      <FormFieldHeader
        fieldName='Currency'
        isRequired={required}
        classNames='fw-semibold mb-2h'
      />
      <CheckboxPillGroupFormHook<JobRequisitionDto>
        formHookContext={{
          controllerName: 'salaryRangeCurrency',
          formControl: props.formControl,
          required: required,
        }}
        resetField={props.formResetField}
        childrenProps={AVAILABLE_CURRENCIES}
      />
    </Col>
  );
}
