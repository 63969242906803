import React from 'react';
import { ModalBody } from './ModalBody';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { InterviewCandidateUser } from '../../../../../../entities/applicant_tracking/InterviewCandidateUser';
import { AlertType } from '../../../../../../components/Alert';

interface PropTypes {
  interviewCandidateUser: InterviewCandidateUser;
  isOpen: boolean;
  candidateName: string;
  setAlertMessage: (message: string) => void;
  setAlertType: (type: AlertType) => void;
  onClose: () => void;
}

export function SendEmailModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      bodyClassName='p-4 pb-6'
      closeDisabled={false}
      backdrop={true}
      headerTitle='Send to candidate'
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
