import React from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { SurveyQuestionField } from '../../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionFieldType } from '../../../../../../entities/survey_engine/SurveyQuestionFieldType';
import { Chip } from '../../../../../../components/Chip';
import classNames from 'classnames';

interface PropTypes {
  question: SurveyQuestion;
  wrongAnswer: boolean;
  classNames?: string;
}

const hasLegend = (fields: SurveyQuestionField[]): boolean => {
  return fields.some(
    (field) => field.options.filter((opt) => !opt.description).length == 0,
  );
};

const getFieldForLegend = (
  fields: SurveyQuestionField[],
): SurveyQuestionField => {
  return fields.find((field) =>
    field.options
      .map((opt) => opt.description != null)
      .every((v) => v === true),
  );
};

function Title(props: {
  question: SurveyQuestion;
  classNames: string;
  wrongAnswer: boolean;
}) {
  return (
    <div className='d-flex align-items-center gap-2'>
      <h3 className={classNames(props.classNames, 'mb-0')}>
        {props.question.title}
      </h3>
      {props.wrongAnswer && (
        <Chip className={'bg-light-danger text-primary'}>
          <i className='bi bi-exclamation-circle me-1'></i>
          Your previous answer was incorrect
        </Chip>
      )}
    </div>
  );
}

function Subtitle(props: { question: SurveyQuestion }) {
  const type = props.question.fields[0].type;
  const correctAnswersCount = props.question.fields[0].correct_answers_count;

  if (
    type !== SurveyQuestionFieldType.MultiOptions &&
    type !== SurveyQuestionFieldType.Options
  ) {
    return null;
  }

  if (correctAnswersCount === 1) return null;

  return (
    <p className='mb-0'>Select {correctAnswersCount} options from the list.</p>
  );
}

export function SurveyQuestionTitle(props: PropTypes) {
  return (
    <Row>
      <Col
        xs='12'
        className={classnames({
          'mb-2':
            hasLegend(props.question.fields) &&
            !props.classNames?.includes('mb-0'),
        })}
      >
        <Title
          question={props.question}
          classNames={props.classNames}
          wrongAnswer={props.wrongAnswer}
        />
        <Subtitle question={props.question} />
      </Col>
      {hasLegend(props.question.fields) && (
        <Col xs='12'>
          {getFieldForLegend(props.question.fields)
            .options.sort((a, b) => a.weight - b.weight)
            .map((opt) => (
              <>
                <b>
                  {opt.weight}. {opt.name}
                </b>
                , {opt.description}.<br />
              </>
            ))}
        </Col>
      )}
    </Row>
  );
}
