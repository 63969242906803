import React, { useEffect } from 'react';
import {
  Path,
  PathValue,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { FormFieldHeader } from '../FormFieldHeader';
import {
  FileContent,
  UseFilePickerConfig,
  Validator,
  useFilePicker,
} from 'use-file-picker';
import { AttachmentInputErrorMessages } from './AttachmentInputErrorMessages';
import {
  AttachmentInputBody,
  PropTypes as BodyProps,
} from './AttachmentInputBody';
import { AttachmentInputFooter } from './AttachmentInputFooter';
import { FileWithPath } from 'file-selector';
import { MimeTypeAsString, MimeTypes } from '../../utils/fileMimeTypes';

export const UnsupportedFileType = 'unsupportedFileType';

interface PropTypes<E> {
  allowedFormats: MimeTypeAsString[];
  inputBodyClassNames?: string;
  controllerContentName: string;
  controllerFileName: string;
  fieldName: string;
  disabled?: boolean;
  isRequired?: boolean;
  headerClassNames?: string;
  maxFileSizeMb: number;
  hideHeader?: boolean;
  hideAllowedFormats?: boolean;
  getValues: UseFormGetValues<E>;
  setValue: UseFormSetValue<E>;
  formRegister: UseFormRegister<E>;
  Body?: React.ComponentType<BodyProps<E>>;
}

function getContent(filesContent: FileContent[]): string | undefined {
  if (filesContent?.length === 0) {
    return undefined;
  }

  return filesContent[0].content;
}

function handleChange<E>(
  controllerContentName: string,
  controllerFileName,
  filesContent: FileContent[],
  setValue: UseFormSetValue<E>,
) {
  if (filesContent?.length !== 0) {
    setValue(
      controllerContentName as Path<E>,
      getContent(filesContent) as PathValue<E, Path<E>>,
      { shouldValidate: true },
    );
    setValue(
      controllerFileName as Path<E>,
      filesContent[0].name as PathValue<E, Path<E>>,
      { shouldValidate: true },
    );
  }
}

class CustomValidator implements Validator {
  async validateBeforeParsing(config: UseFilePickerConfig, plainFiles: File[]) {
    const acceptedTypes = []
      .concat(config.accept)
      .map((v) => MimeTypes[v].valueOf());

    return new Promise<void>((res, rej) =>
      Array.from(plainFiles).every((file) => acceptedTypes.includes(file.type))
        ? res()
        : rej({ name: UnsupportedFileType }),
    );
  }

  async validateAfterParsing(
    _config: UseFilePickerConfig,
    _file: FileWithPath,
    _reader: FileReader,
  ) {
    return;
  }
}

export function SingleAttachmentInputFormField<E>(props: PropTypes<E>) {
  const hideHeader = props.hideHeader ?? false;
  const value = props.getValues(props.controllerFileName as Path<E>);

  const [openFileSelector, { filesContent, errors, clear }] = useFilePicker({
    multiple: false,
    readAs: 'DataURL',
    accept: props.allowedFormats,
    maxFileSize: props.maxFileSizeMb,
    readFilesContent: true,
    validators: [new CustomValidator()],
  });

  useEffect(() => {
    handleChange(
      props.controllerContentName,
      props.controllerFileName,
      filesContent,
      props.setValue,
    );
  }, [filesContent]);

  const Body = props.Body != null ? props.Body : AttachmentInputBody;

  return (
    <>
      <AttachmentInputErrorMessages
        allowedFormats={props.allowedFormats}
        errors={errors}
        maxFileSizeMb={props.maxFileSizeMb}
        maxFiles={1}
      />
      {!hideHeader && (
        <FormFieldHeader
          fieldName={props.fieldName}
          isRequired={props.isRequired}
          classNames={props.headerClassNames}
        />
      )}
      <Body
        classNames={props.inputBodyClassNames}
        allowedFormats={props.allowedFormats}
        controllerContentName={props.controllerContentName}
        controllerFileName={props.controllerFileName}
        value={value}
        disabled={props.disabled}
        isRequired={props.isRequired}
        clear={clear}
        formRegister={props.formRegister}
        openFileSelector={openFileSelector}
        setValue={props.setValue}
      />
      <AttachmentInputFooter
        allowedFormats={props.allowedFormats}
        hideAllowedFormats={props.hideAllowedFormats}
        maxFileSizeMb={props.maxFileSizeMb}
      />
    </>
  );
}
