import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  ListScorecard,
  Scorecard,
} from '../../../../entities/applicant_tracking/Scorecard';
import { Answer } from '../../../../entities/v1/survey_engine/Answer';
import { BatchCreateScorecardsRequest } from './requests/BatchCreateScorecardsRequest';

interface ScorecardsRequest {
  applicationId: number;
  status?: string;
  page?: number;
  pageSize?: number;
  ownScorecards?: boolean;
  expand?: string[];
}

export class ScorecardService {
  public static show(id: number, expand?: string[]): Promise<Scorecard> {
    return ApiService.get<Scorecard>(
      `/api/v1/applicant_tracking/scorecards/${id}`,
      snakecaseKeys({ expand: expand }),
    );
  }

  public static list(req: ScorecardsRequest): Promise<ListScorecard> {
    return ApiService.get<ListScorecard>(
      '/api/v1/applicant_tracking/scorecards',
      snakecaseKeys({
        applicationId: req.applicationId,
        status: req.status,
        page: req.page ?? 1,
        pageSize: req.pageSize ?? 100,
        ownScorecards: req.ownScorecards,
        expand: req.expand,
      }),
    );
  }

  public static batchCreate(request: BatchCreateScorecardsRequest) {
    return ApiService.post<ListScorecard>(
      '/api/v1/applicant_tracking/scorecards/batch_create',
      snakecaseKeys(request),
    );
  }

  public static answerAttribute(id, answer: Answer) {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/scorecards/${id}/scorecard_attributes/${answer.questionId}/answer`,
      snakecaseKeys({
        surveyId: answer.surveyId,
        questionId: answer.questionId,
        value: answer.value,
      }),
    );
  }
}
