import { SelectOption } from '../../../../components/Select';
import React from 'react';
import { CandidateAnalytics } from '../../../../entities/candidate_experience/CandidateAnalytics';
import { DemandTopChoices } from './DemandTopChoices';
import { DiscoveryTopChoices } from './DiscoveryTopChoices';
import { ApplicationImprovementTopChoices } from './ApplicationImprovementTopChoices';
import { DropOffTopChoices } from './DropOffTopChoices';
import { InterestTopChoices } from './InterestTopChoices';
import { SatisfactionTopChoices } from './SatisfactionTopChoices';
import { DecisionAndImprovementTopChoices } from './DecisionAndImprovementTopChoices';
import { OfferedSalaryTopChoices } from './OfferedSalaryTopChoices';
import { ExtraMultiOptions } from './ExtraMultiOptions';

export function CandidateTopChoicePanels(props: {
  analytics: CandidateAnalytics;
  benchmarkType: SelectOption;
}) {
  return (
    <>
      <SatisfactionTopChoices
        topPositives={props.analytics.top_positives}
        topNegatives={props.analytics.top_negatives}
      />
      <InterestTopChoices
        companyInterest={props.analytics.company_interest}
        jobInterest={props.analytics.job_interest}
      />
      <DiscoveryTopChoices discovery={props.analytics.discovery} />
      <ApplicationImprovementTopChoices
        applicationImprovements={props.analytics.application_improvements}
      />
      <DropOffTopChoices dropOff={props.analytics.drop_off} />
      <DecisionAndImprovementTopChoices
        decision={props.analytics.decision}
        improvements={props.analytics.improvements}
        benchmarkType={props.benchmarkType}
      />
      {props.analytics.extra_multi_options && (
        <ExtraMultiOptions
          extraMultiOptions={props.analytics.extra_multi_options}
          benchmarkType={props.benchmarkType}
        />
      )}
      <DemandTopChoices candidateDemand={props.analytics.candidate_demand} />
      <OfferedSalaryTopChoices offeredSalary={props.analytics.offered_salary} />
    </>
  );
}
