import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../../../../../components/Chip';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function JobsCount(props: PropTypes) {
  return (
    <td
      className={classNames(
        styles['table-data-cell-border-bottom'],
        'text-end pb-4',
      )}
    >
      <Chip className='bg-gray fw-semibold text-primary fs-5'>
        {props.hiring_member.jobs_count}
      </Chip>
    </td>
  );
}
