import React, { useEffect, useState } from 'react';
import { JobStageTemplateInput } from '../../../../../../../PlanTemplates/SetupPlanTemplatePage';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { InterviewKitPanel } from '../../../../../../../../components/ApplicantTracking/InterviewKitPanel';
import { ScorecardTemplateTable } from '../../../../../../../../components/ApplicantTracking/ScorecardTemplateTable';
import { DeprecatedPanel } from '../../../../../../../../components/DeprecatedPanel';
import { ScorecardTemplate } from '../../../../../../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { ScorecardTemplateService } from '../../../../../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { LoadingSpinner } from '../../../../../../../../components/LoadingSpinner';

interface PropTypes {
  jobStage: JobStageTemplateInput;
}

export function JobStageDetail(props: PropTypes) {
  const [scorecardTemplate, setScorecardTemplate] =
    useState<ScorecardTemplate>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function load() {
      const template = await ScorecardTemplateService.show(
        props.jobStage.scorecardTemplate.id,
        {
          expand: [
            'survey_template',
            'survey_template.sections',
            'survey_template.sections.questions',
          ],
        },
      );
      setScorecardTemplate(template);
      setIsLoading(false);
    }

    load();
  }, [props.jobStage]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames={classNames(
        styles['job-stage-container'],
      )}
      className='mt-4'
    >
      <div className='fs-2 fw-bold mb-4'>{props.jobStage.name}</div>
      <div className='my-1 fs-3 fw-semibold'>Interview Questions</div>
      <div className='fs-5 mt-3 fw-semibold'>
        {props.jobStage.interviewKitTemplate.name}
      </div>
      <InterviewKitPanel
        sections={props.jobStage.interviewKitTemplate.sections}
      />
      <div className='mt-4 mb-3 fs-3 fw-semibold'>Interview Scorecard</div>
      <div className='my-1 mb-3 fs-5 fw-semibold'>
        {scorecardTemplate.surveyTemplate.name}
      </div>
      <div className='mb-3 pb-1'>
        <ScorecardTemplateTable scorecardTemplate={scorecardTemplate} />
      </div>
    </DeprecatedPanel>
  );
}
