import React from 'react';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { Step } from './Step';

interface PropTypes {
  steps: ApprovalFlowStep[];
  approversBreakLine?: boolean;
  className?: string;
  currentStepOrder?: number;
  declinedStepOrder?: number;
}

export function ApprovalFlowSummary(props: PropTypes) {
  return (
    <div className={props.className}>
      {props.steps.map((step, index) => (
        <Step
          key={index}
          index={index}
          step={step}
          totalSteps={props.steps.length}
          approversBreakLine={props.approversBreakLine}
          isCurrentStep={step.order === props.currentStepOrder}
          declinedStepOrder={props.declinedStepOrder}
        />
      ))}
    </div>
  );
}
