import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../../../../../components/Chip';
import { Trend } from '../../../../../../../../../components/survey_engine/Trend';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  value: number;
  trend: number;
  bgColor: string;
}

export function ValueWithTrend(props: PropTypes) {
  return (
    <td className={classNames(styles['table-data-cell-border-bottom'], 'pb-4')}>
      <div className='d-flex justify-content-end align-items-center'>
        <Chip
          className={classNames(
            `bg-${props.bgColor}`,
            'fw-semibold',
            'text-primary',
            'fs-5',
          )}
        >
          {Math.round(props.value * 10) / 10}
        </Chip>
        <div className='text-end' style={{ width: '50px' }}>
          <Trend trend={Math.round(props.trend)} className={'fs-6'} />
        </div>
      </div>
    </td>
  );
}
