import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconButton } from '../../../components/IconButton';

interface PropTypes {
  searchOccurrences: number;
  selectedSearchOccurenceIndex: number | null;
  onFindString: (findString: string) => void;
  onGoToPreviousSearchOccurence: () => void;
  onGoToNextSearchOccurence: () => void;
}

export function TranscriptSearchBox(props: PropTypes) {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const screenWidth = window.innerWidth;
  const smScreen = screenWidth < 1500;
  const xsmScreen = screenWidth < 1250;

  const expanded = (value && value !== '') || focused;

  function handleInput(e) {
    setValue(e.target.value);
    props.onFindString(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key !== 'Enter') return;
    props.onGoToNextSearchOccurence();
  }

  return (
    <div
      className={`input-group input-group-sm ${
        !expanded ? styles.container : ''
      }`}
    >
      <DebounceInput
        minLength={3}
        debounceTimeout={300}
        type='search'
        className={classNames(
          'form-control',
          styles.input,
          xsmScreen
            ? styles['search-input-xsm']
            : `search-input ${styles['search-input-sm']}`,
        )}
        placeholder='Search (min. 3 chars)'
        onChange={handleInput}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={handleKeyDown}
      />
      {expanded && (
        <>
          <span
            className={`input-group-text bg-white text-secondary ${
              styles.occurrences
            } ${smScreen ? styles['occurrences-sm'] : ''}`}
          >
            {props.selectedSearchOccurenceIndex != null
              ? props.selectedSearchOccurenceIndex + 1
              : 0}
            /{props.searchOccurrences || 0}
          </span>

          <>
            <IconButton
              color='borderless'
              size={smScreen ? 'sm' : 'md'}
              iconName='bi-chevron-up'
              onClick={props.onGoToPreviousSearchOccurence}
              disabled={props.searchOccurrences === 0}
            />
            <IconButton
              color='borderless'
              size={smScreen ? 'sm' : 'md'}
              iconName='bi-chevron-down'
              onClick={props.onGoToNextSearchOccurence}
              disabled={props.searchOccurrences === 0}
            />
          </>
        </>
      )}
    </div>
  );
}
