import React, { useEffect, useRef, useState } from 'react';
import { GroupBase, components, OnChangeValue, ActionMeta } from 'react-select';
import {
  AsyncPaginate as AsyncPaginateBase,
  AsyncPaginateProps,
  wrapMenuList,
} from 'react-select-async-paginate';
import classNames from 'classnames';
import { Size } from '../../utils/types/Size';
import { selectTheme } from './selectTheme';
import { selectStyle } from './selectStyle';
import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { Option } from './Option';
import { ValueContainer } from './ValueContainer';

const MENU_CLASS_NAME = 'select__menu';

export interface PropTypes<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
> extends Omit<
    AsyncPaginateProps<OptionType, Group, Additional, true>,
    'placeholder'
  > {
  testId?: string;
  size?: Size;
  placeholder?: string;
  disableDefaultListCacheOnChange?: boolean;
  onChange?: (
    newValue: OnChangeValue<OptionType, true>,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
}

const CustomMenuList = wrapMenuList(function (props: any) {
  return (
    <div className={MENU_CLASS_NAME}>
      <input
        style={{
          width: '100%',
          boxSizing: 'border-box',
          padding: 10,
          border: 'none',
          borderBottom: '1px solid lightgrey',
        }}
        autoCorrect='off'
        autoComplete='off'
        spellCheck='false'
        type='text'
        value={props.selectProps.inputValue}
        onChange={(event) => {
          props.selectProps.onInputChange(event.target.value, {
            action: 'input-change',
          });
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          (e.target as HTMLInputElement).focus();
        }}
        onFocus={props.selectProps.onMenuInputFocus}
        placeholder='Search...'
        ref={props.innerRef}
      />
      <components.MenuList {...props} />
    </div>
  );
});

function getComponentKey(
  value?: any,
  disableDefaultListCacheOnChange?: boolean,
) {
  // By default, it should cache default list
  // For e.g. paginated async multi select on Organic Post Skills, it is useful to disable cache
  //   so we can render selected options on top when search is empty
  const disableCache = disableDefaultListCacheOnChange ?? false;

  if (disableCache) {
    // For multiselect we can assume value to be an array
    // Caching leverages the key, so we should create one per list of ids
    return (value as any)?.map((o) => o.value).join(',');
  } else {
    return undefined;
  }
}

export function MultiPaginatedSelect<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
>(props: PropTypes<OptionType, Group, Additional>) {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isLoading = props.isLoading ?? false;

  function handleDomClick(event: MouseEvent) {
    const root = containerRef.current;
    const menu = root.querySelector(`.${MENU_CLASS_NAME}`);

    if (menu) {
      if (!menu.contains(event.target)) {
        setIsFocused(false);
        setInputValue('');
      }
    } else {
      if (root.contains(event.target)) {
        setIsFocused(true);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDomClick, true);

    return () => {
      document.removeEventListener('click', handleDomClick);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <AsyncPaginateBase
        {...props}
        key={getComponentKey(
          props.value,
          props.disableDefaultListCacheOnChange,
        )}
        className={classNames(
          props.className,
          props.size === 'sm' ? 'fs-6' : 'fs-5',
        )}
        inputValue={inputValue}
        isLoading={isLoading}
        isMulti={true}
        isSearchable={false}
        onInputChange={setInputValue}
        id={props.testId}
        placeholder={
          <div className='text-truncate'>
            {props.placeholder || 'Select...'}
          </div>
        }
        menuPlacement={props.menuPlacement || 'auto'}
        components={{
          MenuList: CustomMenuList,
          ClearIndicator,
          DropdownIndicator,
          ValueContainer,
          Option,
        }}
        theme={selectTheme}
        styles={selectStyle(props.size || 'md')}
        menuIsOpen={isFocused}
        closeMenuOnSelect={false}
        aria-label={props.placeholder}
        cacheUniqs={[props.placeholder]}
        onChange={(
          nv: OnChangeValue<OptionType, true>,
          am: ActionMeta<OptionType>,
        ) => {
          if (props.closeMenuOnSelect) setIsFocused(false);
          props.onChange(nv, am);
        }}
      />
    </div>
  );
}
