import classNames from 'classnames';
import React, { useState } from 'react';
import { CardTitle, Collapse } from 'reactstrap';
import { DeprecatedPanel } from '../DeprecatedPanel';
import styles from './styles.module.scss';

interface PropTypes {
  title: JSX.Element;
  children: JSX.Element;
  subtitle?: JSX.Element;
  collapsable?: boolean;
  isOpen?: boolean;
  marginTop?: string;
}

AnalyticsPanel.defaultProps = {
  collapsable: false,
  isOpen: true,
};

export function AnalyticsPanel(props: PropTypes) {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

  const collapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DeprecatedPanel
      className={classNames('w-100 h-100 text-primary')}
      additionalInnerContainerClassNames='p-3 p-xxl-4 h-100'
      innerMostClassName='h-100 d-flex flex-column'
    >
      <div
        className={classNames(
          'd-flex',
          props.collapsable && 'justify-content-between',
        )}
      >
        <div className='flex-fill'>
          <CardTitle className={classNames(styles.title)}>
            {props.title}
          </CardTitle>
          <div>{props.subtitle}</div>
        </div>
        {props.collapsable && (
          <div
            onClick={collapse}
            className={classNames(
              'd-flex align-items-center me-3 fs-2',
              styles.collapseIcon,
            )}
          >
            <i className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`}></i>
          </div>
        )}
      </div>
      <Collapse
        isOpen={isOpen}
        className={classNames(
          props.children.props?.className,
          props.marginTop ? props.marginTop : 'mt-3',
        )}
      >
        {props.children}
      </Collapse>
    </DeprecatedPanel>
  );
}
