import classNames from 'classnames';
import React from 'react';

export function JobPageGroupHeader(props: {
  className?: string;
  title: string;
  description: string;
  mandatory: boolean;
  lastElement: boolean;
  children: JSX.Element;
}) {
  return (
    <div
      className={classNames(
        props.className,
        !props.lastElement && 'mt-2 pb-4 mb-4 border-bottom',
      )}
    >
      <div className='mb-4'>
        <div className='d-flex'>
          <h3 className='mb-2'>{props.title}</h3>
          {props.mandatory && (
            <span className='ps-1' style={{ color: 'red' }}>
              *
            </span>
          )}
        </div>
        <small className='text-dark-200'>{props.description}</small>
      </div>
      {props.children}
    </div>
  );
}
