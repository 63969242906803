import React from 'react';
import { HeaderRow } from '../HeaderRow';
import { EmailTemplate } from '../../../../../../../entities/EmailTemplate';
import { Select, SelectOption } from '../../../../../../../components/Select';

interface PropTypes {
  emailTemplate?: EmailTemplate;
  emailTemplates: EmailTemplate[];
  setEmailTemplate: (emailTemplate: EmailTemplate) => void;
}

function mapToOption(
  emailTemplate: EmailTemplate,
  selectedEmailTemplate?: EmailTemplate,
): SelectOption {
  if (emailTemplate == null) {
    return null;
  }

  return {
    label: emailTemplate.name,
    value: emailTemplate.id.toString(),
    selected: emailTemplate.id === selectedEmailTemplate?.id,
  };
}

export function EmailTemplates(props: PropTypes) {
  const options = props.emailTemplates?.map((e) =>
    mapToOption(e, props.emailTemplate),
  );

  return (
    <HeaderRow label='Template'>
      <Select
        className='fs-5 fw-normal'
        placeholder='Select email template'
        maxMenuHeight={250}
        options={options}
        selected={options?.find((v) => v.selected)}
        onChange={(selected: SelectOption) =>
          props.setEmailTemplate(
            props.emailTemplates?.find(
              (i) => i.id.toString() === selected.value,
            ),
          )
        }
      />
    </HeaderRow>
  );
}
