import React from 'react';
import { SimpleSelectField } from '../SimpleSelectField';
import { JobPostingDetailsForm } from '..';

interface PropTypes {
  form: JobPostingDetailsForm;
  isClearable?: boolean;
  mandatory?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function SalaryPeriod(props: PropTypes) {
  return (
    <SimpleSelectField
      label='Salary period'
      options={[
        { label: 'Yearly', value: 'yearly' },
        { label: 'Monthly', value: 'monthly' },
      ]}
      value={props.form.salaryPeriod}
      name='salaryPeriod'
      onInputChange={props.onInputChange}
      isClearable={props.isClearable}
      mandatory={props.mandatory}
      placeholder={'Select Range'}
    />
  );
}
