import React from 'react';
import { IconButton } from '../../../components/IconButton';

interface PropTypes {
  atsEnabled: boolean;
  interviewRatingEnabled: boolean;
  scorecardUrl: string;
  onRatingClick: () => void;
}

interface ScorecardButtonPropTypes {
  enabled: boolean;
  icon: string;
  text: string;
  onClick: () => void;
}

function ScorecardButton(props: ScorecardButtonPropTypes) {
  return (
    <IconButton
      buttonText={props.text}
      disabled={!props.enabled}
      color='borderless'
      onClick={props.onClick}
      iconName={`bi-${props.icon}`}
    />
  );
}

export function InterviewRatingButton(props: PropTypes) {
  return (
    <>
      {props.atsEnabled ? (
        <ScorecardButton
          enabled={props.scorecardUrl !== null}
          icon='clipboard-check'
          text='View Scorecard'
          onClick={() => (window.location.href = props.scorecardUrl)}
        />
      ) : (
        <ScorecardButton
          enabled={props.interviewRatingEnabled}
          icon='star'
          text='Rate Interview'
          onClick={props.onRatingClick}
        />
      )}
    </>
  );
}
