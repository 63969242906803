import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { Body } from './Body';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { Location } from '../../../../entities/applicant_tracking/Location';
import { UpsertLocationDto } from './UpsertLocationDto';
import { PageTitle } from '../../../../components/PageTitle';
import { FormErrorMessage } from '../../../../components/FormErrorMessage';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { LocationService } from '../../../../services/applicant_tracking/LocationService';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { BackArrow } from '../../../../components/BackArrow';
import { getLocationString } from '../../../../components/LocationSelect';

interface PropTypes {
  edit: boolean;
  location: Location;
}

function getInitLocation(location?: Location): UpsertLocationDto {
  if (location == null) return null;

  return {
    id: location.id,
    name: location.name,
    location: {
      name: getLocationString({
        address: location.address,
        city: location.city,
        state: location.state,
        country: location.country,
      }),
      address: location.address,
      city: location.city,
      state: location.stateCode,
      country: location.countryCode,
      latitude: null,
      longitude: null,
    },
  };
}

function Title() {
  return (
    <div className='d-flex me-auto align-items-center'>
      <BackArrow url='/organization_settings/locations' />
      <PageTitle text={'Locations'} />
    </div>
  );
}

function Errors(props: {
  errors: Partial<FieldErrorsImpl<UpsertLocationDto>>;
}) {
  if (props.errors == null) {
    return null;
  }

  if (props.errors.name?.message) {
    return (
      <FormErrorMessage
        error={props.errors}
        errorMessage={{
          title: `${props.errors.name?.message} `,
          detail: 'Please change the location name',
        }}
      />
    );
  } else {
    return <FormErrorMessage error={props.errors} />;
  }
}

export default function SetupLocationPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
  } = useForm<UpsertLocationDto>({
    mode: 'onChange',
    defaultValues: getInitLocation(props.location),
  });

  const onSubmit = handleSubmit(
    async (
      data: UpsertLocationDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      setIsLoading(true);

      try {
        await LocationService.upsert(data);

        window.location.href = '/organization_settings/locations';
      } catch (e: unknown) {
        handleFormErrors(e, setError);
      }

      setIsLoading(false);
    },
  );

  return (
    <Row className='row g-3 mb-4 align-items-center justify-content-between'>
      <Errors errors={errors} />
      <Title />
      <DeprecatedPanel additionalInnerContainerClassNames='p-4'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Body
            formControl={control}
            location={props.location}
            errors={errors}
            edit={props.edit}
            isValid={isValid}
            onCancel={() =>
              (window.location.href = '/organization_settings/locations')
            }
            onSubmit={onSubmit}
            register={register}
          />
        )}
      </DeprecatedPanel>
    </Row>
  );
}
