import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Card as BootstrapCard } from 'reactstrap';

interface PropTypes {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function Card(props: PropTypes) {
  return (
    <BootstrapCard
      className={classNames('p-4 rounded-4 shadow-sm', props.className)}
      style={props.style}
    >
      {props.children}
    </BootstrapCard>
  );
}

export function CardTitle(props: { children: ReactNode }) {
  return <div className='fs-3 fw-semibold'>{props.children}</div>;
}
