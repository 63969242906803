import React from 'react';
import { Avatar } from '../../../../../components/Avatar';
import { Application } from '../../../../../entities/Application';
import { AverageRating } from './AverageRating';

interface PropTypes {
  application: Application;
  hrisLogo: string;
}

export function CandidateDetails(props: PropTypes) {
  return (
    <div className='d-flex mb-4 justify-content-between'>
      <Avatar
        name={props.application.candidate.name}
        size='xl'
        sideAvatarImg={props.hrisLogo}
        sideAvatarUrl={props.application.hrisEmployee?.externalUrl}
      />
      <AverageRating value={props.application.rating} />
    </div>
  );
}
