import React from 'react';
import { ApprovalFlowApprover } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { Avatar } from '../../../../../../components/Avatar';
import { TruncateText } from '../../../../../../components/TruncateText';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { ApproverAnswer } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';

interface PropTypes {
  approver: ApprovalFlowApprover;
  currentStepOrder: number;
  declinedStepOrder?: number;
}

interface AvatarProps {
  name: string;
  type: 'photo' | 'initials' | 'icon';
  className?: string;
  avatarClassName?: string;
  disabled?: boolean;
}

function buildAvatarProps(
  approver: ApprovalFlowApprover,
  currentStepOrder: number,
  declinedStepOrder?: number,
): AvatarProps {
  if (approver.answer) {
    return {
      name:
        approver.answer === ApproverAnswer.Approved ? 'bi-check-lg' : 'bi-x-lg',
      type: 'icon',
      avatarClassName: classNames(
        approver.answer === ApproverAnswer.Approved
          ? ['text-success', styles['avatar-approved']]
          : ['text-danger', styles['avatar-declined']],
      ),
    };
  }

  const user = approver.user;

  return {
    name: user.photoUrl ?? user.name,
    type: user.photoUrl ? 'photo' : 'initials',
    disabled:
      declinedStepOrder != null && currentStepOrder >= declinedStepOrder,
  };
}

export function Approver(props: PropTypes) {
  const avatarProps = buildAvatarProps(
    props.approver,
    props.currentStepOrder,
    props.declinedStepOrder,
  );

  return (
    <div className='d-flex align-items-center'>
      <Avatar
        name={avatarProps.name}
        size='lg'
        type={avatarProps.type}
        className={avatarProps.className}
        avatarClassName={avatarProps.avatarClassName}
        disabled={avatarProps.disabled}
      />
      <div
        className={classNames(
          'fs-5 fw-semibold text-nowrap',
          avatarProps.disabled ? 'text-gray-700' : 'text-dark-500',
        )}
      >
        <TruncateText
          ellipsize
          tooltip
          text={props.approver.user.name}
          maxChars={25}
        />
      </div>
    </div>
  );
}
