import classNames from 'classnames';
import React from 'react';
import { Chip } from '../../../../../../../components/Chip';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  tag: string;
}

const categories = {
  eval_expectations: 'Expectation Set',
  eval_ramp_up: 'Ramp-up',
  hit_performance_targets: 'Performance',
  impact_on_team_performance: 'Impact',
  company_culture_fit: 'Culture-fit',
};

export function Category(props: PropTypes) {
  return (
    <td className={classNames(styles['table-data-cell-border-bottom'], 'ps-4')}>
      <Chip className='bg-light-info fw-semibold text-primary fs-6'>
        {categories[props.tag]}
      </Chip>
    </td>
  );
}
