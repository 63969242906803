import { NpsClassification } from '../../../entities/candidate_experience/NpsClassification';

export function findRatingClassificationByScore(
  ratingLegend: {
    minScore: number;
    bgColor: string;
    classification: NpsClassification;
  }[],
  score: number,
) {
  const legend = findRatingLegendByScore(ratingLegend, score);
  return legend.classification;
}

export function findRatingBgColorByScore(
  ratingLegend: {
    minScore: number;
    label?: string;
    bgColor: string;
  }[],
  score: number,
) {
  const legend = findRatingLegendByScore(ratingLegend, score);
  return legend?.bgColor || 'light-danger';
}

function findRatingLegendByScore(
  ratingLegend: {
    minScore: number;
    label?: string;
    color?: string;
    classification?: NpsClassification;
    bgColor: string;
  }[],
  score: number,
) {
  const reversedLegend = [...ratingLegend].reverse();
  return reversedLegend.find((rl) => rl.minScore < score);
}
