import { useEffect, useState } from 'react';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import React from 'react';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { FIELD_CONFIGS, fetchTemplate } from '../data';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { IconSpan } from '../../../../components/IconSpan';
import { appendRedirectUri } from '../../../../utils/url';

interface PropTypes {
  id: number;
}

export default function ShowInterviewKitTemplatePage(props: PropTypes) {
  const [interviewKitTemplate, setInterviewKitTemplate] = useState({
    name: '',
    default: true,
    sections: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function load() {
      const template = await fetchTemplate(props.id.toString());
      setInterviewKitTemplate(template);
      setIsLoading(false);
    }

    load();
  }, [props.id]);

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <Body
        id={props.id}
        name={interviewKitTemplate.name}
        default={interviewKitTemplate.default}
        sections={interviewKitTemplate.sections}
      />
    </>
  );
}

function Body(props: {
  id: number;
  name: string;
  default: boolean;
  sections: SectionData[];
}) {
  return (
    <div className='container'>
      <PageHeader
        title={props.name}
        backUrl='/organization_settings/interview_questions'
      />
      <Card>
        <SetupPageHeader
          title='Interview Questions'
          firstItems={[]}
          intermediateItems={[
            {
              title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
              type: 'Navigate',
              action: () =>
                (window.location.href = appendRedirectUri(
                  `/organization_settings/interview_questions/${props.id}/edit`,
                )),
              disabled: props.default,
            },
          ]}
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput name={props.name} readOnly={true} />
          </div>
        </div>
        <SectionList
          fieldConfigs={FIELD_CONFIGS}
          singleTypeSections={true}
          sections={props.sections}
          readOnly={true}
          title='Questions'
          addFieldButtonText='Add Question'
          subtitle='Define and manage your interview questions'
          columns={[
            {
              fieldName: 'text',
              className: 'col',
              label: 'Questions',
            },
          ]}
        />
      </Card>
    </div>
  );
}
