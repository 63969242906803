import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../../../components/PageTitle';
import { EmailTemplateService } from '../../../services/EmailTemplateService';
import { EmailTemplate } from '../../../entities/EmailTemplate';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Body } from './Body';
import { UpsertEmailTemplateDto } from './UpsertEmailTemplateDto';
import { handleFormErrors } from '../../../services/ApiService/handler';
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from '../../../components/FormErrorMessage';

interface PropTypes {
  edit: boolean;
  emailTemplate: EmailTemplate;
}

async function loadEmailTemplateOptions(): Promise<EmailTemplate[]> {
  return await EmailTemplateService.list();
}

function getInitEmailTemplate(
  emailTemplate?: EmailTemplate,
): UpsertEmailTemplateDto {
  return {
    id: emailTemplate?.id,
    name: emailTemplate?.name,
    emailTemplateReferenceId: emailTemplate?.emailTemplateReferenceId,
    subject: emailTemplate?.subject,
    subjectText: emailTemplate?.subjectText,
    template: emailTemplate?.template,
    templateHtml: emailTemplate?.templateHtml,
  };
}

function Title(props: { edit: boolean; name?: string }) {
  if (props.edit) {
    return (
      <PageTitle
        text={
          <>
            <span>Edit / </span>
            <span className='text-dark-200'>{props.name}</span>
          </>
        }
      />
    );
  } else {
    return <PageTitle text={'Add Email Template'} />;
  }
}

export default function SetupEmailTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [html, setHtml] = useState<string>(null);
  const [subjectText, setSubjectText] = useState<string>(null);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
  } = useForm<UpsertEmailTemplateDto>({
    mode: 'onChange',
    defaultValues: getInitEmailTemplate(props.emailTemplate),
  });

  useEffect(() => {
    (async () => {
      setEmailTemplates(await loadEmailTemplateOptions());

      setIsLoading(false);
    })();
  }, []);

  const onSubmit = handleSubmit(
    async (
      data: UpsertEmailTemplateDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      setIsLoading(true);

      try {
        await EmailTemplateService.upsert(data, subjectText, html);

        window.location.href = '/organization_settings/email_templates';
      } catch (e: unknown) {
        handleFormErrors(e, setError);
      }

      setIsLoading(false);
    },
  );

  return (
    <Row className='row g-3 mb-4 align-items-center justify-content-between'>
      {errors.name?.message && (
        <FormErrorMessage
          error={errors}
          errorMessage={{
            title: `${errors.name?.message}`,
            detail: 'Please change the email template name',
          }}
        />
      )}
      <Title edit={props.edit} name={props.emailTemplate?.name} />
      <DeprecatedPanel additionalInnerContainerClassNames='p-4'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Body
            control={control}
            emailTemplates={emailTemplates}
            errors={errors}
            isValid={isValid}
            onCancel={() =>
              (window.location.href = '/organization_settings/email_templates')
            }
            onSubmit={onSubmit}
            register={register}
            setHtml={setHtml}
            setSubjectText={setSubjectText}
          />
        )}
      </DeprecatedPanel>
    </Row>
  );
}
