import React from 'react';
import { Col, Row } from 'reactstrap';
import { Nps } from '../../../../entities/survey_engine/Nps';
import { Trend } from '../../Trend';
import { NpsChip } from './NpsChip';
import { OverallNpsCharts } from './OverallNpsCharts';

interface PropTypes {
  nps: Nps;
  npsTrend?: number;
  detractorsText: string;
  neutralsText: string;
  promotersText: string;
  standardNpsCalculation?: boolean;
}

export function OverallNpsContent(props: PropTypes) {
  return (
    <div className='flex-grow-1 d-flex align-items-center'>
      <Row>
        <Col xs={6} className='px-4 d-flex flex-column align-items-center'>
          <OverallNpsCharts
            nps={props.nps}
            standardNpsCalculation={props.standardNpsCalculation}
          />
          <div className='mt-3 fs-6'>
            <Trend trend={props.npsTrend} />
          </div>
        </Col>
        <Col
          xs={6}
          className='d-flex flex-column justify-content-center ps-5 pe-0'
        >
          <dl className='row mt-5'>
            <NpsChip
              npsRatio={props.nps.detractors}
              npsText={props.detractorsText}
            />
            <NpsChip
              npsRatio={props.nps.neutrals}
              npsText={props.neutralsText}
            />
            <NpsChip
              npsRatio={props.nps.promoters}
              npsText={props.promotersText}
            />
          </dl>
        </Col>
      </Row>
    </div>
  );
}
