/** @file Typed wrappers for localStorage. */

export type KnownKeys = 'offer_letter_template';

export function get<T>(key: KnownKeys): T {
  return JSON.parse(localStorage.getItem(key));
}

export function set<T>(key: KnownKeys, value: T): T {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
}

export function pop<T>(key: KnownKeys): T {
  const value: T = get(key);
  localStorage.removeItem(key);
  return value;
}
