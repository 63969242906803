import React, { useCallback } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import { EventSlot } from '../../../../../components/cronofy/CronofyDatePicker/EventSlot';
import ScheduleInterviewPage from '../index';
import { User } from '../../../../../entities/User';
import candidateSchedulingImage from '../../../../../images/candidate-experience-mailer-new-survey.png';
import { Application } from '../../../../../entities/applicant_tracking/PublicPages/Application';
import { InterviewCandidateUser } from '../../../../../entities/applicant_tracking/InterviewCandidateUser';
import { InterviewCandidateUserService } from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { appendCurrentRedirectUri } from '../../../../../utils/url';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  application: Application;
  interviewCandidateUser: InterviewCandidateUser;
  organizer: User;
  interviewId: number;
  interviewerUsers: User[];
  interviewStage: InterviewStage;
  meetingProvider: VcsProvider;
  startDate: Date;
}

export default function SelfScheduleInterview(props: PropTypes) {
  const onConfirm = useCallback(async (eventSlot: EventSlot) => {
    try {
      await InterviewCandidateUserService.selfSchedule(
        props.interviewCandidateUser.secureId,
        eventSlot.slot.start,
        eventSlot.slot.end,
        eventSlot.tzid,
      );

      window.location.href = appendCurrentRedirectUri(
        `/applicant_tracking/interview_candidate_users/${props.interviewCandidateUser.secureId}/schedule/success`,
      );
    } catch (e) {
      window.location.href = appendCurrentRedirectUri(
        '/applicant_tracking/interview_candidate_users/schedule/error',
      );
    }
  }, []);

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.organizer}
      applicationId={props.application.id}
      title={`Hi ${props.application.candidate.name}, welcome!`}
      description={
        'Here you can pick the time and self-schedule your interview.'
      }
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      candidate={props.application.candidate}
      participants={props.interviewerUsers}
      availableParticipants={[]}
      interviewStage={props.interviewStage}
      meetingProviders={[]}
      meetingProvider={props.meetingProvider}
      durationMinutes={props.interviewCandidateUser.meetingDuration}
      onConfirm={onConfirm}
      customImage={candidateSchedulingImage}
      startDate={props.interviewCandidateUser.updatedAt}
      scheduleFromHours={props.interviewCandidateUser.scheduleFromHours}
      dateRange={props.interviewCandidateUser.dateRange}
      jobName={props.application.jobName}
      selfScheduleView={false}
      candidateView={true}
      allowChangingMeetingOrganizer={false}
      allowIgnoreParticipantCalendar={false}
    />
  );
}
