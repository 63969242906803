import React from 'react';
import { Dropdown } from '../../../components/Dropdown';
import { IconSpan } from '../../../components/IconSpan';
import { PropTypes as ActionPropTypes } from '../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  viewEmailTemplateUrl: string;
  editEnabled: boolean;
  editEmailTemplateUrl: string;
}

function viewTemplate(viewEmailTemplateUrl: string): ActionPropTypes {
  return {
    action: () => (window.location.href = viewEmailTemplateUrl),
    buttonChild: (
      <IconSpan
        spanText={'View'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function editTemplate(editEmailTemplateUrl: string): ActionPropTypes {
  return {
    action: () => (window.location.href = editEmailTemplateUrl),
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const menuActions = [viewTemplate(props.viewEmailTemplateUrl)];

  if (props.editEnabled) {
    menuActions.push(editTemplate(props.editEmailTemplateUrl));
  }

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-5 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
