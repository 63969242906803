import React from 'react';
import { ListCandidate } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { Chip } from '../../../../../../../../components/Chip';

interface PropTypes {
  candidate: ListCandidate;
}

export function Jobs(props: PropTypes) {
  if (
    props.candidate.application == null ||
    props.candidate.application.job == null
  )
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <div className='d-flex align-items-center fs-5'>
      <div className='text-truncate'>
        {props.candidate.application.job.name}
      </div>
      {props.candidate.totalApplications > 1 && (
        <Chip className='ms-2 text-primary bg-light-info py-1 px-2h fs-6 fw-semibold'>
          +{props.candidate.totalApplications - 1}
        </Chip>
      )}
    </div>
  );
}
