import React from 'react';
import { Row } from 'reactstrap';
import { StepperItem } from './HorizontalStepperItem';
import { HorizontalDivider } from '../HorizontallDivider';
import classNames from 'classnames';

export interface ItemPropTypes {
  title: string;
  subtitle: string;
  slug?: string;
}

interface PropTypes {
  activeIndex: number;
  items: ItemPropTypes[];
  displayInModal?: boolean;
}

export function HorizontalStepper(props: PropTypes) {
  return (
    <Row
      className={classNames(
        { 'rounded-3 mb-4': !props.displayInModal },
        { 'mb-4': props.displayInModal },
        'bg-white mx-0',
      )}
    >
      {props.items.map((item, index) => (
        <StepperItem
          displayInModal={props.displayInModal}
          key={index}
          step={index + 1}
          isFirstItem={index == 0}
          isLastItem={index == props.items.length - 1}
          isActive={index == props.activeIndex}
          title={item.title}
          subtitle={item.subtitle}
        />
      ))}
      {props.displayInModal && <HorizontalDivider />}
    </Row>
  );
}
