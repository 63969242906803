import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { BooleanInput } from '../../../../components/BooleanInput';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';

interface PropTypes {
  label: string;
  labelClassName?: string;
  formRegister: UseFormRegister<any>;
  formControl: Control<any, any>;
  shouldRender: boolean;
  disabled: boolean;
  required: boolean;
}

export function HasWorkingVisaInput(props: PropTypes) {
  const controllerName = 'hasWorkingVisa';

  if (!props.shouldRender) return null;

  return (
    <div className='d-flex flex-column fs-5'>
      <FormFieldHeader
        fieldName={props.label}
        isRequired={props.required}
        classNames={classNames('me-2h fw-semibold', props.labelClassName)}
      />
      <Controller
        name={controllerName}
        control={props.formControl}
        rules={{ validate: props.required ? (x) => x != null : undefined }}
        render={({ field }) => (
          <BooleanInput
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            disabled={props.disabled}
            trueLabel='Yes'
            falseLabel='No'
          />
        )}
      />
    </div>
  );
}
