import React from 'react';
import { Select, SelectOption } from '../../../../../../components/Select';

interface PropTypes {
  options: SelectOption[];
  selected: SelectOption;
  onChange: (opt: SelectOption) => void;
}

export function LocationSelect(props: PropTypes) {
  return (
    <Select
      name={'Location'}
      placeholder={'All Locations'}
      options={props.options}
      selected={props.selected}
      className='mt-4'
      isClearable={true}
      onChange={props.onChange}
    />
  );
}
