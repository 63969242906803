import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../components/candidate_experience/Filters/filterState';
import React from 'react';
import { TopTabNavigation } from './TopTabNavigation';
import InterviewerPulseTab from './InterviewerPulseTab';
import { BenchmarkType } from '../../../entities/candidate_experience/BenchmarkType';
import HiringManagerPulseTab from './HiringManagerPulseTab';

interface PropTypes {
  benchmark_types: BenchmarkType[];
  interviewer_pulse_enabled: boolean;
  hiring_manager_pulse_enabled: boolean;
  activeTab: string;
  filterStates: FilterStates;
  moreFiltersShown: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
}

export function HiringTeamPulseAnalytics(props: PropTypes) {
  return (
    <main>
      <div className='d-flex align-items-center mb-4'>
        <h1 className='mb-0 me-auto'>Hiring Team Pulse</h1>
      </div>
      {props.interviewer_pulse_enabled &&
        props.hiring_manager_pulse_enabled && (
          <TopTabNavigation
            activeTab={props.activeTab}
            interviewer_pulse_enabled={props.interviewer_pulse_enabled}
            hiring_manager_pulse_enabled={props.hiring_manager_pulse_enabled}
          />
        )}
      {props.activeTab === 'Interviewer' && (
        <InterviewerPulseTab
          benchmark_types={props.benchmark_types}
          activeTab={props.activeTab}
        />
      )}
      {props.activeTab === 'Hiring Manager' && (
        <HiringManagerPulseTab
          benchmark_types={props.benchmark_types}
          activeTab={props.activeTab}
        />
      )}
    </main>
  );
}
