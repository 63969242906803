import React from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { Row } from 'reactstrap';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../../components/Select';

interface PropTypes {
  formControl: Control<CandidateDto, any>;
  hiringMemberOptions: SelectOption[];
  errors: FieldErrors;
  formRegister: UseFormRegister<CandidateDto>;
  formResetField?: (controllerName: string, options: any) => void;
  watch: UseFormWatch<CandidateDto>;
  getValues: () => CandidateDto;
  isLoading?: boolean;
}

export function ReferralInputData(props: PropTypes) {
  const referralFormError = props.errors.referrerEmail?.message;
  const validateEmailAndReferral = () => {
    const formData = props.getValues();
    if (formData['email'] == null) return true;
    if (formData['email'] === formData['referrerEmail']) {
      return 'The referral email address can not be the same as that of the person who gets the credit.';
    }
    return true;
  };

  const referrerEmail = props.watch('referrerEmail');

  return (
    <Row>
      <div className={styles.column}>
        <SelectFormHook
          placeholder={'Select referrer'}
          options={props.hiringMemberOptions}
          disabled={!!props.watch('referrerEmail')}
          formHookContext={{
            controllerName: 'referrerId',
            formControl: props.formControl,
            required: referrerEmail == null || referrerEmail == '',
          }}
          isLoading={props.isLoading}
          formResetField={props.formResetField}
        />
      </div>
      <div
        className={classNames(
          'fs-5 text-dark-200 fw-normal px-0 align-self-start pt-2',
          styles['or-column'],
        )}
      >
        OR
      </div>
      <div className={styles.column}>
        <input
          className={classNames('form-control', 'fs-5')}
          disabled={!!props.watch('referrerId')}
          type='email'
          placeholder='Insert referrer email'
          {...props.formRegister('referrerEmail', {
            required: props.watch('referrerId') == null,
            validate: validateEmailAndReferral,
          })}
        />
        {referralFormError && (
          <div className='mt-2 text-danger fs-6'>{referralFormError}</div>
        )}
      </div>
    </Row>
  );
}
