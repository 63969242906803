import React, { useEffect, useState } from 'react';
import { DateFilter } from '../../../../components/DateRangePicker';
import { Button, Col, Row } from 'reactstrap';
import { LabelledDatePicker } from '../../../../components/LabelledDatePicker';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../components/Select';
import { FilterService } from '../../../../services/applicant_tracking/analytics/FilterService';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import { uniqBy } from 'lodash';
import { DynamicMultiSelect } from '../../../../components/DynamicMultiSelect';
import { Job } from '../../../../entities/v1/applicant_tracking/Job';
import { Location } from '../../../../entities/v1/applicant_tracking/Location';

export const DEFAULT_FILTER_BY: SelectOption = {
  label: 'Departments',
  value: 'department',
};

export const FILTER_BY_OPTIONS: SelectOption[] = [
  DEFAULT_FILTER_BY,
  {
    label: 'Jobs',
    value: 'job',
  },
];

export interface FilterData {
  filterBys: SelectOption[];
  jobs: Job[];
  locations: Location[];
}

export interface FilterState {
  filterBy: SelectOption;
  entries: SelectOption[];
  locations: SelectOption[];
  dateRange: DateFilter;
}

interface PropTypes {
  filterState: FilterState;
  onFilterUpdate: (
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) => void;
  onApplyClick: () => void;
  onResetClick: () => void;
}

export function Filters(props: PropTypes) {
  const [filterData, setFilterData] = useState<FilterData>(null);

  useEffect(() => {
    downloadFilterData().then((filterData) => setFilterData(filterData));
  }, []);

  return (
    <div className='mb-3 w-100'>
      <Row className='gap-2 fs-5 h-100'>
        <Col xs='12' md='4' xl='2'>
          <LabelledSelect
            options={(() => {
              if (filterData == null) return [];

              return filterData.filterBys;
            })()}
            selected={props.filterState.filterBy}
            onChange={(newFilter) =>
              props.onFilterUpdate('filterBy', newFilter)
            }
            label='Filter by'
            className={'w-100'}
            disabled={filterData == null}
          />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <MultiSelect
            state={props.filterState}
            onFilterUpdate={props.onFilterUpdate}
            data={filterData}
          />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <DynamicMultiSelect
            options={
              filterData?.locations?.map((l) => ({
                label: l.name,
                value: l.id.toString(),
              })) ?? []
            }
            selected={props.filterState.locations ?? []}
            label={'Locations'}
            value={'location'}
            onChange={(newValues) =>
              props.onFilterUpdate('locations', newValues)
            }
            max={10}
            disabled={filterData == null}
          />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <LabelledDatePicker
            label='Date'
            dateFilter={props.filterState.dateRange}
            onChange={(start, end, range) =>
              props.onFilterUpdate('dateRange', { start, end, range })
            }
            className={'w-100'}
          />
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'd-flex text-nowrap align-bottom align-self-end',
            styles['filter-buttons-column'],
          )}
        >
          <div
            className={classNames(
              'd-flex gap-3 justify-content-between p-0',
              styles['filter-buttons'],
            )}
          >
            <Button
              type='button'
              color='secondary'
              onClick={props.onResetClick}
            >
              Reset
            </Button>
            <Button type='button' color='primary' onClick={props.onApplyClick}>
              Apply
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

async function downloadFilterData() {
  const filterData = await FilterService.show();
  filterData.filterBys = FILTER_BY_OPTIONS;
  return filterData;
}

function mapToSelectOptions(
  state: FilterState,
  data: FilterData,
): SelectOption[] {
  if (data == null) return [];

  switch (state.filterBy.value) {
    case 'department': {
      let departments = data.jobs.map((job) => job.department);
      departments = uniqBy(departments, (department) => department.id);
      return departments.map((d) => ({
        label: d.name,
        value: d.id.toString(),
      }));
    }
    case 'job':
      return data.jobs.map((job) => ({
        label: job.name,
        value: job.id.toString(),
      }));
    default:
      throw new Error(`${state.filterBy.value} not implemented.`);
  }
}

function MultiSelect(props: {
  state: FilterState;
  onFilterUpdate: (
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) => void;
  data?: FilterData;
}) {
  return (
    <DynamicMultiSelect
      options={mapToSelectOptions(props.state, props.data)}
      selected={props.state.entries}
      label={props.state.filterBy.label}
      value={props.state.filterBy.value}
      onChange={(newValues) => props.onFilterUpdate('entries', newValues)}
      max={10}
      disabled={props.data == null}
    />
  );
}
