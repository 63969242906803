import React, { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import { Candidate } from '../../../../../entities/applicant_tracking/Candidate';
import { HiringMember } from '../../../../../entities/applicant_tracking/HiringMember';
import { JobPost } from '../../../../../entities/applicant_tracking/JobPost';
import { Source } from '../../../../../entities/applicant_tracking/Source';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { HiringMemberService } from '../../../../../services/applicant_tracking/HiringMemberService';
import { JobPostService } from '../../../../../services/applicant_tracking/JobPostService';
import { SourceService } from '../../../../../services/applicant_tracking/SourceService';
import { ApplicationForm } from '../../../Applications/ApplicationForm';
import { SubmitButton } from '../../../../../components/SubmitButton';

interface PropTypes {
  candidate: Candidate;
  dto: CandidateDto;
  onStep: (n: number) => void;
}

export function JobApplicationStep(props: PropTypes) {
  const [jobPosts, setJobPosts] = useState<JobPost[]>(null);
  const [sources, setSources] = useState<Source[]>(null);
  const [hiringMembers, setHiringMembers] = useState<HiringMember[]>(null);

  useEffect(() => {
    JobPostService.list({
      excludingCandidateId: props.candidate.id,
      restrictedAccess: true,
    }).then((jobPosts) => setJobPosts(jobPosts));
    SourceService.list().then((sources) => setSources(sources));
    HiringMemberService.list().then((hiringMembers) =>
      setHiringMembers(hiringMembers),
    );
  }, []);

  async function handleSuccessfulSubmit(application: Application) {
    window.location.href = `/applications/${application.id}`;
  }

  return (
    <ApplicationForm
      candidateId={props.candidate.id}
      candidateDto={props.dto}
      jobPosts={jobPosts}
      sources={sources}
      hiringMembers={hiringMembers}
      onSuccessfulSubmit={handleSuccessfulSubmit}
      headerFactory={(formState) => (
        <FormHeader
          formState={formState}
          redirectUrl='/candidates'
          onStep={props.onStep}
        />
      )}
    />
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  redirectUrl: string;
  onStep: (n: number) => void;
}) {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <h2>Candidate Profile</h2>
        <div className='ms-auto d-flex gap-3'>
          <Button
            className='text-info border-info'
            onClick={() => props.onStep(0)}
          >
            Previous
          </Button>
          <VerticalDivider />
          <Button
            color='danger'
            onClick={() => (window.location.href = props.redirectUrl)}
          >
            Cancel
          </Button>
          <SubmitButton formState={props.formState} value='save_and_submit'>
            Save and Submit
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}
