import React, { useEffect, useState } from 'react';
import { User } from '../../../../entities/User';
import { Header } from './Header';
import { Row } from 'reactstrap';
import { ScorecardBody } from './ScorecardBody';
import { InterviewStage } from '../../../../entities/applicant_tracking/InterviewStage';
import { Application } from '../../../../entities/Application';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { SelectOption } from '../../../../components/Select';
import { JobService } from '../../../../services/applicant_tracking/JobService';
import { InterviewKitCard } from './InterviewKitCard';
import { InterviewKitPanel } from '../../../../components/ApplicantTracking/InterviewKitPanel';
import { IconButton } from '../../../../components/IconButton';
import { IconSpan } from '../../../../components/IconSpan';
import { LinkButton } from '../../../../components/LinkButton';
import { ScorecardService } from '../../../../services/v1/applicant_tracking/ScorecardService';
import { Scorecard } from '../../../../entities/applicant_tracking/Scorecard';
import { appendRedirectUri } from '../../../../utils/url';

interface PropTypes {
  application: Application;
  jobId: number;
  interviewStage: InterviewStage;
  user: User;
  canFillScorecard: boolean;
  interviewId?: number;
}

function convertToSelectOption(
  interviewStage: InterviewStage,
  selectedId?: number,
): SelectOption {
  return {
    value: interviewStage.id.toString(),
    label: interviewStage.name,
    selected: interviewStage.id === selectedId,
  };
}

async function loadInterviewStageOptions(
  jobId: number,
  selectedId?: number,
): Promise<SelectOption[]> {
  return (await JobService.listInterviewStages(jobId)).map(
    (interviewStage: InterviewStage) =>
      convertToSelectOption(interviewStage, selectedId),
  );
}

function ViewCandidateButton(props: { application: Application }) {
  const applicationUrl = appendRedirectUri(
    `/applications/${props.application.id}`,
  );

  return (
    <LinkButton
      color='borderless'
      link={{ href: applicationUrl, classNames: 'me-2h' }}
    >
      <IconSpan
        spanText='View Candidate'
        icon={{ name: 'bi-person', className: 'fs-6' }}
        className='text-blue'
        spanClassName='fs-6 fw-normal'
      />
    </LinkButton>
  );
}

function ViewCVButton(props: { application: Application }) {
  if (props.application.applicationResume == null) {
    return null;
  }

  return (
    <LinkButton
      color='borderless'
      link={{
        href: props.application.applicationResume.url,
        classNames: 'me-2h',
        target: '_blank',
      }}
    >
      <IconSpan
        spanText={'View CV'}
        icon={{ name: 'bi-file-text', className: 'fs-6' }}
        className={'text-blue'}
        spanClassName={'fs-6 fw-normal'}
      />
    </LinkButton>
  );
}

export default function InterviewKitPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingFillScorecard, setIsLoadingFillScorecard] =
    useState<boolean>(false);
  const [interviewStagesOptions, setInterviewStagesOptions] =
    useState<SelectOption[]>(null);

  useEffect(() => {
    (async () => {
      try {
        setInterviewStagesOptions(
          await loadInterviewStageOptions(props.jobId, props.interviewStage.id),
        );
      } catch (error: unknown) {
        setInterviewStagesOptions([]);
      }

      setIsLoading(false);
    })();
  }, []);

  async function handleFillScorecard() {
    setIsLoadingFillScorecard(true);

    let scorecards: Scorecard[] = [];

    if (props.interviewId != null) {
      // InterviewId is provided, so expects to create one or zero scorecards.
      scorecards = (
        await ScorecardService.batchCreate({
          interviewId: props.interviewId,
        })
      ).scorecards;
    } else {
      // InterviewId is not provided, so it may return multiple scorecards.
      scorecards = (
        await ScorecardService.batchCreate({
          applicationId: props.application.id,
          interviewStageId: props.interviewStage.id,
        })
      ).scorecards;
    }

    setIsLoadingFillScorecard(false);

    if (scorecards?.length === 1) {
      window.location.href = `/applicant_tracking/scorecards/${scorecards[0].id}`;
    } else {
      window.location.href = `/applications/${props.application.id}/scorecards?own_scorecards=true`;
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header
            application={props.application}
            interviewStagesOptions={interviewStagesOptions}
          />
          <div className='d-flex mt-4 justify-content-between'>
            <div className='fs-2 fw-bold'>{props.interviewStage.name} Kit</div>
            <div>
              <ViewCandidateButton application={props.application} />
              <ViewCVButton application={props.application} />
              {props.canFillScorecard && (
                <IconButton
                  buttonText='Fill in Scorecard'
                  color='borderless'
                  iconName='bi-clipboard-check'
                  textClass='fs-6 fw-normal'
                  iconClass='fs-6'
                  onClick={handleFillScorecard}
                  isLoading={isLoadingFillScorecard}
                />
              )}
            </div>
          </div>
          <Row className='mt-4'>
            <InterviewKitCard title='Scorecard'>
              <ScorecardBody
                user={props.user}
                scorecard={props.interviewStage.scorecard}
              />
            </InterviewKitCard>
            <InterviewKitCard title='Interview Questions'>
              <InterviewKitPanel
                sections={props.interviewStage.sections}
                recruiterName={props.application.job?.recruiters?.[0].name}
              />
            </InterviewKitCard>
          </Row>
        </>
      )}
    </>
  );
}
