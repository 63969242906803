import React from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';

interface PropTypes {
  interviewTemplateId: number;
  showInterviewTemplateUrl: string;
  editInterviewTemplateUrl: string;
}

function buildMenuAction(
  action: () => void,
  label: string,
  iconName: string,
  enabled: boolean,
) {
  if (!enabled) return null;

  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={label}
        icon={{ name: iconName, className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={[
        buildMenuAction(
          () => (window.location.href = props.showInterviewTemplateUrl),
          'View',
          'bi-eye',
          true,
        ),
        buildMenuAction(
          () => (window.location.href = props.editInterviewTemplateUrl),
          'Edit',
          'bi-pencil',
          true,
        ),
      ].filter(Boolean)}
    />
  );
}
