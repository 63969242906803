import classNames from 'classnames';
import React, { useState } from 'react';
import { IntegrationButton } from '../IntegrationButton';
import { Integration } from '../../entities/Integration';
import { Chip } from '../Chip';
import { humanize } from '../../utils/humanize';
import { getHrisName } from '../../utils/hris';
import { HrisProvider } from '../../enums/IntegrationProvider/HrisProvider';
import { IntegrationProvider } from '../../enums/IntegrationProvider';

export interface PropTypes {
  integration: Integration;
  lastElement: boolean;
}

function humanReadableName(provider: IntegrationProvider) {
  if (provider === HrisProvider.BambooHr || provider === HrisProvider.HiBob) {
    return getHrisName(provider as HrisProvider);
  }
  return humanize(provider);
}

export function IntegrationRow(props: PropTypes) {
  const [integration, setIntegration] = useState(props.integration);

  const title =
    props.integration.title ?? humanReadableName(props.integration.provider);
  const description =
    props.integration.subtitle ??
    `Connect the organization's ${title} account.`;

  return (
    <div
      className={classNames('item py-3', {
        'border-bottom': !props.lastElement,
      })}
    >
      <div className='row justify-content-between align-items-center'>
        <div className='col-auto'>
          <img src={props.integration.logoPath} style={{ width: 36 }} />
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col-6'>
              <h5 className='mb-0 d-flex gap-2 align-items-center'>
                {title}
                {integration.connected && (
                  <Chip className='text-primary bg-light-success'>
                    Connected
                  </Chip>
                )}
              </h5>
            </div>
            <div className='col-12 text-muted'>
              <small>{description}</small>
            </div>
          </div>
        </div>
        <div className='col-auto text-end'>
          <IntegrationButton
            integration={integration}
            setIntegration={setIntegration}
          />
        </div>
      </div>
    </div>
  );
}
