import React from 'react';
import { ActionItem } from '../../../../../../entities/ActionItem';
import { ActionItemLink } from '../ActionItemLink';

interface PropTypes {
  pendingActionItems: ActionItem[];
  completedActionItems: ActionItem[];
}

function scorecardUrl(actionItems: ActionItem[]): string {
  return actionItems.length === 1
    ? `/applicant_tracking/scorecards/${actionItems[0]?.actionIntentValue}`
    : `/applications/${actionItems[0].applicationId}/scorecards?own_scorecards=true&status=pending`;
}

function CompletedFillScorecard() {
  return (
    <ActionItemLink
      text='Fill in scorecard'
      icon='bi-clipboard-check'
      className='text-blue-200'
      completed
    />
  );
}

export function FillScorecardItem(props: PropTypes) {
  const pendingScorecards = props.pendingActionItems.filter(
    (actionItem) => actionItem.intent === 'fill_scorecard',
  );

  const completedScorecards = props.completedActionItems.filter(
    (actionItem) => actionItem.intent === 'fill_scorecard',
  );

  if (pendingScorecards?.length === 0 && completedScorecards?.length === 0) {
    return null;
  } else if (pendingScorecards?.length === 0) {
    return <CompletedFillScorecard />;
  }

  return (
    <a href={scorecardUrl(pendingScorecards)}>
      <ActionItemLink
        text='Fill in scorecard'
        icon='bi-clipboard-check'
        scorecardSize={pendingScorecards.length}
        className='text-info'
      />
    </a>
  );
}
