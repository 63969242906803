import React from 'react';
import { Control } from 'react-hook-form';
import { UpsertEmailTemplateDto } from '../../UpsertEmailTemplateDto';
import { Col } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { EMAIL_TEMPLATE_VARIABLES } from '../CopyVariables';
import { InputRichTextComposer } from '../../../../../components/RichTextEditor/composers/InputRichTextComposer';
import { RichTextEditor } from '../../../../../components/RichTextEditor';
import { PasteVariablePlugin } from '../../../../../components/RichTextEditor/plugins/PasteVariablePlugin';
import { EmailTemplate } from '../../../../../entities/EmailTemplate';
import { UpdateStatePlugin } from '../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto, any>;
  referredEmailTemplate?: EmailTemplate;
  setSubjectText?: (text: string) => void;
}

export function SubjectInput(props: PropTypes) {
  return (
    <Col xs={6}>
      <FormFieldHeader fieldName={'Subject'} isRequired={true} />
      <RichTextEditor<UpsertEmailTemplateDto>
        formHookContext={{
          controllerName: 'subject',
          formControl: props.control,
          required: true,
        }}
        render={(field) => (
          <InputRichTextComposer
            defaultEditorState={field.value as string}
            onChangeText={props.setSubjectText}
            onChangeJson={(value: string) => field.onChange(value)}
            externalPlugins={[
              <UpdateStatePlugin
                key={1}
                editorState={props.referredEmailTemplate?.subject}
              />,
              <PasteVariablePlugin
                key={2}
                variables={Object.values(EMAIL_TEMPLATE_VARIABLES)}
              />,
            ]}
            placeholder='Insert subject'
          />
        )}
      />
    </Col>
  );
}
