import React from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { SingleAttachmentInputFormField } from '../../../../components/SingleAttachmentInputFormField';

interface PropTypes {
  referral?: boolean;
  formRegister: UseFormRegister<CandidateDto>;
  getValues: UseFormGetValues<CandidateDto>;
  setValue: UseFormSetValue<CandidateDto>;
}

export function CandidateResumeInput(props: PropTypes) {
  return (
    <Row>
      <Col xs={6}>
        <SingleAttachmentInputFormField<CandidateDto>
          getValues={props.getValues}
          setValue={props.setValue}
          formRegister={props.formRegister}
          headerClassNames='fw-semibold'
          controllerContentName='resumeContent'
          controllerFileName='resumeFilename'
          fieldName={'Resume / Curriculum Vitae'}
          allowedFormats={['.pdf', '.doc', '.docx']}
          maxFileSizeMb={10}
          isRequired={false}
          disabled={false}
        />
      </Col>
    </Row>
  );
}
