import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../../utils/pdfHandler';
import { DateFilter } from '../../../../../components/DateRangePicker';
import { OverallNps } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNps';
import { OverallNpsHistory } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNpsHistory';
import { SurveyMetricsPanel } from '../../../../../components/survey_engine/SurveyMetricsPanel';
import { CandidateAnalytics } from '../../../../../entities/candidate_experience/CandidateAnalytics';
import { FilterStates } from '../../../../../components/candidate_experience/Filters/filterState';

export function SurveyMetricsAndNpsPanels(props: {
  npsTitle: string;
  npsDescription: string;
  npsPromotersText: string;
  npsNeutralsText: string;
  npsDetractorsText: string;
  analytics: CandidateAnalytics;
  dateRange: DateFilter;
  exportToPDF: boolean;
  benchmarkLabel: string;
  standardNpsCalculation: boolean;
  filterStates: Partial<FilterStates>;
}) {
  return (
    <Row className={classNames('gy-4', REPORTING_SECTION_CLASS)}>
      <Col sm='12' lg='5' className='d-flex flex-column'>
        <Row>
          <Col sm='12' lg='12' style={{ minHeight: '122px' }}>
            <SurveyMetricsPanel
              benchmarkLabel={props.benchmarkLabel}
              filterStates={props.filterStates}
            />
          </Col>
        </Row>
        <Row className={'mt-4 flex-grow-1'}>
          <Col sm='12' lg='12'>
            <OverallNps
              nps={props.analytics?.cps}
              npsTrend={props.analytics?.cps_trend}
              title={`Overall ${props.npsTitle}`}
              detractorsText={props.npsDetractorsText}
              neutralsText={props.npsNeutralsText}
              promotersText={props.npsPromotersText}
              tooltipText={
                <>
                  <p>{props.npsDescription}</p>
                  <p className='mb-0'>
                    ({props.npsTitle} = % {props.npsPromotersText.toLowerCase()}{' '}
                    - % {props.npsDetractorsText.toLowerCase()})
                  </p>
                </>
              }
              standardNpsCalculation={props.standardNpsCalculation}
            />
          </Col>
        </Row>
      </Col>
      <Col sm='12' lg='7' style={{ minHeight: '463px' }}>
        <div className={'h-100'}>
          <OverallNpsHistory
            npsHistory={props.analytics?.cps_history}
            npsBenchmark={props.analytics?.cps_benchmark}
            dateRange={props.dateRange}
            title={`${props.npsTitle} History`}
            exportToPDF={props.exportToPDF}
            standardNpsCalculation={props.standardNpsCalculation}
          />
        </div>
      </Col>
    </Row>
  );
}
