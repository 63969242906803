import React from 'react';
import { Section } from '../../../../entities/v1/survey_engine/Section';
import { Col } from 'reactstrap';
import { Question } from '../../../../entities/v1/survey_engine/Question';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  section: Section;
  showFieldLabels?: boolean;
}

export function OpenTextSection(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-3 mb-1 mt-5h'>{props.section.name}</div>
      {props.section.questions.map((question: Question) => (
        <Col xs='12' className='align-items-stretch mt-4' key={question.id}>
          <div className='d-flex fs-5 fw-semibold text-primary-dark mb-2 justify-content-between'>
            {question.title}
            {props.showFieldLabels && (
              <div className='text-dark-500'>Open Text Area</div>
            )}
          </div>
          <div
            className={classNames(
              styles['text-field'],
              'border border-gray-300 rounded-1 flex-fill fs-5 text-muted pt-2 px-2h',
            )}
          >
            Insert response here
          </div>
        </Col>
      ))}
    </>
  );
}
