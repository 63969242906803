import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Table } from 'reactstrap';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import { EmptyState } from '../../../../../components/EmptyState';
import UndrawEmptyState from '../../../../../images/undraw_empty_state_cactus.svg';
import styles from './styles.module.scss';
import tableStyles from '../styles.module.scss';
import { SessionUserService } from '../../../../../services/training/SessionUserService';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { formatAsPercentage } from '../../../../../utils/formatAsPercentage';
import { OverviewChip } from '../../../components/OverviewChip';
import { Chip } from '../../../../../components/Chip';
import { PanelHeader } from '../../../../../components/PanelHeader';
import { PanelFooter } from '../../../../../components/PanelFooter';

interface UserOverview {
  name: string;
  accuracy: number;
  completion: number;
  assignments: number;
}

interface UsersOverview {
  users: UserOverview[];
  totalUsers: number;
  page: number;
  pageSize: number;
}

interface Filters {
  page?: number;
  user?: string;
  sort?: string;
  direction?: number;
}

const columns = [
  { key: 'name', label: 'Name' },
  { key: 'accuracy', label: 'Accuracy', numeric: true },
  { key: 'completion', label: 'Completion', numeric: true },
  { key: 'assignments', label: 'Assignments', numeric: true },
];
const sortIcons = ['chevron-down', 'chevron-expand', 'chevron-up'];

function TableRows(props: { users: UserOverview[] }) {
  return (
    <>
      {props.users.map((user, idx) => (
        <tr key={idx}>
          <td>
            <Chip className='bg-gray fw-semibold text-primary px-2'>
              {user.name}
            </Chip>
          </td>
          <td className='text-end'>
            <Chip className='bg-pin fw-normal text-white px-2'>
              {user.accuracy == null ? 'N/A' : `${user.accuracy.toFixed(1)}%`}
            </Chip>
          </td>
          <td className='text-end'>
            <Chip className='bg-gray fw-semibold text-primary px-2'>
              {user.completion == null
                ? 'N/A'
                : `${formatAsPercentage(user.completion)}%`}
            </Chip>
          </td>
          <td className='text-end'>
            <OverviewChip
              iconClass='bi-journal-arrow-up'
              value={user.assignments}
            />
          </td>
        </tr>
      ))}
    </>
  );
}

function TableBody(props: { loading: boolean; users: UserOverview[] }) {
  return (
    <tbody>
      {!props.loading ? (
        props.users.length != 0 ? (
          <TableRows users={props.users} />
        ) : (
          <tr>
            <td>
              <div className={styles['empty-state-container']}>
                <EmptyState
                  cardless
                  title='No results found'
                  text='Please refine your search and try again'
                  imageSrc={UndrawEmptyState}
                />
              </div>
            </td>
          </tr>
        )
      ) : (
        <tr>
          <td>
            <div className={styles['loading-spinner-container']}>
              <LoadingSpinner />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function TableHead(props: {
  loading: boolean;
  sort: string;
  sortDirection: number;
  onSortUpdate: (sort: string) => void;
}) {
  function sortIcon(column: string) {
    const direction = props.sort === column ? props.sortDirection : 0;

    return sortIcons[direction + 1];
  }

  return (
    <thead className='text-nowrap'>
      <tr className='fs-5'>
        {columns.map(({ key, label, numeric }, idx) => {
          return (
            <th
              className={classNames('col-auto', idx == 0 && 'ps-0')}
              key={idx}
            >
              <a
                className={classNames(
                  'd-flex',
                  idx === 0 && 'justify-content-between',
                  numeric ? 'justify-content-end' : 'justify-content-center',
                  props.loading && styles['disabled-link'],
                )}
                href='#'
                onClick={() => props.onSortUpdate(key)}
              >
                <span className='text-truncate d-block'>{label}</span>
                <i className={`bi bi-${sortIcon(key)} ms-2`}></i>
                {idx === 0 && (
                  <span>{/* dummy el to force chevron in the middle */}</span>
                )}
              </a>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export function OverviewAllUsersMetric() {
  const [filters, setFilters] = useState<Filters>({
    page: 1,
    sort: 'name',
    direction: 1,
  });
  const [usersOverview, setUsersOverview] = useState<UsersOverview>({
    users: [],
    totalUsers: 0,
    page: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState<boolean>(false);

  async function updateSort(sort: string) {
    const direction = filters.sort === sort ? filters.direction * -1 : 1;

    await updateFilters({ sort: sort, direction, page: 1 });
  }

  async function updateFilters(props?: Filters) {
    setFilters({ ...filters, ...props });
  }

  const updateRows = useCallback(async () => {
    setLoading(true);

    const overview = await SessionUserService.overview({
      page: filters.page,
      name: filters.user,
      sort: filters.sort,
      direction: filters.direction == 1 ? 'ASC' : 'DESC',
    });

    setUsersOverview({
      ...usersOverview,
      users: overview.users,
      totalUsers: overview.total_users,
    });
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    updateRows();
  }, [filters]);

  return (
    <DeprecatedPanel additionalInnerContainerClassNames='p-4 pb-0'>
      <div className={styles['module-container']}>
        <PanelHeader
          onSearchUpdated={(name) => updateFilters({ user: name, page: 1 })}
          title='All Users'
          placeholder={'Search Users'}
          searchInputSize={'sm'}
        />
        <div className={styles['all-users-container']}>
          <Table
            borderless
            className={classNames(
              'mt-3',
              'table-fixed',
              tableStyles['overview-table'],
              styles['all-users-table'],
            )}
          >
            <TableHead
              loading={loading}
              sort={filters.sort}
              sortDirection={filters.direction}
              onSortUpdate={updateSort}
            />
            <TableBody loading={loading} users={usersOverview.users} />
          </Table>
        </div>
        {!!usersOverview.totalUsers && (
          <PanelFooter
            page={filters.page}
            pageSize={usersOverview.pageSize}
            totalRows={usersOverview.totalUsers}
            disabled={loading}
            onPageChanged={(page) => updateFilters({ page: page })}
          />
        )}
      </div>
    </DeprecatedPanel>
  );
}
