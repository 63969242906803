import React, { useCallback, useState } from 'react';
import { SchedulingUserIntegration } from '../../../entities/SchedulingUserIntegration';
import { PageTitle } from '../../../components/PageTitle';
import { Col, Row } from 'reactstrap';
import { AvailabilityEvent } from '../../../components/cronofy/CronofyAvailabilityRules';
import { CronofyOptions } from '../../../components/cronofy/CronofyOptions';
import { Alert, AlertType } from '../../../components/Alert';
import { SchedulingAvailabilityService } from '../../../services/applicant_tracking/SchedulingAvailabilityService';
import { AvailabilityEmptyState } from './AvailabilityEmptyState';
import { AvailabilityGrid } from './AvailabilityGrid';
import { Helper } from './Helper';
import { EditHeader } from './EditHeader';

interface PropTypes {
  cronofyUserAuthLink: string;
  cronofyOptions?: CronofyOptions;
  schedulingUserIntegration?: SchedulingUserIntegration;
  bypassEmptyState?: boolean;
}

export default function Availability(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Success);

  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [withAvailabilityDefined, setAvailabilityDefined] = useState<boolean>(
    props.schedulingUserIntegration?.availabilities.length > 0,
  );

  const alertSuccess = () => {
    setAlertMessage('Your interview availability was successfully defined.');
    setAlertType(AlertType.Success);
  };

  const alertError = () => {
    setAlertMessage('Your interview availability could not be saved!');
    setAlertType(AlertType.Danger);
    setSaveEnabled(true);
  };

  const onCallback = useCallback(async (event: AvailabilityEvent) => {
    const notificationType = event.notification.type;
    if (
      notificationType === 'availability_rule_edited' ||
      notificationType === 'availability_rule_not_found'
    ) {
      setSaveEnabled(true);
    } else if (notificationType === 'availability_rule_saved') {
      try {
        setSaveEnabled(false);

        await SchedulingAvailabilityService.create({
          slots: event.availability_rule.weekly_periods.map((period) => {
            return {
              day: period.day,
              startTime: period.start_time,
              endTime: period.end_time,
            };
          }),
          timezone: event.availability_rule.tzid,
        });

        alertSuccess();
      } catch (e) {
        alertError();
      }
    } else {
      alertError();
    }
  }, []);

  const onSave = () => {
    // Click on the hidden Cronofy button.
    (
      document.querySelector('button.cronofy__submit') as HTMLButtonElement
    ).click();
  };

  return (
    <>
      <PageTitle text={'Your Availability'} />
      {alertMessage && (
        <Alert
          type={alertType}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
        >
          {alertMessage}
        </Alert>
      )}
      <div className='app-card app-card-account shadow-sm align-items-start mt-3 p-3 p-xxl-4'>
        {withAvailabilityDefined || !!props.bypassEmptyState ? (
          <>
            <EditHeader isSaveEnabled={isSaveEnabled} onSave={onSave} />
            <Row className={'w-100'}>
              <Col lg={7}>
                <AvailabilityGrid
                  cronofyOptions={props.cronofyOptions}
                  onCallback={onCallback}
                />
              </Col>
              <Col lg={5}>
                <Helper />
              </Col>
            </Row>
          </>
        ) : (
          <AvailabilityEmptyState
            setAvailabilityDefined={setAvailabilityDefined}
            isCalendarConnected={
              props.schedulingUserIntegration?.calendarId != null
            }
            cronofyUserAuthLink={props.cronofyUserAuthLink}
          />
        )}
      </div>
    </>
  );
}
