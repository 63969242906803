import React from 'react';
import { Col, Row } from 'reactstrap';
import { IconButton } from '../../../../../../../components/IconButton';
import { Trigger } from '../../../../../../../entities/applicant_tracking/Trigger';

interface PropTypes {
  disabled?: boolean;
  triggers: Trigger[];
  setTriggers: (triggers: Trigger[]) => void;
}

export function AddTriggerButton(props: PropTypes) {
  const onClick = () => {
    props.setTriggers([
      ...props.triggers,
      { uniqueKey: window.crypto.randomUUID() } as Trigger,
    ]);
  };

  return (
    <Row>
      <Col xs='auto'>
        <IconButton
          color='secondary'
          iconName='bi-plus-circle'
          buttonText='Add Trigger'
          disabled={props.disabled}
          onClick={onClick}
        />
      </Col>
    </Row>
  );
}
