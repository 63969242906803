import React from 'react';
import { Button } from 'reactstrap';
import { IconButton } from '../../../../../components/IconButton';
import { AttachmentInput } from '../../../../CandidatePage/CandidateActions/SendEmailModal/EmailModalBody/EmailFooter/AttachmentInput';
import { FileError } from 'use-file-picker';

interface PropTypes {
  attachmentErrors: FileError[];
  files: File[];
  maxFiles: number;
  maxFileSizeMb: number;
  step: number;
  isValid: boolean;
  onClose: () => void;
  onPreview: () => void;
  onSubmit: () => void;
  openFileSelector: () => void;
  setStep: (step: number) => void;
}

function PreviewOrAttachmentOffer(props: PropTypes) {
  if (props.step < 2) {
    return null;
  } else if (props.step == 2) {
    return (
      <AttachmentInput
        classNames='me-auto'
        files={props.files}
        errors={props.attachmentErrors}
        maxFiles={props.maxFiles}
        maxFileSizeMb={props.maxFileSizeMb}
        openFileSelector={props.openFileSelector}
      />
    );
  }

  return (
    <IconButton
      color='borderless'
      onClick={props.onPreview}
      iconName={'bi-eye'}
      buttonText='Preview'
    />
  );
}

function SubmitButton(props: PropTypes) {
  if (props.step < 3) {
    return (
      <Button
        color='primary'
        disabled={!props.isValid}
        onClick={() => props.setStep(props.step + 1)}
      >
        {props.step < 2 ? 'Next' : 'Review and Send'}
      </Button>
    );
  }

  return (
    <Button color='primary' onClick={props.onSubmit}>
      Send
    </Button>
  );
}

export function Footer(props: PropTypes) {
  return (
    <>
      <PreviewOrAttachmentOffer {...props} />
      <div className='d-flex gap-3'>
        {
          <Button color='danger' onClick={props.onClose}>
            Cancel
          </Button>
        }
        {props.step > 0 && (
          <Button
            color='secondary'
            onClick={() => props.setStep(props.step - 1)}
          >
            Previous
          </Button>
        )}
        <SubmitButton {...props} />
      </div>
    </>
  );
}
