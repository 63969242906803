import { Control } from 'react-hook-form';
import { SelectOption } from '../../../../components/Select';
import { LabelledSelectFormHook } from '../../../../components/LabelledSelectFormHook';
import React from 'react';

interface PropTypes {
  label: string;
  required: boolean;
  formControl: Control<any>;
  disabled?: boolean;
}

export function AcademicDegreeInput(props: PropTypes) {
  return (
    <LabelledSelectFormHook<any>
      label={props.label}
      placeholder={'Select academic degree'}
      formHookContext={{
        controllerName: 'academicDegree',
        formControl: props.formControl,
        required: props.required,
      }}
      options={ACADEMIC_OPTIONS.map((opt) => mapToSelectOption(opt))}
      disabled={props.disabled}
    />
  );
}

const ACADEMIC_OPTIONS = ['Associate', "Bachelor's", 'Graduate', 'Doctorate'];

function mapToSelectOption(option: string): SelectOption {
  return {
    value: option,
    label: option,
  };
}
