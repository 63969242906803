import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { getQueryParam, getRedirectUriQueryParam } from '../../../../utils/url';
import {
  ApprovalAssignedTo,
  ApprovalFlowStep,
  RequisitionType,
  ListApprovalFlowsMetadata,
} from '../../../../entities/applicant_tracking/ApprovalFlow';
import { createNewApprovalFlowStep } from '../components/ApprovalSteps';
import { ApprovalFlowBody } from '../components/ApprovalFlowBody';
import { ApprovalReviewModal } from '../components/ApprovalReviewModal';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import {
  isApprovalFlowSavable,
  loadExistingFlowMetadata,
  mapDataToAPIRequest,
} from '../utils';
import { ApprovalFlowService } from '../../../../services/v1/applicant_tracking/ApprovalFlowService';

interface PropTypes {
  offerFlowFfEnabled: boolean;
}

export default function NewApprovalFlowPage(props: PropTypes) {
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [existingFlows, setExistingFlows] =
    useState<ListApprovalFlowsMetadata>(null);
  const [assignedTo, setAssignedTo] =
    useState<ApprovalAssignedTo>('organization');
  const [requisitionType, setRequisitionType] = useState<RequisitionType>(
    props.offerFlowFfEnabled
      ? (getQueryParam('requisition_type') as RequisitionType) ?? 'offer'
      : 'job',
  );
  const [assignedLocationOrDepartmentIds, setAssignedLocationOrDepartmentIds] =
    useState<number[]>([]);
  const [approvalSteps, setApprovalSteps] = useState<ApprovalFlowStep[]>([
    createNewApprovalFlowStep(1),
  ]);
  const [reviewModalOpen, setReviewModalOpen] = useState<boolean>(false);

  const isSavable = () =>
    isApprovalFlowSavable(
      name,
      assignedTo,
      !!existingFlows?.organizationFlowId,
      assignedLocationOrDepartmentIds,
      approvalSteps,
    );

  useEffect(() => {
    setAssignedTo('organization');
    loadExistingFlowMetadata(requisitionType).then(setExistingFlows);
  }, [requisitionType]);

  useEffect(() => setAssignedLocationOrDepartmentIds([]), [assignedTo]);

  async function handleSave() {
    setIsLoading(true);
    await ApprovalFlowService.create(
      mapDataToAPIRequest(
        name,
        assignedTo,
        requisitionType,
        assignedLocationOrDepartmentIds,
        approvalSteps,
      ),
    );

    window.location.href =
      getRedirectUriQueryParam() ?? '/organization_settings/approval_flows';
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <div className='container'>
        <PageHeader
          title='Add Approval Flow'
          backUrl='/organization_settings/approval_flows'
        />
        <Card>
          <CardHeader
            title='Approval Flow'
            saveText='Review and Save'
            titleClassNames='mb-0'
            classNames='align-items-center'
            isSavable={isSavable()}
            onSaveClick={() => setReviewModalOpen(true)}
            cancelUrl='/organization_settings/approval_flows'
          />
          <ApprovalFlowBody
            name={name}
            assignedTo={assignedTo}
            requisitionType={requisitionType}
            approvalSteps={approvalSteps}
            assignedLocationOrDepartmentIds={assignedLocationOrDepartmentIds}
            disabledLocationIds={existingFlows?.assignedLocationIds}
            disabledDepartmentIds={existingFlows?.assignedDepartmentIds}
            organizationFlowExists={!!existingFlows?.organizationFlowId}
            offerFlowFfEnabled={props.offerFlowFfEnabled}
            onName={setName}
            setApprovalSteps={setApprovalSteps}
            setRequisitionType={setRequisitionType}
            onAssignedToChange={setAssignedTo}
            setAssignedLocationOrDepartmentIds={
              setAssignedLocationOrDepartmentIds
            }
          />
        </Card>
      </div>
      {reviewModalOpen && (
        <ApprovalReviewModal
          steps={approvalSteps}
          onCancel={() => setReviewModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
}
