import React from 'react';
import classNames from 'classnames';
import { SingleScore } from '../../../../../../InterviewRatingResponse/SingleScore';
import { RateOption } from '../../../../../../../entities/RateOption';
import { ratingOptions } from '../../../../ScorecardPage/ScorecardBody/ScorecardScore';
import { AverageRating } from './AverageRating';
import { ScorecardBlockTitle } from './ScorecardBlockTitle';
import { Chip } from '../../../../../../../components/Chip';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import styles from '../styles.module.scss';
import { User } from '../../../../../../../entities/User';
import { Icon } from '../../../../../../../components/Icon';
import { Scorecard } from '../../../../../../../entities/applicant_tracking/Scorecard';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

export function ScorecardHeader(props: PropTypes) {
  const ratedOption = ratingOptions().find(
    (option: RateOption) => option.value === props.scorecard.score,
  );

  return (
    <div className='d-flex justify-content-between w-100 align-items-center'>
      <div className='d-flex align-items-center'>
        <ScorecardBlockTitle
          currentUser={props.currentUser}
          scorecard={props.scorecard}
          viewAllScorecards={props.viewAllScorecards}
        />
        {ratedOption ? (
          <Chip
            className={classNames(
              'fs-6 ms-0 text-truncate ms-1 px-3 me-3',
              styles[ratedOption.color],
            )}
          >
            <SingleScore score={ratedOption} logoAtStart={true} />
          </Chip>
        ) : (
          <Chip className='fs-6 ms-0 text-truncate ms-1 px-3 me-3 bg-gray text-primary'>
            <IconSpan icon={{ name: 'bi-clipboard-x' }} spanText='Not Rated' />
          </Chip>
        )}
        <AverageRating scorecard={props.scorecard} />
      </div>
      {props.scorecard.survey?.status === 'completed' &&
        props.scorecard.user.id === props.currentUser.id && (
          <SpanTooltip
            className='me-2'
            text={
              <a
                className={classNames(
                  'rounded-2',
                  'p-2',
                  styles['edit-scorecard'],
                )}
                onClick={() =>
                  (window.location.href = `/applicant_tracking/scorecards/${props.scorecard.id}`)
                }
              >
                <Icon name='bi-pencil' className='text-info fs-4' />
              </a>
            }
            tooltipText='Edit Scorecard'
          />
        )}
    </div>
  );
}
