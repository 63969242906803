import { DateFilter } from '../../../components/DateRangePicker';
import { SurveyMetrics } from '../../../entities/survey_engine/SurveyMetrics';
import { ApiService } from '../../ApiService';

export class SurveyMetricsService {
  public static get(
    filter_selector: string,
    job_names: string[],
    department_names: string[],
    hiring_manager_names: string[],
    recruiter_names: string[],
    location_names: string[],
    survey_template_ids: string[],
    benchmark_type: string,
    dateRange: DateFilter,
    ethnicities: string[],
    genders: string[],
    ageRanges: string[],
  ): Promise<SurveyMetrics> {
    return ApiService.get<SurveyMetrics>(
      '/api/v1/candidate_experience/analytics/survey_metrics',
      {
        filter_selector,
        job_names,
        department_names,
        hiring_manager_names,
        recruiter_names,
        location_names,
        survey_template_ids,
        benchmark_type,
        start_date: dateRange?.start?.toJSON(),
        end_date: dateRange?.end?.toJSON(),
        date_range: dateRange?.range,
        ethnicities: ethnicities,
        genders: genders,
        age_ranges: ageRanges,
      },
    );
  }
}
