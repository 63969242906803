import React from 'react';
import { Chip } from '../../../../../../../../components/Chip';
import { ListCandidateApplication } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { channelTypeLabel } from '../../../../../../../../utils/applicant_tracking/sources';

interface PropTypes {
  application?: ListCandidateApplication;
}

export function Channel(props: PropTypes) {
  if (props.application == null || props.application.source == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <div className='d-flex align-items-center'>
      <Chip className={'py-1 px-2h fs-6 fw-semibold text-primary bg-gray'}>
        {channelTypeLabel(props.application?.source?.channelType)}
      </Chip>
    </div>
  );
}
