import React, { useState } from 'react';
import { RejectedReasonService } from '../../../../services/v1/applicant_tracking/RejectedReasonService';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import {
  appendQueryParam,
  getRedirectUriQueryParam,
} from '../../../../utils/url';
import { RejectedByPills } from '../components/RejectedByPills';
import { FormHeader } from '../components/FormHeader';
import { ReasonsInput } from '../components/ReasonsInput';
import { Alert, AlertType } from '../../../../components/Alert';
import { IconSpan } from '../../../../components/IconSpan';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { RejectedBy } from '../../../../entities/v1/applicant_tracking/RejectedReason';
import { REJECTED_REASON_MAX_CHARACTERS } from '../NewRejectedReasonPage';

const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/reject_reasons';
const SUCCESSFUL_ALERT_MESSAGE = 'The reject reason was successfully updated!';
const DUPLICATES_FOUND_ALERT_MESSAGE =
  'This rejection reason already exists. Change the name before proceeding.';

interface PropTypes {
  id: number;
  name: string;
  rejectedBy: RejectedBy;
}

export default function EditRejectedReasonPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>([props.name]);
  const [rejectedBy, setRejectedBy] = useState<RejectedBy>(props.rejectedBy);
  const [duplicates, setDuplicates] = useState<string[]>([]);
  const [alertActive, setAlertActive] = useState<boolean>(false);

  function isSavable() {
    return (
      reasons.length > 0 &&
      reasons.every((reason) => reason.length > 0) &&
      duplicates.every((duplicate) => !reasons.includes(duplicate))
    );
  }

  async function handleSave() {
    setIsLoading(true);

    try {
      await RejectedReasonService.update(
        props.id,
        reasons[0].trim(),
        rejectedBy,
      );
      window.location.href = appendQueryParam(
        REDIRECT_URI,
        'flash',
        SUCCESSFUL_ALERT_MESSAGE,
      );
    } catch (error) {
      if (error.status !== 422) throw error;

      setDuplicates(reasons);
      setAlertActive(true);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable
          autoClearTimeout={4000}
          onClose={() => setAlertActive(false)}
        >
          <IconSpan
            spanText={DUPLICATES_FOUND_ALERT_MESSAGE}
            icon={{ name: 'bi-info-circle' }}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <div className='container'>
        <PageHeader title='Add Reject Reason' backUrl={REDIRECT_URI} />
        <Card className={classNames(styles['card'])}>
          <CardHeader
            title='Reject Reason'
            isSavable={isSavable()}
            onSaveClick={handleSave}
            cancelUrl={REDIRECT_URI}
          />
          <div className='fw-semibold fs-5 mt-4 mb-1'>Rejection type</div>
          <RejectedByPills
            rejectedBy={rejectedBy}
            setRejectedBy={setRejectedBy}
          />
          <FormHeader
            mandatory
            title='Rejection reason'
            constraint={`${reasons[0].length}/${REJECTED_REASON_MAX_CHARACTERS}`}
          />
          <ReasonsInput
            singleInput
            reasons={reasons}
            duplicates={duplicates}
            setReasons={setReasons}
          />
        </Card>
      </div>
    </>
  );
}
